import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CustomUserFields } from 'src/app/shared/utils';
import { UserType } from 'src/app/shared/enums/commonEnums';
import { COLLECTIONS } from 'src/app/shared/config';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsService {
  constructor(private db: AngularFirestore, private userService: UserService, private toastr: ToastrService) {}

  async getCustomFieldsOfId(uId) {
    if (!uId) {
      throw new Error('getCustomFieldsOfId: missing uId parameter');
    }
    const snapshot = await this.db.collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS).doc(uId).ref.get();
    return snapshot.data();
  }

  getCustomFields(user: any, editCustomField = false): Observable<any> {
    try {
      if (editCustomField) {
        return this.db
          .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS, (ref) => ref.where('uId', '==', user.userType === UserType.ADMIN ? user.superUserId : user.uid))
          .get()
          .pipe(
            map((d) => {
              return d.docs.map((x) => x.data());
            }),
            map((d) => d[0] as CustomUserFields)
          );
      }
      if (user.isEnterprise) {
        if (user.userType === UserType.SUPER_ADMIN || user.userType === UserType.ADMIN) {
          return this.db
            .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS, (ref) => ref.where('uId', '==', user.superUserId ?? user.uid))
            .get()
            .pipe(
              map((d) => {
                return d.docs.map((x) => x.data());
              }),
              map((d) => d[0] as CustomUserFields)
            );
        }
        if (user.userType === UserType.ADMIN) {
          return this.db
            .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS, (ref) =>
              ref.where('uId', 'in', [user.superUserId, user.managerId ?? user.uid])
            )
            .get()
            .pipe(
              map((d) => {
                return d.docs.map((x) => x.data());
              }),
              map((d: CustomUserFields[]) => {
                const temp: CustomUserFields[] = [];
                const id1 = user.superUserId;
                const id2 = user.managerId ?? user.uid;
                const cust_id1 = [...d].filter((c) => c.uId === id1);
                const cust_id2 = [...d].filter((c) => c.uId === id2);
                temp.push(...cust_id1, ...cust_id2);
                return temp;
              }),
              map((d: CustomUserFields[]) => {
                let data = [];
                if (d) {
                  data = [...d];
                }
                return (data).reduce((cf1, cf2) => {
                  return {
                    custom_fields_contacts: [...cf1.custom_fields_contacts, ...cf2.custom_fields_contacts],
                    custom_fields_transaction: [...cf1.custom_fields_transaction, ...cf2.custom_fields_transaction],
                    user_custom_fields_value: [...cf1.user_custom_fields_value, ...cf2.user_custom_fields_value],
                    uId: cf1.uId,
                  };
                });
              })
            );
        }
        return this.db
          .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS, (ref) =>
            ref.where('uId', 'in', [
              user.superUserId,
              ...(user.managerId ? [user.managerId] : []),
              user.teamLeaderId ?? user.uid,
            ])
          )
          .get()
          .pipe(
            map((d) => {
              return d.docs.map((x) => x.data());
            }),
            map((d: CustomUserFields[]) => {
              const temp: CustomUserFields[] = [];
              const id1 = user.superUserId;
              const id2 = user.managerId;
              const id3 = user.teamLeaderId ?? user.uid;
              const cust_id1 = [...d].filter((c) => c.uId === id1);
              const cust_id2 = [...d].filter((c) => c.uId === id2);
              const cust_id3 = [...d].filter((c) => c.uId === id3);

              temp.push(...cust_id1, ...cust_id2, ...cust_id3);
              return temp;
            }),
            map((d: CustomUserFields[]) => {
              let data = [];
              if (d) {
                data = [...d]
              }
              return data.reduce((cf1, cf2) => {
                return {
                  custom_fields_contacts: [...cf1.custom_fields_contacts, ...cf2.custom_fields_contacts],
                  custom_fields_transaction: [...cf1.custom_fields_transaction, ...cf2.custom_fields_transaction],
                  user_custom_fields_value: [...cf1.user_custom_fields_value, ...cf2.user_custom_fields_value],
                  uId: cf1.uId,
                };
              });
            })
          );
      }

      return this.db
        .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS, (ref) => ref.where('uId', '==', user.parentId ?? user.uid))
        .get()
        .pipe(
          map((d) => {
            return d.docs.map((x) => x.data());
          }),
          map((d: CustomUserFields[]) => {
            return d[0];
          })
        );


    } catch (error) {
       console.error(error)
       return of(null);
    }
  }

  async saveCustomFields(
    uid: any,
    data: {
      custom_fields_contacts: any[];
      custom_fields_transaction: any[];
      user_custom_fields_value: any[];
      uId: string;
    },
    userType?: string
  ): Promise<any> {
    return this.db
      .collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS)
      .doc(uid)
      .set({ ...data });
  }

  async purchasePlan(payload: { planName: string; no_of_fields: number }) {
    try {
      const response: { error: boolean; msg: string } = await (<Promise<{ error: boolean; msg: string }>>(
        this.userService.chargeCustomFields(payload)
      ));
      if (response.error) {
        this.toastr.error(response.msg);
      } else {
        this.toastr.success(response.msg.split('cf_tier_').join('tier '));
        return true;
      }
      return false;
    } catch (error) {
      this.toastr.error(error.error.msg);
      return false;
    }
  }

  getSuperUserCustomFields(superUserId: string) {
    return this.db.collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS).doc(superUserId).valueChanges();
  }

  getManagerCustomFields(managerId: string) {
    return this.db.collection<any>(COLLECTIONS.CUSTOM_USER_FIELDS).doc(managerId).valueChanges();
  }
}
