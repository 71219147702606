<div
  *ngIf="isMilestonesBlocked()"
  class="milestones-notification-container truncate"
  #warningNotification
  (window:resize)="isEllipsisActive(warningNotification)"
>
    <span
      class="milestones-notification-content position-relative"
      popoverClass="popover-class"
      placement="bottom-left"
      triggers="mouseenter:mouseleave"
      [ngbPopover]="milestonesDisabledWarningPopover"
      [disablePopover]="!showPopup"
      #p="ngbPopover"
    >
      <span class="milestones-warning-icon">
        <img src="../../../../assets/icons/warning-solid.svg" alt="" />
      </span>
      <span class="color-black-grey-text d-lg-inline d-none milestone-notification">Milestones are disabled. Automated actions triggered by a milestone status
        change will not occur until milestones are enabled. Drip campaigns and one-off communications are not affected.
        Go to Settings > Milestones to enable.</span>
    </span>
</div>
<ng-template #milestonesDisabledWarningPopover>
  <div class="m-2">
    <div class="font-12 milestones-z-index">Milestones are disabled. Automated actions triggered by a milestone status
      change will not occur until milestones are enabled. Drip campaigns and one-off communications are not affected.
      Go to Settings > Milestones to enable.</div>
  </div>
</ng-template>
