<form>
  <div class="px-2 pb-3 v2-ui-integretion">
    <div class="modal-header pb-0">
      <h4 class="color-grey-text text-uppercase px-2">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <i class="material-icons icon-color"> close </i>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex flex-column">
        <div class="">
          <p class="">Your new mail id is verified.&nbsp; Are you sure to Continue?</p>
          <div class="">
            <p class="">Clicking yes will change your email and you will be logged out from the session.</p>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-3 bottom-btn">
          <button type="button" class="btn btn-normal mr-2 px-4" (click)="closeModal()">No</button>
          <button *ngIf="!loading" type="button" class="btn btn-primary px-4" (click)="changeEmail()">Yes</button>
          <button *ngIf="loading" [disabled]="loading" class="btn btn-normal px-4">
            Yes<span
              [ngClass]="{
                nil: !loading
              }"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
