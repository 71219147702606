
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, merge } from 'rxjs';
import { map, mergeMap, catchError, switchMap, withLatestFrom, takeUntil, delay, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { AppService } from 'src/app/shared/app.service';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { DripCampaignService } from '../services/drip-campaign.service';
import * as DripStateActions from './action';
import { SettingsService } from '../../settings/services';

@Injectable()
export class DripEffects {
  constructor(
    private dripservice: DripCampaignService,
    private actions$: Actions,
    private router: Router,
    private settingsService: SettingsService,
    private store$: Store<State>,
    private appService: AppService,
    private subService: SubscriptionService
  ) {}

  ListDrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.ListDrip),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.dripservice.getcampaignList(payload.action.id, payload.state.login.currentUser, payload.action.no_limit)
        ).pipe(
          take(1),
          takeUntil(this.subService.unsubscribe$),
          map((list) => {
            this.appService.showLoading(false);
            return DripStateActions.ListDripSuccess({ data: list });
          }),
          catchError((err) => {
            this.appService.showLoading(false);
            return of(DripStateActions.ListDripError({ msg: err.message }));
          })
        );
      })
    )
  );

  AddDrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.AddDrip),
      switchMap((payload) =>
        from(
          this.dripservice.campaignAdd(
            payload.data,
            payload.id,
            payload.date,
            payload.deleted_steps,
            payload.pushDripObj,
            payload.logData
          )
        ).pipe(
          delay(3000),
          switchMap((_) => {
            this.router.navigate(['/drip-campaign']);
            return merge(
              of(DripStateActions.AddDripSuccess()),
              of(DripStateActions.ResetDemandScrollData()),
              of(DripStateActions.GetDripsList({ data: { curPage: 1 } }))
            );
          }),
          catchError((error) => {
            return of(DripStateActions.AddDripError({ msg: error.message }));
          })
        )
      )
    )
  );

  EditDrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.EditDrip),
      switchMap((payload) =>
        from(this.dripservice.editDrip(payload.id)).pipe(
          map((_) => DripStateActions.EditDripSuccess()),
          catchError((error) => of(DripStateActions.EditDripError({ msg: error.message })))
        )
      )
    )
  );

  DeleteDripDteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.DeleteDripStep),
      switchMap((payload) =>
        from(this.dripservice.deleteDripStep(payload.id)).pipe(map((_) => DripStateActions.DeleteDripStepSuccess()))
      )
    )
  );

  FetchDripsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.GetDripsCount),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(this.dripservice.fetchCount(payload.state.login.currentUser, payload.state.drip.onlyMe)).pipe(
          map((data) => {
            return DripStateActions.SaveDripsCount({ count: data['count'], active: data['activeCount'] });
          })
        )
      )
    )
  );

  FetchDrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.GetDripsList),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.dripservice.fetchDrips(
            { curPage: payload.state.drip.curPage },
            payload.state.login.currentUser,
            payload.state.drip.onlyMe
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return DripStateActions.ListDripSuccess({ data: { list: data['data'], count: data['count'] } });
          })
        );
      })
    )
  );

  SearchDrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.SearchDrip),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.dripservice.searchDrip(payload.action.data, payload.state.login.currentUser, payload.state.drip.onlyMe)
        ).pipe(
          map((data) => {
            return DripStateActions.ListDripSuccess({ data: { list: data['data'], count: data['count'] } });
          })
        )
      )
    )
  );

  FilterTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.FilterDrip),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.dripservice.filterDrips(payload.action.data, payload.state.login.currentUser, payload.state.drip.onlyMe)
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return DripStateActions.ListDripSuccess({
              data: { list: data['data'], count: data['count'], active: data['activeCount'] },
            });
          })
        );
      })
    )
  );

  SortDrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.SortDrip),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.dripservice.sortDrip(payload.action.data, payload.state.login.currentUser, payload.state.drip.onlyMe)
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return DripStateActions.ListDripSuccess({ data: { list: data['data'], count: data['count'] } });
          })
        );
      })
    )
  );

  ListSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.ListSources),
      switchMap((payload) => {
        return from(this.settingsService.getSources(payload.user, true)).pipe(
          map((list) => {
            return DripStateActions.ListSourcesSuccess({ data: list });
          }),
          catchError((err) => {
            return of(DripStateActions.ListSourcesError({ msg: err.message }));
          })
        );
      })
    )
  );

  ListDripWithPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.ListDripWithDemandScroll),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(this.dripservice.fetchDripsDemandScroll(payload.action, payload.state.drip.last_doc)).pipe(
          switchMap((drip: any[]) => {
            return merge(
              of(
                DripStateActions.ListDripWithDemandScrollSuccess({
                  data: drip,
                  curPage: payload.action.data && payload.action.data.curPage ? payload.action.data.curPage : null,
                  search: payload.action.search ? payload.action.search : null,
                })
              )
            );
          }),
          catchError((error) => {

            return of(DripStateActions.ListDripWithDemandScrollError({ data: error.message }));
          })
        );
      })
    )
  );

  ListDripWithPaginationMongo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.ListDripWithDemandScrollMongo),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(this.dripservice.fetchDripsDemandScrollMongo(payload.action)).pipe(
          switchMap((response: any) => {
            return merge(
              of(
                DripStateActions.ListDripWithDemandScrollMongoSuccess({
                  data: response.data ?? [],
                  curPage: payload.action.curPage,
                })
              )
            );
          }),
          catchError((error) => {
            return of(DripStateActions.ListDripWithDemandScrollMongoError({ data: error.message }));
          })
        );
      })
    )
  );

  SearchForJlo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DripStateActions.SearchForJlo),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(this.dripservice.SearchForJlo(payload.action.data)).pipe(
          map((data) => {
            if (payload.action.data) {
              return DripStateActions.JloSearchSuccess({
                data: data['data'],
              });
            }
          })
        );
      })
    )
  );
}
