import { Component, OnInit, Input } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router } from '@angular/router';
import * as braintreeDropIn from 'braintree-web-drop-in'

@Component({
  selector: 'dailyai-popup-payment',
  templateUrl: './popup-payment.component.html',
  styleUrls: ['./popup-payment.component.scss'],
})
export class PopupPaymentComponent implements OnInit {
  @Input() public customerId;

  @Input() public fromWhere;

  @Input() public mandatoryAdd = false;

  @Input() public user;

  error: string;

  customer_Id: string;

  paymentMessage = '';

  isLoading = false;

  page = ' ';

  braintreeInstance: any;

  braintreeInstanceTorndown = false;

  constructor(
    private paymentService: PaymentService,
    public ngbModal: NgbModal,
    public activeModal: NgbActiveModal,
    private toast: ToastrService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (this.customerId) {
      this.customer_Id = this.customerId;
    }
    if (this.fromWhere) {
      this.page = this.fromWhere;
    }
    await this.setupBraintreeUI()
  }

  async setupBraintreeUI() {
    const clientToken = await this.paymentService.getBraintreeClientToken();

    this.braintreeInstance = await braintreeDropIn.create({
      authorization: clientToken,
      container: '#dropin-container',
      vaultManager: false,
      card: {
        cardholderName: {
          required: true
        },
      },
    });

    this.braintreeInstance.on('paymentMethodRequestable', (event) => {
      (document.querySelector('#add-braintree-card') as HTMLElement).removeAttribute('disabled');
    });

    this.braintreeInstance.on('noPaymentMethodRequestable', (event) => {
      (document.querySelector('#add-braintree-card') as HTMLElement).setAttribute("disabled", "");
    });
  }

  async addCardBraintree() {
    try {
      this.isLoading = true;
      this.paymentMessage = 'Please wait...';
      const paymentMethodNonce = await this.braintreeInstance.requestPaymentMethod();

      if (this.mandatoryAdd || (this.user?.isEnterprise && this.page === 'unlimited_call_button')) {
        await this.paymentService.createBraintreeCustomer(this.user, paymentMethodNonce);
        this.toast.success('Card Added Successfully');
        this.isLoading = false;
        this.sharedService.addCardLayout({ stat: true });
        await this.braintreeInstance.teardown();
        this.braintreeInstanceTorndown = true;
        this.ngbModal.dismissAll();
      } else {
        await this.paymentService.addBraintreeCard(this.customerId, paymentMethodNonce, this.page);
        this.toast.success('Card Added Successfully');
        this.isLoading = false;
        this.sharedService.addCardLayout({ stat: true });
        await this.braintreeInstance.teardown();
        this.braintreeInstanceTorndown = true;
        this.ngbModal.dismissAll();
      }
    } catch (error) {
      console.warn(error);
      this.isLoading = false;
      await this.braintreeInstance.teardown();
      this.toast.error(
        error?.error?.msg === 'Do Not Honor'
          ? 'Card Verification Failed'
            : 'Unable to Add Card'
      );
      await this.setupBraintreeUI();
      (document.querySelector('#add-braintree-card') as HTMLElement).setAttribute("disabled", "");
    }
  }

  signOut() {
    this.ngbModal.dismissAll();
    this.router.navigate(['/logout']);
  }

  ngOnDestroy() {
    if (!this.braintreeInstanceTorndown) {
      this.braintreeInstance.teardown();
    }
  }
}
