import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-email-body',
  templateUrl: './email-body.component.html',
  styleUrls: ['./email-body.component.scss'],
})
export class EmailBodyComponent implements OnInit {
  emailContent;
  
  ckeConfigReadOnly = {
    title: '',
    autoParagraph: false,
    contentsCss: `
    .cke_top {
      display: none !important;
    },`,
    toolbar: [{ name: 'empty', items: [] }],
    removePlugins: 'elementspath',
    resize_enabled: false,
    allowedContent: true,
  };

  viewEditorLoaded = false;

  emptyValue = '';

  constructor(public modal: NgbModal) {}

  ngOnInit() {}

  viewEditorready() {
    this.viewEditorLoaded = true;
  }

  urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="${  url  }" target="_blank" class="urlmms" style="color: #fff;">${  url  }</a>`;
    });
  }

  @Input()
  set emailData(data) {

    if (data) {
      this.emailContent = data;
    }
  }
}
