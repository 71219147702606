import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public unsubscribeComponent$ = new Subject<void>();

  public unsubscribe$ = this.unsubscribeComponent$.asObservable();

  public init() {
    this.unsubscribeComponent$ = new Subject<void>();
    this.unsubscribe$ = this.unsubscribeComponent$.asObservable();
  }

  public logOut() {
    this.unsubscribeComponent$.next();
    this.unsubscribeComponent$.complete();
  }
}
