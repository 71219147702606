import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DripState } from './state';

export const selectDripState = createFeatureSelector<DripState>('drip');
export const selectDripList = createSelector(selectDripState, (dripState) => dripState.DripList);
export const selectDripListSuccess = createSelector(selectDripState, (dripState) => dripState.DripListSuccess);
export const selectDripListError = createSelector(selectDripState, (dripState) => dripState.DripListError);
export const selectDripDeleteSuccess = createSelector(selectDripState, (dripState) => dripState.DripDeleteSuccess);
export const getDripsCount = createSelector(selectDripState, (dripState) => dripState.count);

export const selectSourcesList = createSelector(selectDripState, (dripState) => dripState.ListSources);
export const LoadDripList = createSelector(selectDripState, (dripState) => dripState.LoadDrip);
export const activeDrips = createSelector(selectDripState, (dripState) => dripState.active);

export const selectDripWithDemand = createSelector(selectDripState, (dripState) => dripState.DripListWithDemand);
export const selectDripWithDemandMongo = createSelector(
  selectDripState,
  (dripState) => dripState.DripListWithDemandMongo
);
export const selectjloSearchResult = createSelector(selectDripState, (dripState) => dripState.jlosearchResults);
