import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : Number.parseInt(date[1], 10),
        month : Number.parseInt(date[0], 10),
        year : Number.parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if(!date) {
      return '';
    }

    const monthStr = `${date.month}`.padStart(2, '0');
    const dayStr = `${date.day}`.padStart(2, '0');
    return `${monthStr}${this.DELIMITER}${dayStr}${this.DELIMITER}${date.year}`;
  }
}
