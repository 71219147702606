import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaService } from '../../services/getMedia.service';
import { MediaAudioListComponent } from '../media-audio-list/media-audio-list.component';

@Component({
  selector: 'dailyai-media-audio-select-from',
  templateUrl: './media-audio-select-from.component.html',
  styleUrls: ['./media-audio-select-from.component.scss']
})
export class MediaAudioSelectFromComponent implements OnInit, OnDestroy {
  @Output() private selectedSystemMediaFile = new EventEmitter();

  dripTyp = '';

  value;

  frmWhere = '';

  modalRef: NgbModalRef

  @Output() private close = new EventEmitter()

  ngUnsubscribe: Subject<any> = new Subject<any>();

  url = '';

  campaignStepIndex: string;

  constructor(private modalService: NgbModal, private mediaSvc: MediaService, private router: Router) { }

  ngOnInit() {
    this.url = this.router.url.split('/')[1];

  }

  openMediaAudioList() {
    this.modalRef = this.modalService.open(MediaAudioListComponent, {
      centered: true,
      windowClass: 'media-drip-popup',
      backdrop: 'static',
      keyboard: true
    });
    this.modalRef.componentInstance.user = this.dripTyp;
    (this.modalRef.componentInstance.close as Observable<any>).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      x && x === "close" && this.modalRef.close()
      if (x && x === 'close-all') {
        this.close.emit('close all');
        this.modalRef.close();
      }
    })


  }

  uploadFile(event) {
    this.mediaSvc.sendSelected({ ...event.target.files, frmWhere: this.frmWhere, dripStepIndex: this.campaignStepIndex, dripType: this.dripTyp });
    this.close.emit('close all');


  }

  closeSelectModal() {
    this.close.emit('close all');
  }

  @Input() set dripType(driptype: string) {
    this.dripTyp = driptype;
  }

  @Input() set dripStepIndex(index: string) {
    this.campaignStepIndex = index;
  }

  @Input() set fromWhere(fromWhere: string) {
    this.frmWhere = fromWhere;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
