import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, merge } from 'rxjs';
import { switchMap, catchError, withLatestFrom, tap, takeUntil, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import * as EnterpriseActions from './actions';
import { TeamsService } from '../teams.service';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private teamsService: TeamsService,
    private toastr: ToastrService,
    private subservice: SubscriptionService
  ) {}

  GetTeamsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetTeamsList),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        const cu = payload.state.login.currentUser;
        return from(
          this.teamsService.getTeamsList({
            ...payload.action.payload,
            enterpriseId: cu.enterpriseId ?? cu.parentId ?? cu.uid,
            id: cu.uid,
            userType: cu.userType,
          })
        ).pipe(
          take(1),
          switchMap((teamList) =>
            of(EnterpriseActions.GetTeamsListSuccess({ data: teamList.data, count: teamList.count }))
          ),
          catchError((err) => of(EnterpriseActions.GetTeamsListError({ data: err })))
        );
      })
    )
  );

  GetTeamData = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetTeamData),
      switchMap((payload) =>
        this.teamsService.getTeamData(payload.teamId).pipe(
          takeUntil(this.subservice.unsubscribe$),
          switchMap((t) => of(EnterpriseActions.GetTeamDataSuccess({ data: t }))),
          catchError((err) => of(EnterpriseActions.GetTeamDataError({ data: err })))
        )
      )
    )
  );

  GetEnterpriseUsersList = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetEnterpriseUsersList),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        const cu = payload.state.login.currentUser;
        return from(
          this.teamsService.getUsersList({
            enterpriseId: cu.enterpriseId ?? cu.parentId ?? cu.uid,
            id: cu.uid,
            userType: cu.userType,
            ...payload.action.data,
          })
        ).pipe(
          take(1),
          switchMap((resp) => {
            if (payload.action.forTeam) {
              return of(EnterpriseActions.GetTeamUsersListSuccess({ data: resp.list, count: resp.count }));
            }
              return of(
                EnterpriseActions.GetEnterpriseUsersListSuccess({ data: resp.list, count: resp.count })
              );

          }),
          catchError((error) => {
            if (payload.action.forTeam) {
              return of(EnterpriseActions.GetTeamUsersListError({ data: error }));
            }
              return of(EnterpriseActions.GetEnterpriseUsersListError({ data: error }));

          })
        );
      })
    )
  );

  GetEnterpriseUsersTeamNameAndAddedBy = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedBy),
      switchMap((payload) =>
        from(this.teamsService.getUsersTeamNameAndAddedBy(payload)).pipe(
          takeUntil(this.subservice.unsubscribe$),
          switchMap((data) => of(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedBySuccess({ data }))),
          catchError((err) => of(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedByError({ data: err })))
        )
      )
    )
  );

  GetEntepriseListDemand = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetEnterpriseUserListDemand),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        const cu = payload.state.login.currentUser;
        return from(
          this.teamsService.getUsersList({
            enterpriseId: cu.enterpriseId ?? cu.parentId ?? cu.uid,
            userType: payload.action.data.id ? 'Super Admin' : cu.userType,
            id: payload.action.data.id ? payload.action.data.id : cu.uid,
            ...payload.action.data,
            limit: 5,
          })
        ).pipe(
          take(1),
          switchMap((resp) => of(EnterpriseActions.GetDemandListSuccess({ data: resp.list })))
        );
      }),
      catchError((err) => of(EnterpriseActions.GetDemandListError()))
    )
  );

  GetTeamsListDemand = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.GetTeamsListDemand),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        const cu = payload.state.login.currentUser;
        return from(
          this.teamsService.getTeamsList({
            ...payload.action.data,
            limit: 5,
            enterpriseId: cu.enterpriseId ?? cu.parentId ?? cu.uid,
            id: cu.uid,
            userType: cu.userType,
          })
        ).pipe(
          take(1),
          switchMap((resp) => of(EnterpriseActions.GetTeamsDemandListSuccess({ data: resp.data })))
        );
      }),
      catchError((err) => of(EnterpriseActions.GetTeamsDemandListError()))
    )
  );

  updateTeam = createEffect(() =>
    this.actions$.pipe(
      ofType(EnterpriseActions.updateTeam),
      switchMap((payload) =>
        from(this.teamsService.updateTeam(payload.data)).pipe(
          tap(() => !payload.skipToast && this.toastr.success('Team Updated Successfully')),
          switchMap(() => {
            return merge(
              of(EnterpriseActions.GetTeamData({ teamId: payload.data.teamId }))
            );
          })
        )
      )
    )
  );
}
