<div class="media-layout pb-3 pt-4 v2-ui-integretion" [ngClass]="{
    'drip-media-height': url === 'drip-campaign',
    'drip-media-height-settings': url === 'settings'
  }">
  <div class="d-flex justify-content-center align-items-center no-files" *ngIf="videoList.length == 0">
    <span class="font-16 color-black-text">There are no recorded files</span>
  </div>
  <div class="d-flex flex-wrap">
    <div class="video-card d-flex flex-column mb-2" *ngFor="let items of videoList| filter: searchText; let i = index"
      [ngClass]="{
        'col-md-4 col-12': url === 'drip-campaign',
        'col-md-3 col-12': url !== 'drip-campaign'
      }">
      <div class="box-card shadow cursor-pointer position-relative" (click)="$event.stopPropagation();selectVideo(i, items)" id="videoSelect">
        <img class="w-100" [src]="items.thumbnail_actual ?items.thumbnail_actual: items.thumbnail" alt="" /> <span
          *ngIf="!items.thumbnail">loading</span>
        <div class="video-select" [style.display]="displayNone[i] === 1 ? 'block' : 'none'">
          <i class="material-icons"> check_circle </i>
        </div>
        <div class="overlay-img d-flex justify-content-center align-items-center">
          <i class="material-icons"
            (click)=" $event.stopPropagation();videoPlayModel(items.url, items.thumbnail_actual ? items.thumbnail_actual : items.thumbnail); $event.stopPropagation()">
            play_circle_filled
          </i>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center py-2 video-details">
        <span class="font-14 text-capitalize">{{ items.file_name }}</span>
        <span class="font-12 text-capitalize">{{ items.duration }}</span>
      </div>
    </div>
  </div>
</div>