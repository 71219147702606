import { User } from 'src/app/models/user.model';
import { selectUserList, selectChildList } from 'src/app/modules/settings/store/selector';
import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { NgbTypeahead, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { AuthService } from 'src/app/services/auth.service';
import { GetChildList } from 'src/app/modules/settings/store/action';
import { ToastrService } from 'ngx-toastr';
import { timeZonesMap } from 'src/app/shared/utils';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { ContactService } from '../../../modules/contacts/services/contact.service';
import { GetContactDetail, GetMySLO, SearchContact } from '../../../modules/contacts/store/action';
import { ContactDetail } from '../../../modules/contacts/store/models';
import { selectGetDetailOfContact, selectMySLO, selectSearchContacts } from '../../../modules/contacts/store/selector';
import { AddTaskService } from '../../../modules/contacts/services/create-task.service';
import { selectCurrentUser } from '../../../modules/login/store/selectors';
import { SharedService } from '../../services/shared.service';

interface TaskPayload {
  assignedto: any;
  task: string;
  transactionid: any;
  dueTime: any;
  assignedby: any;
  taskStatus: string;
  subject: string;
  note: string;
  customTask?: string;
  taskId?: string;
  visibility: boolean;
  yyyymmdd: number;
  setReminder: boolean;
  setReminderdate: any;
  setRemindertime: string;
  reminderTimestamp: any;
  notified?: boolean;
  toCalendar?: boolean;
}
@Component({
  selector: 'dailyai-share-add-task',
  templateUrl: './share-add-task.component.html',
  styleUrls: ['./share-add-task.component.scss'],
})
export class ShareAddTaskComponent implements OnInit, OnDestroy {
  tasks = ['call', 'message', 'mail', 'other'];

  transactionStatuses = ['completed', 'postponed', 'on hold'];

  transactionIds = [];

  forContact: any;

  touched1 = false;

  touched2 = false;

  assignedto: User;

  assignedby: User;

  duedate = new Date();

  users: User[] = [];

  invalidTime = false;

  public today = new Date();

  tempForContact: any = {};

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  @ViewChild('instance2', { static: true }) instance2: NgbTypeahead;

  @Output() taskPerformData: EventEmitter<any> = new EventEmitter<any>();

  focus$ = new Subject<{ val: string; fromWhere: string }>();

  click$ = new Subject<string>();

  usersList$: Observable<any>;

  childList$: Observable<any>;

  contactList$: Subject<any> = new Subject();

  contactList: any[];

  currentUser: any;

  customtask = false;

  dateSplit: any[];

  @Input() contactid = '';

  @Input() withContact = false;

  @Input() dateStr = '';

  contactDetails: ContactDetail;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  time;

  timepicked = false;

  assignderr = false;

  contactnameerr = false;

  MySLO$: Observable<any>;

  mySLO: any;

  setRemainder = false;

  currentTask: any;

  toCalendar = false;

  addForm = this.fb.group({
    task: [null, Validators.required],
    customTask: [''],
    duedate: ['', Validators.required],
    assignedTo: ['', Validators.required],
    assignedContact: [''],
    setReminder: [false],
    setReminderdate: [''],
    setRemindertime: [''],
    reminderTimestamp: '',
    transactionid: [null],
    subject: ['', [Validators.required, Validators.pattern('^(?! ).+')]],
    note: [''],
    toCalendar: [false],
  });

  show = true;

  remain_check = false;

  url = '';

  reminderTimestamp: any;

  fromCalenderValue = false;

  setToCalendarValue = false;

  onlyClickContact = false;

  enableCalender = false;

  showAll = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private store: Store<any>,
    private taskService: AddTaskService,
    private atp: AmazingTimePickerService,
    private auth: AuthService,
    private toast: ToastrService,
    private contactService: ContactService,
    private router: Router,
    private sharedService: SharedService
  ) {
    Date.prototype.yyyymmdd = function (date?) {
      if (date) {
        const d = date.toString();
        this.setYear(d[0] + d[1] + d[2] + d[3]);
        this.setMonth(d[4] + d[5], 1);
        this.setDate(d[6] + d[7]);
        return this;
      }
      return this.getFullYear() * 10000 + this.getMonth() * 100 + this.getDate();
    };
    this.sharedService
      .getFeatureFlag('calendar-ui')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: any) => {
        this.enableCalender = !val ? true : val.enabled;
      });
  }

  ngOnInit() {
    this.url = this.router.url.split('/')[1];
    if (this.dateStr) {
      this.withContact = true;
      this.dateSplit = this.dateStr.split('-');
      this.addForm.patchValue({
        duedate: {
          year: Number(this.dateSplit[0]),
          month: Number(this.dateSplit[1]),
          day: Number(this.dateSplit[2]),
        },
      });
    }

    if (this.addForm.value.setReminder) {
      this.remain_check = true;
    }
    if (this.addForm.value.setRemindertime) {
      this.timepicked = true;
    }
    this.addForm
      .get('task')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((task) => {
        if (task === 'other') {
          this.customtask = true;
          this.addForm.get('customTask').setValidators([Validators.required]);
          this.addForm.get('customTask').updateValueAndValidity();
        } else {
          this.customtask = false;
          this.addForm.get('customTask').clearValidators();
          this.addForm.get('customTask').updateValueAndValidity();
        }
      });
    if (this.withContact === true) {
      this.store.pipe(select(selectSearchContacts), takeUntil(this.ngUnsubscribe)).subscribe((list) => {
        this.contactList = list;
        if (this.instance?.isPopupOpen()) {
          this.instance2?.dismissPopup();
        }
        this.contactList$.next(list);
      });
    }
    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectGetDetailOfContact)).subscribe((detail) => {
      this.contactDetails = detail;
    });
    this.usersList$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectUserList));
    this.childList$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectChildList));
    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectCurrentUser)).subscribe((data) => {
      if (data && data.uid) {
        this.currentUser = data;
        this.setRemainderFlag(this.currentUser.uid);
        if (!this.currentTask) {
          this.assignedto = data;
          this.assignedby = data;
        }
        if (data.parentId) {
          this.store.dispatch(GetMySLO({ id: data.parentId }));
        }
        const parentId = data.parentId ? data.parentId : data.uid;
        this.store.dispatch(GetChildList({ parentId }));
      }
    });
    this.MySLO$ = this.store.pipe(select(selectMySLO));
    this.MySLO$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((slos) => {
      if (slos) {
        const slo = slos[0];
        if (slo) {
          const existing = this.users.findIndex((u) => {
            return u.uid === slo.uid;
          });
          if (existing < 0) {
            this.mySLO = slo;
            this.users.push(slo);
          }
        }
      }
      this.users = _.uniq(this.users, (x) => x.uid);
    });
    this.childList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((children) => {
      if (this.currentUser) {
        this.users = this.currentUser.parentId ? [...children] : [...children, this.currentUser];
        if (this.mySLO) {
          this.users.push(this.mySLO);
        }
      }
      this.users = _.uniq(this.users, (x) => x.uid);
    });
  }

  onSubmit() {
    const payload = this.getFormattedPayload();
    if (!this.isFormValid()) {
      this.addForm.markAllAsTouched();
      this.touched1 = true;
      this.touched2 = true;
      return;
    }
    if (this.addForm.invalid || (!this.timepicked && this.remain_check)) {
      this.addForm.markAllAsTouched();
      this.touched1 = true;
      this.touched2 = true;
      return;
    }
    if (typeof this.assignedto === 'object' && this.isFormValid()) {
      if (this.withContact === true && typeof this.forContact === 'object') {
        this.forContact = this.addForm.get('assignedContact').value;
        this.taskService.createTask(payload, this.forContact);
      } else {
        this.taskService.createTask(payload, this.addForm.get('assignedContact').value);
      }
      this.activeModal.dismiss();
    }
  }

  async updateTask() {
    const { task_id } = this.currentTask;
    if (this.addForm.invalid || (!this.timepicked && this.remain_check)) {
      this.addForm.markAllAsTouched();
      this.touched1 = true;
    } else {
      const payload = this.getFormattedPayload();
      payload.taskId = this.currentTask.task_id;
      if (this.forContact || this.contactDetails) {
        const result = await this.taskService.updateCurrentTask(
          payload,
          this.contactDetails ? this.contactDetails : this.forContact
        );
      } else {
        const result = await this.taskService.updateCurrentTask(payload, false);
      }
      this.toast.success('Task Updated Successfully');
      this.activeModal.dismiss();
      const dataToPerform = {
        ...payload,
        contactId: this.contactDetails ? this.contactDetails.doc_id : null,
        contactName: this.contactDetails ? `${this.contactDetails.firstName} ${this.contactDetails.lastName}` : null,
        phoneNumber:
          this.contactDetails && this.contactDetails.phoneNumber
            ? this.contactDetails && this.contactDetails.phoneNumber
            : null,
        task_id,
        email: this.contactDetails && this.contactDetails.email ? this.contactDetails.email : null,
        contact_assigned_to:
          this.contactDetails && this.contactDetails.contact_assigned_to
            ? this.contactDetails.contact_assigned_to
            : null,
      };
      if (this.fromCalenderValue && this.contactDetails && this.onlyClickContact) {
        this.taskPerformData.emit(dataToPerform);
      }
    }
  }

  async setRemainderFlag(id) {
    const aiDetails = await this.taskService.checkAiNotification(id);
    if (aiDetails && aiDetails.taskNotification) {
      this.setRemainder = true;
    }
  }

  cancel() {
    this.activeModal.dismiss('cancel button');
  }

  showValidator() {
    this.show = !this.show;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.showAll = false;
      })
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(() => !this.instance2?.isPopupOpen())
    );
    const inputFocus$ = this.focus$.pipe(
      filter((val) => val.fromWhere === 'assignedto'),
      map((val) => val.val),
      tap(() => {
        this.showAll = true;
      })
    );
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(() => !this.instance2?.isPopupOpen()),
      map((term) => {
        this.users?.sort((a, b) => {
          if (a.firstName > b.firstName) return 1;
          return -1;
        });
        if (term === '' || this.showAll) {
          return this.users || [];
        }
        this.assignderr = false;
        return this.users
          .filter((v) => `${v.firstName} ${v.lastName}${v.email}`.toLowerCase().includes(term.toLowerCase()))
          .slice(0, 10);
      })
    );
  };

  searchContact = (text$: Observable<string>) => {
    let term = '';
    text$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(200),
        distinctUntilChanged(),
        tap((sTerm) => (term = sTerm))
      )
      .subscribe(this.searchContacts.bind(this));
    const inputFocus$ = this.focus$.pipe(filter((val) => val.fromWhere === 'assignedContact'));
    const clicksWithClosedPopup$ = this.click$.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(() => !this.instance?.isPopupOpen())
    );
    return merge(this.contactList$, inputFocus$, clicksWithClosedPopup$).pipe(
      distinctUntilChanged(),
      map((trigger) => {
        if (term === '') {
          return [];
        }
        this.contactnameerr = false;
        const ss = this.contactList
          .filter((v) => `${v.firstName} ${v.lastName}${v.email}`.toLowerCase().includes(term.toLowerCase()))
          .slice(0, 10);
        return ss;
      })
    );
  };

  formatter(x: User | any) {
    return `${x.firstName} ${x.lastName}`;
  }

  getFormattedPayload() {
    if (this.addForm.value.setReminderdate && this.timepicked) {
      this.reminderTimestamp = this.generateDateWithTimeZone(
        this.addForm.value.setReminderdate.day,
        this.addForm.value.setReminderdate.month,
        this.addForm.value.setReminderdate.year,
        this.time.slice(0, 2),
        this.time.slice(3, 5),
        this.currentUser.timezone
      );
    }
    const payload: TaskPayload = {
      assignedto: this.assignedto,
      task: this.addForm.value.task,
      transactionid: this.addForm.value.transactionid,
      dueTime: this.getDate(this.addForm.value.duedate),
      yyyymmdd: new Date(this.getDate(this.addForm.value.duedate)).yyyymmdd(),
      assignedby: this.assignedby,
      setReminder: this.remain_check,
      setReminderdate: this.addForm.value.setReminderdate ? this.addForm.value.setReminderdate : '',
      setRemindertime: this.time ? this.time : '',
      reminderTimestamp: this.reminderTimestamp ? this.reminderTimestamp : '',
      taskStatus: 'open',
      visibility: true,
      subject: this.addForm.value.subject,
      note: this.addForm.value.note,
      toCalendar: this.setToCalendarValue ? this.setToCalendarValue : this.addForm.value.toCalendar,
    };
    if (payload.setReminder) {
      payload.notified = false;
    }
    payload.customTask = this.customtask ? this.addForm.value.customTask : null;
    if (this.currentTask) {
      const tempPayload = {
        assignedto: payload.assignedto && payload.assignedto.uid ? payload.assignedto.uid : null,
        assignedby: payload.assignedby && payload.assignedby.uid ? payload.assignedby.uid : null,
        task: payload.task,
        dueTime: payload.dueTime,
        subject: payload.subject && payload.subject.length > 0 ? payload.subject.length : null,
      };
      const currentPayload = {
        assignedto:
          this.currentTask.assignedto && this.currentTask.assignedto.uid ? this.currentTask.assignedto.uid : null,
        assignedby:
          this.currentTask.assignedby && this.currentTask.assignedby.uid ? this.currentTask.assignedby.uid : null,
        task: this.currentTask.task,
        dueTime: this.currentTask.dueTime,
        subject: this.currentTask.subject && this.currentTask.subject.length > 0 ? this.currentTask.subject : null,
      };
      if (JSON.stringify(tempPayload) === JSON.stringify(currentPayload)) {
        payload.taskStatus = this.currentTask.taskStatus ? this.currentTask.taskStatus : 'open';
      }
    }
    return payload;
  }

  getDate(dateobj: { year: number; month: number; day: number }) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let dueTime = null;
    if (dateobj) {
      dueTime = moment()
        .tz(timeZonesMap[this.currentUser.timezone])
        .year(dateobj.year)
        .month(dateobj.month - 1)
        .date(dateobj.day)
        .hour(0)
        .minute(0)
        .second(0)
        .milliseconds(0)
        .toISOString();
    } else if (!dateobj && this.currentTask) {
      dueTime = this.currentTask.dueTime;
    }
    return dueTime;
  }

  handle(e, c) {
    e.preventDefault();
  }

  isFormValid() {
    this.assignderr = typeof this.assignedto !== 'object' ? true : false;
    this.contactnameerr = typeof this.forContact !== 'object';
    if (this.contactDetails && this.currentTask) {
      this.addForm.patchValue({ assignedContact: this.contactDetails });
    } else if (this.withContact === false) {
      this.addForm.patchValue({ assignedContact: this.contactDetails });
    }
    return (
      this.assignedto &&
      typeof this.assignedto === 'object' &&
      this.assignedby &&
      typeof this.assignedby === 'object' &&
      this.addForm.valid
    );
  }

  ngOnDestroy() {
    this.currentTask = null;
    this.forContact = null;
    this.contactDetails = null;
    this.contactList$.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  open(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {
      this.timepicked = true;
      this.time = time;
    });
  }

  searchContacts(eve) {
    if (typeof eve === 'object' && this.currentTask) {
      this.onlyClickContact = true;
      this.contactDetails = eve;
    }
    this.store.dispatch(SearchContact({ data: { term: eve, curPage: 1, ngb: true } }));
  }

  @Input() set fromCalender(val: boolean) {
    this.fromCalenderValue = val;
  }

  @Input() set setToCalendar(val: boolean) {
    this.setToCalendarValue = val;
  }

  @Input()
  set Selectedtasks(task: any) {
    setTimeout(() => {
      this.currentTask = task;
      if (this.currentTask.contactId) {
        this.store.dispatch(GetContactDetail({ id: this.currentTask.contactId }));
      }
      this.withContact = true;
      const userTimeZoneDate = moment.tz(task.dueTime, timeZonesMap[this.currentUser.timezone]);
      const year = userTimeZoneDate.year();
      const month = userTimeZoneDate.month();
      const day = userTimeZoneDate.date();
      this.assignedto = task.assignedto;
      this.assignedby = task.assignedby;
      if (this.currentTask) {
        this.addForm.get('assignedContact').setValidators(null);
        this.addForm.get('assignedContact').updateValueAndValidity();
        if (!this.currentTask.subject || this.currentTask.subject.length === 0) {
          this.addForm.get('subject').clearValidators();
          this.addForm.get('subject').setValidators([Validators.pattern('^(?! ).+')]);
          this.addForm.get('subject').updateValueAndValidity();
        }
      }
      if (task.task === 'other') {
        this.customtask = true;
      }
      this.addForm.patchValue(task);
      this.addForm.get('toCalendar').setValue(task?.toCalendar ?? false);
      this.addForm.patchValue({ duedate: { year, month: month + 1, day } });
    }, 10);
  }

  remainCheck(eve) {
    this.remain_check = eve.target.checked;
  }

  generateDateWithTimeZone(
    date: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    timezone: string
  ) {
    const dateIso = moment()
      .tz(timeZonesMap[timezone])
      .year(year)
      .month(month - 1)
      .date(date)
      .hour(hours)
      .minute(minutes)
      .toISOString();
    return new Date(dateIso).getTime();
  }
}
declare global {
  interface Date {
    yyyymmdd(date?): any;
  }
}
