<div class="px-2 pb-4 v2-ui-integretion">
  <div class="modal-header pb-0">
    <h5 class="pt-3 color-grey-text">Create New Event</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addEventForm" autocomplete="off">
      <div class="row flex-row mx-0">
        <div class="col-12 px-0 d-flex flex-column">
          <label class="font-14 font-lato-bold mt-2">Event Name</label>
          <input type="text" placeholder="Enter event name" class="form-control" formControlName="eventName" required />
          <div class="error-box">
            <label class="error" *ngIf="!addEventForm.get('eventName').valid && addEventForm.get('eventName').touched">
              *Add Event
            </label>
          </div>
        </div>
      </div>
      <div class="row flex-column flex-md-row mt-2">
        <div class="col-12 col-md-6 pr-0 pr-md-3 pr-0 d-flex">
          <div class="d-flex flex-column col-6 pl-0 position-relative">
            <label class="font-14 font-lato-bold mt-2">Event Start Date</label>
            <input
              class="form-control"
              type="text"
              ngbDatepicker
              formControlName="eventStartDate"
              #d="ngbDatepicker"
              placeholder={{tDate}}
              value={{tDate}}
              (click)="d.toggle()"
              (blur)="showValidator()"
              name="dp"
              autoClose="outside"
              [appNoKeypress]="true"
              [minDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
              [maxDate]="{ year: 2048, month: 12, day: 31 }"
            />
            <i class="material-icons calender-icon cursor-pointer" (click)="d.toggle()"> date_range </i>
          </div>
          <div class="d-flex flex-column col-6 px-0 position-relative">
            <label class="font-14 font-lato-bold mt-2">Start Time </label>
            <input
              class="form-control"
              type="text"
              placeholder={{startTime}}
              formControlName="eventStartTime"
              value="{{ startTime }}"
              (click)="openStartTime($event)"
              readonly
            />
            <i class="material-icons calender-icon cursor-pointer" style="right: 5px !important" (click)="openStartTime($event)">
              schedule
            </i>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 d-flex">
          <div class="d-flex flex-column col-6 pl-0 position-relative">
            <label class="font-14 font-lato-bold mt-2">Event End Date</label>
            <input
              class="form-control"
              type="text"
              ngbDatepicker
              formControlName="eventEndDate"
              #dq="ngbDatepicker"
              placeholder={{tDate}}
              (click)="dq.toggle()"
              (blur)="showValidator()"
              name="dp"
              value={{tDate}}
              autoClose="outside"
              [appNoKeypress]="true"
              [minDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
              [maxDate]="{ year: 2048, month: 12, day: 31 }"
            />
            <i class="material-icons calender-icon cursor-pointer" (click)="dq.toggle()"> date_range </i>
          </div>
          <div class="d-flex flex-column col-6 pl-0 position-relative">
            <label class="font-14 font-lato-bold mt-2">End Time </label>
            <input
              class="form-control"
              type="text"
              placeholder={{endTime}}
              formControlName="eventEndTime"
              value="{{ endTime }}"
              (click)="openEndTime($event)"
              readonly
            />
            <i class="material-icons calender-icon cursor-pointer" (click)="openEndTime($event)"> schedule </i>
          </div>
        </div>
      </div>
      <div class="row flex-column mx-0 mt-2">
        <div class="col-12 px-0 d-flex flex-column">
          <label class="font-14 mt-2">Description</label>
          <textarea
            style="height: 90px !important"
            class="form-control"
            formControlName="eventDesc"
            id="message"
            placeholder="Enter event description  "
            rows="5"
            ng-reflect-name="note"
          ></textarea>
        </div>
        <div class="col-12 d-flex px-0 flex-column">
          <label class="font-14 mt-2">Attendees</label>
          <div class="chip-input-box form-control d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center w-90">
              <div class="d-flex">
                <div
                  class="d-flex pl-1 align-items-center chip-input-item mr-1"
                  *ngFor="let item of attendeesList | slice: 0:3; let i = index"
                >
                  <span class="tag-name font-12 px-1 text-truncate">{{ item }}</span>
                  <div class="close-box">
                    <span class="material-icons font-14" (click)="deleteChip(i, 'attendees')">close</span>
                  </div>
                </div>
              </div>
              <input
                placeholder="Enter Attendees Email"
                formControlName="eventAttendees"
                #eventAttendees
                type="email"
                (keyup.enter)="onEnterOrganizer(addEventForm.value, 'attendees')"
                (keyup.space)="onEnterOrganizer(addEventForm.value, 'attendees')"
              />
            </div>
            <div *ngIf="attendeesList.length > 3">
              <span class="d-flex align-items-center justify-content-center font-10"
                            [ngbPopover]="popContent" triggers="manual" #p1="ngbPopover" placement="bottom top"
                            autoClose="outside" popoverClass="dropdown-custom-popover" (click)="openTagList(p1)"
                            (shown)="onPopoverOpen(p1)">
                            +{{ attendeesList.length - 3 }} More
                          </span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #popContent placement="bottom">
        <div class="row pt-3 pb-1 px-3 v2-ui-integretion">
          <div class="d-flex flex-column w-100 body-layout mb-0">
            <table class="m-0 w-100 table">
              <tbody class="table-body heightscroll">
                <tr class="w-100" *ngFor="let item of attendeesList; let i = index" [attr.data-index]="i">
                  <td class="d-flex align-items-center justify-content-between"
                    style="height: 40px !important">
                    <span class="mr-1 tag-name">{{ item }}</span>
                    <span class="close-box" (click)=removeAttendee(i)
                    >
                      <i class="material-icons font-16" > close </i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
      <div class="mt-4 bottom-btn ml-auto d-flex justify-content-end">
        <input type="button" class="btn btn-normal mr-2" (click)="activeModal.dismiss('Cross click')" value="Cancel">
        <input type="button" [disabled]="!this.currentUser?.hasCalendarIntegration" class="btn btn-primary"(click)="createEvent()"value="Create Event" >
      </div>
    </form>
  </div>
</div>
