import { Component } from '@angular/core';
import {
  IToolPanel
} from '@ag-grid-community/all-modules';

@Component({
  selector: 'saved-filters-panel',
  template: ` <div class="d-flex flex-column" style="width:250px">
    <p class="m-2" *ngIf="gridStateList && gridStateList.length > 0">
      Apply a saved filter to edit and save changes.
    </p>
    <p class="m-2" *ngIf="gridStateList && gridStateList.length === 0">
      You have no saved filters.
    </p>
    <p class="m-2" *ngIf="gridStateList && gridStateList.length === 0">
      First, create a filter in columns, then save and name your filter.  Saved filters will appear here.
    </p>
    <br />
    <ul class="list-group w-100">
      <li *ngFor="let gridState of gridStateList"
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        [ngClass]="{active : gridState.id === this.selectedStateId}"
        (click)="onFilterSelect(gridState) "
        >
        {{gridState.description}}
        <button
          class="d-flex daily-icons daily-icon-button"
          type="button"
          (click)="onDeleteClick(gridState)">
          <i class="icon-delete" ></i>
        </button>
      </li>
    </ul>
  </div>`,
  styles: [`
    .list-group li.list-group-item {
      color:#666 !important;
      padding: 0.4rem 0.9rem !important;
      border-radius: 0 !important;
    }
    .list-group li.list-group-item.active {
      background-color: #e7e7e7;
      border-color: #e1e1e1;
    }
    button.daily-icon-button:hover {
      border-color: red  !important;
    }
    i.icon-delete:hover {
      color:red !important;
    }
  `]
})

export class SavedFiltersPanelComponent implements IToolPanel {
  private params: any;

  public selectedStateId:string;

  public gridStateList: any;

  refresh(): void {}

  agInit(params: any): void {
    this.params = params;

    this.gridStateList = params.gridStateList;

    this.selectedStateId = params?.selectedStateId;
  }

  onDeleteClick (gridState) {
    this.params.deleteGridState(gridState)
  }

  onFilterSelect(gridState) {
    this.selectedStateId = gridState.id;
    this.params.onFilterSelected(gridState);
  }
}
