import { createReducer, on } from '@ngrx/store';
import { EnterpriseState } from './state';
import * as EnterpriseActions from './actions';

const initialState: EnterpriseState = {
  teamList: null,
  teamListUsersCount: 0,
  getTeamsListError: null,
  getTeamsListSuccess: null,
  getTeamsListErrorData: null,
  gettingTeamsList: false,
  count: null,
  currentTeam: null,
  getTeamDataError: false,
  getTeamDataSuccess: null,
  getTeamErrorData: null,
  gettingTeamData: false,
  enterpriseUsers: [],
  getEnterpriseUsersError: false,
  getEnterpriseUsersSuccess: false,
  gettingEnterpriseUsers: false,
  getEnterpriseUsersTeamNameAndAddedByError: false,
  getEnterpriseUsersTeamNameAndAddedByErrorData: null,
  getEnterpriseUsersTeamNameAndAddedBySuccess: false,
  gettingEnterpriseUsersTeamNameAndAddedBy: false,
  teamUsers: [],
  userListCountEnterprise: 0,
  enterpriseUserListDemand: [],
  gettingEnterpriseUsersDemandList: false,
  teamsListDemand: [],
  gettingTeamsDemandList: false,
  updatingTeam: false,
  TeamPayload: null
};

export const EnterpriseReducer = createReducer(
  initialState,
  on(EnterpriseActions.GetTeamsList, (state, payload) => ({
    ...state,
    getTeamsListSuccess: false,
    getTeamsListError: false,
    gettingTeamsList: true
  })),
  on(EnterpriseActions.GetTeamsListSuccess, (state, payload) => ({
    ...state,
    teamList: payload.data,
    count: payload.count,
    getTeamsListSuccess: true,
    getTeamsListError: false,
    gettingTeamsList: false
  })),
  on(EnterpriseActions.GetTeamsListError, (state, payload) => ({
    ...state,
    getTeamsListError: true,
    getTeamsListSuccess: false,
    getTeamsListErrorData: payload.data,
    gettingTeamsList: false,
  })),
  on(EnterpriseActions.ClearTeamPayload, state => ({ ...state, TeamPayload: null })),
  on(EnterpriseActions.GetTeamData, (state, payload) => ({
    ...state,
    currentTeam: null,
    updatingTeam: false,
    getTeamDataError: null,
    getTeamDataSuccess: null,
    getTeamErrorData: null,
    gettingTeamData: true,
  })),
  on(EnterpriseActions.GetTeamDataSuccess, (state, payload) => ({
    ...state,
    TeamPayload: payload.payload ? { ...payload.payload } : state.TeamPayload,
    currentTeam: payload.data,
    getTeamDataError: null,
    getTeamDataSuccess: true,
    getTeamErrorData: null,
    gettingTeamData: false,
  })),
  on(EnterpriseActions.GetTeamDataError, (state, payload) => ({
    ...state,
    currentTeam: null,
    getTeamDataError: true,
    getTeamDataSuccess: false,
    getTeamErrorData: payload.data,
    gettingTeamData: false,
  })),
  on(EnterpriseActions.GetEnterpriseUsersList, (state, payload) => ({
    ...state,
    teamList: payload.forTeam ? state.teamList : [],
    teamUsers: (!payload.silent && payload.forTeam) ? [] : state.teamUsers,
    enterpriseUsers: (payload.forTeam || payload.silent) ? [...(state.enterpriseUsers ?? [])] : [],
    getEnterpriseUsersError: false,
    getEnterpriseUsersSuccess: false,
    gettingEnterpriseUsers: true,
  })),
  on(EnterpriseActions.GetEnterpriseUsersListSuccess, (state, payload) => ({
    ...state,
    enterpriseUsers: payload.data,
    userListCountEnterprise: payload.count,
    getEnterpriseUsersError: false,
    getEnterpriseUsersSuccess: true,
    gettingEnterpriseUsers: false,
  })),
  on(EnterpriseActions.GetEnterpriseUsersListError, (state, payload) => ({
    ...state,
    enterpriseUsers: [],
    getEnterpriseUsersError: true,
    getEnterpriseUsersSuccess: false,
    gettingEnterpriseUsers: false,
  })),
  on(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedBy, (state, payload) => ({
    ...state,
    getEnterpriseUsersTeamNameAndAddedByError: false,
    getEnterpriseUsersTeamNameAndAddedBySuccess: false,
    gettingEnterpriseUsersTeamNameAndAddedBy: true
  })),
  on(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedBySuccess, (state, payload) => ({
    ...state,
    enterpriseUsers: state.enterpriseUsers.map((user, i) => ({ ...user, ...payload.data[i] })),
    getEnterpriseUsersTeamNameAndAddedByError: false,
    getEnterpriseUsersTeamNameAndAddedBySuccess: true,
    gettingEnterpriseUsersTeamNameAndAddedBy: false
  })),
  on(EnterpriseActions.GetEnterpriseUsersTeamNameAndAddedByError, (state, payload) => ({
    ...state,
    getEnterpriseUsersTeamNameAndAddedByErrorData: payload.data,
    getEnterpriseUsersTeamNameAndAddedByError: true,
    getEnterpriseUsersTeamNameAndAddedBySuccess: false,
    gettingEnterpriseUsersTeamNameAndAddedBy: false
  })),
  on(EnterpriseActions.GetTeamUsersListSuccess, (state, payload) => ({
    ...state,
    teamUsers: payload.data,
    teamListUsersCount: payload.count,
    getEnterpriseUsersError: false,
    getEnterpriseUsersSuccess: true,
    gettingEnterpriseUsers: false,
  })),
  on(EnterpriseActions.GetTeamUsersListError, (state, payload) => ({
    ...state,
    teamUsers: [],
    getEnterpriseUsersError: true,
    getEnterpriseUsersSuccess: false,
    gettingEnterpriseUsers: false,
  })),
  on(EnterpriseActions.GetEnterpriseUserListDemand, (state, payload) => ({
    ...state,
    gettingEnterpriseUsersDemandList: true,
  })),
  on(EnterpriseActions.ResetDamndList, (state, payload) => ({
    ...state,
    enterpriseUserListDemand: [],
  })),
  on(EnterpriseActions.GetDemandListSuccess, (state, payload) => {
    let {enterpriseUserListDemand} = state;
    const temp = payload.data.map((u) => ({
      userName: `${u.firstName} ${u.lastName}`,
      email: u.email,
      uid: u.uid,
      teamId: u.teamId,
      userType: u.userType
    }));
    enterpriseUserListDemand = [...enterpriseUserListDemand, ...temp];
    return {
      ...state,
      enterpriseUserListDemand,
      gettingEnterpriseUsersDemandList: false
    };
  }),
  on(EnterpriseActions.GetDemandListError, state => ({
    ...state,
    gettingEnterpriseUsersDemandList: false
  })),
  on(EnterpriseActions.GetTeamsListDemand, (state, payload) => ({
    ...state,
    gettingTeamsDemandList: true,
  })),
  on(EnterpriseActions.ResetDemandListTeam, (state, payload) => ({
    ...state,
    teamsListDemand: [],
  })),
  on(EnterpriseActions.GetTeamsDemandListSuccess, (state, payload) => {
    let {teamsListDemand} = state;
    const temp = payload.data.map((u) => ({
      teamName: u.teamName,
      manager: u.manager?.userName ?? null,
      teamId: u.teamId,
      teamLeader: u.teamLeader?.userName ?? null,
      userType: u.userType
    }));
    teamsListDemand = [...teamsListDemand, ...temp];
    return {
      ...state,
      gettingTeamsDemandList: false,
      teamsListDemand
    };
  }),
  on(EnterpriseActions.GetTeamsDemandListError, state => ({
    ...state,
    gettingTeamsDemandList: false
  })),
  on(EnterpriseActions.updateTeam, (state, payload) => ({
    ...state,
    updatingTeam: true,
  }))
);
