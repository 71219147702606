<div class="d-flex flex-grow-1 flex-wrap align-items-center mr-3 py-1 pt-2" *ngIf="appliedFilters"
[ngClass]="{
  'bg-white': (appliedFilters && filterBubbleTotal > 0),
  'border': (appliedFilters && filterBubbleTotal > 0)
}"
>
  <applied-filter-bubbles
    [(filtersList)]="appliedFilters"
    [keyHeaderValues]="keyHeaderValues"
    (filterTrimmed)="onFilterTrimmed($event)"
  >
  </applied-filter-bubbles>
  <div class="ml-3 d-flex flex-column">
    <div class="d-flex align-items-center" *ngIf="isFilterChanged">
      <div class="input-button" *ngIf="!isSavedFilterSelected"
      [ngClass]="{
        'success': this.filterSaved
      }"
      >
        <input type="text"
          placeholder="Save Filter"
          [(ngModel)]="this.gridStateDescription" />
        <button type="button"
          [ngbTooltip]='!this.gridStateDescription ? "Please add a description to save a filter." : "Save filter"'
          tooltipClass="tooltip-page-class"
          (click)="this.saveGridState()"
        >
          <i class="icon-save"
          [ngClass]="{
            'disabled' : !this.gridStateDescription
          }"
          ></i>
        </button>
      </div>
      <button type="button" class="btn link-button"
      (click)="clearAllFilters()">Clear all</button>
    </div>
    <div class="text-success animation"
      [ngClass]="{
        'd-none': !this.filterSaved,
        'd-flex': this.filterSaved,
        'fade-out': this.filterSaved
      }"
      style="font-size: .75em; " *ngIf="isFilterChanged"
    >
      Filter successfully saved in the Saved Filters Panel
    </div>
  </div>
</div>
