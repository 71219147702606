import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { ContactsState } from './store/state';

const api = environment.mongoUrl;

@Injectable()
export class ContactResolver implements Resolve<any> {
  constructor(private http: HttpClient, private authSvc: AuthService, private store: Store<ContactsState>) {}

  async resolve() {
    const user = await this.authSvc.getCurrentUserUid();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/contacts/fetchContact`, { details: { id: '', curPage: 1 } }, httpOptions);
  }
}
