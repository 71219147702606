import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicationsState } from './state';

export const selectCommunicationsState = createFeatureSelector<CommunicationsState>('communications');

export const selectCommunicationContactsList = createSelector(selectCommunicationsState, (state) => state.ContactsList);
export const selectCommunicationContactSmsLog = createSelector(
  selectCommunicationsState,
  (state) => state.ContactSmsLog
);
export const selectCommunicationContactEmailLog = createSelector(
  selectCommunicationsState,
  (state) => state.ContactEmailLog
);
export const selectCurrentContact = createSelector(selectCommunicationsState, (state) => state.CurrentSelectedContact);
export const selectSendingSms = createSelector(selectCommunicationsState, (state) => state.ContactSendingSms);
export const selectSendingEmail = createSelector(selectCommunicationsState, (state) => state.ContactSendingMail);
export const selectSmsLoader = createSelector(selectCommunicationsState, (state) => state.smsLoader);
export const selectEmailLoader = createSelector(selectCommunicationsState, (state) => state.emailLoader);
export const selectCommunicationContactsListEnd = createSelector(
  selectCommunicationsState,
  (state) => state.endOfContactList
);

export const selectMyConversationLoading = createSelector(
  selectCommunicationsState,
  (state) => state.conversationLoading
);
export const selectEndOfSmsLogs = createSelector(
  selectCommunicationsState,

  (state) => state.endOfContactSmsLog
);
export const selectEndOfEmailLogs = createSelector(
  selectCommunicationsState,

  (state) => state.endOfContactEmailLog
);
