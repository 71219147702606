import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dailyai-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {       
      provide: NG_VALUE_ACCESSOR, 
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true     
    }   
  ]
})
export class SearchInputComponent implements ControlValueAccessor {
  term = '';

  disabled = false;

  open = false;

  @Input() placeholder = '';

  @Input() class = '';

  @Input() type = 'text';

  @Input() searching = false;

  @Input() id = '';

  onChange: (val: string) => void;

  onTouched: () => void;

  writeValue(val: string): void {
    this.term = val;
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
