import {
  Component,
  ElementRef,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import Inputmask from 'inputmask';
import { NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../../injectables/date-parser.injectable';

@Component({
  selector: 'dailyai-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class DateInputComponent implements OnInit, AfterViewInit {

  public today = new Date();

  @ViewChild('dateInput') myInputElementRef: ElementRef;

  @Input() public form: FormGroup;

  @Input() public controlName: string;

  @Input() public placement = "bottom";

  @Input() public id: string;

  @Input() public min = { year: this.today.getFullYear() - 100, month: 1, day: 1 };

  @Input() public max = { year: this.today.getFullYear() + 50, month: this.today.getMonth() + 1, day: this.today.getDate() };

  @Input() public disabled: boolean;

  ngbToday = this.calendar.getToday();

  model: NgbDateStruct;

  constructor(private calendar: NgbCalendar) { }

  ngOnInit(): void {
    this.model = this.form.value[this.controlName];
  }

  ngAfterViewInit(): void {
    Inputmask('datetime', {
      inputFormat: 'mm/dd/yyyy',
      placeholder: 'mm/dd/yyyy',
      alias: 'datetime',
      // min: '01/01/2010',
      clearMaskOnLostFocus: false,
      isComplete: (buffer, opts) => {
      }
    }).mask(this.myInputElementRef.nativeElement);
  }
}
