import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumberFormat' })
export class PhoneNumberFormat implements PipeTransform {
  cCode: any = null;

  newNumber: string = null;

  transform(value: string): string {
    const length: number = value?.length || 0;
    if (length >= 10) {
      const oldStr: string = value;
      const newStr: string = value.slice(-10);
      if (length > 10) {
        this.cCode = oldStr.toString().slice(0, -10);
      }
      this.newNumber = `(${newStr.slice(0, 3)}) ${newStr.slice(3, 6)}-${newStr.slice(6, 10)}`;
      if (this.cCode != null) {
        this.newNumber = `${this.newNumber}`;
      }
      return this.newNumber;
    } else if (length > 0) {
      return value;
    } else {
      return '--'
    }

  }
}
