import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { LoginState } from 'src/app/modules/login/store/state';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'dailyai-confirm-verified-modal',
  templateUrl: './confirm-verified-modal.component.html',
  styleUrls: ['./confirm-verified-modal.component.scss']
})
export class ConfirmVerifiedModalComponent implements OnInit, OnDestroy {
  @Input() fromPhoneConfirm;

  newVerificationCode: any;

  currentUser$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  currentUser: any;

  phoneStatus: any;

  phoneStatusFail: any;

  newPhoneObj: any;

  constructor(
    private ngbModal: NgbModal,
    private _sharedService: SharedService,
    private toastr: ToastrService,
    private store: Store<LoginState>,

  ) {
    this.currentUser$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectCurrentUser));
    this.currentUser$.subscribe((data) => {
      this.currentUser = data;
    })
  }

  ngOnInit(): void {
    this.newVerificationCode = this.fromPhoneConfirm.prop1.toString().split('');
    this.newPhoneObj = this.fromPhoneConfirm.prop2;
    this.getPhoneStatus()
  }

  getPhoneStatus() {
    this._sharedService
      .changeUserPhone(this.currentUser?.uid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const {status} = data;
        if (this.phoneStatus === undefined) {
          this.phoneStatus = status;
        } else if (this.phoneStatus !== status) {
          this.phoneStatus = status;
        }

      });
  }

  cancel() {
    this.ngbModal.dismissAll();
  }

  async retryCode() {
    const request = await this._sharedService.getValidationCode(this.newPhoneObj);
    request.pipe(take(1)).subscribe(
      (response: any) => {
        if (response && response.validationCode) {
          this.newVerificationCode = response.validationCode.toString().split('');
        }
      },
      (error) => {
        if(error?.error?.toast){
          return this.toastr.error(error?.error?.toast, 'Error Message');
        }
          return this.toastr.error('User already exists in CRM', 'Error Message');

      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
