import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoRecordingService } from '../../services/video-recording.service';
import { DataTransactionService } from '../../services/data-transaction.service';
import { AudioRecordingService } from '../../services/audio-recording.service';

@Component({
  selector: 'dailyai-video-model',
  templateUrl: './video-record-model.component.html',
  styleUrls: ['./video-record-model.component.scss']
})
export class VideoRecordModelComponent implements OnDestroy, OnInit {
  @ViewChild('video') video;

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  isRecording = false;

  recordedTime;

  blobUrl;

  videoUrl;

  private currentUser$: Observable<any>;

  private userId: string;

  videoLoading = false;

  recordStopped = false;

  play = true;

  pause = false;

  videoName = '';

  finalOut;

  videoNameFlag = false;

  constructor(
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer,
    private dts: DataTransactionService,
    private store: Store<any>,
    private videoSvc: VideoRecordingService,
    public modal: NgbModal
  ) {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.subscribe(data => {
      if (data) {
        this.userId = data.uid;
      }
    });

    this.videoSvc.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.videoSvc.getRecordedTime().subscribe(time => {
      this.recordedTime = time;
      const duration = time.split(':');
      if (duration[1] == '16') {
        this.stopVideoRecording();
      }

    });

    this.videoSvc.recordingPreview().subscribe(stream => {
      const video: HTMLVideoElement = this.video.nativeElement;
      video.srcObject = stream;
      video.muted = true;
    });

    this.videoSvc.getRecordedBlob().subscribe(data => {
      this.videoUrl = data.url;
      const video: HTMLVideoElement = this.video.nativeElement;
      video.src = this.videoUrl;
      video.muted = false;
      this.finalOut = data.blob;
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.muted = false;
    video.controls = true;
    video.autoplay = true;
    video.controls = false;
    this.videoSvc.defaultStream();
  }

  startVideoRecording(type?) {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.controls = false;
    this.isRecording = true;
    this.recordStopped = false;
    (type) ? this.videoSvc.startNewRecording() : this.videoSvc.startRecording();
  }

  stopVideoRecording() {
    this.videoSvc.stopRecording();
    const video: HTMLVideoElement = this.video.nativeElement;
    video.controls = false;
    this.recordStopped = true;
  }

  playAgain() {
    const video: HTMLVideoElement = this.video.nativeElement;
    if (!this.recordStopped) {
      this.videoSvc.resumeRecording();
      this.play = true;
      this.pause = false;
    } else {
      video.play();
      this.play = true;
      this.pause = false;
    }
  }

  pauseVideo() {
    const video: HTMLVideoElement = this.video.nativeElement;
    if (!this.recordStopped) {
      this.videoSvc.pauseRecording();
      this.play = false;
      this.pause = true;
    } else {
      video.pause();
      this.play = false;
      this.pause = true;
    }
  }

  muteVideo() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.muted = true;
  }

  vidEnded() {
    this.play = false;
    this.pause = true;
  }


  abortRecording() {
    this.audioRecordingService.abortRecording();
    this.videoSvc.abortRecording();
  }

  saveVideo() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.pause();
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const dataURI = canvas.toDataURL('image/jpeg');
    this.videoNameFlag = this.videoName !== '';
    this.dts.uploadFile(this.userId, this.finalOut, 'video', this.recordedTime, `${this.videoName}`, dataURI);
    this.saved.emit('success');
    this.modal.dismissAll();
  }


  clearRecordedData() {
    this.blobUrl = null;
  }

  closeModal() {
    this.modal.dismissAll();
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }
}
