import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaService } from '../../../modules/drip-campaign/services/getMedia.service';
import { ShareMediaAudioListComponent } from '../share-media-audio-list/share-media-audio-list.component';

@Component({
  selector: 'dailyai-share-media-audio-select',
  templateUrl: './share-media-audio-select.component.html',
  styleUrls: ['./share-media-audio-select.component.scss']
})
export class ShareMediaAudioSelectComponent implements OnInit, OnDestroy {
  @Output() private selectedSystemMediaFile = new EventEmitter();

  dripTyp = '';

  value;

  modalRef: NgbModalRef

  @Output() private close = new EventEmitter()

  ngUnsubscribe: Subject<any> = new Subject<any>();

  url = '';

  constructor(private modalService: NgbModal, private mediaSvc: MediaService, private router: Router) { }

  ngOnInit() {
    this.url = this.router.url.split('/')[1];
  }

  openMediaAudioList() {
    this.modalRef = this.modalService.open(ShareMediaAudioListComponent, {
      centered: true,
      windowClass: 'media-drip-popup',
      backdrop: 'static',
      keyboard: true
    });
    this.modalRef.componentInstance.user = this.dripTyp;
    (this.modalRef.componentInstance.close as Observable<any>).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      x && x === "close" && this.modalRef.close()
      if (x && x === 'close-all') {
        this.close.emit('close all');
        this.modalRef.close();
      }
    })
  }

  uploadFile(event) {
    this.mediaSvc.sendSelected(event.target.files);
    this.close.emit('close all');
  }

  closeSelectModal() {
    this.close.emit('close all');
  }

  @Input() set dripType(driptype: string) {
    this.dripTyp = driptype;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
