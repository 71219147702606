<div class=" px-0 pb-2 v2-ui-integretion">
  <div class="modal-header pt-4 pb-0 px-4">
    <h6 class="pt-4 font-16">PLEASE CONFIRM</h6>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <i class="material-icons icon-color" (click)="onCancel()"> close </i>
    </button>
  </div>
  <hr class="mt-0" />
  <div class="modal-body py-2 tabs-items">
    <div class="table-check-box w-100">
      <label class="v2-checkbox mb-0 ">
        <input type="checkbox" id="confirmed" [(ngModel)]="check" /> <span class="checkmark"></span>
      </label>
      <label class="font-14 ml-4" for="confirmed"
        >I certify that I have consent to contact the people included in this mass action</label
      >
    </div>
  </div>
  <hr />
  <div>
    <div class="d-flex  my-3 confirmationfooter justify-content-end mr-3">
      <button class="btn btn-normal mr-1 w-25" (click)="onCancel()">Cancel</button>
      <button [disabled]="!check" class="btn btn-primary ml-1 w-25" (click)="onSubmit('submit')">Confirm</button>
    </div>
  </div>
</div>
