<div class="modal-body v2-ui-integretion">
  <dailyai-hidden-input></dailyai-hidden-input>
  <div>
    <div class="form-group">
      <label for="title" class="text-capitalize" *ngIf="emailContent?.templateName"
        >Template Name: {{ emailContent['templateName'] }}</label
      ><br />
      <label for="title" class="text-capitalize">Subject</label>
      <div class="">
        <input
          id="title"
          class="form-control"
          [readonly]="true"
          placeholder="Subject"
          [value]="
            emailContent?.email_subject
              ? emailContent.email_subject
              : emailContent?.drip_name
              ? emailContent.drip_name
              : emailContent?.templateSubject
              ? emailContent.templateSubject
              : ''
          "
        />
      </div>
    </div>
    <div class="form-group position-relative">
      <label for="message">Message</label>
      <ckeditor
        class="read_only_ck"
        [(ngModel)]="
          emailContent?.body
          ? emailContent['body']
          : emailContent?.templateContent
            ? emailContent['templateContent']
            : emptyValue
        "
        [readonly]="true"
        name="myckeditor"
        [config]="ckeConfigReadOnly"
        debounce="500"
        (ready)="viewEditorready()"
      >
      </ckeditor>
      <div *ngIf="!viewEditorLoaded || !emailContent" class="d-flex align-items-center position-absolute inset-0 justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="mt-2 d-flex flex-wrap mt-1" *ngIf="emailContent">
      <div
        class="d-flex align-items-center attach-files mr-2 truncate"
        *ngFor="let item of emailContent['email_attachments_array']"
        placement="top"
        ngbTooltip="click to download"
        tooltipClass="tooltip-page-class"
      >
        <a href="{{ item['firebase_url'] }}" class="max-w-100px" target="_blank">
          <span class="px-2 py-1 file-name font-14 truncate">{{ item['file_name'] }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer v2-ui-integretion">
  <button type="button" class="btn btn-normal px-3" (click)="modal.dismissAll()">Close</button>
</div>
