import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './action';
import { DashboardState } from './state';

export const initialState: DashboardState = {
  call_count: null,
  loadCommunicationsError: null,
  loadCommunicationsSuccess: null,
  loadingCommunications: false,
  sms_count: null,
  userCommunications: null,
  voice_drop_count: null,
  voice_drop_limit: null,
  call_limit: null,
  mms_count: null,
  mms_limit: null,
  sms_limit: null,
  taskOpenList: null,
  taskDueList: null,
  taskAllList: null,
  taskListSuccess: null,
  mms_balance_monthly: null,
  mms_balance_extra: null,
  call_balance_monthly: null,
  call_balance_extra: null,
  sms_balance_monthly: null,
  sms_balance_extra: null,
  voice_drop_balance_monthly: null,
  voice_drop_balance_extra: null,
  allContactActivityList: null,
  callContactActivityList: null,
  mailContactActivityList: null,
  msgContactActivityList: null,
  contactNurtureAnniversary: null,
  contactNurtureBirthday: null,
  email_campaign_limit: null,
  email_campaign_balance_extra: null,
  email_campaign_balance_monthly: null,
  hourly_sms_count: null,
  daily_sms_count: null,
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.LoadingCommunications, (state) => ({
    ...state,
    loadingCommunications: true,
    loadCommunicationsError: null,
  })),
  on(DashboardActions.ListTask, (state) => ({
    ...state,
    taskListSuccess: true,
  })),
  on(DashboardActions.ListOpenTaskSuccess, (state, payload) => ({
    ...state,
    taskOpenList: payload.data,
    taskListSuccess: true,
  })),
  on(DashboardActions.ListDueTaskSuccess, (state, payload) => ({
    ...state,
    taskDueList: payload.data,
    taskListSuccess: true,
  })),
  on(DashboardActions.ListAllTaskSuccess, (state, payload) => ({
    ...state,
    taskAllList: payload.data,
    taskListSuccess: true,
  })),
  on(DashboardActions.LoadCommunicationError, (state, payload) => ({
    ...state,
    loadCommunicationsSuccess: null,
    loadCommunicationsError: payload.msg,
    loadingCommunications: false,
  })),
  on(DashboardActions.ListContactActivity, (state) => ({
    ...state,
  })),
  on(DashboardActions.ListAllContactActivity, (state, payload) => ({
    ...state,
    allContactActivityList: payload.data,
  })),
  on(DashboardActions.ListCallContactActivity, (state, payload) => ({
    ...state,
    callContactActivityList: payload.data,
  })),
  on(DashboardActions.ListMsgContactActivity, (state, payload) => ({
    ...state,
    msgContactActivityList: payload.data,
  })),
  on(DashboardActions.ListMailContactActivity, (state, payload) => ({
    ...state,
    mailContactActivityList: payload.data,
  })),
  on(DashboardActions.ListContactNurtureAnniversary, (state, payload) => ({
    ...state,
    contactNurtureAnniversary: payload.data.data,
  })),
  on(DashboardActions.ListContactNurtureBirthday, (state, payload) => ({
    ...state,
    contactNurtureBirthday: payload.data.data,
  })),
  on(DashboardActions.LoadCommunicationSuccess, (state, payload) => {
    const sms_details: any[] = payload.sms_details ? payload.sms_details : [];
    let sms_count = 0;
    let call_minutes = 0;
    const call_details: any[] = payload.call_details ? payload.call_details : [];
    call_details.forEach((a) => {
      const duration = Number.parseInt(a['data']['duration']);
      let mins = Math.floor(duration / 60);
      const rem = duration % 60;
      if (rem > 0) {
        mins = mins + 1;
      }
      call_minutes = call_minutes + mins;
    });
    sms_details.forEach((a) => {
      sms_count = sms_count + Number.parseInt(a['data']['numSegments']);
    });
     return {
      ...state,
      loadCommunicationsSuccess: true,
      userCommunications: payload,
      sms_count,
      call_count: call_minutes,
      call_limit: payload.call_limit ? payload.call_limit : 0,
      sms_limit: payload.sms_limit ? payload.sms_limit : 0,
      mms_count: payload.mms_count ? payload.mms_count : 0,
      mms_limit: payload.mms_limit ? payload.mms_limit : 0,
      voice_drop_count: payload.voice_drop_count ? payload.voice_drop_count : 0,
      voice_drop_limit: payload.voice_drop_limit ? payload.voice_drop_limit : 0,
      call_balance_extra: payload.call_balance_extra ? payload.call_balance_extra : 0,
      call_balance_monthly: payload.call_balance_monthly ? payload.call_balance_monthly : 0,
      mms_balance_monthly: payload.mms_balance_monthly ? payload.mms_balance_monthly : 0,
      mms_balance_extra: payload.mms_balance_extra ? payload.mms_balance_extra : 0,
      sms_balance_monthly: payload.sms_balance_monthly ? payload.sms_balance_monthly : 0,
      sms_balance_extra: payload.sms_balance_extra ? payload.sms_balance_extra : 0,
      voice_drop_balance_monthly: payload.voice_drop_balance_monthly ? payload.voice_drop_balance_monthly : 0,
      voice_drop_balance_extra: payload.voice_drop_balance_extra ? payload.voice_drop_balance_extra : 0,
      email_campaign_balance_monthly: payload.email_campaign_balance_monthly
        ? payload.email_campaign_balance_monthly
        : 0,
      email_campaign_balance_extra: payload.email_campaign_balance_extra ? payload.email_campaign_balance_extra : 0,
      email_campaign_limit: payload.email_campaign_limit ? payload.email_campaign_limit : 0,
      daily_sms_count: payload.daily_sms_count ? payload.daily_sms_count : 0,
      hourly_sms_count: payload.hourly_sms_count ? payload.hourly_sms_count : 0,
    };
  })
);
