import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { COLLECTIONS } from 'src/app/shared/config';
import { NoteVisibility } from '../components/note-popup/note-popup.component';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private db: AngularFirestore) {}

  addNote(data) {
    if (data.note_id) {
      return this.db.collection(COLLECTIONS.NOTES)
        .doc(data.note_id)
        .update(data);
    }
    return this.db.firestore
      .collection(COLLECTIONS.NOTES)
      .add(data)
      .then((ref) => ref.update({ note_id: ref.id }));
  }

  getNote(id, currentUserId) {
    const allPrivateNotes = this.db
      .collection<any>(COLLECTIONS.NOTES, (ref) =>
        ref
          .where('contact_id', '==', id)
          .where('creator_id', '==', currentUserId)
          .where('visibilityType', '==', NoteVisibility.private)
          .orderBy('created_at', 'desc')
      )
      .valueChanges();
    const allPublicNotes = this.db
      .collection<any>(COLLECTIONS.NOTES, (ref) =>
        ref
          .where('visibilityType', '==', NoteVisibility.public)
          .where('contact_id', '==', id)
          .orderBy('created_at', 'desc')
      )
      .valueChanges();
    const allInternalNotes = this.db
      .collection<any>(COLLECTIONS.NOTES, (ref) =>
        ref
          .where('visibilityType', '==', NoteVisibility.internal)
          .where('contact_id', '==', id)
          .orderBy('created_at', 'desc')
      )
      .valueChanges();

    return combineLatest([allPrivateNotes, allPublicNotes, allInternalNotes]).pipe(
      map(([privateNotes, publicNotes, internalNotes]) => {
        const notesData = [...privateNotes, ...publicNotes, ...internalNotes];
        return notesData.sort((a, b) => {
          return a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0;
        });
      })
    );
  }

  async updateNote(data) {
    try {
      await this.db.collection(COLLECTIONS.NOTES).doc(data.note_id).update(data);
    } catch (error) {
      console.warn(error);
    }
  }

  deleteNote(data) {
    return this.db.collection<any>(COLLECTIONS.NOTES).doc(data).delete();
  }

  async addIntakeForm(payload) {
    const formRef = this.db.collection(COLLECTIONS.NOTES).doc();

    await formRef.set({
      ...payload,
      note_id: formRef.ref.id,
      type: 'intake',
    });
  }

  addIntakeFromNote(data: any) {
    return this.db.firestore
      .collection(COLLECTIONS.NOTES)
      .add(data)
      .then((ref) => ref.update({ note_id: ref.id }));
  }

  async updateContactCustomInfo(
    intakeFormId: string,
    intakeFormTitle: string,
    currentUser: User,
    id: string,
    contactPayload: any,
    notePayload: any
  ) {
    try {
      await this.db
        .collection(COLLECTIONS.CONTACTS)
        .doc(id)
        .set(
          {
            custom: {
              ...contactPayload,
            },
          },
          { merge: true }
        );
      const intakeNotePayload = {
        contact_id: id,
        created_at: new Date().toISOString(),
        creator_id: currentUser.uid,
        creator_name: `${currentUser.firstName} ${currentUser.lastName}`,
        note: intakeFormTitle,
        visibilityType: NoteVisibility.internal,
        type: 'intakeNote',
        intakeFormId,
        intakeData: notePayload,
      };
      this.addIntakeFromNote(intakeNotePayload);
    } catch (error) {}
  }
}
