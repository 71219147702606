import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class GetTaskListService {
  logsRef = this.db.collection<any>('logs');

  constructor(private db: AngularFirestore) {}

  get(contactid): Observable<any> {
    return this.db
      .collection<any>('tasks', (ref) => ref.where('contactId', '==', contactid).orderBy('created_at', 'desc'))
      .valueChanges();
  }

  getLogs() {}
}
