import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import * as uuid from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TransactionService } from 'src/app/modules/transactions/services/transaction.service';
import { takeUntil, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SearchForJlo } from '../../store/action';
import { DripCampaignService } from '../../services/drip-campaign.service';
import { selectjloSearchResult } from '../../store/selector';

@Component({
  selector: 'dailyai-share-model',
  templateUrl: './share-model.component.html',
  styleUrls: ['./share-model.component.scss'],
})
export class ShareModelComponent implements OnInit, OnDestroy {
  currentUser$: Observable<any>;

  currentUserDetails;

  parentId;

  juniorsList;

  sharedToArray;

  dripCampaingDetails;

  dripCampaingDetailsclone;

  transactionDetails;

  transactionDetailsclone;

  sharedToArrayLength;

  marked = false;

  url;

  transactionSharedto = {};

  userSearch$ = new Subject<string>();

  privilagedList: any;

  select_all = false;

  selected_any = false;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  juniorsList$: Observable<any>;

  Search$: Subject<any> = new Subject<any>();

  userSearch = '';

  public search = '';

  resultArray: any[];

  juniorsListTogetShared: any[];

  tab = 'otherOfficers';

  searchJLOs$: Subject<string> = new Subject();

  constructor(
    private modalService: NgbModal,
    private store: Store<any>,
    private campaignService: DripCampaignService,
    private transactionService: TransactionService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.url = this.router.url;
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.subscribe((data) => {
      if (data) {
        this.currentUserDetails = data;
        this.parentId = data.parentId ? data.parentId : data.uid;
      }
    });
    await this.getAllJuniorUsers(this.parentId);
    this.userSearch$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val) => {
          this.searchJlo(val);
          return of(val);
        })
      )
      .subscribe();
    this.searchJLOs$
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(500), distinctUntilChanged())
      .subscribe(this.searchJlo.bind(this));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async getAllJuniorUsers(parentId) {
    this.juniorsListTogetShared = await this.campaignService.getJuniorOfficersList(parentId);
    this.store.dispatch(SearchForJlo({ data: { id: this.parentId, searchTerm: '' } }));
    this.store.pipe(select(selectjloSearchResult), takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      if (val) {
        this.juniorsList = val.map((v) => ({ ...v, checked: false }));
        if (this.url !== '/transactions') {
          this.juniorsList = this.juniorsList.filter(
            (pvalue) =>
              pvalue.uid !== this.currentUserDetails.uid || this.dripCampaingDetailsclone.added_by !== pvalue.uid
          );
          this.sharedToArray =
            this.dripCampaingDetailsclone && this.dripCampaingDetailsclone['shared_to']
              ? [...this.dripCampaingDetailsclone['shared_to']]
              : [];
          this.resultArray = [...this.sharedToArray];
          let removeAlreadySharedOfficers = this.juniorsList;
          for (const value of this.sharedToArray) {
            removeAlreadySharedOfficers = removeAlreadySharedOfficers.filter((val) => val.uid !== value.uid);
          }
          this.juniorsList = removeAlreadySharedOfficers;
        } else if (this.url === '/transactions') {
          this.sharedToArray = [];
          this.sharedToArray = this.juniorsListTogetShared.filter(
            (jlo) =>
              this.currentUserDetails.transaction_shared_to?.includes(jlo.uid) ||
              this.currentUserDetails.privilaged_shared_to?.includes(jlo.uid)
          );
          this.resultArray = [...this.sharedToArray];
          this.privilagedList = this.juniorsList.filter((jlo) =>
            this.currentUserDetails.privilaged_shared_to?.includes(jlo.uid)
          );

          this.juniorsList = this.juniorsList.filter(
            (jlo) =>
              !this.currentUserDetails.transaction_shared_to?.includes(jlo.uid) &&
              !this.currentUserDetails.privilaged_shared_to?.includes(jlo.uid)
          );
          this.juniorsList = this.juniorsList.map((v) => ({
            ...v,
            checked: false,
          }));
        }
        this.Search$.pipe(takeUntil(this.ngUnsubscribe), debounceTime(300), distinctUntilChanged()).subscribe((v) => {

          if (!v || v.length === 0) {
            this.resultArray = [...this.sharedToArray];
            return;
          }
          const regex = new RegExp(v, 'i');
          this.resultArray = this.sharedToArray.filter(
            (u) =>
              u.firstName.toLowerCase().search(regex) > -1 ||
              u.email.toLowerCase().search(regex) > -1 ||
              u.lastName.toLowerCase().search(regex) > -1 ||
              u.phoneNo.toLowerCase().search(regex) > -1
          );
        });
      }
    });
  }

  async ShareTo() {
    this.sharedToArray = this.juniorsList.filter((item) => item.checked);
    this.sharedToArrayLength = this.sharedToArray.length;
    if (this.sharedToArrayLength) {
      this.juniorsList = this.juniorsList.filter((item) => !item.checked);
      if (this.url === '/drip-campaign') {
        this.dripCampaingDetailsclone['active_contacts_count'] = 0;
        if (this.dripCampaingDetailsclone['active_contacts']) {
          this.dripCampaingDetailsclone['active_contacts'] = [];
        }
        const arr = [];
        let sharedto = [];
        if (this.dripCampaingDetailsclone['number_of_times_shared'] >= 0) {
          const sharedTimes = this.dripCampaingDetailsclone['number_of_times_shared'] + 1;
          this.dripCampaingDetailsclone['campaign_name'] =
            `${this.dripCampaingDetailsclone['campaign_name']  }-shared${  sharedTimes}`;
        } else {
          this.dripCampaingDetailsclone['campaign_name'] = `${this.dripCampaingDetailsclone['campaign_name']  }-shared1`;
        }
        for (const officer of this.sharedToArray) {
          this.dripCampaingDetailsclone['number_of_times_shared'] = 0;
          this.dripCampaingDetailsclone['shared_by'] = this.currentUserDetails['uid'];
          this.dripCampaingDetailsclone['added_by'] = officer['uid'];
          this.dripCampaingDetailsclone['copies_made'] = 0;
          this.dripCampaingDetailsclone['twilio_number'] =
            officer['twilioNumber'] && officer['twilioNumber']['phoneNumber']
              ? officer['twilioNumber']['phoneNumber']
              : '';
          if (this.dripCampaingDetailsclone && this.dripCampaingDetailsclone['campaign_steps']) {
            for (const step of this.dripCampaingDetailsclone['campaign_steps']) {
              step['step_id'] = uuid.v4();
            }
          }

          const { _id, firebase_id, ...data } = this.dripCampaingDetailsclone;
          arr.push(data);
        }
        sharedto = this.dripCampaingDetailsclone['shared_to'] ? [...this.dripCampaingDetailsclone['shared_to']] : [];
        if (this.dripCampaingDetailsclone['shared_to']) {
          for (const officer of this.dripCampaingDetailsclone['shared_to']) {
            this.sharedToArray.push(officer);
          }
        }
        sharedto = this.sharedToArray.map((val) => {
          return {
            firstName: val.firstName,
            lastName: val.lastName,
            email: val.email ? val.email : '',
            phoneNo: val.phoneNo ? val.phoneNo : '',
            uid: val.uid ? val.uid : '',
          };
        });
        if (arr.length > 0) {
          const shareDrips = await this.campaignService.sharedCampaignAdd(arr);
          const shareDripto = await this.campaignService.campaignSharingDetailsUpdate(
            sharedto,
            this.dripCampaingDetails['drip_campaign_id']
          );
        } else {
          this.toastr.error('Please select atleast one name from the list');
        }
        this.toastr.success(`Drip campaign ${  this.dripCampaingDetailsclone['campaign_name']  } shared successfully`);
        this.modalService.dismissAll();
      } else if (this.url === '/transactions') {
        const offlist = [];
        if (!offlist.includes(this.currentUserDetails['uid'])) {
          offlist.push(this.currentUserDetails['uid']);
        }
        if (this.sharedToArray.length > 0) {
          for (const officer of this.sharedToArray) {
            offlist.push(officer.uid);
          }
          this.transactionService.shareTransaction(offlist, this.currentUserDetails.uid);
          this.modalService.dismissAll();
          this.toastr.success('Transactions shared successfully');
        }
      }
    } else {
      this.toastr.error('Please select atleast one name from the list');
    }
  }

  updateItem(e, type) {
    if (!e.target.checked) {
      const updateItem = this.juniorsList.find(this.findIndexToUpdate, type);
      const index = this.juniorsList.indexOf(updateItem);
    }
    const unselectedUsers = this.juniorsList.filter((v) => v.checked === false || !v.checked);
    this.select_all = unselectedUsers.length === 0;
  }

  findIndexToUpdate(type) {
    return type.id === this;
  }

  onSelectAll(e: any): void {
    this.juniorsList = this.juniorsList.map((v) => ({
      ...v,
      checked: e,
    }));
  }

  searchJlo(eve: string) {
    this.store.dispatch(SearchForJlo({ data: { id: this.parentId, searchTerm: eve } }));
  }

  @Input()
  set dripCampaign(pValue) {
    this.dripCampaingDetails = pValue;
    this.dripCampaingDetailsclone = JSON.parse(JSON.stringify(this.dripCampaingDetails));
  }

  @Input()
  set transaction(pValue) {
    this.transactionDetails = pValue;
  }

  tabClick(value) {
    this.tab = value;
  }
}
