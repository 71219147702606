<div class="d-flex justify-content-center mt-3" [ngClass]="{ 'w-650': !isModal }">
  <div *ngFor="let plan of plans; let i = index; first as isFirst; last as isLast"
    (click)="choosePlan(plan.nickname, i)" class="d-flex flex-column justify-content-center pb-3 card-t" [ngClass]="{
      'ml-3': !isFirst,
      'card-box': selectedPlanIndex === i || isModal,
      'card-box-inactive': selectedPlanIndex !== i && !isModal,
      'cursor-pointer w-36': !isModal
    }">
    <div class="header-card w-100">
      <span class="text-capitalize font-16 pb-2">{{ planNames[plan.nickname] }}</span>
      <div *ngIf="!isModal" class="pb-3 pt-3 d-flex justify-content-center w-100">
        <div *ngIf="!loader" class="w-50">
          <select class="custom-select" [(ngModel)]="selectedPeriod[plan.nickname]">
            <option value="1_year">1 year</option>
            <option *ngFor="let coupon of coupons" [value]="coupon.id"> {{ coupon.id.split('_').join(' ') }}</option>
          </select>
        </div>
        <div *ngIf="loader" class="custom-select w-50 d-flex align-items-center justify-content-center">
          <div id="loadingProgressG">
            <div id="loadingProgressG_1" class="loadingProgressG"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center pt-2">
      <span>for </span>
      <span>{{ plan.min }} to {{ plan.max }} users </span>
      <span>you pay</span>
      <div class="d-flex align-items-center line-height-1 py-1 mt-1">
        <span class="font-18 font-weight-normal color-primary">${{
            ((plan.amount / 100) * selectedDiscount(selectedPeriod[plan.nickname]) *
             numberOfUsers[plan.nickname]).toFixed(2).replace(truncator,'')
          }}</span>
        <span class="scratched font-12 pl-1"
          *ngIf="selectedPeriod[plan.nickname]!=='1_year'">${{ (plan.amount / 100) * numberOfUsers[plan.nickname] }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center border w-80px mt-2 p-1">
        <button [disabled]="numberOfUsers[plan.nickname] <= plan.min" (click)="decrUserCount(plan.nickname)"
          class="button-invis">
          <span class="material-icons color-black-grey-text font-18 font-weight-normal cursor-pointer">remove</span>
        </button>
        <span class="font-14 color-black-grey-text">{{ numberOfUsers[plan.nickname] }}</span>
        <button [disabled]="numberOfUsers[plan.nickname] >= plan.max" (click)="incrUserCount(plan.nickname)"
          class="button-invis">
          <span class="material-icons color-black-grey-text font-18 font-weight-normal cursor-pointer">add</span>
        </button>
      </div>
      <span class="font-12 color-black-grey-text">${{ plan.amount / 100 }}/user</span>
      <button class="btn btn-border mx-2 mt-3 w-100px" (click)="upgradePlan(plan.nickname, i)" *ngIf="isModal">
        Upgrade
        <span [ngClass]="{ 'spinner-border spinner-border-sm': isLoading && selectedPlanIndex===i,
         nil: !isLoading }" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>
  <div class="card-box-inactive d-flex flex-column pb-3 ml-3" [ngClass]="{ 'w-36': !isModal }" *ngIf="isModal">
    <div class="header-card w-100">
      <span class="text-capitalize font-16">Enterprise</span>
      <div *ngIf="!isModal" class="pb-3 pt-3 d-flex justify-content-center w-100">
        <select class="custom-select w-50" disabled>
          <option value="1_year">1 year</option>
        </select>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center pt-1 text-center h-100">
      <span>Our team member </span>
      <span>will contact you to </span>
      <span>help you get the </span>
      <span>best price</span>
      <button class="btn btn-border mx-2 mt-4 w-100px" disabled>Upgrade</button>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center mt-3" *ngIf="!isModal">
  <div>
    <div class="d-flex mb-4">
      <label class="v2-checkbox mb-0">
        <input type="checkbox" id="gridCheck" [(ngModel)]="terms" /> <span class="checkmark"></span>
      </label>
      <label class="form-check-label pl-1 font-12">
        I agree to the
        <a class="color-primary cursor-pointer font-12" (click)="openTerms()">Terms and Conditions </a>
      </label>
    </div>
    <div>
      <button class="btn btn-primary w-100" (click)="saveAndProceed()" [disabled]="!selectedPlan || !terms">
        CONFIRM
        <span [ngClass]="{ 'spinner-border spinner-border-sm': isLoading, nil: !isLoading }" role="status"
          aria-hidden="true"></span>
      </button>
    </div>
  </div>
</div>