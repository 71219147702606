import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss']
})
export class OtpPopupComponent implements OnInit {
  constructor(public modal: NgbModal, ) { }

  ngOnInit() { }

  keytab(event, maxLength) {
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
