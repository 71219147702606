import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/modules/settings/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'dailyai-name-change-modal',
  templateUrl: './name-change-modal.component.html',
  styleUrls: ['./name-change-modal.component.scss'],
})
export class NameChangeModalComponent implements OnInit {
  changeNameForm: FormGroup;

  @Input() fromParent;

  isShown = false;

  newFirstName: string;

  newLastName: string;

  currentUser: any;

  saveButtonLabel = 'Save';

  loading = false;

  message = {
    success: `
      <div>Your name was changed.</div>
      <div><b>Note:</b> Your custom email signature needs to be updated manually.</div>
      <br />
      <div>This update applies to your V-Card and merge fields that include your first and/or last name.</div>
    `,
    error: `
      <span>Something went wrong and your name did not change. You can try again, but if the problem persist contact technical support.</span>
      <ul>
        <li>Call Daily AI support at (720)548-6794.</li>
        <li>Email Daily AI support at support@dailyai.io.</li>
      </ul>
    `
  };

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userService: UserService,
  ) {
    this.changeNameForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.newFirstName = this.fromParent.prop1.firstName;
    this.newLastName = this.fromParent.prop1.lastName;
    this.currentUser = this.fromParent.prop1.uid;
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  async changeName() {
    this.loading = true;
    this.saveButtonLabel = 'Saving...';
    try {
      await this.userService.changeUserName(this.newFirstName, this.newLastName);
      this.setPostNameChangeState();
      this.toastr.success(this.message.success, 'Success', {
        enableHtml: true,
        timeOut: 10000,
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-full-width',
      });
    } catch (error) {
      this.setPostNameChangeState();
      this.toastr.error(this.message.error, 'Error', {
        enableHtml: true,
        disableTimeOut: true,
        closeButton: true,
        positionClass: 'toast-top-full-width',
      });
    }
  }

  setPostNameChangeState() {
    this.loading = false;
    this.saveButtonLabel = 'Save';
    this.closeModal();
  }
}
