/* eslint-disable max-classes-per-file */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { LyTheme2, StyleRenderer, LY_THEME, LY_THEME_NAME, LY_THEME_GLOBAL_VARIABLES } from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { color } from '@alyle/ui/color';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LySliderModule } from '@alyle/ui/slider';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { OnlyNumberDirective } from './directives/nonumber.directive';
import { NoKeyDirective } from './directives/nokeypress.directive';
import { HiddenInputComponent } from './components/hidden-input/hidden-input.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { VideoListComponent } from '../modules/media/components/video-list/video-list.component';
import { AudioListComponent } from '../modules/media/components/audio-list/audio-list.component';
import { ScrollableDirective } from './directives/scrollable.directive';
import { CheckboxConfirmationComponent } from './components/checkbox-confirmation/checkbox-confirmation.component';
import { MediaAudioListComponent } from '../modules/drip-campaign/components/media-audio-list/media-audio-list.component';
import { MediaAudioSelectFromComponent } from '../modules/drip-campaign/components/media-audio-select-from/media-audio-select-from.component';
import { ShareAddTaskComponent } from './components/share-add-task/share-add-task.component';
import { ShareAddTemplateComponent } from './components/share-add-template/share-add-template.component';
import { ShareMapAttributesComponent } from './components/share-map-attributes/share-map-attributes.component';
import { ShareMediaAudioSelectComponent } from './components/share-media-audio-select/share-media-audio-select.component';
import { ShareMediaAudioListComponent } from './components/share-media-audio-list/share-media-audio-list.component';
import { ShareAddContactComponent } from './components/share-add-contact/share-add-contact.component';
import { EmailAttachmentComponent } from './components/email-attachment/email-attachment.component';
import { SharedAddEditEmailPopupComponent } from './components/shared-add-edit-email-popup/shared-add-edit-email-popup.component';
import { DripListComponent } from './components/drip-list/drip-list.component';
import { ViewEmailCampaignDetaisPopupComponent } from './components/view-email-campaign-detais-popup/view-email-campaign-detais-popup.component';
import { ConfirmWithNoteComponent } from './components/confirm-with-note/confirm-with-note.component';
import { PhoneNumberFormat } from './pipes/phoneNumber.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { NotingToShowComponent } from './components/noting-to-show/noting-to-show.component';
import { CalenderEventsModelComponent } from './components/calender-events-model/calender-events-model.component';
import { NameChangeModalComponent } from './components/name-change-modal/name-change-modal.component';
import { EmailChangeModalComponent } from './components/email-change-modal/email-change-modal.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { CompanyNameChangeModalComponent } from './components/company-name-change-modal/company-name-change-modal.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { MilestonesNotificationComponent } from './components/milestones-notification/milestones-notification.component';
import { ProfileImageChangeModalComponent } from './components/profile-image-change-modal/profile-image-change-modal.component';
import { VerifiedNumberChangeComponent } from './components/verified-number-change/verified-number-change.component';
import { ConfirmVerifiedModalComponent } from './components/confirm-verified-modal/confirm-verified-modal.component';
import { AppliedFilterBubblesComponent } from './components/applied-filters/applied-filter-bubbles.component';
import { AppliedFiltersComponent } from './components/applied-filters/applied-filters.component';

import { ToCcBccComponent } from './components/to-cc-bcc/to-cc-bcc.component';
import { ResyncMilestoneModalComponent } from './components/resync-milestone-modal/resync-milestone-modal.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ShareSvgComponent } from './components/share-svg/share-svg.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { InfoNotificationComponent } from './components/info-notification/info-notification.component';
import { TextEncodingWarningComponent } from './components/text-encoding-warning/text-encoding-warning.component';



export class GlobalVariables {
  testVal = color(0x00bcd4);

  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e),
  };

  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xb36fbc),
  };

  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87),
  };
}

@NgModule({
  declarations: [
    OnlyNumberDirective,
    VideoListComponent,
    NoKeyDirective,
    FilterPipe,
    PhoneNumberFormat,
    SafeHtmlPipe,
    HiddenInputComponent,
    LoaderComponent,
    ConfirmationComponent,
    AudioListComponent,
    ScrollableDirective,
    CheckboxConfirmationComponent,
    MediaAudioListComponent,
    MediaAudioSelectFromComponent,
    ShareAddTaskComponent,
    ShareAddTemplateComponent,
    ShareMapAttributesComponent,
    ShareMediaAudioSelectComponent,
    ShareMediaAudioListComponent,
    ShareAddContactComponent,
    EmailAttachmentComponent,
    SharedAddEditEmailPopupComponent,
    DripListComponent,
    ViewEmailCampaignDetaisPopupComponent,
    ConfirmWithNoteComponent,
    ChoosePlanComponent,
    NotingToShowComponent,
    CalenderEventsModelComponent,
    NameChangeModalComponent,
    EmailChangeModalComponent,
    EmailConfirmationComponent,
    CompanyNameChangeModalComponent,
    ImageCropperComponent,
    ToCcBccComponent,
    VerifiedNumberChangeComponent,
    ConfirmVerifiedModalComponent,
    AppliedFilterBubblesComponent,
    InfoNotificationComponent,
    MilestonesNotificationComponent,
    AppliedFiltersComponent,
    ProfileImageChangeModalComponent,
    ResyncMilestoneModalComponent,
    SearchInputComponent,
    ShareSvgComponent,
    DateInputComponent,
    TextEncodingWarningComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    NgbModule,
    CKEditorModule,
    PickerModule,
    EmojiModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    AutoSizeInputModule,
  ],
  exports: [
    OnlyNumberDirective,
    NoKeyDirective,
    SafeHtmlPipe,
    FilterPipe,
    PhoneNumberFormat,
    HiddenInputComponent,
    LoaderComponent,
    VideoListComponent,
    AudioListComponent,
    ScrollableDirective,
    EmailAttachmentComponent,
    ChoosePlanComponent,
    NotingToShowComponent,
    ToCcBccComponent,
    AppliedFilterBubblesComponent,
    AppliedFiltersComponent,
    InfoNotificationComponent,
    MilestonesNotificationComponent,
    SearchInputComponent,
    ShareSvgComponent,
    DateInputComponent,
    TextEncodingWarningComponent
  ],
  providers: [
    NgbActiveModal,
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true,
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true,
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
