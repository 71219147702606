import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
import { AuthService } from 'src/app/services/auth.service';
import { selectCustomFieldsList } from 'src/app/modules/settings/store/selector';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ContactsState } from "../../../modules/contacts/store/state";
import {
  AddContact,
  UpdateContact,
  GetContactDetail,
  GetTagsList,
} from "../../../modules/contacts/store/action";
import { selectCurrentUser } from '../../../modules/login/store/selectors';
import {
  selectAddContactError,
  selectAddContact,
  selectAddContactSuccess,
  selectUpdateContactError,
  selectUpdateContactSuccess,
  selectContactList,
  selectGetTagsList,
} from "../../../modules/contacts/store/selector";
import { ContactService } from '../../../modules/contacts/services/contact.service';
import { selectSourcesList , selectDripWithDemand } from '../../../modules/drip-campaign/store/selector';
import {
  ListSources,
  ListDripWithDemandScroll,
  ResetDemandScrollData,
} from '../../../modules/drip-campaign/store/action';
import { DripCampaignService } from '../../../modules/drip-campaign/services/drip-campaign.service';
import { timeZonesMap } from '../../utils';
import { UserType } from '../../enums/commonEnums';

const cCode = environment.countryCode;

@Component({
  selector: 'dailyai-share-add-contact',
  templateUrl: './share-add-contact.component.html',
  styleUrls: ['./share-add-contact.component.scss'],
})
export class ShareAddContactComponent implements OnInit, OnDestroy, AfterViewChecked {
  uid = '';

  userID: string;

  selectValue = 'Select Partner';

  emailValidity = true;

  phoneValidity = true;

  noPhoneEmail = false;

  editContact = false;

  addContactsForm: FormGroup;

  addContacts$: Observable<any>;

  addContactsError$: Observable<any>;

  addContactsSuccess$: Observable<any>;

  updateContactsError$: Observable<any>;

  updateContactsSuccess$: Observable<any>;

  currentUser$: Observable<any>;

  contactList$: Observable<any>;

  sourcesList$: Observable<any>;

  MyDrips$: Observable<any>;

  MyUsers$: Observable<any>;

  MyUsers: any[] = [];

  MySLO$: Observable<any>;

  contactList: any[] = [];

  selectedIds: any[] = [];

  contactMailList: any[] = [];

  fnamePattern = '^([a-zA-Z]+ ?)+$';

  addressPattern = '^([a-zA-Z0-9/.,]+ ?)+$';

  phnoPattern = '^[1-9]{1}[0-9]{9}$';

  cCodePattern = '^\\+[0-9]+$';

  noCommaPattern = '^[^,]+$';

  selectedTags = [];

  checkbox = {};

  searchDrip = '';

  selectedPartner: { partnerName: string; partnerId: string };

  partnersList: { firstName: string; lastName: string; partnerId: string }[] = [];

  ngUnsubscribe: Subject<any> = new Subject<any>();

  public today = new Date();

  dripsForm: FormGroup = this.fb.group({

  });

  tagCheckbox: any = [];

  checkbox1 = true;

  leads = [{ name: 'manual' }, { name: 'LOS' }, { name: 'zapier' }, { name: 'website' }];

  genders = ['Male', 'Female', 'Others'];

  isFormValid = null;

  user: any;

  assignedUser = '';

  newPhoneNumber: number = null;

  tagsList: any = {};

  selectTagvalue = 'Select Tags To Assign';

  tagsList$: Observable<any>;

  checkingValidity = false;

  usersList: any[] = [];

  @Input() contact: any;

  mySLOID: any;

  @Output()
  closed: EventEmitter<any> = new EventEmitter<any>();

  parentId: any;

  @Output() bottom: EventEmitter<any> = new EventEmitter<any>();

  dripData: any[] = [];

  selectvalue = 'Choose Drip Campaigns';

  selectedDripsIds: any[] = [];

  selectedTagsObj: any = {};

  customFields: any[] = [];

  @Input() partnerList: any;

  tagsSearch = '';

  get tagsListOnSearch() {
    return this.tagsSearch === ''
      ? this.tagsList
      : this.tagsList.filter((tag: string) => tag.toLowerCase().match(this.tagsSearch.toLowerCase()));
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<ContactsState>,
    public modal: NgbModal,
    private auth: AuthService,
    private contactService: ContactService,
    private dripService: DripCampaignService,
    private toast: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    const dob: string = this.contact ? this.contact.dateOfBirth : null;
    let contDob = {
      day: 1,
      month: 2,
      year: 2004,
    };
    if (dob) {
      const YMD = dob.split('-');
      const day = Number.parseInt(YMD[2], 10);
      const month = Number.parseInt(YMD[1], 10);
      const year = Number.parseInt(YMD[0], 10);
      contDob = { day, month, year };
    }
    this.addContactsForm = this.fb.group({
      email: [this.contact ? this.contact.email : ''],
      firstName: [
        this.contact ? this.contact.firstName : '',
        [Validators.required, Validators.pattern(this.noCommaPattern)],
      ],
      lastName: [this.contact ? this.contact.lastName : '', [Validators.pattern(this.noCommaPattern)]],
      dateOfBirth: [
        this.contact ? { day: contDob.day, month: contDob.month, year: contDob.year } : '',
        [Validators.pattern('^[0-9]$')],
      ],
      countryCode: [
        this.contact ? (this.contact.countryCode ? this.contact.countryCode : cCode) : cCode,
        [Validators.pattern(this.cCodePattern)],
      ],
      phoneNumber: [
        this.contact ? this.contact.phoneNumber.replace(/\D/g, '').slice(-10) : '',
        [Validators.pattern(this.phnoPattern)],
      ],
      addressLine1: [this.contact ? this.contact.addressLine1 : '', Validators.pattern(this.addressPattern)],
      addressLine2: [this.contact ? this.contact.addressLine2 : '', Validators.pattern(this.addressPattern)],
      city: [this.contact ? this.contact.city : '', Validators.pattern(this.fnamePattern)],
      state: [this.contact ? this.contact.state : '', Validators.pattern(this.fnamePattern)],
      gender: [this.contact ? this.contact.gender : null],
      source: [this.contact ? this.contact.source : null],
      lead: [this.contact ? this.contact.lead : ''],
      partnerId: [this.contact ? this.contact.partnerId : null],
      assignedto: [this.contact ? this.contact.contact_assigned_to : null, Validators.required],
      dripCampaigns: [null],
      tags: [null],
      searchDrip: [''],
    });
  }

  getAddContactTeamLeaderId (user:any) {
    let id: any = "";
    if (user.teamLeaderId)
      id = user.teamLeaderId;
    else
      id = [UserType.MANAGER, UserType.TEAM_LEADER].includes(this.user.userType) ? user.uid : user.enterpriseId;

    return id;
  }

  ngOnInit() {
    this.MyUsers = [];
    if (this.contact) {
      this.addContactsForm.get('source').disable();
      this.store.dispatch(GetContactDetail({ id: this.contact.doc_id }));
    }

    this.tagsList$ = this.store.pipe(select(selectGetTagsList));
    this.tagsList$.subscribe((val) => {
      this.tagsList = val;
      this.tagsList = this.tagsList.filter((item, index, inputArray) => {
        return inputArray.indexOf(item) === index;
      });
    });

    this.contactList$ = this.store.pipe(select(selectContactList));
    this.contactList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list) => {
      this.contactList = list;
      for (const value of this.contactList) {
        this.contactMailList.push(value.email);
      }
      if (this.contact) {
        const mail = this.contact.email;
        const index = this.contactMailList.indexOf(mail);
        this.contactMailList.splice(index, 1);
      }
    });

    this.currentUser$ = this.store.pipe(select(selectCurrentUser), takeUntil(this.ngUnsubscribe));
    this.currentUser$.subscribe(async (data) => {
      this.uid = data ? (data.uid ? data.uid : '') : '';
      this.user = data;
      this.assignedUser = this.uid;
      const userId = data.parentId ? data.parentId : data.uid;
      this.usersList = await this.contactService.getAllUsers(userId);
      this.userID = data.parentId ? data.parentId : data.uid;
      if (data) {
        this.parentId = data.parentId ? data.parentId : data.uid;
        this.getTags(this.parentId, data);
      }
    });

    if (this.user.parentId) {
      this.store.dispatch(ListSources({ user: this.user }));
      this.sourcesList$ = this.store.pipe(select(selectSourcesList));
    } else {
      this.store.dispatch(ListSources({ user: this.user }));
      this.sourcesList$ = this.store.pipe(select(selectSourcesList));
    }
    this.store.dispatch(ResetDemandScrollData());
    this.store.dispatch(ListDripWithDemandScroll({ user_id: this.uid, user: this.user }));
    this.addContacts$ = this.store.pipe(select(selectAddContact), takeUntil(this.ngUnsubscribe));
    this.addContactsSuccess$ = this.store.pipe(select(selectAddContactSuccess), takeUntil(this.ngUnsubscribe));
    this.addContactsError$ = this.store.pipe(select(selectAddContactError), takeUntil(this.ngUnsubscribe));
    this.updateContactsSuccess$ = this.store.pipe(select(selectUpdateContactSuccess), takeUntil(this.ngUnsubscribe));
    this.updateContactsError$ = this.store.pipe(select(selectUpdateContactError), takeUntil(this.ngUnsubscribe));
    this.MyDrips$ = this.store.pipe(select(selectDripWithDemand), takeUntil(this.ngUnsubscribe));
    this.MyDrips$.subscribe((drip) => {
      if (drip) {
        this.dripData = drip;
      }
      if (this.dripData && this.dripData.length > 0) {
        for (const elem of this.dripData) {
          let state = false;
          if (this.selectedDripsIds.includes(elem.drip_campaign_id)) {
            state = true;
          }
          this.dripsForm.addControl(elem.drip_campaign_id, new FormControl(state));
        }
      }
    });

    const newDob = this.contact && this.contact.dateOfBirth ? new Date(this.contact.dateOfBirth) : null;
    const dob: string = this.contact ? this.contact.dateOfBirth : null;
    let contDob = {
      day: 1,
      month: 2,
      year: 2004,
    };
    if (typeof dob === 'string') {
      const YMD = dob.split('-');
      const day = Number.parseInt(YMD[2], 10);
      const month = Number.parseInt(YMD[1], 10);
      const year = Number.parseInt(YMD[0], 10);
      contDob = { day, month, year };
    }
    this.addContactsForm = this.fb.group({
      email: [this.contact ? this.contact.email : ''],
      firstName: [
        this.contact ? this.contact.firstName : '',
        [Validators.required, Validators.pattern(this.noCommaPattern)],
      ],
      lastName: [this.contact ? this.contact.lastName : '', [Validators.pattern(this.noCommaPattern)]],
      dateOfBirth: [
        newDob ? { day: newDob.getDate(), month: newDob.getMonth() + 1, year: newDob.getFullYear() } : null,
      ],
      countryCode: [
        this.contact ? (this.contact.countryCode ? this.contact.countryCode : cCode) : cCode,
        [Validators.pattern(this.cCodePattern)],
      ],
      phoneNumber: [
        this.contact ? (this.contact.phoneNumber ? this.contact.phoneNumber.replace(/\D/g, '').slice(-10) : '') : '',
        [Validators.pattern(this.phnoPattern)],
      ],
      addressLine1: [this.contact ? this.contact.addressLine1 : ''],
      addressLine2: [this.contact ? this.contact.addressLine2 : ''],
      city: [this.contact ? this.contact.city : ''],
      state: [this.contact ? this.contact.state : ''],
      gender: [this.contact ? (this.contact.gender === '' ? null : this.contact.gender) : null],
      source: [this.contact ? this.contact.source : null],
      partnerId: [this.contact ? this.contact.partnerId : null],
      assignedto: [
        this.contact
          ? this.contact.contact_assigned_to
          : this.user.userType === 'MO'
          ? this.user.parentId
          : this.user.uid,
        Validators.required,
      ],
      dripCampaigns: [''],
      searchDrip: [''],
      tags: [''],
    });
    this.addContactsForm
      .get('searchDrip')
      .valueChanges.pipe(debounceTime(30), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(async (data) => {
        this.store.dispatch(
          ListDripWithDemandScroll({
            user_id: this.uid,
            user: this.user,
            data: { text: data, curPage: 1 },
            search: true,
          })
        );
      });
    this.addContactsForm
      .get('email')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(async (value) => {
        const teamLeaderId = this.getAddContactTeamLeaderId(this.user);
        this.emailValidity = false;
        if (this.contact && value) {
          this.checkingValidity = true;
          this.emailValidity = await this.contactService.isEmailValidOnUpdate(value, this.uid,teamLeaderId, this.contact.doc_id);
          this.checkingValidity = false;
        } else if (value) {
          this.checkingValidity = true;
          this.emailValidity = await this.contactService.isEmailValid(value, this.uid);
          this.checkingValidity = false;
        }
      });
    this.addContactsForm
      .get('phoneNumber')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe(async (value) => {
        const teamLeaderId = this.getAddContactTeamLeaderId(this.user);
        this.phoneValidity = false;
        if (this.contact && value) {
          this.checkingValidity = true;
          this.phoneValidity = await this.contactService.isPhoneValidOnUpdate(value, this.uid, teamLeaderId, this.contact.doc_id);
          this.checkingValidity = false;
        } else if (value) {
          this.checkingValidity = true;
          this.phoneValidity = await this.contactService.isPhoneValid(value, teamLeaderId, this.uid);
          this.checkingValidity = false;
        }
      });

    this.store
      .pipe(takeUntil(this.ngUnsubscribe), select(selectCustomFieldsList))
      .subscribe(this.getCustomFields.bind(this));
  }

  getCustomFields(customFields) {
    if (this.contact && this.contact.custom && customFields) {
      this.customFields = customFields.custom_fields_contacts;
      const cc = this.fb.group({});
      this.customFields.forEach((field) => {
        let tempdata = this.contact.custom[field.backendName];
        if (field.fieldType === 'date' && tempdata) {
          tempdata = this.isoStringtodateObj(tempdata, this.user.timezone);
        }
        cc.addControl(field.backendName, new FormControl(tempdata || null));
      });
      this.addContactsForm.addControl('custom', cc);
    } else if (customFields && customFields.custom_fields_contacts) {
        this.customFields = customFields.custom_fields_contacts;
        if (this.customFields) {
          const cc = this.fb.group({});
          this.customFields.forEach((field) => {
            cc.addControl(field.backendName, new FormControl(null));
          });
          this.addContactsForm.addControl('custom', cc);
        }
      }
  }

  async getDrips() {
    if (this.selectedDripsIds && this.selectedDripsIds.length > 0) {
      const dripValue = await this.dripService.getDripDataById(this.selectedDripsIds[0]);
      if (this.selectedDripsIds.length > 1) {
        this.selectvalue = ` ${dripValue.campaign_name} + ${this.selectedDripsIds.length - 1} more..`;
      } else if (this.selectedDripsIds.length === 1) {
        this.selectvalue = dripValue.campaign_name;
      } else {
        this.selectvalue = 'Choose Drip Campaigns';
      }
    }
  }

  tagsSelectionCheckbox(tag) {
    const find = this.selectedTags.find((selectedTag) => selectedTag === tag);
    if (undefined !== find) {
      return true;
    }
    return false;
  }

  getTags1() {
    this.tagsList.filter((val) => this.selectedTags.includes(val));
    if (this.selectedTags.length > 1) {
      this.selectTagvalue = ` ${this.selectedTags[0]} + ${this.selectedTags.length - 1} more..`;
    } else if (this.selectedTags.length === 1) {
      this.selectTagvalue = this.selectedTags[0];
    } else {
      this.selectvalue = 'Select tags to assign';
    }
  }

  setTags(event, tag) {
    if (event.target.checked === true) {
      this.selectedTags.push(tag);
    } else if (event.target.checked === false && this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter((val) => val !== tag);
        this.tagsList.push(tag);
        this.tagsList = this.tagsList.filter((item, index, inputArray) => {
          return inputArray.indexOf(item) === index;
        });
      }
  }

  trackByFn(index, item) {
    return index;
  }

  clearTagSelection() {
    this.selectedTags = [];
  }

  clearSelection() {
    this.selectvalue = 'Choose Drip Campaigns';
    this.addContactsForm.patchValue({ dripCampaigns: [] });
  }

  assignee(event) {
    this.assignedUser = this.addContactsForm.get('assignedto').value;
  }

  changeDob(date) {
    const a = date.split('-');
    return `${a[2]}-${a[1]}-${a[0]}`;
  }

  close(eve) {
    eve.close();
  }

  scrollHandler(event) {
    if (event === 'bottom') {
      this.bottom.emit(event);
    }
  }

  scrollHandlerDrip(event) {
    if (event === 'bottom') {
      this.store.dispatch(ListDripWithDemandScroll({ user_id: this.uid, user: this.user }));
    }
  }

  selectPartner(data) {
    this.selectedPartner = { partnerId: data.partnerId, partnerName: data.partnerName };
    this.selectValue = data.partnerName;
  }

  dripChecked(e, doc_id) {
    if (e.target.checked) {
      this.selectedDripsIds.push(doc_id);
    } else {
      this.selectedDripsIds = this.selectedDripsIds.filter((id) => id !== doc_id);
    }

    this.selectedDripsIds = _.uniq(this.selectedDripsIds);
  }

  generateDateWithTimeZone({
    date,
    month,
    year,
    timezone,
  }: {
    date: number;
    month: number;
    year: number;
    timezone: string;
  }) {
    const dateIso = moment
      .tz(timeZonesMap[timezone])
      .year(year)
      .month(month - 1)
      .date(date)
      .hour(0)
      .minute(1)
      .toISOString();

    return dateIso;
  }

  isoStringtodateObj(isoString, userTimezone) {
    const basedate = moment.tz(isoString, timeZonesMap[userTimezone]);
    const day = basedate.date();
    const month = basedate.month() + 1;
    const year = basedate.year();
    return { day, month, year };
  }

  async addContact() {
    this.selectPartner(this.partnerList);
    if (this.addContactsForm.value.email) {
      this.addContactsForm.controls.email.setValidators(Validators.email);
      this.addContactsForm.controls.email.updateValueAndValidity();
    }
    if (this.addContactsForm.invalid) {
      if (!this.addContactsForm.get('email').touched && !this.addContactsForm.get('phoneNumber').touched) {
        this.addContactsForm.markAllAsTouched();
        this.noPhoneEmail = true;
      }
      this.addContactsForm.markAllAsTouched();
      return;
    }
    if (!this.addContactsForm.value.phoneNumber && !this.addContactsForm.value.email) {
      this.noPhoneEmail = true;
      this.addContactsForm.markAllAsTouched();
    }
    if (
      (this.addContactsForm.get('email').touched &&
        this.addContactsForm.get('email').valid &&
        this.addContactsForm.get('email').value) ||
      (this.addContactsForm.get('phoneNumber').touched &&
        this.addContactsForm.get('phoneNumber').valid &&
        this.addContactsForm.get('phoneNumber').value)
    ) {
      this.noPhoneEmail = false;
      const dob = this.addContactsForm.value.dateOfBirth;
      const contactsData = this.addContactsForm.value;
      if (!this.addContactsForm.get('source').value) {
        contactsData.source = 'manual';
      }
      contactsData.dateOfBirth = dob ? this.setDate(dob) : null;
      contactsData.phoneNumber = this.addContactsForm.get('phoneNumber').value ? `${contactsData.countryCode}${contactsData.phoneNumber}` : '';
      contactsData.addedBy = this.uid;
      contactsData.status = 'Lead';
      contactsData.tags = this.selectedTags;
      const taskData: any = {};
      contactsData.firstName = contactsData.firstName.charAt(0).toUpperCase() + contactsData.firstName.slice(1);
      if (contactsData.lastName) {
        contactsData.lastName = contactsData.lastName.charAt(0).toUpperCase() + contactsData.lastName.slice(1);
      }

      taskData.contactName =
        `${this.addContactsForm.get('firstName').value} ${this.addContactsForm.get('lastName').value}`;
      taskData.task = 'person_add';
      taskData.visibility = false;
      taskData.taskStatus = 'completed';
      if (this.user.parentId) {
        contactsData.viewersList = this.uid === this.assignedUser ? [this.uid, this.user.parentId] : [this.uid, this.assignedUser, this.user.parentId];
      } else {
        contactsData.viewersList = this.uid === this.assignedUser ? [this.uid] : [this.uid, this.assignedUser];
      }
      if (this.user.userType !== 'MO') {
        const mo = await this.auth.getMOData(this.user.parentId ? this.user.parentId : this.user.uid);
        if (mo && mo.uid) {
          contactsData.viewersList.push(mo.uid);
        }
      }

      contactsData.contact_assigned_to = this.assignedUser;
      if (contactsData.partnerId) {
        const partner = this.partnersList.find((item) => item.partnerId === contactsData.partnerId);
        contactsData.partnerName = `${partner.firstName} ${partner.lastName}`;
      }
      if (contactsData.dripCampaigns === '') {
        contactsData.dripCampaigns = [];
      }

      if (this.customFields.length > 0) {
        contactsData.custom = { ...this.addContactsForm.get('custom').value };
        this.customFields.forEach((field) => {
          if (field.fieldType === 'date' && contactsData.custom[field.backendName]) {
            contactsData.custom[field.backendName] = this.generateDateWithTimeZone({
              ...contactsData.custom[field.backendName],
              timezone: this.user.timezone,
              date: contactsData.custom[field.backendName].day,
            });
          }
        });
      }

      if (this.emailValidity && this.phoneValidity && this.addContactsForm.valid) {
        this.store.dispatch(
          AddContact({
            data: { ...contactsData, ...this.selectedPartner, dripCampaigns: this.selectedDripsIds },
            task: taskData,
            ...this.selectedPartner,
          })
        );
        this.selectedPartner = null;
        this.addContactsForm.reset();
        this.modal.dismissAll();
      }
    }
  }

  async updateContact() {
    const teamLeaderId = this.getAddContactTeamLeaderId(this.user);
    if (this.addContactsForm.get('email').value && this.addContactsForm.get('email').touched) {
      this.checkingValidity = true;
      this.emailValidity = await this.contactService.isEmailValidOnUpdate(
        this.addContactsForm.get('email').value,
        this.userID,
        teamLeaderId,
        this.contact.doc_id
      );
      this.checkingValidity = false;
    } else {
      this.emailValidity = true;
    }
    if (this.addContactsForm.get('phoneNumber').value && this.addContactsForm.get('phoneNumber').touched) {
      this.checkingValidity = true;
      this.phoneValidity = await this.contactService.isPhoneValidOnUpdate(
        this.addContactsForm.get('phoneNumber').value,
        this.userID,
        teamLeaderId,
        this.contact.doc_id
      );
      this.checkingValidity = false;
    } else {
      this.phoneValidity = true;
    }
    if (this.addContactsForm.invalid) {
      if (this.addContactsForm.get('email').valid || this.addContactsForm.get('phoneNumber').valid) {
        this.noPhoneEmail = false;
      } else if (!this.addContactsForm.get('email').touched && !this.addContactsForm.get('phoneNumber').touched) {
        this.noPhoneEmail = true;
      }
      if (!this.addContactsForm.value.phoneNumber && !this.addContactsForm.value.email) {
        this.noPhoneEmail = true;
      }
      this.addContactsForm.markAllAsTouched();
      return;
    }
    if (this.addContactsForm.get('email').invalid || this.addContactsForm.get('phoneNumber').invalid) {
      this.noPhoneEmail = true;
      return;
    }
    if (this.addContactsForm.get('email').value || this.addContactsForm.get('phoneNumber').value) {
      this.noPhoneEmail = false;
      const updateData = this.addContactsForm.value;
      const dob = this.addContactsForm.value.dateOfBirth;
      updateData.dateOfBirth = dob ? this.setDate(dob) : null;
      updateData.phoneNumber = this.addContactsForm.get('phoneNumber').value ? `${updateData.countryCode}${updateData.phoneNumber}` : '';
      updateData.contact_assigned_to = this.addContactsForm.get('assignedto').value;
      updateData.viewersList = _.union([this.addContactsForm.get('assignedto').value], this.contact.viewersList);
      updateData.firstName = updateData.firstName.charAt(0).toUpperCase() + updateData.firstName.slice(1);
      if (updateData.lastName) {
        updateData.lastName = updateData.lastName.charAt(0).toUpperCase() + updateData.lastName.slice(1);
      }

      updateData.doc_id = this.contact.doc_id;
      if (this.selectedPartner) {
        updateData.partnerName = this.selectedPartner.partnerName;
        updateData.partnerId = this.selectedPartner.partnerId;
      }
      if (this.emailValidity && this.phoneValidity && this.addContactsForm.valid) {
        this.editContact = true;

        if (this.customFields.length > 0) {
          updateData.custom = { ...this.addContactsForm.get('custom').value };
          this.customFields.forEach((field) => {
            if (field.fieldType === 'date' && updateData.custom[field.backendName]) {
              updateData.custom[field.backendName] = this.generateDateWithTimeZone({
                ...updateData.custom[field.backendName],
                timezone: this.user.timezone,
                date: updateData.custom[field.backendName].day,
              });
            }
          });
        }
        this.store.dispatch(UpdateContact({ data: updateData }));
        this.addContactsForm.reset();
        this.modal.dismissAll();
      } else {
        this.addContactsForm.markAllAsTouched();
      }
    } else {
      this.noPhoneEmail = true;
    }
  }

  closeModal() {
    this.modal.dismissAll();
  }

  closeEvent() {
    this.closed.emit('closed');
  }

  async getTags(parentid, data) {
    this.store.dispatch(GetTagsList({ user: data }));
  }

  /**
   * function to create date according to TimeZone
   * @param date date object
   */
  setDate(date) {
    const dateIso = moment
      .tz(timeZonesMap[this.user.timezone])
      .date(date.day)
      .month(date.month - 1)
      .year(date.year)
      .hour(0)
      .minute(1)
      .toISOString();

    return dateIso;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
