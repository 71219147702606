export const emailEditorConfig = {
  title: '',
  allowedContent: true,
  uiColor: '#ffffff',
  toolbarGroups: [
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    { name: 'links' },
    { name: 'insert' },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    { name: 'styles' },
    { name: 'colors' },
  ],
  skin: 'kama',
  resize_enabled: false,
  removePlugins: 'smiley,indentblock,elementspath',
  forcePasteAsPlainText: false,
  extraPlugins: 'divarea,justify,colordialog,uploadimage',
  colorButton_foreStyle: {
    element: 'font',
    attributes: { color: '#(color)' },
  },
  height: 188,
  removeDialogTabs: '',
  removeButtons:
    'Cut,Copy,Paste,PasteText,PasteFromWord,Save,NewPage,CopyFormatting,RemoveFormat,CreateDiv,Iframe,Flash,Blockquote,Anchor,Templates,Print,Find,Replace,SelectAll,Table,HorizontalRule,SpecialChar,PageBreak,Styles',

  format_tags: 'p;h1;h2;h3;pre;div',
};
