<div class="v2-ui-integretion px-2 pb-2">
  <div class="modal-header pb-0">
    <h5 class="pt-3 text-uppercase font-weight-normal text-dark">{{ campData?.title || ' ' }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="material-icons icon-color" (click)="close()"> close </i>
    </button>
  </div>
  <div class="modal-body">
    <div class="email-modal-details-mails w-100 py-3">
      <div class="d-flex align-items-center px-3 flex-wrap">
        <p class="mb-0 col-01 text-left p-0 color-light-grey font-14">To</p>
        <div
          class="d-flex align-items-center mb-md-0 mb-1"
          *ngFor="let item of contactData | slice: 0:3; let i = index"
        >
          <div class="mr-2 border-mail px-2 color-light-grey font-14">{{ item.contactEmail }}</div>
        </div>
        <div class="btn btn-primary px-2 color-medium-grey font-14 mb-md-0 mb-1" *ngIf="moreto">+{{ moreto }} more</div>
      </div>
    </div>
    <div class="email-modal-details-mails mt-3">
      <p class="mb-0 px-3 py-2 color-light-grey font-14">{{ campData?.title || ' ' }}</p>
    </div>
    <div class="email-modal-details-mails mt-3">
      <div class="mb-0 p-3 color-light-grey font-14 mail-text" [innerHTML]="campData?.email_content"></div>
    </div>
    <div class="d-flex mt-2" *ngIf="campData?.email_attachments_array?.length">
      <p class="mb-0 font-14">Attached Files</p>
    </div>
    <div class="d-flex mt-1 flex-wrap">
      <div
        class="mr-2 mb-1"
        *ngFor="let item of campData?.email_attachments_array; let i = index"
        ngbTooltip="Click To View"
        tooltipClass="tooltip-page-class"
      >
        <div class="attach-files mb-0 px-2 font-14 d-flex">
          <a href="{{ item['firebase_url'] }}" class="max-w-100px" target="_blank">
            <span class="file-name text-truncate">{{ item.file_name }}</span></a
          >
        </div>
      </div>
    </div>
    <div class="d-flex w-100 justify-content-end">
      <div
        (click)="close()"
        class="btn btn-primary px-2 color-medium-grey font-14 close-but d-flex align-items-center justify-content-center"
      >
        Close
      </div>
    </div>
  </div>
</div>
