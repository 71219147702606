import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { sleep } from '../shared/utils';

@Component({
  selector: 'dailyai-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  show = true;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    if (this.auth.currentUser) {
      this.show = false;
      sleep(2000).then(() => this.auth.signout());
    } else {
      this.show = false;
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

}
