import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { COLLECTIONS } from 'src/app/shared/config';

@Injectable({
  providedIn: 'root',
})
export class CommunicationsService {
  constructor(private db: AngularFirestore, private auth: AuthService, private http: HttpClient) {}

  private subject = new Subject<any>();

  /**
   * Function to get a trigger
   * @param type whether email or sms
   */
  emitScroll(type: string) {
    this.subject.next({ type });
  }

  getTop(): Observable<any> {
    return this.subject.asObservable();
  }

  listenRealTime(doc_id: string) {
    return this.db.collection<any>(COLLECTIONS.CONTACTS).doc(doc_id).valueChanges();
  }

  listenPusherChange(doc_id: string) {
    return this.db.collection<any>(COLLECTIONS.CONTACT_PUSHER).doc(doc_id).valueChanges();
  }

  /**
   * Function to list of communicated contacts
   * @param userId current UserId
   * @param last_doc Last doc from reducer
   */
  async fetchContactForCommEnterprise(data, uid, onlyMe?: boolean) {

    const doc_id = false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${environment.mongoUrl}/v0.1/contacts/getContact`,
        { value: data, id: uid, onlyMe: !!onlyMe, doc_id },
        httpOptions
      )
      .toPromise();
  }

  /**
   * Function to list of communicated contacts
   * @param searchTerm serach term
   * @param unread_msg flag to show unread msg
   * @author Rajat Kumar
   */
  async fetchContactForMyConversation(searchTerm, unread_msg, contactType, curPage, limit?: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${environment.mongoUrl}/v0.1/contacts/getMyConversationContact`,
        { searchTerm, unread_msg, contactType, curPage, value: { limit } },
        httpOptions
      )
      .toPromise();
  }

  async communicationContactList(userId: string, last_doc: any, payload, unread?: boolean, enterprise?: boolean) {
    if (enterprise) {
      return this.fetchContactForCommEnterprise(payload, userId, false);
    }
    if (unread) {
      if (!last_doc) {
        return this.db
          .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
            ref
              .where('viewersList', 'array-contains', userId)
              .where('communicated', '==', true)
              .where('unread_msg', '==', true)
              .orderBy('last_msg.time', 'desc')
              .limit(20)
          )
          .get()
          .pipe(map((d) => d.docs.map((c) => c.data())));
      }
        return this.db
          .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
            ref
              .where('viewersList', 'array-contains', userId)
              .where('communicated', '==', true)
              .where('unread_msg', '==', true)
              .orderBy('last_msg.time', 'desc')
              .startAfter(last_doc.last_msg.time)
              .limit(20)
          )
          .get()
          .pipe(map((d) => d.docs.map((c) => c.data())));

    }
    if (!last_doc) {
      return this.db
        .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
          ref
            .where('viewersList', 'array-contains', userId)
            .where('communicated', '==', true)
            .orderBy('last_msg.time', 'desc')
            .limit(20)
        )
        .valueChanges();
    }
      return this.db
        .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
          ref
            .where('viewersList', 'array-contains', userId)
            .where('communicated', '==', true)
            .orderBy('last_msg.time', 'desc')
            .startAfter(last_doc.last_msg.time)
            .limit(20)
        )
        .valueChanges();

  }

  /**
   * function to list sms logs of contacts
   * @param contactId Id of contact of which logs is to be found
   * @param last_doc Last doc of sms_logs fetched
   */
  communicationContactsSmsLog(contactId: string, last_doc?: any) {
    if (!last_doc) {
      return this.db
        .collection<any>(COLLECTIONS.SMS_LOGS, (ref) =>
          ref
            .where('contact_id', '==', contactId)
            .where('type', 'in', [
              'drip_viaMessage_media',
              'drip_viaMessage_text',
              'drip_viaMessage_voiceDrop',
              'normal',
              'inbound',
              'contact_mail',
              'voice_drop',
              'mms_milestone',
              'outbound_call',
              'inbound_call',
            ])
            .orderBy('sent_date', 'desc')
            .orderBy('log_id')
            .limit(10)
        )
        .valueChanges();
    }
      return this.db
        .collection<any>(COLLECTIONS.SMS_LOGS, (ref) =>
          ref
            .where('contact_id', '==', contactId)
            .where('type', 'in', [
              'drip_viaMessage_media',
              'drip_viaMessage_text',
              'drip_viaMessage_voiceDrop',
              'normal',
              'inbound',
              'contact_mail',
              'voice_drop',
              'mms_milestone',
              'outbound_call',
              'inbound_call',
            ])
            .orderBy('sent_date', 'desc')
            .orderBy('log_id')
            .startAfter(last_doc.sent_date, last_doc.log_id)
            .limit(10)
        )
        .valueChanges();

  }

  communicationContactsEmailLog(contactId: string, last_doc?: any) {
    if (!last_doc) {
      return this.db
        .collection<any>(COLLECTIONS.SMS_LOGS, (ref) =>
          ref
            .where('contact_id', '==', contactId)
            .where('type', 'in', [
              'drip_viaMail_',
              'drip_viaMail_media',
              'drip_viaMail_text',
              'inbound_mail',
              'outbound_mail',
            ])
            .orderBy('sent_date', 'desc')
            .orderBy('task_id')
            .limit(10)
        )
        .valueChanges();
    }
      return this.db
        .collection<any>(COLLECTIONS.SMS_LOGS, (ref) =>
          ref
            .where('contact_id', '==', contactId)
            .where('type', 'in', [
              'drip_viaMail_',
              'drip_viaMail_media',
              'drip_viaMail_text',
              'inbound_mail',
              'outbound_mail',
            ])
            .orderBy('sent_date', 'desc')
            .orderBy('task_id')
            .startAfter(last_doc.sent_date, last_doc.task_id)
            .limit(10)
        )
        .valueChanges();

  }

  /**
   * cloud function-call to sent sms to a contact
   * @param data message and contact details
   */
  async communicationContactsSentSms(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/sendSms?addTask=true`;

    return this.http.post(url, data, httpOptions).subscribe();
  }

  async communicationContactsSentMail(data, url) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${url  }?addTask=true`, data, httpOptions).subscribe();
  }

  getCurrentContact(id: string) {
    return this.db.collection<any>(COLLECTIONS.CONTACTS).doc(id).valueChanges();
  }

  setUnreadStatus(id: string) {
    this.db.collection<any>(COLLECTIONS.CONTACTS).doc(id).update({ unread_msg: false, contact_updated_on: new Date().toISOString() });
  }

  async test() {
    const now = new Date();
    const data = await this.db
      .collection<any>(COLLECTIONS.DRIP_TASKS)
      .ref.where('trigger_time', '<=', now)
      .where('drip_status', '==', 'Active')
      .where('status', '==', 'scheduled')
      .orderBy('trigger_time')
      .limit(30)
      .get();
  }
}
