import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserType } from '../../enums/commonEnums';
import { MilestoneService } from '../../../modules/settings/services/milestone.service';
import _ from 'lodash';

@Component({
  selector: 'dailyai-resync-milestone-modal',
  templateUrl: './resync-milestone-modal.component.html',
  styleUrls: ['./resync-milestone-modal.component.scss']
})
export class ResyncMilestoneModalComponent implements OnInit {
  constructor(
    private db: AngularFirestore,
    private activeModal: NgbActiveModal,
    private milestoneService: MilestoneService,
  ) { }

  @Output() modalAction: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentUser: any;

  managerList: any;

  usersList: any[];

  selectedUsersUids = new Set();

  userType = UserType;

  isLoading = false;

  showResyncMilestonesSuccessMessage = false;

  showResyncMilestonesFailedMessage = false;

  showResyncMilestonesPartialSuccessMessage = false;

  isUserChecked(user) {
    return this.selectedUsersUids.has(user.uid);
  }

  isSelectAllIndeterminate() {
    return this.usersList?.length !== this.selectedUsersUids.size && this.selectedUsersUids.size > 0;
  }

  ngOnInit() {
    this.currentUser.userType === UserType.MANAGER ? this.getManagingUsers() : this.getUsers();
  }

  async getManagingUsers() {
    const teamLeadsSnapshot = await this.db.collection('users', (ref) => (
      ref
        .where('enterpriseId', '==', this.currentUser.enterpriseId)
        .where('status', '==', 'Active')
        .where('userType', '==', UserType.TEAM_LEADER)
        .where('managerId', '==', this.currentUser.uid)
    )).get().toPromise();

    this.usersList = teamLeadsSnapshot.docs.map((doc) => doc.data());
  }

  async getUsers() {
    const usersSnapshot = await this.db.collection('users', (ref) => (
      ref
        .where('enterpriseId', '==', this.currentUser.enterpriseId)
        .where('status', '==', 'Active')
        .where('userType', 'in', [UserType.MANAGER, UserType.TEAM_LEADER])
    )).get().toPromise();

    const users = usersSnapshot.docs.map((doc) => doc.data());
    this.managerList = users.filter((user: any) => user.userType === UserType.MANAGER);

    this.managerList.forEach((manager: any) => {
      const teamLeads = users.filter((user: any) => user.managerId === manager.uid);

      manager.teamLeads = teamLeads;
    });

    this.usersList = this.managerList.flatMap((manager) => [manager, ...manager.teamLeads]);

    const restTeamLeads = users.filter((user: any) => user.userType === UserType.TEAM_LEADER && !user.managerId);
    this.usersList = [...this.usersList, ...restTeamLeads];
  }

  toggleUserSelection(checked, user) {
    checked ? this.selectedUsersUids.add(user.uid) : this.selectedUsersUids.delete(user.uid);
  }

  toggleSelectAll(checked) {
    this.usersList.forEach(user => this.toggleUserSelection(checked, user));
  }

  close() {
    this.modalAction.emit(this.isLoading);
    this.activeModal.close(false);
  }

  async submit() {
    this.isLoading = true;
    let hasSuccess = false;
    let hasError = false;
    const requests = [...this.selectedUsersUids].map(async (uid) => {
      try {
        await this.milestoneService.resyncMilestones({fromUserId: this.currentUser.uid, toUserId: uid});
        hasSuccess = true;
      } catch {
        hasError = true;
        const user = this.usersList.find((u) => u.uid === uid);
        user.resyncFailed = true;
      }
    });

    await Promise.all(requests);
    this.isLoading = false;

    this.showResyncMilestonesSuccessMessage = hasSuccess && !hasError;
    this.showResyncMilestonesPartialSuccessMessage = hasSuccess && hasError;
    this.showResyncMilestonesFailedMessage = !hasSuccess && hasError;

    if (this.showResyncMilestonesSuccessMessage) {
      setTimeout(() => {
        this.modalAction.emit(false);
        this.activeModal.close(false);
      }, 3500);
    }
  }
}
