import { environment } from 'src/environments/environment';

// ag-grid start
export const AGGRID_ROW_LIMIT = 100_000;
// ag-grid end

export const API_BASE_URL = environment.cloud_base_url;

// export const API_BASE_URL = 'http://localhost:5001/dailycrm-c9949/us-central1';

// user api
export const USER_ADD_URL = `${API_BASE_URL}/adduser`;
export const USER_DELETE_URL = `${API_BASE_URL}/deleteuser`;
export const CARD_CHARGE_URL = `${API_BASE_URL}/chargecard`;
export const CUSTOM_FIELD_PLAN_URL = `${API_BASE_URL}/custom_field_plan`;
export const USER_ACTIVATE_URL = `${API_BASE_URL}/activatejunioruser`;
export const VALIDATE_CALLERID = `${API_BASE_URL}/validatePhoneNumber`;
export const VERIFY_EMAIL_RESET_PASSWORD = `${API_BASE_URL}/verifyEmailResetPassword`
export const GET_USER = `${API_BASE_URL}/get_user`;
export const UNLIMITED_DAILLER_PLAN_URL = `${API_BASE_URL}/unlimited_dialler_plan`;
export const UNLIMITED_DAILLER_PLAN_JLO_URL = `${API_BASE_URL}/unlimited_dialler_plan_jlo`;
export const UNLIMITED_DAILLER_PLAN_ENTERPRISE = `${API_BASE_URL}/unlimited_dialler_plan_enterprise`;
export const SET_PASSWORD_URL = `${API_BASE_URL}/set_password`;
export const GET_FIRST_TIME_LOGIN_TOKEN_URL = `${API_BASE_URL}/getUserTokenForSSOFirstTimeLogin`
export const SET_SSO_ACTIVE_URL = `${API_BASE_URL}/setSSOActive`
export const VALIDATE_USER_PHONE_NUMBER = `${API_BASE_URL}/validatePhoneNumber`;
export const CHANGE_USER_NAME = `${API_BASE_URL}/changeUserName`;
export const CHANGE_USER_PROFILE_IMAGE = `${API_BASE_URL}/changeUserProfileImage`;
export const CHANGE_COMPANY_NAME = `${API_BASE_URL}/changeCompanyName`;
export const CHANGE_TIMEZONE = `${API_BASE_URL}/changeTimezone`;


// user api end

// milestone api start
export const MILESTONE_ADD_URL = `${API_BASE_URL}/add_milestone`;

// milestone api end

// settings api start

export const RESEND_MAIL_URL = `${API_BASE_URL}/resend_mail`;

export const SET_PERMISSION_URL = `${API_BASE_URL}/set_permissions`;

// settings api end

// change email api start

export const CHANGE_USER_EMAIL = `${API_BASE_URL}/changeUserEmail`;

// change email api end

/// Get twilio numbers
export const GET_TWILIO_NUMBERS = `${API_BASE_URL}/searchNumbers`;

// change twilio number
export const UPDATE_TWILIO_NUMBER = `${API_BASE_URL}/updateTwilioNumber`;

// braintree api start
export const GET_BRAINTREE_CLIENT_TOKEN_URL = `${API_BASE_URL}/getBraintreeClientToken`;

export const CREATE_BRAINTREE_CUSTOMER_URL = `${API_BASE_URL}/createBraintreeCustomer`;

export const ADD_BRAINTREE_CARD_URL = `${API_BASE_URL}/addBraintreeCard`;

export const GET_BRAINTREE_CUSTOMER_URL = `${API_BASE_URL}/getBraintreeCustomer`;

export const CHANGE_BRAINTREE_DEFAULT_CARD_URL = `${API_BASE_URL}/changeBraintreeDefaultCard`;

export const DELETE_BRAINTREE_CARD_URL = `${API_BASE_URL}/deleteBraintreeCard`;

export const PAYNOW_ON_FAIL_BRAINTREE_ENTERPRISE_URL = `${API_BASE_URL}/payNowOnFailBraintreeEnterprise`;
// braintree api end

// encompass api start

export const VALIDATE_ENCOMPASS = `${API_BASE_URL}/validateEncompass`;

export const INTEGRATE_ENCOMPASS = `${API_BASE_URL}/encompassintegration`;

export const SUBSCRIBE_ENCOMPASS = `${API_BASE_URL}/createsubscriptionEncompass`;

export const INTEGRATE_BANK = `${API_BASE_URL}/encompassAccountRequest`;

export const INTEGRATE_BROKER = `${API_BASE_URL}/brokerintegration`;

export const SEND_MAIL_BANK = `${API_BASE_URL}/bankrequestmail`;

// encompass api end

// dashboard api

export const LOAD_GRAPH_DATA = `${API_BASE_URL}/getGraphData`;

export const SMS_LIMIT = 1500;
export const CALL_LIMIT = 90;
export const VOICE_DROP_LIMIT = 100;
export const MMS_LIMIT = 100;
export const EMAIL_CAMPAIGN_LIMIT = 2500;

export const BASE_PRICE = 'PAY NOW $';

// dashboard api end

// plans api start

export const GET_PLANS = `${API_BASE_URL}/get_plans`;
export const GET_COUPONS = `${API_BASE_URL}/get_coupons`;
export const UPGRADE_PLAN_URL = `${API_BASE_URL}/upgrade_plan`;

// plans api end

// discount percents

export const DISCOUNT: { [discountId: string]: number } = {
  '1_year': 0,
  '2_year': 5,
  '3_year': 10,
};

export const DESIGNATIONS: string[] = [
  'Senior Loan Officer',
  'Junior Loan Officer',
  'Loan Officer Assistant',
  'Loan Processor',
  'Sales Manager',
  'Business Development Manager',
  'Administrative Assistant',
  'Marketing Manager',
  'Client Success Manager',
  'Realtor Partner'
];

// end

// Plan names
export const PLAN_NAMES: any = {
  grow_plan_i: 'Growth',
  grow_plan_ii: 'Plus',
  team_plan: 'Team',
  enterprise_plan: 'Enterprise',
};
// end

export const DATE_PICKER_MIN_DATE: {
  year: number;
  month: number;
  day: number;
} = {
  year: 2000,
  month: 1,
  day: 1,
};

export const cleanPhNo = /\D/g;

export const MARKETING_URL = 'https://dailyai.maxadesigns.com';
export const MARKETING_KEY = 'Qr9PGN4sZyxXvAlNv7FnZA';

export const COLLECTIONS = {
  PARTNER_CUSTOMIZATION: "partner_customization",
  PARTNER_PUSHER: 'partner-pusher',
  INTAKE_FORMS: 'intake-forms',
  ENTERPRISE_MILESTONE_DETAILS: 'enterprise_milestone_details',
  MAIL_TEMPLATES: 'mail-templates',
  MILESTONE_DATES: 'milestone_dates',
  MILESTONE_STATUS: 'milestone_status',
  MMS_TEMPLATES: 'mms-templates',
  SMS_TEMPLATES: 'sms-templates',
  TRANSACTIONS: 'transactions',
  USERS: 'users',
  ADD_CONTACT_TO_DRIP: "add_contact_to_drip",
  AI_SETTINGS: "ai_settings",
  AI_EMAIL_TEMPLATES: "ai_email_templates",
  AI_MMS_TEMPLATES: "ai_mms_templates",
  AI_SMS_TEMPLATES: "ai_sms_templates",
  AUTO_ASSIGN_TAGS_SOURCE: "auto-assign-tags-source",
  BANK_INTEGRATION: "bank_integration",
  BANK_LOAN_LOGS: "bank_loan_logs",
  COMPANY_CHANGE: 'company_change',
  CONTACTS: "contacts",
  CONTACT_PUSHER: "contact-pusher",
  CSV_EXTRA_DATA: "csvExtraData",
  CSV_TEMPLATES: 'csv-templates',
  CONTACTS_DETAILS_UI: "contactDetailsUi",
  CUSTOM_USER_FIELDS: "custom_user_fields",
  DRIP_TASKS: "drip_tasks",
  DIST_STATUS: "lead_distribution_status",
  DRIP_CAMPAIGN: "drip-campaign",
  ENTERPRISES: "enterprises",
  ENTERPRISE_LOGOS: "enterpriseLogos",
  EMAIL_CHANGE: "email_change",
  ENCOMPASS_APPROVALS: "encompassApprovals",
  FEATURE_FLAGS: 'feature-flags',
  FILES: "files",
  FOLLOW_UP_TASKS: "follow_up_tasks",
  GROUPS: "groups",
  JUNIOR_USERS: "junior-users",
  LEAD_ROUND_ROBIN_STATUS: 'lead_round_robin_status',
  LEAD_ROUTINGS: "lead_routings",
  NAME_CHANGE: "name_change",
  NOTES: "notes",
  PACKAGES: 'packages',
  PARTNERS: "partners",
  PAYMENT_HISTORY: "payment_history",
  PERMISSIONS: "permissions",
  PHONE_NUMBER_CHANGE: "phone_number_change",
  PHONE_NUMBERS_FOR_LOOKUP_API: 'phone_numbers_for_lookup_api',
  PLANS: 'plans',
  POST_CLOSE_NURTURE_CONTACTS: "post_close_nurture_contacts",
  PROFILE_IMAGE_CHANGE: 'profile_image_change',
  RESET_SMS_COUNTER_TASKS: "reset_sms_counter_tasks",
  ROUTING_RULES: "routing_rules",
  REMOVE_CONTACT_FROM_DRIP: "remove_contact_from_drip",
  SAVED_FILTERS: "saved_filters",
  SIMPLE_NEXUS_BANKS: "simple-nexus-banks",
  SMS_LOGS: "sms_logs",
  SOURCES: "sources",
  TAGS: "tags",
  TASKS: "tasks",
  TEAMS: "teams",
  TIMEZONE_CHANGE: 'timezone_change',
  TRANSACTION_UI: "transactionUi",
  TWILIO_NUMBER_CHANGE: 'twilio_number_change',
  USER_COMMUNICATIONS: "user_communications",
  USER_COMMUNICATION_REFILLS: "user_communication_refills",
  USER_PURCHASE_LOAN_PURPOSE: "user_purchase_loan_purposes",
  USER_REFINANCE_LOAN_PURPOSE: "user_refinance_loan_purposes",
  USERS_DELETE_LOG: "users_delete_log",
  USER_UPLOAD_PROCESS: "user_upload_process",
  USER_PROCESS_LOGS: "user_process_logs",
  VOICE_DROPS: "voice_drops",
  USER_STATE: "user_state",
  WEBSITE: 'website',
}

export const LINKS = {
  SUPPORT_LINK: 'https://support.dailyai.io/',
  EMAIL_SUPPORT: 'mailto:support@dailyai.io?Subject=Support Inquiry',
}
export const DRIP_CONTACT_SET_STATUS_DELAY = "10";
export const DRIP_CONTACT_SET_STATUS_DELAY_FREQ = "minutes";

export const EMAIL_REGEX = /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/;

export const COMMUNICATIONS_REFRESH_INTERVAL = 5000;

export const GENDERS = ['Male', 'Female', 'Others'];

export const THIRD_LOGO_ENABLED_USERS = ['y3a9suDXYkgmmrO2hJPcg0TJCRH3'];
