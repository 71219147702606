
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { finalize, map, take } from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { UserType } from 'src/app/shared/enums/commonEnums';
import { COLLECTIONS } from 'src/app/shared/config';

const NULL = null;
const api = environment.mongoUrl;
@Injectable()
export class PartnerService {
  task: AngularFireUploadTask;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  async checkValidity(uid: string, userData: any, field: string, val: any, teamLeaderId: string) {
    const value = (val || "").toLowerCase().trim();
    const data = await this.firestore
      .collection<any>(COLLECTIONS.PARTNERS)
      .ref.where('addedBy', '==', uid)
      .where(field, '==', value)
      .get();

    const cdata = await this.firestore
      .collection('contacts')
      .ref.where('viewersList', 'array-contains', userData.enterpriseId ?? userData.parentId ?? userData.uid)
      .where(field, '==', value)
      .get();

    if (data.docs.length === 0 && cdata.docs.length === 0) {
      return true;
    }

    let hasMatchedPartner = false;
    let hasMatchedContact = false;
    let matchedTeamLeader = data.docs.some(f => {
      const data1 = f.data();
      return (!teamLeaderId && data1.teamLeaderId ) || data1.teamLeaderId === teamLeaderId
    })

    if (!matchedTeamLeader ) {
      hasMatchedPartner = true;
    }
    if (hasMatchedPartner) {
      matchedTeamLeader = cdata.docs.some((f:any) => {
        const data2 = f.data();
        return (!teamLeaderId && data2.teamLeaderId ) || data2.teamLeaderId === teamLeaderId
      })
      if (!matchedTeamLeader) {
        hasMatchedContact = true;
      }
    }

    return hasMatchedContact && hasMatchedPartner;
  }

  getPartnersList(userId: string): Observable<any> {
    return this.firestore
      .collection<any>(COLLECTIONS.PARTNERS, (ref) =>
        ref.where('viewersList', 'array-contains', userId).orderBy('firstName', 'asc')
      )
      .valueChanges()
      .pipe(take(1));
  }

  listenPartnerPusher(docId) {
    return this.firestore.collection<any>(COLLECTIONS.PARTNER_PUSHER).doc(docId).valueChanges();
  }

  async getPartnerContacts(contactId: string) {
    const ref = await this.firestore.collection<any>(COLLECTIONS.CONTACTS).doc(contactId).ref.get();
    return ref.data();
  }

  async getLoanStatus(loanId: string) {
    const ref = await this.firestore.collection<any>(COLLECTIONS.TRANSACTIONS).doc(loanId).ref.get();
    return ref.data();
  }

  async fetchCount(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/partners/fetchPartnerCount`, { id: data }, httpOptions).toPromise();
  }

  async fetchPartners(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/partners/fetchPartners`, { value: data }, httpOptions).toPromise();
  }

  async searchPartners(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/partners/searchPartners`, { value: data }, httpOptions).toPromise();
  }

  async sortPartners(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/partners/sortPartners`, { value: data }, httpOptions).toPromise();
  }

  async isValidUrl(url: string) {
    const records = await this.firestore.collection<any>(COLLECTIONS.PARTNERS).ref.where('partner_form_url', '==', url).get();
    return records.size === 0;
  }

  async setPartnerFormUrl(partner_id: string, partner_form_url: string, owner: any) {
    await this.firestore
      .collection<any>(COLLECTIONS.PARTNERS)
      .doc(partner_id)
      .update({ partner_form_url, ownerName: `${owner.firstName} ${owner.lastName}` });
  }

  async getOwner(partner_id: string, addedby: string) {
    const primary = this.toData(await this.firestore.collection<any>(COLLECTIONS.USERS).doc(addedby).ref.get());
    if (primary.userType !== UserType.MO) {
      return primary;
    }
    const secondary = (
      await this.firestore
        .collection<any>(COLLECTIONS.USERS)
        .doc(primary.parentId ?? primary.teamLeaderId ?? primary.managerId ?? primary.superUserId)
        .ref.get()
    ).data();
    return secondary;
  }

  async getPartnerCreator(id) {
    const partnerCreator = await this.firestore.collection<any>(COLLECTIONS.USERS).doc(id).ref.get();
    return partnerCreator.data();
  }

  async deletePartners(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    return this.http
      .post(`${environment.cloud_base_url}/batchPartnerDelete`, { partnerData: data }, httpOptions)
      .toPromise();
  }

  listPartnersPaginate(user_id: string, last_doc?: any) {
    if (!last_doc) {
      return this.firestore
        .collection<any>(COLLECTIONS.PARTNERS, (ref) =>
          ref.where('viewersList', 'array-contains', user_id).orderBy('firstName', 'asc').orderBy('uid').limit(10)
        )
        .valueChanges();
    }

    return this.firestore
      .collection<any>(COLLECTIONS.PARTNERS, (ref) =>
        ref
          .where('viewersList', 'array-contains', user_id)
          .orderBy('firstName', 'asc')
          .orderBy('uid')
          .startAfter(last_doc.firstName, last_doc.uid)
          .limit(10)
      )
      .valueChanges();
  }

  async setPartnerCustomMessage(pId: string, customMessage: any) {
    await this.firestore.collection<any>(COLLECTIONS.PARTNERS).doc(pId).update({ customMessage });
  }

  async setAllPartnersCustomMessage(uId, messageData) {
    await this.firestore
      .collection(COLLECTIONS.PARTNER_CUSTOMIZATION)
      .doc(uId)
      .set({ ...messageData, doc_id: uId }, { merge: true });
  }

  async getCustomMessages(userData) {
    let globalCustom: any;
    if(userData.isEnterprise) {
      let agentCustom;
      let teamLeadCustom;
      let managerCustom;
      let enterpriseCustom;
      if (userData.userType === UserType.JLO) {
        agentCustom = this.toData(await this.firestore.collection(COLLECTIONS.PARTNER_CUSTOMIZATION).ref.doc(userData.uid).get());
      }
      if (userData.teamLeaderId || userData.userType === UserType.TEAM_LEADER) {
        teamLeadCustom = this.toData(
          await this.firestore
            .collection(COLLECTIONS.PARTNER_CUSTOMIZATION)
            .ref.doc(userData.teamLeaderId ?? userData.uid)
            .get()
        );
      }
      if (userData.managerId || userData.userType === UserType.MANAGER) {
        managerCustom = this.toData(
          await this.firestore
            .collection(COLLECTIONS.PARTNER_CUSTOMIZATION)
            .ref.doc(userData.managerId ?? userData.uid)
            .get()
        );
      }
      if (userData.enterpriseId) {
        enterpriseCustom = this.toData(
          await this.firestore.collection(COLLECTIONS.PARTNER_CUSTOMIZATION).ref.doc(userData.enterpriseId).get()
        );
      }
      globalCustom = agentCustom ?? teamLeadCustom ?? managerCustom ?? enterpriseCustom;
    }
    return globalCustom;
  }

  toData (snapshot) {
    return snapshot.data();
  }

  async getCustomMessagesforSinglePartner(userData) {
    let globalCustom: any;
    if(userData.isEnterprise) {
      let agentCustom;
      let teamLeadCustom;
      let managerCustom;
      let enterpriseCustom;
      if (userData.userType === UserType.JLO) {
        agentCustom = this.toData(await this.firestore.collection(COLLECTIONS.PARTNER_CUSTOMIZATION).ref.doc(userData.uid).get());
        if (agentCustom) {
          agentCustom = this.removeEmptyString(agentCustom);
        }
      }
      if (userData.teamLeaderId || userData.userType === UserType.TEAM_LEADER) {
        teamLeadCustom = this.toData(
          await this.firestore
            .collection<any>(COLLECTIONS.PARTNER_CUSTOMIZATION)
            .ref.doc(userData.teamLeaderId ?? userData.uid)
            .get()
        );
        if (teamLeadCustom) {
          teamLeadCustom = this.removeEmptyString(teamLeadCustom);
        }
      }
      if (userData.managerId || userData.userType === UserType.MANAGER) {
        managerCustom = this.toData(
          await this.firestore
            .collection<any>(COLLECTIONS.PARTNER_CUSTOMIZATION)
            .ref.doc(userData.managerId ?? userData.uid)
            .get()
        );
        if (managerCustom) {
          managerCustom = this.removeEmptyString(managerCustom);
        }
      }
      if (userData.enterpriseId) {
        enterpriseCustom = this.toData(
          await this.firestore.collection<any>(COLLECTIONS.PARTNER_CUSTOMIZATION).ref.doc(userData.enterpriseId).get()
        );
      }

      globalCustom = enterpriseCustom
        ? {
            ...enterpriseCustom,
            ...(managerCustom && managerCustom),
            ...(teamLeadCustom && teamLeadCustom),
            ...(agentCustom && agentCustom),
          }
        : enterpriseCustom;
    }
    return globalCustom;
  }

  removeEmptyString(_obj) {
    const globalObj = _obj;
    for (const [key, value] of Object.entries(globalObj)) {
      if (value === '') {
        delete globalObj[key];
      }
    }
    return globalObj;
  }

  async uploadCsv(id, file) {
    const date = new Date();
    const time = date.getTime();
    const path = `csv/partners_${id}_${time}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    const uploadObs = this.task.snapshotChanges();
    uploadObs
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {});
        })
      )
      .subscribe();
    return uploadObs;
  }

  async coldBootReducer() {
    this.firestore
      .collection<any>(COLLECTIONS.PARTNERS)
      .add({ delete: true })
      .then((deleteDoc) => {
        this.firestore
          .collection<any>(COLLECTIONS.PARTNERS)
          .doc(deleteDoc.id)
          .update({ delete: false })
          .then(() => {
            const payload = [
              {
                Uid: deleteDoc.id,
                reportTaskName: NULL,
                contactIds: [],
                imageUrl: NULL,
              },
            ];
            this.deletePartners(payload);
          });
      });
  }

  async mergePartner(masterPartner, childPartners, selectedEmail, selectedPhone) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/partnersBatchMerge`;
    console.log(childPartners);
    return this.http.post(url, { masterPartner, childPartners, selectedEmail, selectedPhone }, httpOptions).toPromise();
  }
}
