import { Component, Input } from '@angular/core';

@Component({
  selector: 'dailyai-share-svg',
  templateUrl: './share-svg.component.svg',
  styleUrls: ['./share-svg.component.scss']
})
export class ShareSvgComponent {

  @Input() width = '16px';
  
  @Input() height = '17px';

}
