import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ContactsState } from 'src/app/modules/contacts/store/state';
import { FetchContact } from 'src/app/modules/contacts/store/action';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { SharedService } from 'src/app/shared/services/shared.service';
import { LINKS } from 'src/app/shared/config';
import { UserType } from 'src/app/shared/enums/commonEnums';
import { ViewDetailsComponent } from '../view-details/view-details.component';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'dailyai-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss'],
})
export class SideMenuBarComponent implements OnInit, OnDestroy {
  activeTab: any;

  userRole: string;

  userType: string;

  userProcess$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  userProcess: any[] = [];

  currentUser: any = {};

  canOpen = false;

  permissions: any;

  supportLink: string = LINKS.SUPPORT_LINK;

  emailSupport: string = LINKS.EMAIL_SUPPORT;

  userTypes = UserType;

  @Input()
  set role(user) {
    this.userRole = user;
  }

  @ViewChild('uploadNoti') public uploadPop: NgbPopover;

  @ViewChild('profileMenu') public profileMenu: ElementRef;

  @ViewChild('currentUserFirstName') public currentUserFirstName: ElementRef;

  @ViewChild('currentUserImage') public currentUserImage: ElementRef;

  @ViewChild('profileMenuHeader') public profileMenuHeader: ElementRef;

  sideNavPermissions = {
    calendar: new Set(['manager', 'TeamLeader', 'JLO', 'MO']),
  };

  currentUser$: Observable<any>;

  userImage: any;

  popup_hide: boolean;

  enableCalender = false;

  enterpriseLogo: string;

  currentUserUid: string;

  constructor(
    private route: Router,
    private layoutService: LayoutService,
    private auth: AuthService,
    private store: Store<ContactsState>,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private sharedService: SharedService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when profilemenu is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (
        e.target !== this.profileMenu.nativeElement &&
        e.target !== this.currentUserFirstName.nativeElement &&
        e.target !== this.currentUserImage.nativeElement &&
        e.target !== this.profileMenuHeader.nativeElement
      ) {
        this.popup_hide = false;
      }
    });
    // feature-flag
    this.sharedService
      .getFeatureFlag('calendar-ui')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: any) => {
        this.enableCalender = !val ? true : val.enabled;
        if (this?.enableCalender == false) {
          this.activeTab = this.route.url.split('/')[1];
          if (this.activeTab === 'calendar') {
            this.route.navigateByUrl('/dashboard');
          }
        }
      });
  }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (!data) {
        return;
      }
      this.currentUser = data;
      this.userImage = data.image;
      this.userType = this.currentUser?.userType;
    });
    if (this.currentUser?.isEnterprise) {
      this.auth.getEnterprisePermissionObject(this.currentUser.enterpriseId).subscribe((d) => {
        this.permissions = d;
      });
    }
    this.auth.getCurrentUserUid().then((id) => {
      this.currentUserUid = id;
      this.userProcess$ = this.layoutService.getUserProcess(id);
      this.userProcess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
        if (val.length > 0) {
          this.delay(2000).then((d) => {
            if (this.uploadPop && !this.uploadPop.isOpen() && this.canOpen) {
              this.uploadPop.open();
            }
            this.canOpen = true;
          });
        }
        this.userProcess = val;

        for (const value of val) {
          if (value.process_status === 'Upload Process Completed Successfully') {
            this.delay(3000).then(() => this.store.dispatch(FetchContact({ data: { id, curPage: 1 } })));
            return;
          }
        }
      });
    });

    this.activeTab = this.route.url.split('/')[1];
    this.getLogo();
  }

  getLogo() {
    this.layoutService.getEnterpriseLogo().then((obs) => {
      obs.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.enterpriseLogo = data?.url;
      });
    });
  }

  private delay(timeInMs: number) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(null), timeInMs);
    });
  }

  setPopover(p: NgbPopover) {

  }

  resetTransaction() {
    if (this.route.routerState.snapshot.url === '/transactions') {
      this.route.navigate([], { queryParams: { refresh: true }, queryParamsHandling: 'merge' });
    }
  }

  resetContacts() {
    if (this.route.routerState.snapshot.url === '/contacts') {
      this.route.navigate([], { queryParams: { refresh: true }, queryParamsHandling: 'merge' });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dismissItem(process: any) {
    this.canOpen = false;
    this.layoutService.dismissProcess(process.process_id);
  }

  dismissAll() {
    this.canOpen = false;
    this.layoutService.dismissAll(this.currentUserUid);
  }

  async openViewDetail(pid: string, process_name: string) {
    const logData = await this.layoutService.getProcessLog(pid);
    const paymentRef = this.modalService.open(ViewDetailsComponent, {
      centered: true,
      windowClass: 'chat-lg-popup',
    });
    paymentRef.componentInstance.failed_data = { logData, process_name };
  }

  popup_custom_toggle() {
    this.popup_hide = !this.popup_hide;
  }

  showMenu(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
