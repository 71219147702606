import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-view-email-campaign-detais-popup',
  templateUrl: './view-email-campaign-detais-popup.component.html',
  styleUrls: ['./view-email-campaign-detais-popup.component.scss'],
})
export class ViewEmailCampaignDetaisPopupComponent implements OnInit {
  moreto = 0;

  campData: any;

  contactData = [];

  @Input() set data(d: any) {
    this.campData = d.campData;
    this.contactData = d.contactData;
    this.moreto = this.contactData.length > 3 ? this.contactData.length - 3 : 0;
  }

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {}

  close() {
    this.modal.dismissAll();
  }
}
