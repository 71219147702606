import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

interface RecordedVideoOutput {
  blob: Blob;
  title: string;
  url?: Blob;
}

@Injectable({
  providedIn: 'root',
})
export class VideoRecordingService {
  private stream;

  private recorder;

  private interval;

  private startTime;

  private _recorded = new Subject<RecordedVideoOutput>();

  private _recordingTime = new Subject<string>();

  private _recordingFailed = new Subject<string>();

  private _stream = new Subject<any>();

  paused = false;

  pauseTime;

  playTime;

  counter = 0;

  constructor(private db: AngularFirestore) {}

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  recordingPreview(): Observable<any> {
    return this._stream.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      return;
    }
    this._recordingTime.next('00:00');
    this.record();
  }

  startNewRecording() {
    if (this.recorder) {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((s) => {
        this._stream.next(s);
        this.stream = s;
        this._recordingTime.next('00:00');
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next();
      });
  }

  pauseRecording() {
    if (this.recorder) {
      this.pauseTime = moment();
      this.paused = true;
      this.recorder.pauseRecording();
    }
  }

  resumeRecording() {
    if (this.recorder) {
      this.paused = false;
      this.playTime = moment();
      this.recorder.resumeRecording();
    }
  }

  defaultStream() {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((s) => {
        this._stream.next(s);
        this.stream = s;
      })
      .catch((error) => {
        this._recordingFailed.next();
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm',
      bitsPerSecond: 256 * 8 * 125,
    });
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      let currentTime;
      let diffTime;
      let totalPaused;
      currentTime = moment();
      if (this.pauseTime && this.playTime) {
        totalPaused = moment.duration(this.playTime.diff(this.pauseTime));
        this.counter += totalPaused.seconds() + 1;
        this.pauseTime = undefined;
        this.playTime = undefined;
      }
      currentTime = moment().subtract(this.counter, 'seconds');
      diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = `${this.toString(diffTime.minutes())  }:${  this.toString(diffTime.seconds())}`;
      if (!this.paused) {
        this._recordingTime.next(time);
      }
    }, 1000);
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = `0${  value}`;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(
        (blob) => {
          this._stream.next(this.recorder.getDataURL((dataURL) => {}));
          const newBlob = this.recorder.getBlob();
          const value = new Blob([newBlob], { type: 'video/mp4' });
          if (this.startTime) {
            const mp3Name = encodeURIComponent(`video_${  Date.now()  }.mp4`);
            this.stopMedia();
            this._recorded.next({ blob: newBlob, title: mp3Name, url: blob });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        }
      );
    }
  }

  private stopMedia() {
    this.recorder = null;
    clearInterval(this.interval);
    this.startTime = null;
    if (this.stream) {
      this.stream.getAudioTracks().forEach((track) => track.stop());
      this.stream.getVideoTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }

  getVideoList(userId) {
    return this.db
      .collection<any>('user_recordings', (ref) =>
        ref.where('uid', '==', userId).where('type', '==', 'video/mp4').orderBy('file_name', 'asc')
      )
      .valueChanges();
  }
}
