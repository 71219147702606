<div class="px-0 pb-2">
  <dailyai-hidden-input></dailyai-hidden-input>
  <div class="modal-header pt-4 pb-2 px-4">
    <h6 class="pt-4 text-capitalize font-hn-medium">
      VIEW FAILED
      {{
        failed_data?.process_name === 'contacts_upload'
          ? 'CONTACTS'
          : failed_data?.process_name === 'partner_upload'
          ? 'PARTNERS'
          : 'TRANSACTIONS'
      }}
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
      <i class="material-icons icon-color" (click)="closePopup()"> close </i>
    </button>
  </div>
  <div class="modal-body pt-2" *ngIf="failed_data?.process_name !== 'partner_upload'">
    <div class="table-box">
      <table class="table table-hover">
        <thead class="table-header align-items-center">
          <th class="w-10">Entry No.</th>
          <th class="w-10">{{ failed_data?.process_name === 'contacts_upload' ? 'Contact Name' : 'Borrower Name' }}</th>
          <th class="w-10">{{ failed_data?.process_name === 'contacts_upload' ? 'Email' : 'Loan #' }}</th>
          <th class="w-10">{{ failed_data?.process_name === 'contacts_upload' ? 'Phone Number' : 'City' }}</th>
          <th class="w-30">Remark</th>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let item of failed_data?.logData; let i = index">
            <td class="w-10">{{ item.index ? item.index + 1 : i + 1 }}</td>
            <td class="w-10 text-capitalize">
              {{
                failed_data?.process_name === 'contacts_upload'
                  ? item.firstName + ' ' + item.lastName
                  : item.borrower_name
              }}
            </td>
            <td class="w-10 text-capitalize">
              {{
                failed_data?.process_name === 'contacts_upload' ? (item.email ? item.email : '--') : item.loan_number
              }}
            </td>
            <td class="w-10 text-capitalize">
              {{
                failed_data?.process_name === 'contacts_upload'
                  ? item.phoneNumber
                    ? item.phoneNumber
                    : '--'
                  : item.city
                  ? item.city
                  : '--'
              }}
            </td>
            <td class="text-capitalize w-30">
              <span>{{ item.error_msg }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-body pt-2" *ngIf="failed_data?.process_name === 'partner_upload'">
    <div class="table-box">
      <table class="table table-hover">
        <thead class="table-header align-items-center">
          <th class="w-10">Entry No.</th>
          <th class="w-10">Name</th>
          <th class="w-30">Remark</th>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let item of failed_data?.logData; let i = index">
            <td class="w-10">{{ item.index ? item.index + 1 : i + 1 }}</td>
            <td class="w-10 text-capitalize">
              {{ item.firstName + ' ' + (item?.lastName || '') }}
            </td>
            <td class="text-capitalize w-30">
              <span>{{ item.error_msg }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
