<div class="px-4 pb-3">
  <div class="modal-header px-0 pb-0">
    <h5 class="pt-3 color-grey-text text-uppercase">SELECT CARD TO PAY</h5>
    <button type="button" class="close" aria-label="Close" (click)="closePopup()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <hr />
  <div class="cards-list" *ngIf="!loading">
    <div class="d-flex justify-content-between py-2 card-box"
      *ngFor="let card of braintreeCards; let i = index"
      (click)="braintreeCardSelected(card)">
      <div class="col-2 d-flex justify-content-center align-items-center pr-0">
        <i class="{{ card?.cardClass }}"> </i>
      </div>
      <div class="d-flex flex-column col-8">
        <div class="d-flex">
          <span class="font-16 w-50 truncate font-hn-medium color-black-text">{{ card?.cardType }}</span>
          <span class="font-16 font-hn-medium color-black-text">{{ card?.last4 }}</span>
        </div>
        <span class="font-14 color-grey-black">Expires {{ card?.expirationMonth }}/{{ card?.expirationYear }}</span>
      </div>
      <div class="col-2 d-flex justify-content-center align-items-center">
        <img *ngIf="card === selectedBraintreeCard" src="../../../assets/icons/checked_2.svg" alt="upload" />
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <div *ngIf="loading" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="d-flex justify-content-end pt-2">
    <div class="d-flex justify-content-center align-items-center pr-1 bottom-area cursor-pointer">
      <i class="material-icons font-16 pr-1"> credit_card </i>
      <span class="color-primary font-12 font-lato-regular" (click)="addnewCard()"><u>ADD NEW CARD</u></span>
    </div>
  </div>
  <div class="my-4">
    <button [disabled]="!selectedBraintreeCard" class="btn btn-primary w-100 font-15 font-hn-medium shadow" (click)="pay()">
      {{ payNowtext }}
      <span [ngClass]="{
        'spinner-border spinner-border-sm': isloading,
        nil: !isloading
      }" role="status" aria-hidden="true"></span>
    </button>
  </div>
</div>
