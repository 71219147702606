import * as moment from 'moment-timezone';
import { timeZonesMap } from 'src/app/shared/utils';

export function getReminderTaskDueTime(delay: any) {
  const delayData = delay.split(' ');
  const date = new Date(new Date().setHours(0, 0, 0, 0));
  return moment(date).add(delayData[0], delayData[1]).toDate();
}

export function generateDateWithTimeZone(
  date: number,
  month: number,
  year: number,
  hours: number,
  minutes: number,
  timezone: string
) {
  const dateIso = moment()
    .tz(timeZonesMap[timezone])
    .year(year)
    .month(month)
    .date(date)
    .hour(hours)
    .minute(minutes)
    .toISOString();
  return dateIso;
}

export function generateDateWithTimeZoneforBirthDayandAnniversary(
  date: number,
  month: number,
  year: number,
  timezone: string
) {
  const date_got = moment()
    .tz(timeZonesMap[timezone])
    .year(year)
    .month(month)
    .date(date)
    .hour(9)
    .minute(30)
    .toISOString();

  return date_got;
}
