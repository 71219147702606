import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef) {}

  @Input() appOnlyNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const inputDecimalValueLength =
      this.el.nativeElement.value.split('.')[1] === undefined ? 0 : this.el.nativeElement.value.split('.')[1].length;
    if (this.appOnlyNumber) {
      if (
        [46, 8, 9, 27, 13, 110].includes(e.keyCode) ||
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.keyCode === 67 && e.ctrlKey === true) ||
        (e.keyCode === 88 && e.ctrlKey === true) ||
        (e.keyCode === 86 && e.ctrlKey === true) ||
        (e.keyCode === 190 && !this.el.nativeElement.value.includes('.')) ||
        (e.shiftKey === true && e.keyCode == 187) ||
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {

        return;
      }
      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }

    }
  }
}
