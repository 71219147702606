import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Store, select } from '@ngrx/store';
import { UploadCSVTransactions } from 'src/app/modules/transactions/store/actions';
import { selectCustomFieldsList } from 'src/app/modules/settings/store/selector';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UploadPartnersCSV } from 'src/app/modules/partners/store/actions';
import { CustomUserFields } from '../../utils';
import { SharedService } from '../../services/shared.service';
import { UploadCsv } from '../../../modules/contacts/store/action';

@Component({
  selector: 'dailyai-share-map-attributes',
  templateUrl: './share-map-attributes.component.html',
  styleUrls: ['./share-map-attributes.component.scss'],
})
export class ShareMapAttributesComponent {
  constructor(
    private fb: FormBuilder,
    private taostr: ToastrService,
    private store: Store<any>,
    private sharedService: SharedService
  ) {}

  mappingForm: FormGroup;

  uploading = false;

  temp_map = [];

  actual_fields_contacts = [
    'Email',
    'First Name',
    'Last Name',
    'Mobile Phone',
    'Middle Name',
    'Gender',
    'Address Line1',
    'Address Line2',
    'Anniversary',
    'City',
    'Coborrower_dob',
    'Coborrower_email',
    'Coborrower_name',
    'Coborrower_number',
    'Company Name',
    'Created Date',
    'Date of Birth',
    'Drips Extra',
    'Employer Name',
    'Fax Number',
    'Groups Extra',
    'Home Phone',
    'Job Title',
    'Notes',
    'Office_address',
    'Office_location',
    'Office_state',
    'Office_zip',
    'Property_addressLine1',
    'Property_addressLine2',
    'Property_city',
    'Property_state',
    'Property_zip',
    'Referral Partner Email',
    'Source',
    'State',
    'Status',
    'Tags',
    'Zip',
  ];

  actual_fields_transactions = [
    'First Name',
    'Last Name',
    'Middle Name',
    'Full Name',
    'Gender',
    'Loan #',
    'Account Name',
    'Anniversary',
    'Appraised Value',
    'Birthday',
    'Birthday (Secondary or Co Borrower)',
    'Category (or Group)',
    'City',
    'Closing Date',
    'Co Borrower Name',
    'Created Date',
    'Credit Score',
    'Credit Score (Secondary or Co Borrower)',
    'Data',
    'Down Payment',
    'Drips Extra',
    'Email',
    'Email (Secondary or Co Borrower)',
    'Employer',
    'Employer City',
    'Employer State',
    'Employer Street Address',
    'Employer Zip',
    'Funded Date',
    'Groups Extra',
    'Income',
    'Income (Secondary or Co Borrower)',
    'Lender',
    'LOA',
    'Loan Amount',
    'Loan Officer',
    'Loan Program',
    'Loan Purpose',
    'Loan to Value (LTV) Percentage',
    'Loan Type',
    'Lock Date',
    'Lock Expiration Date',
    'Marital Status',
    'Milestone',
    'Mobile',
    'Occupancy',
    'Owner',
    'Phone',
    'Prequalification Date',
    'Prequalification Expiration Date',
    'Property Address Line1',
    'Property Address Line2',
    'Property City',
    'Property State',
    'Property Type',
    'Property Zip',
    'Purchase Price',
    'Rate',
    'Referral Partner Email',
    'Related Contact',
    'State',
    'Status',
    'Street Address',
    'Tags',
    'Term',
    'Zip',
  ];

  actual_fields_partners = [
    'First Name',
    'Last Name',
    'Middle Name',
    'Email',
    'Phone Number',
    'Company',
    'Type',
    'Address',
    'City',
    'State',
    'Zipcode',
    'Birthday',
    'Anniversary',
  ];

  actual_fields: any[];

  subs: Subscription;

  db_fileds: any[][] = [];

  custom_user_fields: CustomUserFields = null;

  modalRefClose: any;

  prop: {
    id: any;
    file: any;
    assigned_to?: string;
    parentId?: string;
    file_headers?: string[];
    fileType?: string;
    optout?: boolean;
    groupId?: string[];
    selectedDripIds?: string[];
    preloadMappings?: { [key: string]: string };
  };

  @Input() set modalRef(modalRef: any) {
    this.modalRefClose = modalRef;
  }

  @Input() set payload(pValue: {
    id: any;
    file: any;
    assigned_to?: string;
    parentId?: string;
    file_headers?: string[];
    fileType?: string;
    optout?: boolean;
    groupId?: string[];
    selectedDripIds?: string[];
    preloadMappings?: { [key: string]: string };
  }) {
    if (this.subs && this.subs.unsubscribe) {
      this.subs.unsubscribe();
    }
    this.prop = pValue;
    if (pValue.fileType === 'contact') {
      this.actual_fields = this.actual_fields_contacts;
    } else if (pValue.fileType === 'transaction') {
      this.actual_fields = this.actual_fields_transactions;
    } else {
      this.actual_fields = this.actual_fields_partners;
    }
    this.subs = this.store.pipe(select(selectCustomFieldsList), take(2)).subscribe((val) => {
      this.custom_user_fields = val;
      if (pValue.fileType === 'contact' && this.custom_user_fields?.custom_fields_contacts?.length) {
        this.actual_fields = [
          ...this.actual_fields,
          ...this.custom_user_fields.custom_fields_contacts.map((c) => c.backendName),
        ];
      } else if (pValue.fileType === 'transaction' && this.custom_user_fields?.custom_fields_transaction?.length) {
        this.actual_fields = [
          ...this.actual_fields,
          ...new Set([
            ...this.custom_user_fields.custom_fields_transaction.map((c) => c.fieldName),
            ...this.custom_user_fields.custom_fields_contacts.map((c) => c.fieldName),
          ]),
        ];
      }
    });
    this.initForm(pValue.file_headers);
  }

  initForm(file_headers: string[]) {
    const controls = new FormArray([]);
    this.db_fileds = [];
    this.temp_map = [];
    this.temp_map.length = file_headers.length;
    Object.entries(file_headers).forEach((a) => {
      this.db_fileds.push(this.actual_fields);
    });
    file_headers.forEach((header, i) => {
      const mapControl = this.fb.group({
        csv_file_header: [{ value: header, disabled: true }],
        map_to_header: [null],
      });
      if (
        this.prop.preloadMappings &&
        (this.prop.preloadMappings[header.toLowerCase()] ||
          this.db_fileds[i].map((f: string) => f.toLowerCase()).includes(header.toLowerCase()))
      ) {
        let value = this.prop.preloadMappings[header.toLowerCase()] ??
          this.db_fileds[i][this.db_fileds[i].map((f) => f.toLowerCase()).indexOf(header.toLowerCase())];
        if (value === 'Referral Partner') {
          value = 'Referral Partner Email';
        }
        this.selectionChange(
          { target: { value } },
          i
        );
        mapControl
          .get('map_to_header')
          .setValue(value);
      }
      controls.push(mapControl);
    });
    this.mappingForm = this.fb.group({
      mappings: controls,
    });
  }

  selectionChange(e, i) {
    this.temp_map[i] = e.target.value;
    this.db_fileds.forEach((arr, index) => {
      if (index !== i) {
        this.db_fileds[index] = this.actual_fields.filter((f) => {
          return !this.temp_map.includes(f) || f === this.temp_map[index];
        });
      }
    });
  }

  removeMapping(i: number) {
    (this.mappingForm.get('mappings') as FormArray).removeAt(i);
    this.temp_map.splice(i, 1);
    this.db_fileds.splice(i, 1);
    this.db_fileds.forEach((arr, index) => {
      this.db_fileds[index] = this.actual_fields.filter((f) => {
        return !this.temp_map.includes(f) || f === this.temp_map[index];
      });
    });
  }

  clearMapping(i: number) {
    (this.mappingForm.get('mappings') as FormArray).controls[i].get('map_to_header').setValue(null);
    this.temp_map[i] = null;
    this.db_fileds.forEach((arr, index) => {
      this.db_fileds[index] = this.actual_fields.filter((f) => {
        return !this.temp_map.includes(f) || f === this.temp_map[index];
      });
    });
  }

  setMappingAndUpload() {
    if (this.uploading) {
      return;
    }
    this.uploading = true;
    let isMappingValid; let warningMsg;
    if (this.prop.fileType === 'contact') {
      isMappingValid =
        this.temp_map.includes(this.actual_fields[0]) &&
        this.temp_map.includes(this.actual_fields[1]) &&
        this.temp_map.includes(this.actual_fields[3]);
      warningMsg = `'First Name', 'Email', 'Phone Number'`;
    } else if (this.prop.fileType === 'transaction') {
      isMappingValid =
        this.temp_map.includes('Email') &&
        this.temp_map.includes('Loan #') &&
        (this.temp_map.includes('Full Name') || this.temp_map.includes('First Name'));
      warningMsg = `'Loan #' , 'Full Name' or 'First Name', 'Email'`;
    } else {
      isMappingValid =
        this.temp_map.includes('Email') &&
        this.temp_map.includes('Phone Number') &&
        this.temp_map.includes('First Name');
      warningMsg = `'First Name', 'Email', 'Phone Number'`;
    }
    if (isMappingValid) {
      this.uploading = true;
      this.createNewCSVAndUpload();
    } else {
      this.taostr.warning(warningMsg, 'Must Map To Fields');
      this.uploading = false;
    }
  }

  createNewCSVAndUpload() {
    try {
      const headers = [ ...this.prop.file_headers ];
      const {controls} = this.mappingForm.get('mappings') as FormArray;
      const csvMappings = {};
      const mappedHeaders = {};
      controls.forEach(control => {
        const csvFileHeader = control.get('csv_file_header').value;
        const { map_to_header: mapToHeader } = control.value;
        if (mapToHeader) {
          csvMappings[csvFileHeader.toLowerCase()] = mapToHeader;
        }
        mappedHeaders[csvFileHeader] = mapToHeader;
      });
      headers.forEach((header, index) => {
        headers[index] = mappedHeaders[header] ?? '_NO_MAP_';
      });
      this.sharedService
        .updateCsvMappings(this.prop.id, csvMappings, this.prop.fileType)
        .then(() => {})
        .catch((error) => {});
      const reader = new FileReader();
      reader.addEventListener('load', (ev) => {
        const { target } = ev;
        const [old_headers, ...data] = (target.result as string).split(/\r?\n|\r/);
        const newFileData = [headers, ...data].join('\n');

        const blob = new Blob([`\ufeff${  newFileData}`], {
          type: 'text/csv;charset=utf-8;',
        });
        const newFile = new File([blob], 'modifiedMapping.csv', { type: 'text/csv' });
        if (this.prop.fileType === 'contact') {
          const payload = {
            id: this.prop.id,
            assigned_to: this.prop.assigned_to,
            parentId: this.prop.parentId,
            file: newFile,
            groupId: this.prop.groupId,
            optout: this.prop.optout,
            selectedDripIds: this.prop.selectedDripIds,
          };
          this.store.dispatch(UploadCsv(payload));
        } else if (this.prop.fileType === 'transaction') {
          const payload = {
            id: this.prop.id,
            parentId: this.prop.parentId,
            file: newFile,
            groupId: this.prop.groupId,
            optout: this.prop.optout,
            selectedDripIds: this.prop.selectedDripIds,
          };

          this.store.dispatch(UploadCSVTransactions(payload));
        } else {
          const payload = {
            id: this.prop.id,
            file: newFile,
          };
          this.store.dispatch(UploadPartnersCSV(payload));
        }
      });
      reader.readAsText(this.prop.file);
    } catch (error) {
      console.error("csv upload error:", error);
    }
  }

  closeModal() {
    this.modalRefClose.close();
  }
}
