<div class="px-4 pb-4 reassignTask v2-ui-integretion">
  <div class="modal-header pb-0 pt-2 px-0" style="margin-bottom: inherit;" *ngIf="!mandatoryAdd">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"
      *ngIf="!mandatoryAdd">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div id="dropin-container"></div>
  <button id="add-braintree-card" class="btn btn-primary my-3 w-100" disabled (click)="addCardBraintree()">
    <span [ngClass]="{ 'spinner-border spinner-border-sm': isLoading, nil: !isLoading }" role="status" aria-hidden="true"></span>
    Add Card
  </button>
  <div class="d-flex justify-content-end w-100 mt-1" *ngIf="mandatoryAdd">
    <button class="btn btn-normal px-3" (click)="signOut()">
      <span>Logout</span>
    </button>
  </div>
</div>
