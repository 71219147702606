import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Store } from '@ngrx/store';
import { finalize, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { COLLECTIONS } from 'src/app/shared/config';
import * as DripStateActions from '../store/action';
import { Drip, DripSteps } from '../models/drip-campaign.model';

const api = environment.mongoUrl;
@Injectable({
  providedIn: 'root',
})
export class DripCampaignService {
  task: AngularFireUploadTask;

  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private store: Store<any>,
    private toastr: ToastrService
  ) {}

  subArraySize = 4000;

  async campaignAdd(
    payload: Drip,
    drip_campaign_id: string,
    date: any,
    deleted_steps: any[],
    pushDripObj: string[],
    logData: any
  ): Promise<any> {

    const logObj = {...logData};
    let contactUpdated;
    let data: Drip = JSON.parse(JSON.stringify(payload));
    if (data.campaign_name) {
      const name = data.campaign_name.toLowerCase();
      data = { ...data, campaign_name_insensitive: name };
    }
    contactUpdated = data.contactUpdated ?? false;
    delete data.contactUpdated;
    let drip_id = null;
    if (drip_campaign_id) {
      logObj.action = 'update';
      const previousCampData = (
        await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_campaign_id).ref.get()
      ).data() as Drip;
      const getSubCollection = await this.getCampaignContacts(drip_campaign_id);
      logObj.currentContacts = data.active_contacts;
      logObj.currentContactsCount = data.active_contacts.length;
      logObj.drip_campaign_id   = drip_campaign_id;
      logObj.previousData = {
        campaign_name: previousCampData.campaign_name,
        dateOrDelayType: previousCampData.dateOrDelayType,
        doNotStopOnReplyDrip: previousCampData.doNotStopOnReplyDrip,
        drip_timing_details: previousCampData.drip_timing_details,
        parentId: previousCampData.parentId,
        twilio_number: previousCampData.twilio_number,
        active_contacts_count: previousCampData.active_contacts_count,
        status: previousCampData.status,
      }
      logObj.currentData = {
        campaign_name: data.campaign_name,
        dateOrDelayType: data.dateOrDelayType,
        doNotStopOnReplyDrip: data.doNotStopOnReplyDrip,
        drip_timing_details: data.drip_timing_details,
        parentId: data.parentId,
        twilio_number: data.twilio_number,
        active_contacts_count: data.active_contacts.length,
        status: data.status,
      }
      if (contactUpdated) {
        this.modifyContactDripId(
          false,
          drip_campaign_id,
          data.active_contacts,
          logObj.changedBy,
          previousCampData.active_contacts,
          getSubCollection
        );

        data.active_contacts_count = data.active_contacts.length;
        const {active_contacts} = data;
        const currentCollLength = getSubCollection.docs.length - 1;
        const loopSize = Math.floor(active_contacts.length / this.subArraySize);
        for (let subDoc_id = 0; subDoc_id <= Math.max(loopSize, currentCollLength); subDoc_id++) {
          let contactList;
          let docData;
          if (subDoc_id <= loopSize) {
            contactList = active_contacts.splice(0, this.subArraySize);
            docData = { active_contacts: contactList };
          } else {
            docData = { active_contacts: [] };
          }
          await this.db
            .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
            .doc(drip_campaign_id)
            .collection<any>('active_contacts')
            .doc(`${subDoc_id}`)
            .set(docData, { merge: true });
        }
      } else {
        data.active_contacts_count = previousCampData?.active_contacts_count ?? 0;
      }

      data.active_contacts = [];
      if (!data.drip_timing_details) {
        data.drip_timing_details = null;
      }

      await this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
        .doc(drip_campaign_id)
        .set({ ...data, drip_campaign_id }, { merge: true });
      drip_id = drip_campaign_id;
      if (previousCampData.campaign_name !== data.campaign_name) {
        await this.updateDripNamesInMilestoneAndFollowUp(data, drip_campaign_id);
      }
      await this.addDripTasks(drip_id, 'edit', deleted_steps, pushDripObj);
      this.toastr.clear();
      this.toastr.success('Drip Campaign updated successfully');
      this.createLogs(logObj);
      return this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).update({ drip_campaign_id: drip_id });
    }

    const {active_contacts} = data;
    data.active_contacts_count = data.active_contacts ? data.active_contacts.length : 0;
    data.active_contacts = [];

    const value = await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).add(data);
    drip_id = value.id;
    await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).update({ drip_campaign_id: drip_id });
    await this.addDripTasks(value.id, 'add', [], pushDripObj);
    if (contactUpdated && data.active_contacts) {
      logObj.contactsUpdated = true;
      this.modifyContactDripId(true, drip_id, active_contacts,logObj.changedBy);
      const loopSize = Math.floor(active_contacts.length / this.subArraySize);
      for (let subDoc_id = 0; subDoc_id <= loopSize; subDoc_id++) {
        let contactList;
        let docData;
        contactList = active_contacts.splice(0, this.subArraySize);
        docData = { active_contacts: contactList };
        await this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
          .doc(drip_id)
          .collection<any>('active_contacts')
          .doc(`${subDoc_id}`)
          .set(docData, { merge: true });
      }
    }
    logObj.drip_campaign_id = drip_id;
    this.createLogs(logObj);
    this.toastr.clear();
    this.toastr.success('Drip Campaign added successfully');

    return this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).update({ drip_campaign_id: drip_id });
  }

  async addDripTasks(campaign_id: string, optype: string, deleted_steps: any[] = [], pushDripObj) {
    const payload = {
      campaign_id,
      optype,
      deleted_steps,
    };
    const url = `${environment.cloud_base_url}/insertDripTasks`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.http.post(url, payload, httpOptions).subscribe((response) => {
      if (pushDripObj?.length) {
        this.http
          .post(
            `${environment.cloud_base_url}/pushDripToUsers`,
            { baseDripId: campaign_id, users: pushDripObj },
            httpOptions
          )
          .subscribe();
      }
    });
  }

  async deleteDripTasks(data, drip_id, date) {
    const url = `${environment.cloud_base_url}/deleteDripTasks`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.http.post(url, { drip_campaign_id: drip_id }, httpOptions).subscribe(
      (resp) => {

      },
      (err) => {
        console.warn(err);
      }
    );
  }

  async getDripDataById(doc_id: string) {
    const data = (await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(doc_id).ref.get()).data();
    return data;
  }

  getCampaignListWithoutLimit(id, user) {
    let campaignList;
    if (user.userType === 'JLO') {
      campaignList = this.db
        .collection<any>(
          COLLECTIONS.DRIP_CAMPAIGN,
          (ref) => ref.where('added_by', '==', id).orderBy('created_at', 'desc')
        )
        .valueChanges();
    } else {
      campaignList = this.db
        .collection<any>(
          COLLECTIONS.DRIP_CAMPAIGN,
          (ref) => ref.where('parentId', '==', user.superUserId ?? user.parentId).orderBy('created_at', 'desc')
        )
        .valueChanges();
    }
    return campaignList;
  }

  getcampaignList(id, user, no_limit?: boolean) {
    if (no_limit) {
      return this.getCampaignListWithoutLimit(id, user);
    }
    let campaignList;
    if (user.userType === 'JLO') {
      campaignList = this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref.where('added_by', '==', id).orderBy('created_at', 'desc').limit(15)
        )
        .valueChanges();
    } else {
      campaignList = this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref
            .where('parentId', '==', user.superUserId ?? user.parentId)
            .orderBy('created_at', 'desc')
            .limit(15)
        )
        .valueChanges();
    }
    return campaignList;
  }

  getcampaignActiveList(id, user) {
    return this.db
      .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) => {
        let query;
        if (user.userType === 'JLO') {
          query = query.where('added_by', '==', id);
        } else {
          query = query.where('parentId', '==', user.parentId);
        }
        return query.ref.get().ref.data();
      })
      .valueChanges();
  }

  getLeadRoutingList(id) {
    const routeList = this.db
      .collection<any>(COLLECTIONS.LEAD_ROUTINGS, (ref) => ref.where('added_by', '==', id).orderBy('created_at', 'desc'))
      .valueChanges();
    return routeList;
  }

  getcampaignDetails(id) {
    const campaignList = this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(id).get();
    return campaignList;
  }

  async getCampaignContacts(id) {
    return this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(id).collection<any>('active_contacts').ref.get();
  }

  async getcampaignDetailsAsync(id) {
    const campaignList = await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(id).ref.get();
    return campaignList.data();
  }

  editDrip(id) {
    return null;
  }

  deleteDripStep(id) {
    return null;
  }

  async deleteCampaign(list) {
    for (const item of list) {
      await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(item).delete();
      const url = `${environment.cloud_base_url}/deleteDripTasks`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.auth.getUserToken()}`,
          'Access-Control-Allow-Origin': 'http://localhost:4200/',
        }),
      };
      this.http.post(url, { drip_campaign_id: item }, httpOptions).subscribe(
        (del) => {
        },
        (err) => {
          console.warn(err);
        }
      );
    }
    this.store.dispatch(DripStateActions.ResetDemandScrollData());
  }

  campaignUpdate(payload: any[], id) {
    const data = payload;
    for (const a of data) {
      if (!a.doc_id) {
        a.doc_id = null;
      }
    }
    this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(id).update({ active_contacts: data });
  }

  async uploadImage(file: any, id: any) {
    const currentTime = Date.now();
    const path = `drip_campaign/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = ref.putString(file, 'data_url');
    return new Promise((resolve, reject) => {
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe(
              (url) => {
                resolve(url);
              },
              (error) => {
                console.warn(error);
                reject(error);
              }
            );
          })
        )
        .subscribe();
    });
  }

  async uploadImageEmail(file: any, id: any) {
    const currentTime = Date.now();
    const path = `drip_campaign/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    return new Promise((resolve, reject) => {
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe(
              (url) => {
                resolve(url);
              },
              (error) => {
                console.warn(error);
                reject(error);
              }
            );
          })
        )
        .subscribe();
    });
  }

  async fetchCount(data, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    data = cleanUser(data);
    return this.http.post(`${api}/v0.1/drips/fetchDripsCount`, { user: data, onlyMe }, httpOptions).toPromise();
  }

  async fetchDrips(data, uData, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    uData = cleanUser(uData);
    return this.http
      .post(`${api}/v0.1/drips/fetchDrips`, { value: data, user: uData, onlyMe }, httpOptions)
      .toPromise();
  }

  // Demand Scrolling
  fetchDripsDemandScroll(obj: any, last_doc?: any) {
    if (obj.search || obj.user.userType==='TeamLeader'||obj.user.privileged_jlo) {
      return this.searchDrip(obj.data, obj.user, obj.onlyMe ?? false ,obj.loadAll??false ,obj.loadMyDrips??false);
    }
    if (obj.onlyMe) {
      if (!last_doc) {
        return this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
            ref.where('added_by', '==', obj.user.uid).orderBy('campaign_name_insensitive').limit(10)
          )
          .get()
          .pipe(map((d) => d.docs.map((r) => r.data())));
      }
      return this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref
            .where('added_by', '==', obj.user.uid)
            .orderBy('campaign_name_insensitive')
            .orderBy('drip_campaign_id')
            .startAfter(last_doc.campaign_name_insensitive, last_doc.drip_campaign_id)
            .limit(10)
        )
        .get()
        .pipe(map((d) => d.docs.map((r) => r.data())));
    }
    if (obj.user.userType === 'MO') {
      if (!last_doc) {
        return this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
            ref
              .where('parentId', '==', obj.user.parentId)
              .orderBy('campaign_name_insensitive')
              .orderBy('drip_campaign_id')
              .limit(10)
          )
          .get()
          .pipe(map((d) => d.docs.map((r) => r.data())));
      }
      return this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref
            .where('parentId', '==', obj.user.parentId)
            .orderBy('campaign_name_insensitive')
            .orderBy('drip_campaign_id')
            .startAfter(last_doc.campaign_name_insensitive, last_doc.drip_campaign_id)
            .limit(10)
        )
        .get()
        .pipe(map((d) => d.docs.map((r) => r.data())));
    }

    if (obj.user.userType === 'JLO') {
      if (!last_doc) {
        return this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
            ref
              .where('added_by', '==', obj.user.uid)
              .orderBy('campaign_name_insensitive')
              .orderBy('drip_campaign_id')
              .limit(10)
          )
          .get()
          .pipe(map((d) => d.docs.map((r) => r.data())));
      }
      return this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref
            .where('added_by', '==', obj.user.uid)
            .orderBy('campaign_name_insensitive')
            .orderBy('drip_campaign_id')
            .startAfter(last_doc.campaign_name_insensitive, last_doc.drip_campaign_id)
            .limit(10)
        )
        .get()
        .pipe(map((d) => d.docs.map((r) => r.data())));
    }
      if (!last_doc) {
        return this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
            ref
              .where('added_by', '==', obj.user.uid)
              .orderBy('campaign_name_insensitive')
              .orderBy('drip_campaign_id')
              .limit(10)
          )
          .get()
          .pipe(map((d) => d.docs.map((r) => r.data())));
      }
      return this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN, (ref) =>
          ref
            .where('added_by', '==', obj.user.uid)
            .orderBy('campaign_name_insensitive')
            .orderBy('drip_campaign_id')
            .startAfter(last_doc.campaign_name_insensitive, last_doc.drip_campaign_id)
            .limit(10)
        )
        .get()
        .pipe(map((d) => d.docs.map((r) => r.data())));

  }

  async fetchDripsDemandScrollMongo(data: { user_id: string; user: any; curPage: any; contactDrips: string[] }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${api}/v0.1/drips/fetchDrips`,
        {
          value: {
            curPage: data.curPage,
            sortby: { campaign_name_insensitive: 1 },
            skipDrips: data.contactDrips ?? [],
          },
          user: cleanUser(data.user),
          onlyMe: true,
        },
        httpOptions
      )
      .toPromise();
  }

  async deleteMedia(media: string) {
    try {
      return await this.storage.storage.refFromURL(media).delete();
    } catch (error) {
      console.error(error);
    }
  }

  async searchDrip(data, uData, onlyMe: boolean ,loadAll?:boolean ,loadMyDrips?:boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    uData = cleanUser(uData);

    return this.http
      .post(`${api}/v0.1/drips/searchDrips`, { value: data, user: uData, onlyMe ,loadAll,loadMyDrips}, httpOptions)
      .toPromise();
  }

  async filterDrips(data, uData, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    uData = cleanUser(uData);
    return this.http.post(`${api}/v0.1/drips/getDrips`, { value: data, user: uData, onlyMe }, httpOptions).toPromise();
  }

  async sortDrip(data, uData, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    uData = cleanUser(uData);
    return this.http.post(`${api}/v0.1/drips/sort-drip`, { value: data, user: uData, onlyMe }, httpOptions).toPromise();
  }

  async createLeadRouting(payload) {
    const userId = await this.auth.getCurrentUserUid();
    await this.db.collection<any>(COLLECTIONS.LEAD_ROUTINGS).doc(userId).set({ routes: payload, doc_id: userId });
  }

  async getLeadRoutes() {
    const userId = await this.auth.getCurrentUserUid();
    return this.db.collection<any>(COLLECTIONS.LEAD_ROUTINGS).doc(userId).valueChanges();
  }

  async modifyContactDripId(
    newDrip: boolean,
    dripId,
    currentContactList,
    changedBy,
    previousCampContacts?,
    currentSubCollection?
  ) {
    const currentContactIdList = currentContactList.map((contact) => contact.doc_id);
    let payload;
    if (!newDrip) {
      let previousContactIdList;
      previousContactIdList = [];
      if (previousCampContacts && previousCampContacts.length > 0) {
        previousContactIdList = previousCampContacts.map((data) => data.doc_id);
      } else {
        for (const doc of currentSubCollection.docs) {
          const contact_id_list = await doc.data().active_contacts.map((data) => data.doc_id);
          previousContactIdList = [...previousContactIdList, ...contact_id_list];
        }
        previousContactIdList = new Set(previousContactIdList);
        const addListId = new Set(currentContactIdList);
        for (const id of currentContactIdList) {
          if (previousContactIdList.delete(id)) {
            addListId.delete(id);
          }
        }
        payload = {
          campaign_id: dripId,
          contactsToAdd: Array.from(addListId),
          contactsToRemove: Array.from(previousContactIdList),
          changedBy,
        };
      }
    } else {
      const contactsToRemove = [];
      payload = {
        campaign_id: dripId,
        contactsToAdd: currentContactIdList,
        contactsToRemove: [],
        changedBy
      };
    }

    const url = `${environment.cloud_base_url}/updateDripContacts`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    this.http.post(url, payload, httpOptions).subscribe();

  }

  async getcampaignCreator(id) {
    const campaignCreator = await this.db.collection<any>(COLLECTIONS.USERS).doc(id).ref.get();
    return campaignCreator.data();
  }

  async getJuniorOfficersList(userId) {
    const juniorsList = [];
    const juniorslist = await this.db.collection<any>(COLLECTIONS.JUNIOR_USERS).ref.where('parentId', '==', userId).get();
    juniorslist.forEach((element) => {
      let obj = {};
      obj = element.data();
      juniorsList.push(obj);
    });
    return juniorsList;
  }

  async sharedCampaignAdd(payload) {
    try {
      const data = JSON.parse(JSON.stringify(payload));
      let drip_id = null;
      for (let item of data) {
        if (item.campaign_name) {
          const name = item.campaign_name.toLowerCase();
          item = { ...item, campaign_name_insensitive: name };
        }
        const value = await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).add(item);
        drip_id = value.id;
        await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).update({ drip_campaign_id: drip_id });
        await this.addDripTasks(value.id, 'add', [], null);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  campaignSharingDetailsUpdate(payload, dripId) {
    const data = payload;
    this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(dripId).update({ shared_to: data });
  }

  async makeCopyCampaign(copydrip, oldDrip) {
    try {
      const data = JSON.parse(JSON.stringify(copydrip));
      let drip_id = null;
      if (copydrip.campaign_name) {
        const name = copydrip.campaign_name.toLowerCase();
        copydrip = { ...copydrip, campaign_name_insensitive: name };
      }
      if (copydrip.shared_by) {
        delete copydrip.shared_by;
      }
      const value = await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).add(copydrip);
      drip_id = value.id;
      await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).update({ drip_campaign_id: drip_id });
      await (oldDrip['copies_made'] ? this.db
          .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
          .doc(oldDrip.drip_campaign_id)
          .update({ copies_made: oldDrip['copies_made'] + 1 }) : this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(oldDrip.drip_campaign_id).update({ copies_made: 1 }));
      await this.addDripTasks(value.id, 'add', [], null);
    } catch (error) {
      console.warn(error);
    }
  }

  async dripTestingStep(data: DripSteps) {
    this.toastr.info('Drip testing will be done in background', 'Testing Drip steps', {
      progressBar: true,
      progressAnimation: 'decreasing',
    });
    const url = `${environment.cloud_base_url}/testDripStep`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    this.http.post(url, { step: data }, httpOptions).subscribe(
      (resp) => {
        try {
          if (resp && resp['msg'] == 'done' && resp['err'] == false) {
            this.toastr.success('Drip testing successful', 'Testing Drip');
          } else {
            this.toastr.error('Error occured while testing drip', 'Testing Drip');
          }
        } catch (error) {
          console.warn(error);
        }
      },
      (err) => {
        console.warn(err);
      }
    );
  }

  async checkCampaignNameExists(id, user, dripName) {
    const campaignList = [];
    let campaignlist;
    if (user.userType === 'JLO') {
      campaignlist = await this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
        .ref.where('added_by', '==', user.uid)
        .where('campaign_name', '==', dripName)
        .get();
    } else {
      campaignlist = await this.db
        .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
        .ref.where('parentId', '==', user.superUserId ?? user.parentId)
        .where('campaign_name', '==', dripName)
        .get();
    }
    campaignlist.forEach((element) => {
      let obj = {};
      obj = element.data();
      campaignList.push(obj);
    });
    return campaignList.length > 0;
  }

  getOptOutDrip(id) {
    return this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).ref.where('parentId', '==', id).where('optout', '==', true).get();
  }

  async SearchForJlo(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${api}/v0.1/users/searchJLOUser`,
        data.designation
          ? {
              id: data.id,
              designation: data.designation,
              searchTerm: data.searchTerm,
            }
          : {
              id: data.id,
              searchTerm: data.searchTerm,
              jloOnly: data.jloOnly
            },
        httpOptions
      )
      .toPromise();
  }

  async userSearch(payload: { id: string; value: { curPage: number; term?: string; skipArray?: string[] }; user: any }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/users/search-users-typeahead`, payload, httpOptions);
  }

  async updateDripNamesInMilestoneAndFollowUp(drip:Drip , dripCampaignId:string) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.auth.getUserToken()}`,
          'Access-Control-Allow-Origin': 'http://localhost:4200/',
        }),
      };
      const url = `${environment.cloud_base_url}/updateDripNamesInMilestoneAndFollowUp`;

      await this.http.post(url, { drip, dripCampaignId }, httpOptions).toPromise();
    } catch (error) {
      console.log(error);
    }
  }
  async createLogs(log){
    try{
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.auth.getUserToken()}`,
          'Access-Control-Allow-Origin': 'http://localhost:4200/',
          }),
        };
        const url = `${environment.cloud_base_url}/createLogs`;
        await this.http.post(url, {log}, httpOptions).toPromise();
      }catch(error){
        console.log(error);
      }

    }

  }
function cleanUser(user) {
  let formatted_user = user;
  if (user.emailSignature) {
    const { emailSignature, ...noA } = user;
    formatted_user = noA;
  }

  return formatted_user;
}
