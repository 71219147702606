<div class="modal-body v2-ui-integretion p-0">
  <div class="modal-body p-0">
    <div class="d-block d-sm-none responsive-classes">
      <div class="d-flex justify-content-between">
        <h5 class="pt-3 color-grey-text font-18 pt-4 pb-2 pl-3" *ngIf="url === '/transactions'">SHARE TRANSACTION</h5>
        <h5 class="color-grey-text font-18 pt-4 pb-2 pl-3" *ngIf="url !== '/transactions'">
          SHARE DRIP : {{ dripCampaingDetailsclone['campaign_name'] }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <i class="material-icons icon-color"> close </i>
        </button>
      </div>
      <div class="d-flex flex-column">
        <div class="px-2 border-bottom">
          <ul class="nav nav-tabs">
            <li class="px-2 font-14 outline-none w-50" (click)="tabClick('otherOfficers')">
              <a>
                <label class="v2-checkbox mb-0 mt-n1">
                  <input type="checkbox" [(ngModel)]="select_all" (ngModelChange)="onSelectAll($event)" />
                  <span class="checkmark" style="top: 2px"> </span>
                </label>
                <span class="share-text font-14 color-primary" style="position: relative; top: 3px"
                  >Other Officers</span
                >
              </a>
            </li>
            <li class="px-2 font-14 outline-none w-50" (click)="tabClick('sharedOfficers')">
              <a>Shared Officers</a>
            </li>
          </ul>
        </div>
        <div class="mx-3 my-2" *ngIf="tab === 'otherOfficers'">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text material-icons pr-0">search</span></div>
            <input
              type="text"
              class="form-control pl-1"
              type="text"
              placeholder="Search name, phone, email…"
              [(ngModel)]="search"
              (ngModelChange)="searchJLOs$.next($event)"
            />
          </div>
          <span class="font-14 no-data-text" *ngIf="juniorsListTogetShared?.length && juniorsList?.length == 0"
            >No user to share</span
          >
          <div class="shared-loop mt-2" *ngIf="juniorsList">
            <div class="d-flex align-items-center mb-2" *ngFor="let item of juniorsList">
              <div class="table-check-box col-1 px-0">
                <label class="v2-checkbox mb-0">
                  <input
                    type="checkbox"
                    [(ngModel)]="item.checked"
                    (change)="updateItem($event, item)"
                    value="item.id"
                  />
                  <span class="checkmark" style="top: -7px"></span>
                </label>
              </div>
              <div class="d-flex align-items-center w-100">
                <div class="col-1 px-0">
                  <i
                    *ngIf="url !== '/drip-campaign' && item['privilaged_user']"
                    class="material-icons star-icon font-14 pr-2"
                    placement="right"
                    ngbTooltip="Privilaged Officer"
                    tooltipClass="tooltip-custom-class"
                    >star</i
                  >
                </div>
                <div class="d-flex flex-column col-5 px-0">
                  <span class="font-14 user-name text-capitalize w-150px text-truncate"
                    >{{ item['firstName'] }} {{ item['lastName'] }}</span
                  >
                  <div class="font-12 user-email w-150px text-truncate">{{ item['email'] }}</div>
                </div>
              </div>
              <div class="font-14 user-phone col-5 px-0 d-flex justify-content-end" *ngIf="!item['phoneNo']">-NA-</div>
              <div class="font-14 user-phone col-5 px-0 d-flex justify-content-end" *ngIf="item['phoneNo']">
                {{ item['phoneNo'] | phoneNumberFormat }}
              </div>
            </div>
          </div>
        </div>
        <div class="px-3 py-2" *ngIf="tab === 'sharedOfficers'" style="background: #eff4f7">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text material-icons pr-0">search</span></div>
            <input
              type="text"
              class="form-control pl-1"
              type="text"
              placeholder="Search name, phone, email…"
              [(ngModel)]="userSearch"
              (ngModelChange)="userSearch$.next($event)"
              style="background: #eff4f7"
            />
          </div>
          <div class="shared-loop mt-2">
            <span class="font-14 no-data-text" *ngIf="url === '/transactions' && sharedToArray?.length == 0"
              >Transaction has not been shared to any users yet..</span
            >
            <span class="font-14 no-data-text" *ngIf="url !== '/transactions' && sharedToArray?.length == 0"
              >Drip has not been shared to any users yet..</span
            >
            <span class="font-14 no-data-text" *ngIf="sharedToArray?.length && resultArray?.length == 0"
              >No results found..</span
            >
            <div class="d-flex align-items-center mb-2" *ngFor="let item of resultArray">
              <div class="col-1 px-0">
                <i
                  *ngIf="url !== '/drip-campaign' && item['privilaged_user']"
                  class="material-icons star-icon font-14 pr-2"
                  placement="right"
                  ngbTooltip="Privilaged Officer"
                  tooltipClass="tooltip-custom-class"
                  >star</i
                >
              </div>
              <div class="d-flex flex-column col-5 px-0">
                <span class="font-14 user-name text-capitalize text-truncate"
                  >{{ item['firstName'] }} {{ item['lastName'] }}</span
                >
                <div class="font-12 user-email text-truncate">{{ item['email'] }}</div>
              </div>
              <div class="font-14 user-phone col-6 px-0 d-flex justify-content-end">
                {{ item['phoneNo'] | phoneNumberFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <div class="d-flex shared-layout">
        <div class="col-6 pt-4 pb-2 px-4 officers-layout bg-white">
          <h5 class="pt-3 color-grey-text font-18" *ngIf="url === '/transactions'">SHARE TRANSACTION</h5>
          <h5 class="color-grey-text font-18" *ngIf="url !== '/transactions'">
            SHARE DRIP : {{ dripCampaingDetailsclone['campaign_name'] }}
          </h5>
          <div class="d-flex mt-3 pb-2">
            <div class="table-check-box d-flex w-100">
              <label class="v2-checkbox mb-0">
                <input type="checkbox" [(ngModel)]="select_all" (ngModelChange)="onSelectAll($event)" />
                <span class="checkmark" style="top: 2px"
                  ><span class="share-text font-14 color-primary font-weight-bold pl-4 d-flex w-200 mt-n1"
                    >Other Officers</span
                  >
                </span>
              </label>
            </div>
          </div>
          <div class="input-group mt-4">
            <div class="input-group-prepend"><span class="input-group-text material-icons pr-0">search</span></div>
            <input
              type="text"
              class="form-control pl-1"
              type="text"
              id = 'sharesearchID'
              placeholder="Search name, phone, email…"
              [(ngModel)]="search"
              (ngModelChange)="searchJLOs$.next($event)"
            />
          </div>
          <span class="font-14 no-data-text" *ngIf="juniorsListTogetShared?.length && juniorsList?.length == 0"
            >No user to share</span
          >
          <div class="shared-loop mt-2" *ngIf="juniorsList">
            <div class="d-flex align-items-center mb-2" *ngFor="let item of juniorsList ;index as i ">
              <div class="table-check-box col-1 px-0">
                <label class="v2-checkbox mb-0">
                  <input
                    type="checkbox"
                    [(ngModel)]="item.checked"
                    (change)="updateItem($event, item)"
                    value="item.id"
                  />
                  <span [attr.dataindex]="i"  class="checkmark" style="top: -7px"></span>
                </label>
              </div>
              <div class="d-flex align-items-center w-100">
                <div class="col-1 px-0">
                  <i
                    *ngIf="url !== '/drip-campaign' && item['privilaged_user']"
                    class="material-icons star-icon font-14 pr-2"
                    placement="right"
                    ngbTooltip="Privilaged Officer"
                    tooltipClass="tooltip-custom-class"
                    >star</i
                  >
                </div>
                <div class="d-flex flex-column col-5 px-0">
                  <span class="font-14 user-name text-capitalize w-150px text-truncate"
                    >{{ item['firstName'] }} {{ item['lastName'] }}</span
                  >
                  <div class="font-12 user-email w-150px text-truncate">{{ item['email'] }}</div>
                </div>
              </div>
              <div class="font-14 user-phone col-5 px-0 d-flex justify-content-end" *ngIf="!item['phoneNo']">-NA-</div>
              <div class="font-14 user-phone col-5 px-0 d-flex justify-content-end" *ngIf="item['phoneNo']">
                {{ item['phoneNo'] | phoneNumberFormat }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 pt-3 pb-2 px-4 shared-layout d-flex flex-column border-left">
          <div class="d-flex justify-content-end w-100">
            <button type="button" class="close pt-3" aria-label="Close" (click)="closeModal()">
              <i class="material-icons icon-color"> close </i>
            </button>
          </div>
          <span
            [ngClass]="{ 'share-text-margin': url === '/drip-campaign', 'share-text': url !== '/drip-campaign' }"
            class="font-14 color-primary font-weight-bold"
            >Shared Officers</span
          >
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text material-icons pr-0">search</span></div>
            <input
              type="text"
              class="form-control pl-1"
              type="text"
              placeholder="Search name, phone, email…"
              [(ngModel)]="userSearch"
              (ngModelChange)="Search$.next($event)"
            />
          </div>
          <div class="shared-loop mt-2">
            <span class="font-14 no-data-text" *ngIf="url === '/transactions' && sharedToArray?.length == 0"
              >Transaction has not been shared to any users yet..</span
            >
            <span class="font-14 no-data-text" *ngIf="url !== '/transactions' && sharedToArray?.length == 0"
              >Drip has not been shared to any users yet..</span
            >
            <span class="font-14 no-data-text" *ngIf="sharedToArray?.length && resultArray?.length == 0"
              >No results found..</span
            >
            <div class="d-flex align-items-center mb-2" *ngFor="let item of resultArray">
              <div class="col-1 px-0">
                <i
                  *ngIf="url !== '/drip-campaign' && item['privilaged_user']"
                  class="material-icons star-icon font-14 pr-2"
                  placement="right"
                  ngbTooltip="Privilaged Officer"
                  tooltipClass="tooltip-custom-class"
                  >star</i
                >
              </div>
              <div class="d-flex flex-column col-5 px-0">
                <span class="font-14 user-name text-capitalize text-truncate"
                  >{{ item['firstName'] }} {{ item['lastName'] }}</span
                >
                <div class="font-12 user-email text-truncate">{{ item['email'] }}</div>
              </div>
              <div class="font-14 user-phone col-6 px-0 d-flex justify-content-end">
                {{ item['phoneNo'] | phoneNumberFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4 pt-4 pr-3 border-top">
      <button class="btn btn-normal mr-2 px-5" (click)="closeModal()">Cancel</button>
      <button class="btn btn-primary px-5" (click)="ShareTo()">Share</button>
    </div>
  </div>
</div>
