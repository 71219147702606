import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IntakeForm } from 'src/app/models/intake-form.model';
import { User } from 'src/app/models/user.model';
import { UserType } from 'src/app/shared/enums/commonEnums';
import { COLLECTIONS } from 'src/app/shared/config';

@Injectable({
  providedIn: 'root',
})
export class IntakeFormService {
  constructor(
    private db: AngularFirestore,
    private toast: ToastrService,
    private modal: NgbModal
  ) {}

  async saveIntakeForm(formId: string, payload: IntakeForm) {
    try {
      const collection = this.db.collection<any>(COLLECTIONS.INTAKE_FORMS).ref;
      if (!formId) {
        const added = await collection.add(payload);
        collection.doc(added.id).update({
          docId: added.id,
        });
        this.modal.dismissAll();
        this.toast.success('Intake Form Saved');
      } else {
        await collection.doc(formId).update(payload);
        this.modal.dismissAll();
        this.toast.success('Intake Form Saved');
      }
    } catch (error) {
      this.toast.error('Error');
      console.warn(error);
    }
  }

  getAllIntakeForms(userData: User): Observable<any> {
    if (userData.isEnterprise) {
      const { userType, superUserId, uid, managerId, teamLeaderId } = userData;

      if (UserType.ADMIN === userType || UserType.SUPER_ADMIN === userType) {
        return this.db
          .collection(COLLECTIONS.INTAKE_FORMS, (ref) => ref.where('uId', '==', superUserId ?? uid))
          .get()
          .pipe(
            map((d) => {
              return d.docs.map((x) => x.data());
            })
          );
      }

      if (userType === UserType.MANAGER) {
        return this.db
          .collection<IntakeForm>(COLLECTIONS.INTAKE_FORMS, (ref) => ref.where('uId', 'in', [superUserId, managerId ?? uid]))
          .get()
          .pipe(map((d) => d.docs.map((x) => x.data())));
      }
      return this.db
        .collection<any>(COLLECTIONS.INTAKE_FORMS, (ref) =>
          ref.where('uId', 'in', [superUserId, ...(managerId ? [managerId] : []), teamLeaderId ?? uid])
        )
        .get()
        .pipe(
          map((d) => {
            return d.docs.map((x) => x.data());
          })
        );
    }
    return this.db
      .collection<any>(COLLECTIONS.INTAKE_FORMS, (ref) => ref.where('uId', '==', userData.parentId ?? userData.uid))
      .get()
      .pipe(
        map((d) => {
          return d.docs.map((x) => x.data());
        })
      );
  }

  deleteIntakeForm(docId: string) {
    return this.db.collection<any>(COLLECTIONS.INTAKE_FORMS).doc(docId).delete();
  }
}
