import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserState } from 'src/app/modules/settings/store/state';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectMilestoneAddStatus } from 'src/app/modules/settings/store/selector';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dailyai-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @Input() public action;

  @Input() public message;

  @Input() public yesText = 'Yes';

  @Input() public noText = 'No';

  @Input() public titleText: string | boolean = false;

  addStatus = '';

  emit = false;

  disableOnClick = false;

  btnDisable = false;

  loading = false;

  addStatus$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  @Output() modalAction: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private userStore: Store<UserState>) {
    this.addStatus$ = this.userStore.pipe(takeUntil(this.ngUnsubscribe), select(selectMilestoneAddStatus));
  }

  ngOnInit() { }

  submit(action) {
    if (action == 'submit') {
      this.loading = true;
    }
    this.modalAction.emit(action);
  }

  onSubmit(action: string) {
    if (this.disableOnClick) {
      this.btnDisable = true;
      this.loading = true;
    }
    if (this.emit) {
      this.modalAction.emit(action);
      return;
    }
    this.activeModal.close(action);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
