import { createReducer, on } from '@ngrx/store';
import * as TaskStateActions from './action';

import { TaskState } from './state';

export const initialState: TaskState = {
  tasks: [],
  taskList: [],
  taskSuccess: null,
  count: 0,
  pending: 0,
  overdue: 0,
  curPage: 1,
  params: null
};

export const TasksReducer = createReducer(
  initialState,
  on(TaskStateActions.TaskResults, (state, payload) => {
    return {
      ...state,
      taskSuccess: true,
      tasks: payload.data.data ? payload.data.data : payload.data,
      taskList: payload.data.data ? payload.data.data : state.taskList,
      pending: payload.data.pendings ? payload.data.pendings : state.pending,
      overdue: payload.data.overdue ? payload.data.overdue : state.overdue,
      count: payload.data.data ? payload.data.count : state.count
    };
  }),
  on(TaskStateActions.TaskCount, (state, payload) => ({
    ...state,
    count: payload.data
  })),
  on(TaskStateActions.FetchTasks, (state, payload) => ({
    ...state,
    taskSuccess: false,
    curPage: payload.data.curPage ? payload.data.curPage : state.curPage
  })),
  on(TaskStateActions.FilterTasks, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage ? payload.data.curPage : state.curPage,
    params: payload.data ? payload.data : state.params
  }))
);
