import { createAction, props } from '@ngrx/store';
import { Team } from 'src/app/models/teams.model';

export const GetTeamsList = createAction('[Teams Page] GetTeamsList', props<{ payload: any }>());
export const GetTeamsListSuccess = createAction(
  '[Teams Page] GetTeamsListSuccess',
  props<{ data: any[]; count: number }>()
);
export const GetTeamsListError = createAction('[Teams Page] GetTeamsListError', props<{ data: any }>());
export const ClearTeamPayload = createAction('[Teams Page] ClearTeamPayload');

export const GetTeamData = createAction('[Teams Page] Get TEam Data', props<{ teamId: string }>());
export const GetTeamDataSuccess = createAction(
  '[Teams Page] Get TEam Data Success',
  props<{ data: Team; payload?: any }>()
);
export const GetTeamDataError = createAction('[Teams Page] Get TEam Data Error', props<{ data: any }>());

export const GetEnterpriseUsersList = createAction(
  '[Teams Page] Get EnterpriseUser',
  props<{
    data: {
      curPage: number;
      filters?: any;
      sort?: { sortby: string; order: number };
      type?: string | string[];
      teamId?: string;
      searchTerm?: string;
      limit?: number;
    };
    forTeam?: boolean;
    silent?: boolean;
  }>()
);
export const GetEnterpriseUsersListSuccess = createAction(
  '[Teams Page] Get EnterpriseUser success',
  props<{ data: any[]; count: number }>()
);

export const GetEnterpriseUsersListError = createAction(
  '[Teams Page] Get EnterpriseUser error',
  props<{ data: any }>()
);
export const GetEnterpriseUsersTeamNameAndAddedBy = createAction(
  '[Teams Page] GetEnterpriseUsersTeamAndAddedBy',
  props<{ data: any[] }>()
);
export const GetEnterpriseUsersTeamNameAndAddedBySuccess = createAction(
  '[Teams Page] GetEnterpriseUsersTeamAndAddedBySuccess',
  props<{ data: any[] }>()
);
export const GetEnterpriseUsersTeamNameAndAddedByError = createAction(
  '[Teams Page] GetEnterpriseUsersTeamAndAddedByError',
  props<{ data: any }>()
);
export const GetTeamUsersListSuccess = createAction(
  '[Teams Page] Get TeamUser success',
  props<{ data: any[]; count: number }>()
);
export const GetTeamUsersListError = createAction('[Teams Page] Get TeamUser error', props<{ data: any }>());

export const GetEnterpriseUserListDemand = createAction(
  '[Teams Page] Get Enterprise Uers Demand',
  props<{
    data: {
      curPage: number;
      filters?: any;
      sort?: { sortby: string; order: number };
      type?: string | string[];
      teamId?: string;
      searchTerm?: string;
      limit?: number;
      id?: string;
      skipTeam?: string;
    };
  }>()
);
export const ResetDamndList = createAction('[Teams Page] reset Demand user List');
export const GetDemandListSuccess = createAction('[Teams Page] Get Demand User success', props<{ data: any[] }>());
export const GetDemandListError = createAction('[Teams Page] Get Demand User error');
export const GetTeamsListDemand = createAction(
  '[Teams Page] Get Teams Demand',
  props<{
    data: {
      userId: string;
      curPage: number;
      filters?: any;
      sort?: { sortby: string; order: number };
      type?: string;
      teamId?: string;
      searchTerm?: string;
      limit?: number;
    };
  }>()
);
export const ResetDemandListTeam = createAction('[Teams Page] reset Demand teams List');
export const GetTeamsDemandListSuccess = createAction('[Teams Page] Get Demand Team success', props<{ data: any[] }>());
export const GetTeamsDemandListError = createAction('[Teams Page] Get Demand Team error');
export const updateTeam = createAction('[Teams Page] update Team', props<{ data: Team; skipToast?: boolean }>());
