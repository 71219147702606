import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState } from './state';

export const selectLoginState = createFeatureSelector<LoginState>('login');

export const LoginStateSelect = createSelector(selectLoginState, (loginState) => {
  return loginState;
});

export const selectLoginError = createSelector(selectLoginState, (loginState: LoginState) => loginState.loginError);
export const selectCurrentUser = createSelector(selectLoginState, (loginState: LoginState) => loginState.currentUser);
export const selectCurrentUserTransactionSort = createSelector(
  selectLoginState,
  (loginState: LoginState) => loginState.currentUser.transactionSort
);
export const selectCurrentUserContactSort = createSelector(
  selectLoginState,
  (loginState: LoginState) => loginState.currentUser?.contactSort
);
export const selectLoggingIn = createSelector(selectLoginState, (loginState: LoginState) => loginState.loggingIn);
export const selectLoginSuccess = createSelector(selectLoginState, (loginState: LoginState) => loginState.loginSuccess);
export const selecctResetStatus = createSelector(selectLoginState, (loginState: LoginState) => {
  return {
    resetSending: loginState.resetSending,
    resetSent: loginState.resetSent,
    resetError: loginState.resetError,
  };
});
export const selectSetPasswordStatus = createSelector(selectLoginState, (loginstate) => loginstate.setting);
export const selecctResetPasswordStatus = createSelector(selectLoginState, (loginState: LoginState) => {
  return {
    resetting: loginState.resetting,
    resetSuccess: loginState.resetSuccess,
    resetPassError: loginState.resetPassError,
  };
});
export const selectPermissionObject = createSelector(selectLoginState, (loginState: LoginState) => {
  return loginState.permissionObject;
});
