import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PopupPaymentComponent } from 'src/app/modules/settings/components/popup-payment/popup-payment.component';
import { setBraintreeBrandIcons, braintreeCardSortFunction } from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'dailyai-make-payment-popup',
  templateUrl: './make-payment-popup.component.html',
  styleUrls: ['./make-payment-popup.component.scss'],
})
export class MakePaymentPopupComponent implements OnInit, OnDestroy {
  @Input() public customerId;

  @Input() public remainingPayableAmount_dollar;

  payNowtext = 'PAY NOW';

  addnewcardRef: NgbModalRef;

  cus_id: string;

  braintreeCards: any[] = [];

  selectedBraintreeCard: any;

  loading = true;

  isloading = false;

  private subscription_a: Subscription;

  constructor(
    private paymentService: PaymentService,
    private ngbModal: NgbModal,
    private toast: ToastrService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    if (this.customerId) {
      this.cus_id = this.customerId;
    }
    if (this.remainingPayableAmount_dollar) {
      this.payNowtext = `${this.payNowtext  } ${  this.remainingPayableAmount_dollar  }$`;
    }

    try {
      await this.getBraintreeCards();
      setBraintreeBrandIcons(this.braintreeCards);
      this.loading = false;
    } catch (error) {
      console.warn(error);
    }
  }

  braintreeCardSelected(card: any) {
    this.selectedBraintreeCard = card;
  }

  addnewCard() {
    this.ngbModal.dismissAll();
    this.addnewcardRef = this.ngbModal.open(PopupPaymentComponent, {
      centered: true,
      windowClass: 'add-card-popup',
      backdrop: 'static',
      keyboard: false,
    });
    this.addnewcardRef.componentInstance.customerId = this.cus_id;
    this.addnewcardRef.componentInstance.fromWhere = 'banner';
  }

  async getBraintreeCards() {
    try {
      const braintreeCustomer : any = await this.paymentService.getBraintreeCustomer(this.customerId);

      this.braintreeCards = braintreeCustomer.creditCards.sort(braintreeCardSortFunction);

    } catch (error) {
      console.warn('err', error);
    }
  }

  async pay() {
    this.isloading = true;
    try {
      const res: any = await this.paymentService.payNowOnFailBraintree(this.selectedBraintreeCard.token);
      this.toast.success(res.msg);
      this.isloading = true;
      this.authService.setUser();
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toast.error(error.error.msg);
      this.isloading = false;
      this.ngbModal.dismissAll();
    }
  }

  closePopup() {
    this.ngbModal.dismissAll();
  }

  ngOnDestroy() {
    if (this.subscription_a) {
      this.subscription_a.unsubscribe();
    }
  }
}
