<div class="modal-body m-3 text-left v2-ui-integretion">
  <dailyai-hidden-input></dailyai-hidden-input>
  <p *ngIf="titleText" class="font-16">{{ titleText }}</p>
  <p *ngIf="message" class="font-13">{{ message }}</p>
  <p *ngIf="!message" class="font-16">Are you sure you want to continue?</p>
  <div class="d-flex align-items left mt-4 button-container" *ngIf="action !== 'milestoneStatus'">
    <button class="btn btn-normal w-50 mr-2 max-width"
      [disabled]="loading" (click)="onSubmit('close')">
      {{ noText }}
    </button>
    <button class="btn btn-primary shadow w-50 max-width"
      [disabled]="btnDisable" (click)="onSubmit('submit')">
      {{ yesText }}
    </button>
  </div>
  <div class="d-flex align-items center mt-4" *ngIf="action == 'milestoneStatus'">
    <button class="btn btn-normal w-50 mr-2" 
      [disabled]="loading" (click)="submit('close')">
      No
    </button>
    <button class="btn btn-primary shadow w-50" 
      [disabled]="btnDisable" (click)="submit('submit')">
      Yes
      <span
        [ngClass]="{
          'spinner-border spinner-border-sm ml-1': loading
        }"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</div>
