<div class="px-4 pb-4 reassignTask v2-ui-integretion">
  <div class="modal-header pb-0 pt-3 px-0" style="margin-bottom: inherit;">
    <h6 class="color-grey-text mb-0 mt-1 font-18">
      <svg viewBox="0 0 20 20" fit="" preserveAspectRatio="xMidYMid meet" focusable="false" style="margin-right: 6px; height:25px; width:25px;">
        <g fill="none">
          <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
          <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
          <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
          <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path>
        </g>
      </svg>
      Your organization now requires Microsoft sign in.
    </h6>
  </div>
  <div class="d-flex justify-content-between flex-column flex-md-row mt-4" *ngIf="currentAuthUser">
    <div class="font-16 text-black-grey text-center">
      Please sign in to the Microsoft Account: <span style="font-weight: bold;">{{currentAuthUser?.email}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-2 mr-3 mr-md-0">
    <div class="d-flex justify-content-start w-100 mt-1">
      <button class="btn btn-normal px-3" (click)="signOut()">
        <span>Logout</span>
      </button>
    </div>
    <button class="btn btn-primary px-4" style="width: 105px;" (click)="addMicrosoftAccountToUser()" *ngIf="!loading && currentAuthUser">Sign In</button>
    <button class="btn btn-normal px-4" *ngIf="loading" [disabled]="loading">
      <span [ngClass]="{'spinner-border spinner-border-sm': loading, nil: !loading }" role="status" aria-hidden="true"></span>
    </button>
  </div>
</div>
