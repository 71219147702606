<ng-container #otpVerification class="modal">
  <div class="modal-body m-3 p-0 v2-ui-integretion">
    <div class="font-18 font-w-400 text-uppercase mx-3 pt-3 pb-2">Verify new phone number</div>
    <div class="d-flex flex-column mx-3 bottom-dashed-border pb-3">
      <span class="font-14 text-common">
        Please enter this six digit code when you receive our call to verify your number.
      </span>
    </div>
    <div class="mx-3 pt-3">
      <form autocomplete="none">
        <div class="mb-1">
          <label for="number" class="font-14 color-black-grey-text">Your Verification Code</label>
          <div class="d-flex justify-content-between">
            <input
              class="form-control mr-1 text-center"
              maxlength="1"
              autocomplete="off"
              type="text"
              [attr.value]="item"
              *ngFor="let item of newVerificationCode"
              disabled
            />
          </div>
        </div>
        <span class="font-14 mt-2 color-black-grey-text">
          Didn't receive the call?
          <button
            class="color-secondary font-w-500 retry-btn"
            [disabled]="phoneStatus === 'completed'"
            (click)="retryCode()"
          >
            Retry
          </button>
        </span>
        <div class="d-flex py-1 align-items-center">
          <span
            *ngIf="phoneStatus !== 'failed'"
            [ngClass]="{
              'phone-status-completed': phoneStatus === 'completed',
              'phone-status-inprogress': phoneStatus === 'in progress'
            }"
            class="font-16 font-w-500"
          >
            Number Verification {{ phoneStatus }}!
          </span>
          <span
            *ngIf="phoneStatus === 'failed'"
            [ngClass]="{
              'phone-status-failed': phoneStatus === 'failed'
            }"
            class="font-16 font-w-500"
          >
            You’ve entered an incorrect verification code. Please try again
          </span>
        </div>
        <div class="d-flex justify-content-end pt-4 pb-3">
          <button (click)="cancel()" type="button" class="btn btn-normal mr-2 px-4">Close</button>
          <button type="button" class="btn btn-primary px-4 ml-2" (click)="cancel()">Done</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
