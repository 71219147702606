
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models/user.model';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { FilterTasks } from '../../tasks/store/action';
import { ContactDetail } from "../store/models";

interface CreateTaskPayload {
  assignedto: any;
  task: string;
  transactionid: string;
  dueTime?: string;
  assignedby: User;
  taskStatus: string;
  mggInfo?: {
    title: string;
    message: string;
  };
  subject?: string;
  note?: string;
  customTask?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AddTaskService {
  tasksRef = this.db.collection<any>('tasks');

  logsRef = this.db.collection<any>('logs');

  contactsRef = this.db.collection<any>('contacts');

  constructor(private db: AngularFirestore, private store: Store<any>, private toast: ToastrService) {}

  createTask(data: CreateTaskPayload, contactDetails?: ContactDetail, dontShowToast?: boolean): Promise<any> {
    return this.tasksRef
      .add({
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        ...data,
        contactId: contactDetails ? contactDetails.doc_id : null,
        contactName: contactDetails ? `${contactDetails.firstName  } ${  contactDetails.lastName}` : null,
        phoneNumber: contactDetails && contactDetails.phoneNumber ? contactDetails && contactDetails.phoneNumber : null,
        email: contactDetails && contactDetails.email ? contactDetails.email : null,
        contact_assigned_to:
          contactDetails && contactDetails.contact_assigned_to ? contactDetails.contact_assigned_to : null,
      })
      .then(async (res) => {
        await this.tasksRef.doc(res.id).update({ task_id: res.id });
        setTimeout(() => {
          this.store.dispatch(FilterTasks({ data: false }));
        }, 2000);
        const userId = data.assignedto.uid ? data.assignedto.uid : data.assignedto.userId;
        if (contactDetails && !contactDetails.viewersList.includes(userId)) {
          const tempViewresList: any[] = [...contactDetails.viewersList];
          tempViewresList.push(userId);
          this.contactsRef.doc(contactDetails.doc_id).update({
            viewersList: tempViewresList,
          });
        }
        if (dontShowToast) {
          return;
        }
        this.toast.success('Task Created Successfully');
      })
      .catch((error) => {
        this.toast.error('Error Creating Task');
        console.error('Error writing document: ', error);
      });
  }

  async createContactTask(data, id) {
    await this.tasksRef.doc(id)
      .set({
        created_at: new Date().toISOString(),

        ...data,
        task_id: id,
        contactId: id,
      });
  }

  getLogs() {}

  updateCurrentTask(payload, contactDetails): Promise<any> {
    let payloadx = { ...payload };
    const userId = payload.assignedto.uid ? payload.assignedto.uid : payload.assignedto.userId;
    if (contactDetails && !contactDetails.viewersList.includes(userId)) {
      const tempViewresList: any[] = [...contactDetails.viewersList];
      tempViewresList.push(userId);
      this.contactsRef.doc(contactDetails.doc_id).update({
        viewersList: tempViewresList,
      });
    }
    if (contactDetails) {
      payloadx = {
        ...payload,
        contactId: contactDetails ? contactDetails.doc_id : null,
        contactName: contactDetails ? `${contactDetails.firstName  } ${  contactDetails.lastName}` : null,
        phoneNumber: contactDetails && contactDetails.phoneNumber ? contactDetails && contactDetails.phoneNumber : null,
        email: contactDetails && contactDetails.email ? contactDetails.email : null,
        task_id: payload.taskId ? payload.taskId : payload.task_id,
        contact_assigned_to:
          contactDetails && contactDetails.contact_assigned_to ? contactDetails.contact_assigned_to : null,
      };
    }

    return this.tasksRef
      .doc(payload.taskId ? payload.taskId : payload.task_id)
      .update(payloadx)
      .then((val) => {
        setTimeout(() => {
          this.store.dispatch(FilterTasks({ data: false }));
        }, 2000);
      });
  }

  async createReminderTask(payload: any) {
    const newTask = await this.db.collection<any>('tasks').add(payload);
    await newTask.update({ task_id: newTask.id });
    this.toast.success('Reminder Task Created', 'Reminder Tasks');
  }

  async markAsDone(id) {
    try {
      await this.db.collection<any>('tasks').doc(id).update({
        taskStatus: 'completed',
        markAsDone: true,
        updated_at: new Date().toISOString(),
      });
      this.store.dispatch(FilterTasks({ data: false }));
      this.toast.success('Task Updated Successfully');
    } catch (error) {
      this.toast.error('Error Updating Task');
    }
  }

  async checkAiNotification(id) {
    try {
      const ref = await this.db.collection<any>('ai_settings').doc(id).ref.get();
      return ref.data();
    } catch (error) {
      console.warn(error);
    }
  }

  createEvent(data: any, dontShowToast?: boolean): Promise<any> {
    return this.tasksRef
      .add({
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        ...data,
        eventDetails: {},
        eventName: data.eventName,
        description: data.description,
        startDate: data.startDate,
        endDate: data.endDate,
        attendees: data.attendees,
        taskStatus: 'open',
        oAuthCalendarEvent: true,
      })
      .then(async (res) => {
        await this.tasksRef.doc(res.id).update({ task_id: res.id });
        setTimeout(() => {
          this.store.dispatch(FilterTasks({ data: false }));
        }, 2000);
        if (dontShowToast) {
          return;
        }
        this.toast.success('Event Created Successfully');
      })
      .catch((error) => {
        this.toast.error('Error Creating Task');
        console.error('Error writing document: ', error);
      });
  }
}
