import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'dailyai-hidden-input',
  templateUrl: './hidden-input.component.html',
  styleUrls: ['./hidden-input.component.scss']
})
export class HiddenInputComponent implements OnInit {
  @ViewChild('hiddenInput') hiddenInputElementRef: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    const elem: any = this.hiddenInputElementRef.nativeElement.value;
    if (!elem) {
      this.hiddenInputElementRef.nativeElement.focus();
    }
  }
}
