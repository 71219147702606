import { createAction, props } from '@ngrx/store';
import { Transaction } from './state';

export const LoadTransaction = createAction('[Transaction Page] Load Transactions', props<{ user_id: string }>());
export const LoadTransactionSuccess = createAction(
  '[Transaction Page] Load Transaction Success',
  props<{ data: any }>()
);
export const selectedTransaction = createAction('[Trasaction Page] Selected Transactions', props<{ data: any }>());
export const getTransactionData = createAction('[Trasaction Page] Get Transactions', props<{ id: string }>());
export const LoadTransactionError = createAction('[Transaction page] Load Transaction Error', props<{ msg: string }>());
export const AddTransaction = createAction('[Transaction page] Add Transaction', props<{ data: Transaction }>());
export const EditTransaction = createAction('[Transaction page] Edit Transaction', props<{ data: Transaction }>());
export const AddBussinessContact = createAction(
  '[Transaction page] Add Busssiness Contact',
  props<{ data: any; id: string }>()
);
export const AddTransactionSuccess = createAction('[Transaction Page] Add Transaction Success');
export const EditTransactionSuccess = createAction('[Transaction Page] Edit Transaction Success');
export const AddBussinessContactSuccess = createAction('[Transaction page] Add Busssiness Contact Success');
export const AddTransactionError = createAction('[Transaction Page] Add Transaction Error', props<{ msg: string }>());
export const EditTransactionError = createAction('[Transaction Page] Edit Transaction Error', props<{ msg: string }>());
export const AddBussinessContactError = createAction(
  '[Transaction page] Add Busssiness Contact Error',
  props<{ msg: any }>()
);
export const UploadCSVTransactions = createAction(
  '[Transaction Page] Upload CSV Transaction',
  props<{
    id?: string;
    file?: any;
    groupId?: string[];
    optout?: boolean;
    selectedDripIds?: string[];
    parentId?: string;
  }>()
);
export const UploadCSVTransactionsSuccess = createAction('[Transaction Page] CSV Upload Success');
export const UploadCSVTransactionsError = createAction('[Transaction Page] CSV Upload Error', props<{ msg: string }>());
export const GetTransactionCount = createAction('[Transaction Page] Transaction Count');
export const GetTransactions = createAction('[Transaction Page] Get Transactions', props<{ data: any }>());
export const GetTransactionsModified = createAction(
  '[Transaction Page] Get TransactionsModified',
  props<{ data: any; silent?: boolean }>()
);

export const SearchTransaction = createAction('[Transaction Page] Search Transaction', props<{ data: any }>());
export const TransactionCount = createAction('[Transaction Page] Transaction Count Result', props<{ data: any }>());
export const FilterTransaction = createAction('[Transaction Page] Filter Transaction', props<{ data: any }>());
export const SortTransaction = createAction('[Transaction Page] Sort Transaction', props<{ data: any }>());
export const UpdateTransactionStatus = createAction(
  '[Transaction Page] Update Status',
  props<{ id: string; status: string; extraData?: any }>()
);
export const transactionOnlyMe = createAction('[Transaction Page] Change Only Me', props<{ onlyMe: boolean }>());

export const getTransactionCard = createAction('[Transaction Page] Get TransactionsCard', props<{ data: any }>());

export const transactionCardFetchSuccessAll = createAction(
  '[Transaction Page] Get TransactionsCardSuccessAll',
  props<{ milestones: any[] }>()
);

export const transactionCardFetchSuccess = createAction(
  '[Transaction Page] Get TransactionsCardSucessOne',
  props<{ milestone: string; data: any }>()
);

export const transactionCardScrollDemand = createAction(
  '[Transaction Page] Get TransactionsCardScrollDemand',
  props<{ data: any }>()
);

export const toggleLastThirty = createAction('[Transaction Page] ToggleLast thirty', props<{ last_thirty: boolean }>());

export const setAreAllSelected = createAction(
  '[Transaction Page] Set are all selected',
  props<{ areAllSelected: boolean }>()
);

export const deleteTransaction = createAction(
  '[Transaction Page set deleteTransaction',
  props<{ selectedTransaction: any; uId: any }>()
);
export const deleteTransactionSuccess = createAction(
  '[Transaction page] deleteTransactionSuccess]',
  props<{ data: any }>()
);
export const deleteTransactionError = createAction('[Transaction Page] deleteTransaction error', props<{ msg: any }>());

export const setInitPayload = createAction(
  '[Transaction] set LastAction payload',
  props<{ data: { initPayload: any; onlyMe: boolean; last_thirty: boolean } }>()
);

export const GetTransactionUIData = createAction('[Transaction] Get Transaction UI Data');

export const GetTransactionUIDataSuccess = createAction(
  '[Transaction] Get Transaction UI Data Success',
  props<{ uiData: any }>()
);

export const GetTeamMembers = createAction(
  '[Transaction] get team members for filter',
  props<{
    curPage: number;
    term?: string;
    skipArray?: string[];
    noLimit?: boolean;
    limit?: number;
    includeAdmins?: true;
    scroll?: true;
  }>()
);

export const GetTeamMembersSuccess = createAction(
  '[Transaction] get team members for filter success',
  props<{ err: false; success?: true; count: number; data: any[]; scroll?: boolean; }>()
);

export const GetTeamMembersError = createAction(
  '[Transaction] get team members for filter error',
  props<{ error: any; }>()
);
