import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/modules/contacts/services/contact.service';
import * as Twilio from 'twilio-client';
import { ContactDetail } from 'src/app/modules/contacts/store/models';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'dailyai-dialer-popup',
  templateUrl: './dialer-popup.component.html',
  styleUrls: ['./dialer-popup.component.scss'],
})
export class DialerPopupComponent implements OnInit {
  @ViewChild('callModal') dialerModal: TemplateRef<any>;

  contactDetails: any;

  callModalStatus: string;

  minutesLabel: string;

  secondsLabel: string;

  hide = false;

  connection: any;

  loading: boolean;

  totalSeconds = 0;

  timer: any;

  assigned_to: any;

  currentUser: any;

  popup: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private contactService: ContactService,
    private auth: AuthService
  ) {}

  ngOnInit() {}

  minimizeCallBox(modelReference?) {
    this.hide = !this.hide;
    if (this.hide) {
      this.popup.close();
    }
    if (modelReference) {
      this.popup = this.modalService.open(modelReference, {
        centered: true,
        windowClass: 'call-popup',
        backdrop: 'static',
        keyboard: false,
      });
    }
  }

  setTime() {
    this.totalSeconds = this.totalSeconds + 1;
    this.secondsLabel = this.pad(this.totalSeconds % 60);
    const minLabel = this.totalSeconds / 60;
    this.minutesLabel = this.pad(Math.floor(minLabel));
  }

  resetTimer() {
    this.totalSeconds = 0;
    this.secondsLabel = '00';
    this.minutesLabel = '00';
  }

  pad(val) {
    const valString = `${val  }`;
    if (valString.length < 2) {
      return `0${  valString}`;
    }
      return valString;

  }

  disconnectCall() {
    this.hide = false;
    this.connection.disconnectAll();
    this.connection = null;
    this.callModalStatus = 'Call Ended';
    this.modalService.dismissAll();
  }

  async callContact(modal) {
    if (!this.contactDetails.phoneNumber || this.contactDetails.phoneNumber.length <= 10) {
      this.toastr.error('No or Invalid Mobile Phone');
      return;
    }
    this.loading = false;
    this.resetTimer();
    clearInterval(this.timer);

    this.popup = this.modalService

      .open(modal, {
        centered: true,
        windowClass: 'call-popup',
        backdrop: 'static',
        keyboard: false,
      });
    this.resetTimer();

    this.connection = new Twilio.Device();
    this.callModalStatus = 'Connecting..';
    this.connection.on('disconnect', () => {
      this.disconnectCall();
      this.hide = false;
      this.connection = null;
    });
    this.connection.on('connect', () => {
      this.callModalStatus = 'connected';
      this.timer = setInterval(() => {
        this.setTime();
      }, 1000);
    });
    const payload = {
      callerId:
        this.currentUser['userType'] === 'MO'
          ? this.assigned_to.twilioNumber.phoneNumber
          : this.currentUser.twilioNumber.phoneNumber,
      to: this.contactDetails.phoneNumber.split('-').join(''),
      user_id: this.currentUser.uid,
      isMO: this.currentUser['userType'] === 'MO',
      contact_assigned_to: this.contactDetails.contact_assigned_to,
      phoneNumber: this.currentUser['userType'] === 'MO' ? this.assigned_to.phoneNo : this.currentUser.phoneNo,
    };

    const token = await this.contactService.getTwilioToken();
    this.connection.setup(token);
    this.connection.on('ready', () => {
      if (!this.connection) {
        return;
      }
      this.callModalStatus = 'Calling..';
      this.connection.connect({ number: JSON.stringify(payload) });
    });
  }

  @Input() set contactData(pValue: ContactDetail) {
    this.contactDetails = pValue;
    this.auth.getUserByUid(this.contactDetails.contact_assigned_to).then((data) => {
      this.assigned_to = data;
      this.callContact(this.dialerModal);
    });
  }

  @Input() set setUserData(pValue: any) {
    this.currentUser = pValue;
  }
}
