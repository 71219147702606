<div class="d-flex flex-column email-template-layout">
  <div class="modal-header pb-0">
    <h5 class="pb-2 my-2 color-grey-text font-hn-medium">SELECT EMAIL TEMPLATE</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="d-flex">
    <div class="d-flex col-4 px-0 ">
      <div class="contact-layout w-100">
        <div class="d-flex flex-column " *ngFor="let template of genEmailTempList; let i = index">
          <div class="d-flex justify-content-between selected-contact p-3"
            [ngClass]="{ 'active-class': selectedTemplate == template }" (click)="selectedTemp(template)">
            <div class="d-flex w-100">
              <div class=" d-flex justify-content-center align-items-center">
                <div
                  class="user text-uppercase font-12 mr-3 d-flex justify-content-center align-items-center font-hn-medium">
                  {{ i + 1 }}
                </div>
              </div>
              <div class="d-flex flex-column w-75">
                <div class="user-name text-capitalize d-flex justify-content-between font-12 font-hn-medium">
                  <span>{{ template['templateName'] }}</span>
                </div>
                <div class="msg-text  text-truncate">{{ template['templateSubject'] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="template-layout d-flex flex-column ml-2">
      <span class="font-12 font-hn-medium text-capitalize px-3 pt-4 pb-2">Template Name :
        <span class="font-12 font-hn-medium px-2 pt-4 pb-2" *ngIf="selectedTemplate">{{
          selectedTemplate && selectedTemplate['templateName']
          }}</span></span>
      <div class="d-flex justify-content-between align-items-center subject-box pl-3 pr-2 py-2 mr-3">
        <div class="d-flex">
          <span class="font-12 font-hn-medium text-capitalize">Subject :</span>
          <span
            class="font-12 font-lato-medium pl-2">{{selectedTemplate && selectedTemplate['templateSubject'] }}</span>
        </div>
      </div>
      <div class="mail-content d-flex flex-column px-3 py-4"
        [innerHTML]="selectedTemplate && selectedTemplate['templateContent']">
      </div>
      <div class="d-flex justify-content-end px-3  mb-4">
        <button class="btn btn-primary px-5 h-35 font-14 font-lato-medium"
          (click)="useThisTemplate(selectedTemplate)">USE</button>
      </div>
    </div>
  </div>
</div>