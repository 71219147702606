import { createAction, props } from '@ngrx/store';

export const FetchAssignedTasks = createAction('[Task Page] Fetch Tasks');
export const TaskResults = createAction('[Tasks Page] Task Results', props<{ data: any }>());

export const FetchTaskCount = createAction('[Contacts Page] FetchTaskCount', props<{ data: any }>());
export const TaskCount = createAction('[Contacts Page] TaskCount', props<{ data: any }>());
export const FetchTasks = createAction('[Contacts Page] FetchTasks', props<{ data: any }>());
export const SearchTasks = createAction('[Tasks Page] SearchTasks', props<{ data: any }>());
export const FilterTasks = createAction('[Tasks Page] FilterTasks', props<{ data: any }>());
export const SortTasks = createAction('[Tasks Page] SortTasks', props<{ data: any }>());
