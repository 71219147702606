<div class="input-group" [formGroup]="form">
  <input
    #dateInput
    class="form-control input-form-popup"
    [ngClass]="{ 'empty': !model }"
    [id]="id"
    type="text"
    [minDate]="min"
    [maxDate]="max"
    ngbDatepicker
    #d="ngbDatepicker"
    [placement]="placement"
    [formControlName]="controlName"
    [(ngModel)]="model"
    [footerTemplate]="footerTemplate"
  />
  <button class="btn calendar py-0 px-1 d-flex justify-content-center align-items-center" [disabled]="disabled" (click)="d.toggle()" type="button">
    <i class="icon-calendar"></i>
  </button>
  <ng-template #footerTemplate>
    <div class="text-center footer">
      <hr class="my-0">
      <button class="btn btn-text btn-sm mx-auto my-2" (click)="model = ngbToday; d.close()">Today</button>
    </div>
  </ng-template>
</div>
