<div class="px-2 pb-4 v2-ui-integretion">
  <div class="modal-header pb-0">
    <h5 *ngIf="!currentTask" class="pt-3 color-grey-text">Add Task</h5>
    <h5 *ngIf="currentTask" class="pt-3 color-grey-text">Update Task</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addForm" autocomplete="off">
      <div class="row flex-column flex-md-row mx-0">
        <div class="col-12 col-md-6 pr-0 pr-md-3 pl-0 d-flex flex-column">
          <label class="font-14 font-lato-bold mt-2">Subject</label>
          <input type="text" placeholder="Enter a subject.." class="form-control" formControlName="subject" />
          <div class="error-box">
            <label class="error" *ngIf="!addForm.get('subject').valid && addForm.get('subject').touched">
              *Add Subject
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex px-0 flex-column">
          <label class="font-14 mt-2">Task</label>
          <select formControlName="task" class="custom-select" (focus)="d.close()">
            <option selected disabled value="null">Select Task</option>
            <option value="call">Call</option>
            <option value="message">Text Message</option>
            <option value="mail">E-mail</option>
            <option value="other">Other</option>
          </select>
          <div class="error-box">
            <label class="error" *ngIf="!addForm.get('task').valid && addForm.get('task').touched"> *Add Task </label>
          </div>
        </div>
      </div>
      <div class="row flex-column flex-md-row mx-0 mt-2">
        <div class="col-12 col-md-6 pr-0 pr-md-3 pl-0 d-flex flex-column">
          <label class="font-14 mt-2">Custom Task</label>
          <label>
            <input
              *ngIf="!customtask"
              type="text"
              placeholder="Custom Task ..."
              class="form-control"
              [readonly]="!customtask"
              formControlName="customTask"
              [class.disable]="!customtask"
              disabled
            />
            <input
              *ngIf="customtask"
              type="text"
              placeholder="Custom Task ..."
              class="form-control"
              [readonly]="!customtask"
              formControlName="customTask"
              [class.disable]="!customtask"
            />
          </label>
          <div class="error-box">
            <label class="error" *ngIf="!addForm.get('customTask').valid && addForm.get('customTask').touched">
              *Enter Custom Task
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex px-0 flex-column">
          <label class="font-14 mt-2">Assigned To</label>
          <label class="mb-0">
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r?.firstName + ' ' + r?.lastName + '(' + r?.email + ')'" [term]="t">
              </ngb-highlight>
            </ng-template>
            <input
              autocomplete="off"
              name="assignedtoSearch"
              #instance="ngbTypeahead"
              id="typeahead-template"
              type="text"
              placeholder="Select name or email"
              formControlName="assignedTo"
              [(ngModel)]="assignedto"
              class="form-control assigned-to-input"
              [ngbTypeahead]="search"
              [resultTemplate]="rt"
              [inputFormatter]="formatter"
              (focus)="focus$.next({ val: $event.target.value, fromWhere: 'assignedto' })"
            />
          </label>
          <div class="error-box">
            <label class="error" *ngIf="addForm.get('assignedTo').invalid && addForm.get('assignedTo').touched">
              *Choose Assignee
            </label>
            <label class="error" *ngIf="assignderr && assignedto"> *Please choose name from our list </label>
          </div>
        </div>
        <div class="col-12 col-md-6 pr-0 pr-md-3 pl-0 d-flex flex-column" *ngIf="withContact">
          <label class="font-14 mt-2">Contact Name</label>
          <label class="mb-0">
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight
                [result]="
                  r?.firstName +
                  ' ' +
                  r?.lastName +
                  '(' +
                  (r.email ? r.email : '') +
                  ')' +
                  (r?.partnerContact ? '(P) ' : '')
                "
                [term]="t"
              >
              </ngb-highlight>
            </ng-template>
            <input
              *ngIf="!currentTask || !currentTask.contactId"
              autocomplete="off"
              name="assignedContactSearch"
              #instance2="ngbTypeahead"
              id="typeahead-template-2"
              type="text"
              placeholder="Contact Name"
              formControlName="assignedContact"
              [(ngModel)]="forContact"
              class="form-control"
              [ngbTypeahead]="searchContact"
              [resultTemplate]="rt"
              [inputFormatter]="formatter"
              (focus)="focus$.next({ val: $event.target.value, fromWhere: 'assignedContact' })"
            />
            <input
              *ngIf="currentTask && currentTask.contactId"
              type="text"
              class="form-control"
              [class.disable]="currentTask"
              disabled
              [value]="currentTask.contactName"
            />
          </label>
          <div class="error-box">
            <label
              class="error"
              *ngIf="addForm.get('assignedContact').invalid && addForm.get('assignedContact').touched"
            >
              *Choose Contact
            </label>
            <label class="error" *ngIf="contactnameerr && forContact"> *Please choose name from our list </label>
          </div>
        </div>
        <div
          class="col-12 col-md-6 px-0 d-flex flex-column position-relative"
          [ngClass]="{ 'col-12': url === 'contacts', 'pl-0 pr-3': !withContact }"
        >
          <label class="font-14 mt-2">Due Date</label>
          <input
            class="form-control"
            type="text"
            ngbDatepicker
            #d="ngbDatepicker"
            placeholder="dd/mm/yy"
            (click)="d.toggle()"
            (blur)="showValidator()"
            name="dp"
            formControlName="duedate"
            autoClose="outside"
            [appNoKeypress]="true"
            [minDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
            [maxDate]="{ year: 2048, month: 12, day: 31 }"
          />
          <i class="material-icons calender-icon cursor-pointer" (click)="d.toggle()"> date_range </i>
          <div class="error-box">
            <label class="error" *ngIf="addForm.get('duedate').invalid && addForm.get('duedate').touched && show">
              *Choose Date
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex pl-0" *ngIf="setRemainder">
        <div class="d-flex mt-2">
          <label class="v2-checkbox mb-0">
            <input
              type="checkbox"
              (change)="remainCheck($event)"
              formControlName="setReminder"
              class="form-check-input"
              id="toReminder"
            />
            <span class="checkmark"> </span>
          </label>
          <label class="font-14 mb-0" for="toReminder">Send Reminder</label>
        </div>
      </div>
      <div class="row flex-md-row flex-column mx-0 send-remainder mt-2" *ngIf="remain_check">
        <div class="col-12 col-md-6 pr-0 pr-md-3 pl-0 pl-0 d-flex">
          <div class="input-group" id="transaction-listSearch">
            <input
              class="form-control searchItems"
              type="text"
              ngbDatepicker
              #d="ngbDatepicker"
              placeholder="dd/mm/yy"
              (click)="d.toggle()"
              formControlName="setReminderdate"
              (blur)="showValidator()"
              name="dp"
              autoClose="outside"
              [appNoKeypress]="true"
              [minDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
              [maxDate]="{ year: 2048, month: 12, day: 31 }"
            />
            <div class="input-group-append searchIcon">
              <span class="input-group-text cursor-pointer" (click)="d.toggle()"
                ><i class="material-icons"> event_note </i></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 px-0 d-flex mt-md-0 mt-2">
          <div class="input-group" id="transaction-listSearch">
            <input
              class="form-control searchItems"
              formControlName="setRemindertime"
              placeholder="00:00"
              value="{{ time }}"
              (click)="open($event)"
            />
            <div class="input-group-append searchIcon">
              <span class="input-group-text cursor-pointer" (click)="open($event)"
                ><i class="material-icons"> schedule </i></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="error-box">
        <label
          class="error"
          *ngIf="
            (remain_check && addForm.get('setReminderdate').invalid && addForm.get('setReminderdate').touched) ||
            (remain_check && !timepicked)
          "
        >
          *Choose both reminder date and time
        </label>
      </div>
      <div class="col-12 px-0 d-flex flex-column mt-2">
        <label class="font-14 mt-2">Notes</label>
        <textarea
          class="form-control"
          formControlName="note"
          id="message"
          placeholder="Notes.."
          rows="5"
          ng-reflect-name="note"
        ></textarea>
      </div>
      <div class="d-flex flex-column">
        <ng-container *ngIf="enableCalender">
          <div class="justify-content-start">
            <div class="d-flex mt-2" *ngIf="!setToCalendarValue">
              <label class="v2-checkbox mb-0">
                <input type="checkbox" formControlName="toCalendar" class="form-check-input" id="tocalender" />
                <span class="checkmark"> </span>
              </label>
              <label class="font-14 mb-0" for="tocalendar">Add to Calendar</label>
            </div>
          </div>
        </ng-container>
        <div class="mt-2 bottom-btn ml-auto d-flex">
          <button type="button" class="btn btn-normal mr-2" (click)="activeModal.dismiss('Cross click')">Cancel</button>
          <button *ngIf="!currentTask" (click)="onSubmit()" class="btn btn-primary">Create Task</button>
          <button *ngIf="currentTask" (click)="updateTask()" class="btn btn-primary update-task-btn">
            Update Task
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
