import { NgxSpinnerService } from 'ngx-spinner';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { selectFileUploadProgress } from './modules/contacts/store/selector';
import { selectFileUpload } from './modules/settings/store/selector';

declare var Appcues: any;

@Component({
  selector: 'dailyai-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'dailyai-frontend';

  fileUploadProgress: Observable<any>;

  constructor(private store: Store<any>, private spinner: NgxSpinnerService, private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      Appcues.page();
    });
    this.fileUploadProgress = this.store.pipe(select(selectFileUploadProgress));
    this.store.pipe(select(selectFileUpload)).subscribe((val) => {
      val ? this.spinner.show() : this.spinner.hide();

    });
  }
}
