
import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'applied-filters',
  templateUrl: './applied-filters.component.html'
})
export class AppliedFiltersComponent implements OnChanges {

  filterBubbleTotal = 0;

  @Input()
  isFilterChanged = false;

  @Input()
  isSavedFilterSelected = false;

  gridStateDescription = "";

  @Input()
  appliedFilters: any;

  @Input()
  keyHeaderValues: any;

  @Input()
  filterSaved: boolean;

  @Output()
  saveFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  filterTrimmed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  filterCleared: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.appliedFilters)
      return;

    if (changes.appliedFilters.currentValue
      && Object.keys(changes.appliedFilters.currentValue).length === 0) {
        this.appliedFilters = undefined;
        this.filterBubbleTotal = 0;
        this.gridStateDescription = "";
    } else {
      this.appliedFilters = changes.appliedFilters.currentValue;
      this.filterBubbleTotal = this.appliedFilters ? Object.keys(this.appliedFilters).length : 0;
    }
  }

  saveGridState() {
    this.saveFilter.emit(this.gridStateDescription);
  }

  clearAllFilters() {
    this.appliedFilters = null;
    this.filterBubbleTotal = 0;
    this.gridStateDescription = "";
    this.filterCleared.emit();
  }

  onFilterTrimmed(key) {
    this.appliedFilters.id = '-1';
    this.filterBubbleTotal = 0;
    this.gridStateDescription = "";
    this.filterTrimmed.emit(key);
  }
}
