import { createReducer, on } from '@ngrx/store';
import * as AddPartnerStateActions from './actions';

import { AddPartnerState } from './state';

export const initialState: AddPartnerState = {
  partnersList: [],
  partnersListSuccess: null,
  partnersListError: null,
  partnersListWithDemand: [],
  partnersListWithDemandError: null,
  partnersListWithDemandSuccess: null,
  partnersListWithDemandLoading: false,
  last_doc: null,
  addPartner: false,
  addPartnerError: null,
  addPartnerSuccess: null,
  updatePartner: false,
  updatePartnerSuccess: false,
  updatePartnerError: null,
  DeletePartner: false,
  DeletingPartner: false,
  DeletePartnerError: null,
  count: 0,
  curPage: 1,
  addedPartner: null,
  uploadCSVSuccess: null,
  uploadingCSV: false,
  uploadCSVError: null,
};

export const addPartnerReducer = createReducer(
  initialState,
  on(AddPartnerStateActions.AddPartnerSuccess, (state) => ({
    ...state,
    addPartnerSuccess: true,
    addPartner: false,
    addPartnerError: null,
  })),
  on(AddPartnerStateActions.AddPartnerError, (state, payload) => ({
    ...state,
    addPartnerSuccess: null,
    addPartner: false,
    addPartnerError: payload.msg,
  })),
  on(AddPartnerStateActions.AddPartner, (state) => ({
    ...state,
    addPartnerError: null,
    addPartnerSuccess: null,
    addPartner: true,
  })),
  on(AddPartnerStateActions.PartnersList, (state) => ({
    ...state,
  })),
  on(AddPartnerStateActions.PartnersListSuccess, (state, payload) => {
    let list = payload.data && payload.data.data ? payload.data.data : payload.data;
    if (payload.scroll) {
      list = [...state.partnersList, ...list];
    }
    return {
      ...state,
      partnersListSuccess: true,
      partnersList: list,
      count: payload.data && payload.data.count !== undefined ? payload.data.count : state.count,
    };
  }),
  on(AddPartnerStateActions.PartnersListError, (state, payload) => ({
    ...state,
    partnersListSuccess: false,
    partnersListError: payload.msg,
  })),
  on(AddPartnerStateActions.ResetPartnerList, (state) => ({
    ...state,
    partnersList: [],
  })),
  on(AddPartnerStateActions.UpdatePartnerSuccess, (state) => ({
    ...state,
    updatePartnerSuccess: true,
    updatePartner: false,
    updatePartnerError: null,
  })),
  on(AddPartnerStateActions.UpdatePartnerError, (state, payload) => ({
    ...state,
    updatePartnerSuccess: false,
    updatePartner: false,
    updatePartnerError: payload.msg,
  })),
  on(AddPartnerStateActions.UpdatePartner, (state) => ({
    ...state,
    updatePartnerError: null,
    updatePartnerSuccess: false,
    updatePartner: true,
  })),
  on(AddPartnerStateActions.PartnerCount, (state, payload) => ({
    ...state,
    count: payload.count,
  })),
  on(AddPartnerStateActions.FetchPartners, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage,
  })),
  on(AddPartnerStateActions.addedPatener, (state, payload) => {
    return {
      ...state,
      addedPartner: payload.data.addedPartner,
    };
  }),
  on(AddPartnerStateActions.DeletePartner, (state) => ({
    ...state,
    DeletePartner: true,
    DeletingPartner: true,
  })),
  on(AddPartnerStateActions.DeletePartnerSuccess, (state) => ({
    ...state,
    DeletePartner: true,
    DeletingPartner: false,
    DeletePartnerError: null,
  })),
  on(AddPartnerStateActions.DeletePartnerError, (state, payload) => ({
    ...state,
    DeletePartner: false,
    DeletingPartner: false,
    DeletePartnerError: payload.msg,
  })),

  on(AddPartnerStateActions.ListPartnersWithDemandScroll, (state) => ({
    ...state,
    partnersListWithDemandLoading: true,
    partnersListWithDemandError: null,
    partnersListWithDemandSuccess: null,
  })),
  on(AddPartnerStateActions.ListPartnersWithDemandScrollError, (state, payload) => ({
    ...state,
    partnersListWithDemandLoading: false,
    partnersListWithDemandError: payload.data,
    partnersListWithDemandSuccess: null,
  })),
  on(AddPartnerStateActions.ListPartnersWithDemandScrollSuccess, (state, payload) => {
    let tempArray = payload.data;
    if (state.last_doc) {
      tempArray = [...state.partnersListWithDemand, ...tempArray];
    }
    return {
      ...state,
      partnersListWithDemandLoading: false,
      partnersListWithDemandSuccess: true,
      partnersListWithDemandError: null,
      partnersListWithDemand: tempArray,
      last_doc: tempArray && tempArray.length > 0 ? tempArray[tempArray.length - 1] : null,
    };
  }),
  on(AddPartnerStateActions.ResetPartnerDemandList, (state) => ({
    ...state,
    partnersListWithDemand: [],
    last_doc: null,
    partnersList: [],
  })),
  on(AddPartnerStateActions.UploadPartnersCSV, (state) => ({
    ...state,
    uploadingCSV: true,
    uploadCSVError: null,
    uploadCSVSuccess: null,
  })),
  on(AddPartnerStateActions.UploadPartnersCSVSuccess, (state) => ({
    ...state,
    uploadingCSV: false,
    uploadCSVError: null,
    uploadCSVSuccess: true,
  })),
  on(AddPartnerStateActions.UploadPartnersCSVError, (state, payload) => ({
    ...state,
    uploadingCSV: false,
    uploadCSVError: payload.msg,
    uploadCSVSuccess: null,
  })),
);
