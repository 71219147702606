import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest, from } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { timeZonesDisplayNameMap } from 'src/app/shared/utils';
import {
  USER_ADD_URL,
  USER_DELETE_URL,
  CARD_CHARGE_URL,
  USER_ACTIVATE_URL,
  CUSTOM_FIELD_PLAN_URL,
  UNLIMITED_DAILLER_PLAN_URL,
  UNLIMITED_DAILLER_PLAN_JLO_URL,
  UNLIMITED_DAILLER_PLAN_ENTERPRISE,
  CHANGE_USER_NAME,
  CHANGE_USER_EMAIL,
  CHANGE_USER_PROFILE_IMAGE,
  CHANGE_COMPANY_NAME,
  UPDATE_TWILIO_NUMBER,
  CHANGE_TIMEZONE,
} from '../../../shared/config';

const api = environment.mongoUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private auth: AuthService,
    private toastr: ToastrService
  ) {}

  addUser(payload): Observable<any> {
    return this.http.post<any>(USER_ADD_URL, payload);
  }

  activateUser(payload): Observable<any> {
    const payload_toSend = { id: payload.juniorId };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${payload.token}`,
      }),
    };

    return this.http.post<any>(USER_ACTIVATE_URL, payload_toSend, httpOptions);
  }

  deleteUser(data): Observable<any> {
    const payoad = { id: data.uId, action: data.action, assignedTasks: data.assignedTasks };
    if (data.taskId && data.taskId.length > 0) {
      payoad['taskId'] = data.taskId;
    }
    return this.http.post<any>(USER_DELETE_URL, payoad);
  }

  getUsers(payload): Observable<any> {
    const usersRef = this.db
      .collection<any>('junior-users', (ref) =>
        ref.where('parentId', '==', payload.parentId).orderBy('firstName', 'asc').limit(10)
      )
      .valueChanges();
    return usersRef;
  }

  getChild(payload, user, moExtra?: boolean): Observable<any> {
      let typesArray = ['manager', 'TeamLeader', 'JLO', 'MO'];
      let fn = (ref: CollectionReference) =>
        ref
          .where('enterpriseId', '==', user.enterpriseId)
          .where('status', '==', 'Active')
          .where('userType', 'in', typesArray);
      switch (user.userType) {
      case 'manager': {
        typesArray = ['TeamLeader', 'JLO', 'MO'];
        fn = (ref) =>
          ref
            .where('enterpriseId', '==', user.enterpriseId)
            .where('status', '==', 'Active')
            .where('managerId', '==', user.uid)
            .where('userType', 'in', typesArray);

      break;
      }
      case 'TeamLeader': {
        typesArray = ['TeamLeader', 'JLO', 'MO'];
        fn = (ref) =>
          ref
            .where('enterpriseId', '==', user.enterpriseId)
            .where('status', '==', 'Active')
            .where('teamLeaderIdList', 'array-contains', user.uid)
            .where('userType', 'in', typesArray);

      break;
      }
      case 'JLO':
      case 'MO': {
        if (!user.teamIdList?.length) {
          return from([[]]);
        }
        if (user.userType === 'JLO') {
          return from(this.getAssigneeForJLO());
        }
        typesArray = ['JLO', 'MO', 'TeamLeader'];
        fn = (ref) =>
          ref
            .where('enterpriseId', '==', user.enterpriseId)
            .where('status', '==', 'Active')
            .where('teamIdList', 'array-contains', user.teamId)
            .where('userType', 'in', typesArray);
        if (moExtra) {
          const a = [this.db.collection<any>('users', fn).valueChanges()];
          if (user.managerId) {
            a.push(this.db.collection<any>('users', (ref) => ref.where('uid', '==', user.managerId)).valueChanges());
          }
          a.push(
            this.db
              .collection<any>('users', (ref) =>
                ref.where('enterpriseId', '==', user.enterpriseId).where('userType', '==', 'admin')
              )
              .valueChanges()
          );
          return combineLatest(a).pipe(
            map((val) => {
              const data = [];
              val.forEach((d) => data.push(...d));
              return data;
            })
          );
        }

      break;
      }
      default:
        break;
      }
      return this.db.collection<any>('users', fn).valueChanges();

  }

  getUser() {
    this.db
      .collection<any>('users')
      .doc('2qIfvMTI4BWwLX0w3rpjL55fgR53')
      .get()
      .subscribe();
  }

  getuserinUsers(uid, newuser = false): Observable<any> {
    if (!newuser) return this.db.collection<any>('users', (ref) => ref.where('userId', '==', uid)).valueChanges();
    return this.db.collection<any>('users', (ref) => ref.where('uid', '==', uid)).valueChanges();
  }

  getuserinUsersandJuniorUsers(email): Observable<any> {
    const users1 = this.db.collection<any>('users', (ref) => ref.where('email', '==', email)).valueChanges();

    const users2 = this.db.collection<any>('junior-users', (ref) => ref.where('email', '==', email)).valueChanges();

    const users = combineLatest([users1, users2]);
    return users;
  }

  async chargeCard(payload): Promise<any> {
    const currentUser = await this.auth.getCurrentUser();
    const toSend = { ...payload, uid: currentUser.uid };
    return this.http.post(CARD_CHARGE_URL, toSend).toPromise();
  }

  async chargeCustomFields(payload: { planName: string; no_of_fields: number }) {
    const currentUser = await this.auth.getCurrentUser();
    const toSend = { ...payload, uid: currentUser.uid };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CUSTOM_FIELD_PLAN_URL, toSend, httpOptions).toPromise();
  }

  async fetchCount(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/users/user-count`, { id: data }, httpOptions).toPromise();
  }

  async getAssigneeForJLO() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
      }),
    };
    return this.http
      .post(`${api}/v0.1/users/getAssigneeForJLO`, {}, httpOptions)
      .pipe(map((val: any) => val.data))
      .toPromise();
  }

  async fetchUsers(data, uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/users/list`, { value: data, id: uid }, httpOptions).toPromise();
  }

  async searchUsers(data, uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/users/search-users`, { value: data, id: uid }, httpOptions).toPromise();
  }

  async updateUnlimitedDaillerPlan(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
      return this.http
        .post(UNLIMITED_DAILLER_PLAN_JLO_URL, { uid: payload.uid, action: payload.action }, httpOptions)
        .toPromise();

  }

  async updateUnlimitedDaillerPlanEnterprise(action: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(UNLIMITED_DAILLER_PLAN_ENTERPRISE, { action }, httpOptions).toPromise();
  }

  async updateAgentPrivilegedStatus(agent, privileged_jlo) {
    try {
      let toastmessage = '';
      toastmessage = privileged_jlo ? 'JLO made privileged successfully' : 'Privileged JLO made normal JLO successfully';
      if (agent.status === 'Active')
        await this.db
          .collection<any>('users')
          .doc(agent.uid)
          .update({ privileged_jlo, shareTransactions: privileged_jlo });

      await this.db
        .collection<any>('junior-users')
        .doc(agent.userId)
        .update({ privilaged_user: privileged_jlo, shareTransactions: privileged_jlo });
      this.toastr.success(toastmessage);
      return true;
    } catch {
      this.toastr.error('Error occured while updating');
      return false;
    }
  }

  async sendPasscode(purpose: string, newEmail: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_USER_EMAIL, { purpose, newEmail }, httpOptions).toPromise();
  }

  async changeUserName(firstName: string, lastName: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_USER_NAME, { firstName, lastName }, httpOptions).toPromise();
  }

  async changeProfileImage(imageUrl: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_USER_PROFILE_IMAGE, { imageUrl }, httpOptions).toPromise();
  }

  async changeCompanyName(name: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_COMPANY_NAME, { name }, httpOptions).toPromise();
  }

  async changeUserEmail(purpose: string, passcode: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_USER_EMAIL, { purpose, passcode }, httpOptions).toPromise();
  }

  async getTwilioNumbers(areaCode) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${environment.cloud_base_url}/searchNumbers`, { countryCode: 'US', areaCode }, httpOptions)
      .toPromise();
  }

  async updateTwilioNumber(twilioNumber, updateVcard: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(UPDATE_TWILIO_NUMBER, { phoneNumber: twilioNumber, vCard: updateVcard }, httpOptions);
  }

  async setBetaDashboard(uid: string, enabled: boolean) {
    await this.db.collection<any>('users').doc(uid).update({ legacyDashboard: !enabled });
  }

  async setBetaContacts(uid: string, enabled: boolean) {
    await this.db.collection<any>('users').doc(uid).update({ legacyContacts: !enabled });
  }

  async changeTimezone(newTimezone: keyof typeof timeZonesDisplayNameMap) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(CHANGE_TIMEZONE, { newTimezone }, httpOptions).toPromise();
  }
}
