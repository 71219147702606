import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import { finalize, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { COLLECTIONS, RESEND_MAIL_URL } from 'src/app/shared/config';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase';
import { FileUploadProgress } from '../../contacts/store/action';
import { fileupload } from '../store/action';
import { UserType } from 'src/app/shared/enums/commonEnums';

const api = environment.mongoUrl;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  task: AngularFireUploadTask;

  percentage: Observable<number>;

  snapshot: Observable<any>;

  downloadURL: Observable<string>;

  JOT: any;

  isloading = false;

  thumbNailUrl: any = '';

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private toastr: ToastrService,
    private http: HttpClient,
    private store: Store<any>
  ) { }


  updatePhoneNumberName(
    phno,
    firstName,
    lastName,
    imgUrl,
    userType,
    userId,
    companyName,
    enterpriseId,
    isEnterprise
  ): Promise<any> {
    const uId = this.auth.currentUser.uid;
    if (!isEnterprise && (userType === UserType.JLO || userType === UserType.MO)) {
      this.db.collection<any>(COLLECTIONS.JUNIOR_USERS).doc(userId).update({
        phoneNo: phno,
        firstName,
        lastName,
        image: imgUrl,
      });
    }
    if (userType === UserType.SUPER_ADMIN && companyName) {
      this.db.collection<any>(COLLECTIONS.ENTERPRISES).doc(enterpriseId).update({
        enterpriseName: companyName,
      });
    }
    const obj = {
      phoneNo: phno,
      firstName,
      lastName,
      image: imgUrl,
    };
    if (companyName) obj['companyName'] = companyName;

    return this.db.collection<any>(COLLECTIONS.USERS).doc(uId).update(obj);
  }

  addUser(payload): Promise<any> {
    return this.db.collection<any>(COLLECTIONS.USERS).add(payload);
  }

  async addTags(payload) {
    const {userId} = payload;
    const ref = await this.db.collection<any>(COLLECTIONS.TAGS).doc(userId).ref.get();
    if (ref.exists) {
      ref.ref.update(payload);
    } else {
      ref.ref.set(payload);
    }
    this.toastr.success('Tag added successfully');
  }

  async addSources(payload) {
    const {userId} = payload;
    const ref = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(userId).ref.get();
    if (ref.exists) {
      ref.ref.update(payload);
    } else {
      ref.ref.set(payload);
    }
    this.toastr.success('Source added successfully');
  }

  async getWebsiteData(uid: string) {
    return (await this.db.collection<any>(COLLECTIONS.WEBSITE).doc(uid).ref.get()).data();
  }

  async deleteSources(payload, tag) {
    const {userId} = payload.data;
    const ref = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(userId).ref.get();
    if (ref.exists) {
      ref.ref.update(payload.data);
    }
    const user_data_ref = await this.db.collection<any>(COLLECTIONS.USERS).doc(payload.userId).ref.get();
    if (user_data_ref.exists) {
      const user_data: any = user_data_ref.data();
      let parentId = payload.userId;
      if (user_data.parentId) {
        parentId = user_data.parentId;
      }
      const lead_routings_ref = await this.db.collection<any>(COLLECTIONS.LEAD_ROUTINGS).doc(parentId).ref.get();
      if (lead_routings_ref.exists) {
        const lead_routings: {
          campaign_id: string;
          contactSource: string;
        }[] = (lead_routings_ref.data() as any).routes;
        const new_routes = lead_routings.filter((route) => {
          return route.contactSource !== tag;
        });
        await this.db.collection<any>(COLLECTIONS.LEAD_ROUTINGS).doc(parentId).update({ routes: new_routes });
      }
      this.toastr.success('Source deleted successfully');
    }
  }

  async getSourcesStat(sources: string[], uid: string) {
    const promises = sources.map((s) =>
      this.db
        .collection<any>(COLLECTIONS.CONTACTS)
        .ref.where('viewersList', 'array-contains', uid)
        .where('source', '==', s)
        .limit(1)
        .get()
    );
    const res = await Promise.all(promises);
    return res.map((d, i) => ({ source: sources[i], stat: d.size }));
  }

  async deleteTags(payload, tag) {
    const {userId} = payload;
    const ref = await this.db.collection<any>(COLLECTIONS.TAGS).doc(userId).ref.get();
    if (ref.exists) {
      ref.ref.update(payload);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/removeTagsFromContact`;
    this.http.post(url, { tag }, httpOptions).subscribe();
    this.toastr.success('Tag deleted successfully');
  }

  async getDripsListWithSelectedTag(tag: string, currentUser: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    return this.http
      .post(`${api}/v0.1/drips/getDripsContainingTags`, { user: currentUser, tag, onlyMe: false }, httpOptions)
      .toPromise();
  }

  async getParentSources(userId) {
    const userRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(userId).ref.get();
    if (userRef.exists) {
      return userRef.data() as { sources: string[]; userId: string };
    }
      return { sources: [], userId };

  }

  async getParentTags(userId) {
    const userRef = await this.db.collection<any>(COLLECTIONS.TAGS).doc(userId).ref.get();
    if (userRef.exists) {
      return userRef.data() as { tags: string[]; userId: string };
    }
      return { tags: [], userId };

  }

  async getSources(user: any, forDropDown = false) {
    const userId = [UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType) ? user.enterpriseId : user.uid;
    const userRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(userId).ref.get();
    let sourcesCombined = null;
    if (userRef.exists) {
      sourcesCombined = userRef.data() as { sources: string[]; userId: string };
    } else {
      sourcesCombined = { sources: [], userId };
    }
    if (!forDropDown && ([UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType))) {
      return sourcesCombined;
    } if (forDropDown && ([UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType))) {
      return sourcesCombined.sources;
    } if (!user.isEnterprise) {
        if (!user.parentId || user.parentId === user.uid) {
          return sourcesCombined.sources;
        }
          const parentRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(user.parentId).ref.get();
          if (parentRef.exists) {
            return [...sourcesCombined.sources, ...parentRef.data().sources];
          }
            return sourcesCombined.sources;


      }
        sourcesCombined['shared'] = [];
        const enterpriseRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(user.enterpriseId).ref.get();
        if (enterpriseRef.exists) {
          sourcesCombined['shared'].push(...enterpriseRef.data().sources);
        }
        if (user.userType !== UserType.MANAGER) {
          if (user.teamId && user.managerId) {
            const managerRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(user.managerId).ref.get();
            if (managerRef.exists) {
              sourcesCombined['shared'].push(...managerRef.data().sources);
            }
          }
          if (user.userType !== UserType.TEAM_LEADER) {
            if (user.teamId && user.teamLeaderId) {
              const teamLeadRef = await this.db.collection<any>(COLLECTIONS.SOURCES).doc(user.teamLeaderId).ref.get();
              if (teamLeadRef.exists) {
                sourcesCombined['shared'].push(...teamLeadRef.data().sources);
              }
            }
            if (forDropDown) {
              return [...sourcesCombined['shared'], ...sourcesCombined.sources];
            }
            return sourcesCombined;
          }
            if (forDropDown) {
              return [...sourcesCombined['shared'], ...sourcesCombined.sources];
            }
            return sourcesCombined;

        }
          if (forDropDown) {
            return [...sourcesCombined['shared'], ...sourcesCombined.sources];
          }
          return sourcesCombined;


  }

  async getTags(user: any, forDropDown = false) {
    const userId = [UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType) ? user.enterpriseId : user.uid;
    const userRef = await this.db.collection<any>(COLLECTIONS.TAGS).doc(userId).ref.get();
    let tagsCombined = null;
    if (userRef.exists) {
      tagsCombined = userRef.data() as { tags: string[]; userId: string };
    } else {
      tagsCombined = { tags: [], userId };
    }
    if (!forDropDown && (!user.isEnterprise || [UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType))) {
      return tagsCombined;
    } if (forDropDown && (!user.isEnterprise || [UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType))) {
      return tagsCombined.tags;
    }
        tagsCombined['shared'] = [];
        const enterpriseRef = await this.db.collection<any>(COLLECTIONS.TAGS).doc(user.enterpriseId).ref.get();
        if (enterpriseRef.exists) {
          tagsCombined['shared'].push(...enterpriseRef.data().tags);
        }
        if (user.userType !== UserType.MANAGER) {
          if (user.teamId && user.managerId) {
            const managerRef = await this.db.collection<any>(COLLECTIONS.TAGS).doc(user.managerId).ref.get();
            if (managerRef.exists) {
              tagsCombined['shared'].push(...managerRef.data().tags);
            }
          }
          if (user.userType !== UserType.TEAM_LEADER) {
            if (user.teamId && user.teamLeaderId) {
              const teamLeadRef = await this.db.collection<any>(COLLECTIONS.TAGS).doc(user.teamLeaderId).ref.get();
              if (teamLeadRef.exists) {
                tagsCombined['shared'].push(...teamLeadRef.data().tags);
              }
            }
            if (forDropDown) {
              return [...tagsCombined['shared'], ...tagsCombined.tags];
            }
            return tagsCombined;
          }
            if (forDropDown) {
              return [...tagsCombined['shared'], ...tagsCombined.tags];
            }
            return tagsCombined;

        }
          if (forDropDown) {
            return [...tagsCombined['shared'], ...tagsCombined.tags];
          }
          return tagsCombined;


  }

  async getAutoAssignData(user_id: string) {
    const ref = await this.db.collection<any>(COLLECTIONS.AUTO_ASSIGN_TAGS_SOURCE).doc(user_id).ref.get();
    if (!ref.exists) {
      return null;
    }
      return ref.data() as { zapier: { source: string; tags: string[] } };

  }

  async saveAutoAssign(data: any, uid: string) {
    await this.db.collection<any>(COLLECTIONS.AUTO_ASSIGN_TAGS_SOURCE).doc(uid).set(data);
  }

  async fileUpload(file: any, bucketPath?: string) {
    const id = this.auth.currentUser.uid;
    const currentTime = Date.now();
    const path = `user/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(bucketPath ? `${bucketPath}/${id}/${id}_${currentTime}` : path);
    const task = this.storage.upload(bucketPath ? `${bucketPath}/${id}/${id}_${currentTime}` : path, file);
    const snapshot = await task;
    const url = await snapshot.ref.getDownloadURL();
    return url;
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.auth.getCurrentUser2();
    const credentials = firebase.default.auth.EmailAuthProvider.credential(user.email, oldPassword);
    try {
      await user.reauthenticateWithCredential(credentials);
      await user.updatePassword(newPassword);
    } catch (error) {
      throw error;
    }
  }

  async updatemailSignature(sign: string) {
    try {
      await this.auth.getDBref().update({ emailSignature: sign });
      this.toastr.success('Signature Updated', 'Email Signature');
    } catch {
      console.warn('error updating Signature');
    }
  }

  updateZapierKey(id: string, key: string) {
    try {
      this.db.collection<any>(COLLECTIONS.USERS).doc(id).update({ dailyaiToken: key });
    } catch {
      console.warn('error adding key');
    }
  }

  async uploadImageEmail(file: any, id: any) {
    id || 'rinu';
    this.store.dispatch(fileupload({ start: true }));
    const currentTime = Date.now();
    const path = `settings/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    this.task.percentageChanges().subscribe((num) => {
      this.store.dispatch(FileUploadProgress({ progress: num }));
    });
    return new Promise((resolve, reject) => {
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe(
              (url) => {
                if (file.type.split('/')[0] === 'video') {
                  this.createGif(url, `${id}_${currentTime}`).then((sub) => {
                    sub.subscribe((res) => {
                      this.store.dispatch(fileupload({ start: false }));

                      resolve(url);
                    });
                  });
                } else {
                  this.store.dispatch(fileupload({ start: false }));

                  resolve(url);
                }
              },
              (error) => {
                console.warn(error);
                this.store.dispatch(fileupload({ start: false }));

                reject(error);
              }
            );
          })
        )
        .subscribe();
    });
  }

  async createGif(url: string, fileName) {
    const payload = {
      url,
      fileName,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const link = `${environment.cloud_base_url}/generateGif`;
    return this.http.post(link, payload, httpOptions);
  }

  async UploadDripAttachment(file: any, id: any) {
    id || 'rinu';
    this.store.dispatch(fileupload({ start: true }));
    const currentTime = Date.now();
    const path = `drip_email_attachment/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    this.task.percentageChanges().subscribe((num) => {
      this.store.dispatch(FileUploadProgress({ progress: num }));
    });

    return new Promise((resolve, reject) => {
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe(
              (url) => {
                this.store.dispatch(fileupload({ start: false }));
                resolve(url);
              },
              (error) => {
                console.warn(error);
                this.store.dispatch(fileupload({ start: false }));

                reject(error);
              }
            );
          })
        )
        .subscribe();
    });
  }

  async uploadImageEmailNew(file: any, id: any) {
    const currentTime = Date.now();
    const path = `settings/${id}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = ref.putString(file, 'data_url');
    const currUpload = await this.task.snapshotChanges().toPromise();
    const curFileUrl = await currUpload.ref.getDownloadURL();
    return curFileUrl;
  }

  addVcard(data) {
    return this.db.collection<any>(COLLECTIONS.USERS).doc(data.uid).update({ vCard: data.vCardData });
  }

  async vCardUpload(id, file, name) {
    const date = new Date();
    const time = date.getTime();

    const path = `vCard/${id}/${time}.vcf`;
    const ref = this.storage.ref(path);
    const task = this.storage.upload(path, file);
    const snpahsot = await task;

    snpahsot.ref.updateMetadata({
      contentDisposition: `inline; name="${name}"; filename="${name.split(' ').join('-')}.vcf"`,
    });
    const url = await snpahsot.ref.getDownloadURL();

    return url;
  }

  async deletevCard(url) {
    try {
      const photoRef = this.storage.storage.refFromURL(url);
      await photoRef.delete();
    } catch (error) { console.error(error); }

  }

  async sendSms(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/sendVcard`;
    return this.http.post(url, data, httpOptions).subscribe();
  }

  async getCampaignUsers(payload: {
    parentId: string;
    search?: string;
    sort?: {
      sortBy: string;
      order: number;
    };
    curPage: number;
    filters?: any[];
  }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.mongoUrl}/v0.1/mailCampaign/listUserCampaign`;
    return this.http.post(url, payload, httpOptions).toPromise() as Promise<{ data: any[]; count: number }>;
  }

  async getCampaignList(payload: {
    user_id: string;
    search?: string;
    sort?: {
      sortBy: string;
      order: number;
    };
    curPage: number;
  }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.mongoUrl}/v0.1/mailCampaign/listCampaignProgress`;
    return this.http.post(url, payload, httpOptions).toPromise() as Promise<{ data: any[]; count: number }>;
  }

  async deintegrateEmail(uid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/deintegrateEmail`;
    await this.http.post(url, { userId: uid }, httpOptions).toPromise();
  }

  async deintegrateLos(uid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/deintegrateLos`;
    await this.http.post(url, { userId: uid }, httpOptions).toPromise();
  }

  async integrateSimpleNexus(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`
      }),
    };

    const url = `${environment.cloud_base_url}/IntegrateSimpleNexus`;
    await this.http.post(url, payload, httpOptions).toPromise();
  }

  async validateNexusLoanOfficer(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`
      }),
    };

    const url = `${environment.cloud_base_url}/GetLoanOfficer`;
    return this.http.post(url, payload, httpOptions).toPromise();
  }

  async integrateMeridianLink(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
      }),
    };
    const url = `${environment.cloud_base_url}/integrateMLM`;
    await this.http.post(url, { email }, httpOptions).toPromise();
  }

  async removeMeridianLinkIntegration() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
      }),
    };
    const url = `${environment.cloud_base_url}/removeMLMIntegration`;
    await this.http.post(url, undefined, httpOptions).toPromise();
  }

  async integrateAI(botName?: string) {
    const bot_name = botName || 'Rue AI';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/integrateUserTrueAI`;
    await this.http.post(url, { bot_name }, httpOptions).toPromise();
  }

  async editBotName(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/editTrueAIUser`;
    await this.http.post(url, data, httpOptions).toPromise();
  }

  async resendEmail(id): Promise<any> {
    const tHeader: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${await this.auth.getUserToken()}`,
    });

    return this.http.post(RESEND_MAIL_URL, { junior_id: id }, { headers: tHeader }).toPromise();
  }

  getJLOs(user_id: string) {
    return this.db
      .collection<any>(COLLECTIONS.USERS, (ref) => ref.where('parentId', '==', user_id).where('userType', '==', UserType.JLO))
      .valueChanges()
      .pipe(
        map((val: any[]) => {
          if (val && val.length > 0) {
            return val.map((u) => ({
              uid: u.uid as string,
              name: `${u.firstName} ${u.lastName}`,
              email: u.email,
            }));
          }
          return [];
        })
      );
  }

  getManagingUsers(user_id: string) {
    return this.db
      .collection<any>(COLLECTIONS.USERS, (ref) => ref.where('managerId', '==', user_id))
      .valueChanges()
      .pipe(
        map((val: any[]) => {
          if (val && val.length > 0) {
            return (
              val
                .filter((u) => u.userType !== UserType.MO)
                ?.map((u) => ({
                  uid: u.uid as string,
                  name: `${u.firstName} ${u.lastName}`,
                  email: u.email,
                  userType: u.userType,
                })) || []
            );
          }
          return [];
        })
      );
  }

  getTeamMembers(user_id: string) {
    return this.db
      .collection<any>(COLLECTIONS.USERS, (ref) => ref.where('teamLeaderId', '==', user_id))
      .valueChanges()
      .pipe(
        map((val: any[]) => {
          if (val && val.length > 0) {
            return (
              val
                .filter((u) => u.userType !== UserType.MO)
                ?.map((u) => ({
                  uid: u.uid as string,
                  name: `${u.firstName} ${u.lastName}`,
                  email: u.email,
                  userType: u.userType,
                })) || []
            );
          }
          return [];
        })
      );
  }

  getRoutingRules(user_id: string) {
    return this.db
      .collection<any>(COLLECTIONS.ROUTING_RULES)
      .doc(user_id)
      .valueChanges()
      .pipe(
        map((val: { dist: any; claim: any; round_robin: any; }) => {
          if (!val) {
            return { dist: {}, claim: {} };
          }
          return {
            dist: val.dist ? val.dist : {},
            claim: val.claim ? val.claim : {},
            round_robin: val.round_robin ? val.round_robin : {}, 
          };
        })
      );
  }

  async setDistRules(
    user_id: string,
    data: {
      entries: {
        source: string;
        users: { user_id: string; distribution: string }[];
      }[];
    }
  ) {
    await this.db.collection<any>(COLLECTIONS.ROUTING_RULES).doc(user_id).set({ dist: data }, { merge: true });
    await this.db
      .collection<any>(COLLECTIONS.DIST_STATUS)
      .doc(user_id)
      .set({ data: data.entries.map((e) => ({ source: e.source, count: 0 })) });
  }

  async setClaimRules(user_id: string, data: any) {
    await this.db.collection<any>(COLLECTIONS.ROUTING_RULES).doc(user_id).set({ claim: data }, { merge: true });
  }
  
  async setRoundRobinRules(user_id: string, data: any) {
    await this.db.collection<any>(COLLECTIONS.ROUTING_RULES).doc(user_id).set({ round_robin: data }, { merge: true });
    await this.db
    .collection<any>(COLLECTIONS.LEAD_ROUND_ROBIN_STATUS)
    .doc(user_id)
    .set({ data: data.entries.map((e) => ({ source: e.source, current_index: 0 })) });
  }

  async toggleAi(uid: string, toggle: boolean, isAdmin: boolean) {

    const aiQuery = await this.db.collection<any>(COLLECTIONS.AI_SETTINGS).doc(uid).ref.get();

    if (!aiQuery.exists) {
      const to_set = {
        doc_id: uid,
      };
      if (!isAdmin) {
        to_set['ai_assistant'] = toggle;
      }
      await this.db.collection<any>(COLLECTIONS.AI_SETTINGS).doc(uid).set(to_set);
    } else {
      await this.db.collection<any>(COLLECTIONS.AI_SETTINGS).doc(uid).update({ ai_assistant: toggle });
    }
  }

  async saveLeadNotification(payload: any) {
    if (payload.data.birthday_to_upload) {
      const url = await this.fileUpload(payload.data.birthday_to_upload, 'settings/mms-templates/');
      const { birthday_to_upload, ...rest } = payload.data;
      if (payload.data.birthday_to_upload.type !== "video/mp4") {
        payload.data = { ...rest, birthday_mms_image_url: url, birthday_mms_video_url: "", birthday_mms_video_thumbnail: "" };
      } else {
        let birthdayMMSVideoThumbnail: any = ''
        try {
          const gifRes = (await this.createGif(url, `${new Date()}`)).toPromise();
          const data: any = await gifRes;
          birthdayMMSVideoThumbnail = data.gif_url
        } catch (error) {
          console.log('error save lead', error);
        }
        payload.data = { ...rest, birthday_mms_video_url: url, birthday_mms_video_thumbnail: birthdayMMSVideoThumbnail, birthday_mms_image_url: '' };
      }
    }
    if (payload.data.aniversary_to_upload) {
      const url = await this.fileUpload(payload.data.aniversary_to_upload, 'settings/mms-templates/');
      const { aniversary_to_upload, ...rest } = payload.data;
      if (payload.data.aniversary_to_upload.type !== "video/mp4") {
        payload.data = { ...rest, aniversary_mms_image_url: url, aniversary_mms_video_url: "", aniversary_mms_video_thumbnail: "" };
      } else {
        let aniversaryMMSVideoThumbnail: any = ''
        try {
          const gifRes = (await this.createGif(url, `${new Date()}`)).toPromise();
          const data: any = await gifRes;
          aniversaryMMSVideoThumbnail = data.gif_url
        } catch (error) {
          console.log('error save lead', error);
        }
        payload.data = { ...rest, aniversary_mms_video_url: url, aniversary_mms_video_thumbnail: aniversaryMMSVideoThumbnail, aniversary_mms_image_url: '', };
      }
    }
    if (payload.data.postNurture_to_upload) {
      const url = await this.fileUpload(payload.data.postNurture_to_upload, 'settings/mms-templates/');
      const { postNurture_to_upload, ...rest } = payload.data;
      if (payload.data.postNurture_to_upload.type !== "video/mp4") {
        payload.data = { ...rest, postNurture_mms_image_url: url, postNurture_mms_video_url: "", postNurture_mms_video_thumbnail: "" };
      } else {
        let postNurtureMMSVideoThumbnail: any = ''
        try {
          const gifRes = (await this.createGif(url, `${new Date()}`)).toPromise();
          const data: any = await gifRes;
          postNurtureMMSVideoThumbnail = data.gif_url
        } catch (error) {
          console.log('error save lead', error);
        }
        payload.data = { ...rest, postNurture_mms_video_url: url, postNurture_mms_video_thumbnail: postNurtureMMSVideoThumbnail, postNurture_mms_image_url: '', };
      }
    }
    if (!payload.enterprise || payload.userType !== UserType.ADMIN) {
      let resp;
      try {
         resp = this.db
          .collection(COLLECTIONS.AI_SETTINGS)
          .doc(payload.uid)
          .update({ ...payload.data, uid: payload.uid });
      } catch (error) {
        console.log("error in ai_settings update",error);
      }
      return resp
    }
    return this.db
      .collection<any>(COLLECTIONS.AI_SETTINGS)
      .doc(payload.enterpriseId)
      .update({ ...payload.data, enterpriseId: payload.enterpriseId });
  }

  getAiSettings(payload: { uid: string; enterpriseId: string; userType: string; shared: boolean }): Observable<any> {
    if (payload.userType === UserType.ADMIN) {
      return this.db.collection<any>(COLLECTIONS.AI_SETTINGS).doc(payload.enterpriseId).valueChanges();
    }
    if (!payload.shared || payload.userType === UserType.MO) {
      return this.db.collection<any>(COLLECTIONS.AI_SETTINGS).doc(payload.uid).valueChanges();
    }
    return this.db
      .collection<any>(COLLECTIONS.AI_SETTINGS)
      .doc(payload.uid)
      .valueChanges()
      .pipe(
        withLatestFrom(
          this.db
            .collection<any>(COLLECTIONS.AI_SETTINGS)
            .doc(payload.enterpriseId)
            .valueChanges()
            .pipe(
              map((shared: any) => {
                const {
                  aniversary_types,
                  anniversary_notification,
                  birthday_notification,
                  birthday_types,
                  nurture_dates,
                  postNurture_types,
                  ...sharedData
                } = shared;
                return sharedData;
              })
            ),
          (self: any, shared: any) => {
            return { ...self, ...shared };
          }
        )
      );
  }

  updateCustomInformation(uid: string, payload: any) {
    return this.db
      .collection<any>(COLLECTIONS.USERS)
      .doc(uid)
      .update({
        user_custom_fields_value: { ...payload },
      });
  }

  getHistory(
    payload: {
      page?: number;
      term?: string;
      params?: { status?: string; date?: { from?: string; to?: string }; amount: { from?: Number; to?: Number } };
    },
    uid: string,
    token: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    if (payload.params && payload.params.date) {
      let params_obj = payload.params;
      let date_obj = payload.params.date;
      if (payload.params.date.to) {
        date_obj = { ...date_obj, to: new Date(payload.params.date.to).toISOString() };
      }
      if (payload.params.date.from) {
        date_obj = { ...date_obj, from: new Date(payload.params.date.from).toISOString() };
      }
      params_obj = { ...params_obj, date: date_obj };

      payload = { ...payload, params: params_obj };
    }
    const url = `${environment.mongoUrl}/v0.1/history/listHistory`;
    const to_send = { uid, value: payload };
    return this.http.post(url, to_send, httpOptions) as Observable<{ data: any[]; count: number }>;
  }

  async getPurpose(user: any, radio: string): Promise<any> {
    let collection = this.db.collection<any>(COLLECTIONS.USER_REFINANCE_LOAN_PURPOSE).ref;
    if (radio === 'purchase') {
      collection = this.db.collection<any>(COLLECTIONS.USER_PURCHASE_LOAN_PURPOSE).ref;
    }

      let vL = [user.uid];
      if (user.userType === UserType.JLO) {
        if (user?.teamLeaderId) {
          vL = [user?.teamLeaderId];
        }
        if (user?.managerId && !user?.teamLeaderId) {
          vL = [user?.managerId];
        }
        if (!user?.managerId && !user?.teamLeaderId && user?.enterpriseId) {
          vL = [user?.enterpriseId];
        }
      }

      if (user.userType === UserType.SUPER_ADMIN || user.userType === UserType.ADMIN) {
        vL = [user?.enterpriseId];
      }

      return (await collection.where('viewersList', 'array-contains-any', vL).get()).docs.map((d) => d.data());

  }

  async savePurpose(user: any, purpose: string, radio: string): Promise<any> {
    let collection = this.db.collection<any>(COLLECTIONS.USER_REFINANCE_LOAN_PURPOSE);

    if (radio === 'purchase') {
      collection = this.db.collection<any>(COLLECTIONS.USER_PURCHASE_LOAN_PURPOSE);
    }

    if (user.isEnterprise && [UserType.ADMIN, UserType.SUPER_ADMIN].includes(user.userType)) {
      const added = await collection.add({
        purpose_name: purpose,
        added_by: user.enterpriseId,
        viewersList: [user.enterpriseId],
      });
      await collection.doc(added.id).update({
        doc_id: added.id,
      });

      return added;
    }

    const added = await collection.add({
      purpose_name: purpose,
      added_by: user.uid,
      viewersList: [user.uid],
    });
    await collection.doc(added.id).update({
      doc_id: added.id,
    });

    return added;
  }

  async deletePurpose(user: any, doc_id: string, radio: string): Promise<any> {
    let collection = this.db.collection<any>(COLLECTIONS.USER_REFINANCE_LOAN_PURPOSE);
    if (radio === 'purchase') {
      collection = this.db.collection<any>(COLLECTIONS.USER_PURCHASE_LOAN_PURPOSE);
    }

    const deleted = await collection.doc(doc_id).delete();

    return deleted;
  }

  async getPresentTotal(uid: string): Promise<any> {
    try {
      const url = `${environment.cloud_base_url}/get_present_total`;
      const res: any = await this.http.post(url, { uid }).toPromise();
      return res;
    } catch (error) {
      return error;
    }
  }

  async getPackage(enterpriseId: string): Promise<any> {
    const needed_package = {};
    const enterprise = (await this.db.collection<any>(COLLECTIONS.ENTERPRISES).doc(enterpriseId).ref.get()).data();
    if (!enterprise.customPackage) {
      const packageId = enterprise.packageDetails.package_id ?? null;

      const package_got: any = (await this.db.collection<any>(COLLECTIONS.PACKAGES).doc(packageId).ref.get()).data();
      needed_package['packageName'] = package_got.packageName;
      needed_package['thresholdAccounts'] = package_got.thresholdAccounts;
      needed_package['monthlyCharge'] = package_got.monthlyCharge;
      needed_package['additionalLicenseCharge'] = package_got.additionalLicenseCharge;
    } else {
      needed_package['packageName'] = 'Custom package';
      needed_package['thresholdAccounts'] = enterprise.packageDetails.thresholdAccounts;
      needed_package['monthlyCharge'] = enterprise.packageDetails.monthlyCharge;
      needed_package['additionalLicenseCharge'] = enterprise.packageDetails.additionalLicenseCharge;
    }

    return needed_package;
  }

  downloadExcel(
    payload: {
      page?: number;
      term?: string;
      params?: { status?: string; date?: { from?: string; to: string }; amount: { from?: Number; to?: Number } };
    },
    uid: string,
    token: string
  ): Observable<any> {
    const to_send = { uid, value: payload };
    const options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
      responseType: 'blob',
    };
    return this.http.post(`${environment.mongoUrl}/v0.1/history/listHistory`, to_send, options).pipe(
      switchMap((resp) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(resp);
        link.download = `payment_history_${new Date().toLocaleString()}.xls`;
        link.click();
        return of();
      })
    );
  }

  async enterpriseLogoUpload(file: any) {
    const id = this.auth.currentUser.uid;
    const user = (await this.db.collection<any>(COLLECTIONS.USERS).doc(id).ref.get()).data();
    const docId = user.userType === UserType.SUPER_ADMIN || user.userType === UserType.ADMIN ? user.enterpriseId : user.uid;
    const currentTime = Date.now();
    const path = `logos/${docId}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);

    const task = ref.putString(file, 'data_url');
    const snpahsot = await task;
    const url = await snpahsot.ref.getDownloadURL();
    await this.db.collection<any>(COLLECTIONS.ENTERPRISE_LOGOS).doc(docId).set({ url, docId });
  }

  async getEnterpriseLogo() {
    const id = this.auth.currentUser.uid;
    const user = (await this.db.collection<any>(COLLECTIONS.USERS).doc(id).ref.get()).data();
    let docId = null;
    if (user.isEnterprise) {
      const { enterpriseId, managerId, teamLeaderId } = user;
      const uid = [UserType.MANAGER, UserType.TEAM_LEADER].includes(user.userType) ? user.uid : null;

      const idArrays = [uid, enterpriseId, managerId, teamLeaderId].filter((id) => !!id);
      return this.db
        .collection<any>(COLLECTIONS.ENTERPRISE_LOGOS, (ref) => ref.where('docId', 'in', idArrays))
        .valueChanges()
        .pipe(
          map((docs: any) => {
            docId = null;
            const tempObj = {};
            docs.forEach((d) => (tempObj[d.docId] = d));
            if (!docId && uid) {
              const logoDataEnterprise = tempObj[uid];
              if (logoDataEnterprise) {
                docId = uid;
              }
            }
            if (!docId && teamLeaderId) {
              const logoDataEnterprise = tempObj[teamLeaderId];
              if (logoDataEnterprise) {
                docId = teamLeaderId;
              }
            }
            if (!docId && managerId) {
              const logoDataEnterprise = tempObj[managerId];
              if (logoDataEnterprise) {
                docId = managerId;
              }
            }
            if (!docId && enterpriseId) {
              const logoDataEnterprise = tempObj[enterpriseId];
              if (logoDataEnterprise) {
                docId = enterpriseId;
              }
            }
            if (docId) {
              return tempObj[docId];
            }
            return undefined;
          })
        );
    } else {
      if (user.userType === UserType.JLO || user.userType === UserType.MO) {
        docId = user.parentId;
      }
      if (!docId) {
        return of(undefined);
      }
      const logoData = this.db.collection<any>(COLLECTIONS.ENTERPRISE_LOGOS).doc(docId).valueChanges();
      return logoData;
    }
  }

  async removeLogo() {
    const id = this.auth.currentUser.uid;
    const user = (await this.db.collection<any>(COLLECTIONS.USERS).doc(id).ref.get()).data();
    const docId = user.userType === UserType.SUPER_ADMIN || user.userType === UserType.ADMIN ? user.enterpriseId : user.uid;
    const logoRef = this.db.collection<any>(COLLECTIONS.ENTERPRISE_LOGOS).doc(docId);
    const logoData = await logoRef.ref.get();
    if (logoData.exists) {
      await logoRef.ref.delete();
      return true;
    }
      return false;
  }
}
