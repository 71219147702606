<div *ngIf="showNotification && !dismiss" class="info-notification-container truncate">
  <span
    class="info-notification-content position-relative"
    popoverClass="popover-class"
    placement="bottom-left"
    triggers="mouseenter:mouseleave"
    [ngbPopover]="milestonesDisabledWarningPopover"
    #p="ngbPopover"
  >
    <span class="info-icon">
      <img src="../../../../assets/icons/icons8-info.svg" alt="" />
    </span>
    <span class="color-black-grey-text d-lg-inline d-none info-notification">{{ notificationText }}</span>
    <span class="dismiss-icon" (click)="dismiss = true">
      <img src="../../../../assets/icons/icons8-close.svg" alt="" />
    </span>
  </span>
</div>
<ng-template #milestonesDisabledWarningPopover>
  <div class="m-2">
    <div class="font-12 info-z-index">{{ notificationDescription }}</div>
    <a class="font-12" *ngIf="extraLink" [href]="extraLink?.link">{{ extraLink?.text }}</a>
  </div>
</ng-template>
