import { createReducer, on } from '@ngrx/store';
import * as SignupStateActions from './action';

import { SignupState } from './state';

export const initailState: SignupState = {
  signingUp: false,
  signupError: null,
  signupSuccess: null
};

export const signupReducer = createReducer(
  initailState,
  on(SignupStateActions.SignupSuccess, state => ({
    ...state,
    signupSuccess: true,
    signingUp: false,
    signupError: null
  })),
  on(SignupStateActions.SignupError, (state, payload) => ({
    ...state,
    signupError: payload.msg,
    signupSuccess: null,
    signingUp: false
  })),
  on(SignupStateActions.SigningUp, state => ({
    ...state,
    signupError: null,
    signupSuccess: null,
    signingUp: true
  })),
  on(SignupStateActions.SignupReset, state => ({
    ...initailState
  }))
);
