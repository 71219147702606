<div class="d-sm-flex d-none">
  <div class="input-group">
    <input type="{{type}}" class="form-control transition {{class}}" [ngClass]="{ 'px-0 w-0': !open, 'pl-2': open }" placeholder="{{placeholder}}"
      [(ngModel)]="term" [disabled]="disabled" (ngModelChange)="onChange($event)" #searchInput />
    <div class="input-group-append cursor-pointer" (click)="open = !open; searchInput.focus()">
      <span class="input-group-text material-icons" *ngIf="!searching">search</span>
      <span class="input-group-text p-1 font-16 icon-color" *ngIf="searching">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </div>
  </div>
</div>
<div class="d-sm-none d-flex">
  <div class="search-input-box transition position-absolute overflow-hidden" [ngClass]="{ 'px-0 w-0': !open }">
    <div class="input-group">
      <input type="{{type}}" class="form-control pl-2 {{class}}" placeholder="{{placeholder}}"
        [(ngModel)]="term" [disabled]="disabled" (ngModelChange)="onChange($event)" id="{{id}}" #searchInputRes />
      <div class="input-group-append cursor-pointer" (click)="open = !open;">
        <span class="input-group-text material-icons" *ngIf="!searching">search</span>
        <span class="input-group-text p-1 font-16 icon-color" *ngIf="searching">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
      </div>
    </div>
  </div>
  <button class="btn btn-border d-flex align-items-center" *ngIf="!searching" (click)="open = true; searchInputRes.focus()">
    <span class="material-icons">
      search
    </span>
  </button>
</div>
