<div class="d-none d-sm-block">
  <div class="container d-flex flex-column justify-content-between mx-0 px-0 side-bar-menu v2-ui-integretion">
    <div class="column align-items-center">
      <div class="logo-img mt-3 mb-3">
        <img class="w-100 p-1" [src]="enterpriseLogo || '../../../assets/icons/menu_icons/logo.svg'" />
      </div>
      <div class="menu-box d-flex flex-column mt-2">
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Dashboard"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="userType !== userTypes.SUPER_ADMIN && currentUser?.legacyDashboard"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/dashboard']"
          >
            <img src="../../../assets/icons/menu_icons/dashboard.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="The Art of Homeownership"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="permissions?.aohLaunchpad"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/art-of-homeownership']"
          >
            <img height="20" width="20" src="../../../assets/icons/menu_icons/Aoh.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Dashboard Beta"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="userType !== userTypes.SUPER_ADMIN && !currentUser?.legacyDashboard"
        >
        <a
          class="menu-a d-flex justify-content-center align-items-center"
          routerLinkActive="active"
          [routerLink]="['/dashboard-beta']"
        >
          <img src="../../../assets/icons/menu_icons/dashboard.svg" />
        </a>
      </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Enterprise"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="
            [userTypes.SUPER_ADMIN, userTypes.MANAGER, userTypes.ADMIN].includes(currentUser?.userType) ||
            (currentUser?.userType === userTypes.TEAM_LEADER && currentUser?.teamsLeading?.length)
          "
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/enterprise']"
          >
            <img class="h-20" src="../../../assets/icons/menu_icons/enterprise.svg" />
          </a>
        </div>
        <div
          *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Contacts"
          tooltipClass='tooltip-side-nav'
          container='body'
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            (click)="resetContacts()"
            [routerLink]="['/contacts']"
            id="contacts-page-btn"
          >
            <img src="../../../assets/icons/menu_icons/contacts.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Task"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="userType !== userTypes.SUPER_ADMIN"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/task']"
          >
            <img src="../../../assets/icons/menu_icons/task.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Transactions"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/transactions']"
            (click)="resetTransaction()"
          >
            <img src="../../../assets/icons/menu_icons/transactions.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Communications"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="userType !== userTypes.SUPER_ADMIN && userType !== userTypes.ADMIN"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/communications']"
          >
            <img src="../../../assets/icons/menu_icons/communications.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Drip Campaign"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="userType !== userTypes.SUPER_ADMIN"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/drip-campaign']"
          >
            <img src="../../../assets/icons/menu_icons/drip-campaign.svg" />
          </a>
        </div>
        <div
          *ngIf="currentUser?.userType !== userTypes.SUPER_ADMIN"
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Media"
          tooltipClass='tooltip-side-nav'
          container='body'
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/recording']"
          >
            <img src="../../../assets/icons/menu_icons/media.svg" />
          </a>
        </div>
        <div
          *ngIf="
            currentUser?.isEnterprise
              ? currentUser?.userType === userTypes.TEAM_LEADER || currentUser?.userType === userTypes.JLO
              : userRole === userTypes.JLO
          "
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Reporting"
          tooltipClass='tooltip-side-nav'
          container='body'
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/reporting']"
          >
            <img src="../../../assets/icons/menu_icons/reporting.svg" />
          </a>
        </div>
        <div
          *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Partners"
          tooltipClass='tooltip-side-nav'
          container='body'
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/partners']"
            id="partner-groups-btn"
          >
            <img src="../../../assets/icons/menu_icons/partners.svg" />
          </a>
        </div>
        <div *ngIf="
                    (currentUser?.isEnterprise &&
                      (currentUser.userType === userTypes.MANAGER ||
                        currentUser.userType === userTypes.TEAM_LEADER ||
                        currentUser.userType === userTypes.JLO))
                  " class="menu-tooltip mt-1" placement="right" ngbTooltip="Website" tooltipClass='tooltip-side-nav'
          container='body'>
          <a class="menu-a d-flex justify-content-center align-items-center" routerLinkActive="active"
            [routerLink]="['/website']">
            <img src="../../../assets/icons/menu_icons/website.svg" />
          </a>
        </div>
        <ng-container *ngIf="enableCalender">
          <div
            class="menu-tooltip mt-1"
            placement="right"
            ngbTooltip="Calendar"
            tooltipClass='tooltip-side-nav'
          container='body'
            *ngIf="sideNavPermissions['calendar'].has(currentUser?.userType)"
          >
            <a
              class="menu-a d-flex justify-content-center align-items-center"
              routerLinkActive="active"
              [routerLink]="['/calendar']"
            >
              <img src="../../../assets/icons/menu_icons/calendar.svg" />
            </a>
          </div>
        </ng-container>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Marketing"
          tooltipClass='tooltip-side-nav'
          container='body'
          *ngIf="permissions?.markettingScreen"
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center"
            routerLinkActive="active"
            [routerLink]="['/marketing']"
          >
            <img src="../../../assets/icons/menu_icons/marketing.svg" />
          </a>
        </div>
        <div
          class="menu-tooltip mt-1"
          placement="right"
          ngbTooltip="Settings"
          tooltipClass='tooltip-side-nav'
          container='body'
          #target
        >
          <a
            class="menu-a d-flex justify-content-center align-items-center settings-side-panel-btn"
            routerLinkActive="active"
            [routerLink]="['/settings']"
          >
            <img src="../../../assets/icons/menu_icons/settings.svg" />
          </a>
        </div>
      </div>
      <div
        *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN && currentUser.userType !== userTypes.ADMIN"
        class="menu-drop-box w-100"
        (click)="showMenu(target)"
      >
        <a class="menu-drop-down d-flex justify-content-center align-items-center">
          <img src="../../../assets/down-arrow.svg" />
        </a>
      </div>
    </div>
    <div class="d-flex column">
      <div class="d-flex justify-content-center mb-4">
        <div
          *ngIf="userProcess?.length"
          class="bottom-arrow cursor-pointer d-flex justify-content-center align-items-center"
          [ngbPopover]="popContent"
          popoverClass="processPopover"
          triggers="manual"
          (click)="uploadNoti.open()"
          #uploadNoti="ngbPopover"
          (hidden)="setPopover(uploadNoti)"
          (shown)="setPopover(uploadNoti)"
        >
          <span class="badge-red p-0"></span> <i class="material-icons"> arrow_upward </i>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-3 position-relative">
        <div class="user-img cursor-pointer cus-img" (click)="popup_custom_toggle()">
          <span class="badge-green"></span>
          <img #profileMenu *ngIf="userImage" class="propic-image" src="{{ userImage }}" alt="" />
          <img *ngIf="!userImage" #profileMenu src="../../../assets/adduser-icon.svg" alt="" />
        </div>
        <div
          [style.visibility]="!popup_hide ? 'hidden' : 'visible'"
          id="popUp"
          class="popup-custom d-flex flex-column border"
        >
          <div #profileMenuHeader class="border-bottom d-flex align-items-center px-2 py-3">
            <div>
              <img class="propic-image" #currentUserImage *ngIf="userImage" src="{{ userImage }}" alt="" />
              <img *ngIf="!userImage" #currentUserImage src="../../../assets/adduser-icon.svg" alt="" />
            </div>
            <div class="d-flex flex-column pl-2">
              <span class="font-14 font-weight-bold text-break" #currentUserFirstName>
                {{ currentUser.firstName }} {{ currentUser.lastName }}
              </span>
            </div>
          </div>
          <a [href]="supportLink" target="_blank">
            <div class="d-flex align-items-center cursor-pointer px-3 py-2 menu-items font-14">Visit the Knowledge Base</div>
          </a>
          <a [href]="emailSupport" target="_top">
            <div class="d-flex align-items-center cursor-pointer px-3 py-2 menu-items font-14">Contact Support</div>
          </a>
          <div
            id="my-user-logout-btn"
            class="d-flex align-items-center cursor-pointer px-3 py-2 menu-items"
            [routerLink]="['/logout']"
          >
            <span class="font-14">Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #popContent>
    <div>
      <h5 class="pt-3 color-grey-text font-14 font-hn-medium pb-2">UPLOADS</h5>
      <div class="heightScroll">
        <div *ngFor="let process of userProcess">
          <div
            class="uploading-started p-2 mb-2 d-flex align-items-center justify-content-between"
            *ngIf="process.process_status === 'In Progress'"
          >
            <div class="d-flex">
              <i class="material-icons pr-2"> error_outline </i>
              <span class="text-capatilize font-14">{{ process.process_name }}</span>
            </div>
            <span class="pl-3 font-10">Preparing...</span>
          </div>
          <div
            class="uploading p-2 mb-2 d-flex align-items-center"
            *ngIf="
              process.process_status === 'Processing Records' ||
              process.process_status === 'Adding transactions' ||
              process.process_status === 'Adding Contacts'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> error_outline </i></div>
            <div class="d-flex flex-column position-relative w-100" style="width: 84% !important">
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-14">{{
                  process.process_name === 'contacts_upload' ? 'Contacts' : 'Transactions'
                }}</span>
                <span class="font-10">Processing</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">Added: {{ process.process_data.processed_records }}</span>
                <span class="font-10">Failed: {{ process.process_data.failed_records }}</span>
                <span class="font-10">Total: {{ process.process_data.total_records }}</span>
              </div>
            </div>
          </div>
          <div
            class="uploaded p-2 mb-2 d-flex align-items-center mw-100 csv-added-dialogue-box"
            *ngIf="
              process.process_status === 'Process completed successfully' ||
              process.process_status === 'Upload Process Completed Successfully'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> check </i></div>
            <div class="d-flex flex-column position-relative w-85">
              <span class="close-icon close-modal-for-failed" (click)="dismissItem(process)"
                ><i class="material-icons"> clear </i></span
              >
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-13">{{ process.process_name }}</span>
                <span class="font-10">Completed</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">Added: {{ process.process_data.processed_records }}</span>
                <span class="font-10 csv-failed-number">Failed: {{ process.process_data.failed_records }}</span>
                <span class="font-10">Total: {{ process.process_data.total_records }}</span>
                <a
                  class="font-10 failed-upload-view-btn"
                  (click)="openViewDetail(process.process_id, process.process_name)"
                  *ngIf="process.process_data.failed_records > 0"
                  >View Failed</a
                >
              </div>
            </div>
          </div>
          <div
            class="uploaded-failed p-2 mb-2 d-flex align-items-center"
            *ngIf="
              process.process_status === 'Upload failed due to CSV field name mismatch' ||
              process.process_status === 'Upload Failed due to csv headers mismatch'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> clear </i></div>
            <div class="d-flex flex-column position-relative w-100">
              <span class="close-icon" (click)="dismissItem(process)"><i class="material-icons"> clear </i></span>
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-13">{{ process.process_name }}</span>
                <span class="font-10">Failed</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">{{ process.process_status }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <button class="btn p-0 m-0 csv-clear-all-btn" (click)="dismissAll()">
          <i class="material-icons pr-2">clear_all</i>
        </button>
      </div>
    </div>
  </ng-template>
</div>
<div class="d-block d-sm-none responsive-classes">
  <div class="d-flex flex-column mobile-side-nav">
    <div class="d-flex">
      <div class="d-flex align-items-center sidebar-icon">
        <div class="logo-img mt-3 mb-3"><img class="w-75 p-1" src="../../../assets/icons/menu_icons/logo-white.svg" /></div>
      </div>
      <div class="sidebar-text">
        <span class="font-18 color-primary page-title">{{ activeTab }}</span>
      </div>
    </div>
    <div class="d-flex flex-column menu-box">
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/dashboard']"
        *ngIf="userType !== userTypes.SUPER_ADMIN && currentUser?.legacyDashboard"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/dashboard.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Dashboard</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/art-of-homeownership']"
        *ngIf="permissions?.aohLaunchpad"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img height="20" width="20" src="../../../assets/icons/menu_icons/Aoh.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">The Art of Homeownership</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/dashboard-beta']"
        *ngIf="userType !== userTypes.SUPER_ADMIN && !currentUser?.legacyDashboard"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img src="../../../assets/icons/menu_icons/dashboard.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Dashboard Beta</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/enterprise']"
        *ngIf="
          [userTypes.SUPER_ADMIN, userTypes.MANAGER, userTypes.ADMIN].includes(currentUser?.userType) ||
          (currentUser?.userType === userTypes.TEAM_LEADER && currentUser?.teamsLeading?.length)
        "
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="h-20" src="../../../assets/icons/menu_icons/enterprise.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Enterprise</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        (click)="resetContacts()"
        *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
        [routerLink]="['/contacts']"
      >
        <div class="d-flex align-items-center sidebar-icon contacts-side-button">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/contacts.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Contacts</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/task']"
        *ngIf="userType !== userTypes.SUPER_ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/task.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Task</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/transactions']"
        (click)="resetTransaction()"
        *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2">
            <img class="w-40 p-1" src="../../../assets/icons/menu_icons/transactions.svg" />
          </div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Transactions</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/communications']"
        *ngIf="userType !== userTypes.SUPER_ADMIN && userType !== userTypes.ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2">
            <img class="w-40 p-1" src="../../../assets/icons/menu_icons/communications.svg" />
          </div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Communications</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/drip-campaign']"
        *ngIf="userType !== userTypes.SUPER_ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2">
            <img class="w-40 p-1" src="../../../assets/icons/menu_icons/drip-campaign.svg" />
          </div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Drip Campaign</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/recording']"
        *ngIf="currentUser?.userType !== userTypes.SUPER_ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/media.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Media</span></div>
      </a>
      <a
        *ngIf="
          currentUser?.isEnterprise
            ? currentUser?.userType === userTypes.TEAM_LEADER || currentUser?.userType === userTypes.JLO
            : userRole === userTypes.JLO
        "
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/reporting']"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/reporting.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Reporting</span></div>
      </a>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/partners']"
        *ngIf="currentUser.userType !== userTypes.SUPER_ADMIN"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/partners.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Partners</span></div>
      </a>
      <a
        *ngIf="
          (currentUser?.isEnterprise &&
            (currentUser.userType === userTypes.MANAGER ||
              currentUser.userType === userTypes.TEAM_LEADER ||
              currentUser.userType === userTypes.JLO))
        "
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/website']"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/website.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Website</span></div>
      </a>
      <ng-container *ngIf="enableCalender">
        <a
          class="menu-a d-flex align-items-center"
          routerLinkActive="active"
          [routerLink]="['/calendar']"
          *ngIf="sideNavPermissions['calendar'].has(currentUser?.userType)"
        >
          <div class="d-flex align-items-center sidebar-icon">
            <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/calendar.svg" /></div>
          </div>
          <div class="sidebar-text pl-2"><span class="font-14">Calendar</span></div>
        </a>
      </ng-container>
      <a
        class="menu-a d-flex align-items-center"
        routerLinkActive="active"
        [routerLink]="['/marketing']"
        *ngIf="permissions?.markettingScreen"
      >
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/marketing.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Marketing</span></div>
      </a>
      <a class="menu-a d-flex align-items-center" routerLinkActive="active" [routerLink]="['/settings']">
        <div class="d-flex align-items-center sidebar-icon">
          <div class="logo-img my-2"><img class="w-40 p-1" src="../../../assets/icons/menu_icons/settings.svg" /></div>
        </div>
        <div class="sidebar-text pl-2"><span class="font-14">Settings</span></div>
      </a>
    </div>
    <div class="d-flex column down-box">
      <div class="d-flex flex-column down-bottom-box">
        <div class="d-flex justify-content-center mb-4">
          <div
            *ngIf="userProcess?.length"
            class="bottom-arrow cursor-pointer d-flex justify-content-center align-items-center"
            [ngbPopover]="popContent"
            popoverClass="processPopover"
            triggers="manual"
            (click)="uploadNoti.open()"
            #uploadNoti="ngbPopover"
            (hidden)="setPopover(uploadNoti)"
            (shown)="setPopover(uploadNoti)"
          >
            <span class="badge-red p-0"></span> <i class="material-icons"> arrow_upward </i>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-3 position-relative">
          <div class="user-img cursor-pointer" (click)="popup_custom_toggle()">
            <span class="badge-green"></span>
            <img *ngIf="userImage" class="propic-image" src="{{ userImage }}" alt="" />
            <img *ngIf="!userImage" src="../../../assets/adduser-icon.svg" alt="" />
          </div>
          <div *ngIf="popup_hide" class="popup-custom d-flex flex-column border">
            <div class="border-bottom d-flex align-items-center px-2 py-3">
              <div>
                <img class="propic-image" *ngIf="userImage" src="{{ userImage }}" alt="" />
                <img *ngIf="!userImage" src="../../../assets/adduser-icon.svg" alt="" />
              </div>
              <div class="d-flex flex-column pl-2">
                <span class="font-14 font-weight-bold text-break">
                  {{ currentUser.firstName }} {{ currentUser.lastName }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center cursor-pointer px-3 py-2 menu-items" [routerLink]="['/logout']">
              <span class="font-14">Logout</span>
            </div>
            <a [href]="emailSupport" target="_top">
              <div class="d-flex align-items-center cursor-pointer px-3 py-2 menu-items font-14">Contact Support</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #popContent>
    <div>
      <h5 class="pt-3 color-grey-text font-14 font-hn-medium pb-2">UPLOADS</h5>
      <div class="heightScroll">
        <div *ngFor="let process of userProcess">
          <div
            class="uploading-started p-2 mb-2 d-flex align-items-center justify-content-between"
            *ngIf="process.process_status === 'In Progress'"
          >
            <div class="d-flex">
              <i class="material-icons pr-2"> error_outline </i>
              <span class="text-capatilize font-14">{{ process.process_name }}</span>
            </div>
            <span class="pl-3 font-10">Preparing...</span>
          </div>
          <div
            class="uploading p-2 mb-2 d-flex align-items-center"
            *ngIf="
              process.process_status === 'Processing Records' ||
              process.process_status === 'Adding transactions' ||
              process.process_status === 'Adding Contacts'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> error_outline </i></div>
            <div class="d-flex flex-column position-relative w-100" style="width: 84% !important">
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-14">{{
                  process.process_name === 'contacts_upload' ? 'Contacts' : 'Transactions'
                }}</span>
                <span class="font-10">Processing</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">Added: {{ process.process_data.processed_records }}</span>
                <span class="font-10">Failed: {{ process.process_data.failed_records }}</span>
                <span class="font-10">Total: {{ process.process_data.total_records }}</span>
              </div>
            </div>
          </div>
          <div
            class="uploaded p-2 mb-2 d-flex align-items-center mw-100"
            *ngIf="
              process.process_status === 'Process completed successfully' ||
              process.process_status === 'Upload Process Completed Successfully'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> check </i></div>
            <div class="d-flex flex-column position-relative w-85">
              <span class="close-icon" (click)="dismissItem(process)"><i class="material-icons"> clear </i></span>
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-13">{{ process.process_name }}</span>
                <span class="font-10">Completed</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">Added: {{ process.process_data.processed_records }}</span>
                <span class="font-10">Failed: {{ process.process_data.failed_records }}</span>
                <span class="font-10">Total: {{ process.process_data.total_records }}</span>
                <a
                  class="font-10"
                  (click)="openViewDetail(process.process_id, process.process_name)"
                  *ngIf="process.process_data.failed_records > 0"
                  >View Failed</a
                >
              </div>
            </div>
          </div>
          <div
            class="uploaded-failed p-2 mb-2 d-flex align-items-center"
            *ngIf="
              process.process_status === 'Upload failed due to CSV field name mismatch' ||
              process.process_status === 'Upload Failed due to csv headers mismatch'
            "
          >
            <div class="icon-box mr-2"><i class="material-icons"> clear </i></div>
            <div class="d-flex flex-column position-relative w-100">
              <span class="close-icon" (click)="dismissItem(process)"><i class="material-icons"> clear </i></span>
              <div class="d-flex justify-content-between">
                <span class="text-capatilize font-13">{{ process.process_name }}</span>
                <span class="font-10">Failed</span>
              </div>
              <div class="d-flex justify-content-between" *ngIf="process.process_data">
                <span class="font-10">{{ process.process_status }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <button class="btn p-0 m-0" (click)="dismissAll()"><i class="material-icons pr-2">clear_all</i></button>
      </div>
    </div>
  </ng-template>
</div>
