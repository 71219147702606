import { createReducer, on } from '@ngrx/store';
import * as UserActions from './action';

import { UserState } from './state';

export const initialState: UserState = {
  UserAdd: false,
  UserDeleteLoading: false,
  UserDelete: false,
  UserAddError: null,
  UserActivate: false,
  UserActivateError: '',
  UserDeleteError: null,
  userList: [],
  UserListSuccess: false,
  childList: [],
  childListError: null,
  childListSuccess: null,
  CreditAdd: false,
  CreditAddError: null,
  count: -1,
  addStatus: '',
  milestoneAdd: 'started',
  milestoneAddError: '',
  milestoneList: [],
  milestoneListError: '',
  milestoneListStatus: false,
  customFieldsList: null,
  customFieldsStatus: false,
  customFieldsListError: '',
  customFieldsListEdit: null,
  customFieldsStatusEdit: false,
  customFieldsListErrorEdit: '',
  saveCustomFieldsSuccess: null,
  saveCustomFieldsError: '',
  templateAdd: false,
  templateAddError: '',
  templateList: [],
  templateListError: '',
  templateListStatus: false,
  templateUpdate: false,
  templateUpdateError: '',
  templateDelete: false,
  templateDeleteError: '',
  sms_templateList: [],
  sms_templateListError: '',
  sms_templateListStatus: false,
  mms_templateList: [],
  mms_templateListError: '',
  mms_templateListStatus: false,
  userVcard: [],
  userVcardSuccess: false,
  userVcardError: false,
  ContactSentSms: false,
  ContactSentSmsSuccess: false,
  ContactSentSmsError: null,
  ContactSendingSms: false,
  AddingVcard: false,
  saveLeadNotificationError: null,
  saveLeadNotificationSuccess: null,
  aiSettings: null,
  aiSettingsError: null,
  aiSettingsSuccess: null,
  toggleAiError: null,
  toggleAiLoading: null,
  toggleAiSuccess: null,
  emailGeneraltemplateList: [],
  emailGeneraltemplateListStatus: false,
  emailGeneraltemplateListError: '',
  emailgeneraltemplateDelete: false,
  emailgeneraltemplateDeleteError: '',
  generaltemplateUpdate: false,
  generaltemplateUpdateError: '',
  AddingCredit: false,
  campaignList: [],
  campaignListCount: 0,
  campaignListCurPage: 1,
  campaignUsersCount: 0,
  campaignUsersCurPage: 1,
  campaignUsersList: [],
  campaignListLoading: false,
  campaignUserLoading: false,
  currentCampaignUid: null,
  oldCampPayload: null,
  fileUploading: false,
  extraMergeFields: [],
  HistoryListLoading: false,
  HistoryList: [],
  HistoryListCount: 0,
  HistoryListError: null,
  PurposeListLoading: false,
  PurposeList: null,
  PurposeListError: null,
  RefinancePurposeListLoading: false,
  RefinancePurposeList: null,
  RefinancePurposeListError: null,
  PurchasePurposeListLoading: false,
  PurchasePurposeList: null,
  PurchasePurposeListError: null,
  PackageLoading: false,
  PackageLoadingError: null,
  Package: null,
  statusNames: [],
  intakeForms: [],
  intakeFormsLoading: false,
  intakeFormsSuccess: false,
};

export const UserReducer = createReducer(
  // cannnot be typed or dont know how to?
  initialState,
  // ADD USER
  on(UserActions.AddUser, (state) => ({
    ...state,
  })),
  on(UserActions.setCurrentCampaignUid, (state, payload) => ({
    ...state,
    currentCampaignUid: payload.uid,
    campaignListCount: 0,
    campaignList: [],
  })),
  on(UserActions.setCampaignListCurPage, (state, payload) => ({ ...state, campaignListCurPage: payload.curPage })),
  on(UserActions.GetCampaignList, (state) => ({ ...state, campaignListLoading: true })),
  on(UserActions.GetCampaignUsers, (state, payload) => ({
    ...state,
    campaignUserLoading: true,
    campaignUsersCurPage: payload.curPage,
    oldCampPayload: payload,
  })),
  on(UserActions.CampaignListSuccess, (state, payload) => ({
    ...state,
    campaignListLoading: false,
    campaignListCount: payload.count,
    campaignList: payload.data,
  })),
  on(UserActions.CampaignUsersSuccess, (state, payload) => ({
    ...state,
    campaignUserLoading: false,
    campaignUsersCount: payload.count,
    campaignUsersList: payload.data,
  })),
  on(UserActions.AddUserSuccess, (state) => ({
    ...state,
    UserAdd: true,
    UserAddError: null,
    addStatus: 'success',
  })),
  on(UserActions.AddUserError, (state, payload) => ({
    ...state,
    UserAddError: payload.msg,
    UserAdd: false,
    addStatus: 'failed',
  })),
  on(UserActions.AddCredit, (state) => ({
    ...state,
    AddingCredit: true,
  })),
  on(UserActions.AddCreditSuccess, (state) => ({
    ...state,
    CreditAdd: true,
    CreditAddError: null,
    AddingCredit: false,
  })),
  on(UserActions.AddCreditError, (state, payload) => ({
    ...state,
    CreditAddError: payload.msg,
    CreditAdd: false,
    AddingCredit: false,
  })),
  on(UserActions.ListUser, (state) => ({
    ...state,
    UserListSuccess: false,
  })),
  on(UserActions.SearchUsers, (state) => ({
    ...state,
    UserListSuccess: false,
  })),
  on(UserActions.ListUserSuccess, (state, payload) => {
    return {
      ...state,
      UserListSuccess: true,
      userList: payload.data.data ? payload.data.data : payload.data,
      count: payload.data.count ?? -1,
    };
  }),
  on(UserActions.GetChildListSuccess, (state, payload) => ({
    ...state,
    childListSuccess: true,
    childList: payload.data,
    childListError: null,
  })),
  on(UserActions.GetChildListError, (state, payload) => ({
    ...state,
    childListSuccess: null,
    childListError: payload.msg,
  })),
  // DELETE USER
  on(UserActions.DeleteUser, (state) => ({
    ...state,
    UserDeleteLoading: true,
    UserDelete: false,
    UserDeleteError: null,
    UserDeleteSuccess: true,
  })),
  on(UserActions.DeleteUserSuccess, (state) => ({
    ...state,
    UserDeleteLoading: false,
    UserDelete: true,
    UserDeleteError: null,
    UserDeleteSuccess: true,
  })),
  on(UserActions.DeleteUserError, (state, payload) => ({
    ...state,
    UserDeleteLoading: false,
    UserDelete: false,
    UserDeleteError: payload.msg,
    UserDeleteSuccess: false,
  })),
  // ACTIVATE USER
  on(UserActions.ActivateUser, (state) => ({
    ...state,
  })),
  on(UserActions.ActivateUserSuccess, (state) => ({
    ...state,
    UserActivate: true,
  })),
  on(UserActions.ActivateUserError, (state, payload) => ({
    ...state,
    UserActivate: false,
    UserActivateError: payload.msg,
  })),
  on(UserActions.UserCount, (state, payload) => ({
    ...state,
    count: payload.data,
  })),
  // ADD MILESTONE
  on(UserActions.AddMilestone, (state, payload) => ({
    ...state,
    milestoneAdd: 'started',
  })),
  on(UserActions.AddMilestoneSuccess, (state, payload) => ({
    ...state,
    milestoneAdd: 'added',
  })),
  on(UserActions.AddMilestoneError, (state, payload) => ({
    ...state,
    milestoneAddError: payload.msg,
    milestoneAdd: 'notAdded',
  })),
  // LIST MILESTONE
  on(UserActions.GetMilestone, (state) => ({
    ...state,
    milestoneListStatus: false,
  })),
  on(UserActions.GetMilestoneSuccess, (state, payload) => {
    return {
      ...state,
      milestoneListStatus: true,
      milestoneList: payload.data ? payload.data : [],
      statusNames: payload.statusNames ? payload.statusNames : [],
    };
  }),
  on(UserActions.GetMilestoneError, (state, payload) => ({
    ...state,
    milestoneListStatus: true,
    milestoneListError: payload.msg,
  })),
  // LIST CUSTOM FIELDS
  on(UserActions.GetCustomFieldsSuccess, (state, payload) => {
    let extraMergeFields = [];
    if (payload.user_custom_fields_value) {
      extraMergeFields = Object.entries(payload.user_custom_fields_value)
        .filter(([key, value]) => value && payload.data?.user_custom_fields_value?.some((cf) => cf.backendName === key))
        .map(([key, value]) => key);
    }

    if (payload?.data?.custom_fields_contacts?.length) {
      extraMergeFields.push(
        ...(payload?.data?.custom_fields_contacts.filter((c) => c.includeAsMerge)?.map((c) => c.mergeFieldName) ?? [])
      );
    }
    // adding custom fileds as merge fields
    if (payload?.data?.custom_fields_transaction?.length) {
      extraMergeFields.push(...payload?.data?.custom_fields_transaction.map((c) => c.backendName));
    }
    return {
      ...state,
      customFieldsStatus: true,
      customFieldsList: payload.data
        ? payload.data
        : {
            custom_fields_contacts: [],
            custom_fields_transaction: [],
            uId: payload.uId,
          },
      extraMergeFields,
    };
  }),
  on(UserActions.GetCustomFieldsError, (state, payload) => ({
    ...state,
    customFieldsError: payload.msg,
  })),
  on(UserActions.GetCustomFieldsSuccessEdit, (state, payload) => {
    return {
      ...state,
      customFieldsStatusEdit: true,
      customFieldsListEdit: payload.data
        ? payload.data
        : {
            custom_fields_contacts: [],
            custom_fields_transaction: [],
            uId: payload.uId,
          },
    };
  }),
  on(UserActions.GetCustomFieldsErrorEdit, (state, payload) => ({
    ...state,
    customFieldsErrorEdit: payload.msg,
  })),
  // SAVE CUSTOM FIELDS
  on(UserActions.SaveCustomFieldsSuccess, (state, payload) => {
    return {
      ...state,
      saveCustomFieldsSuccess: true,
      customFieldsListEdit: payload.data,
    };
  }),
  on(UserActions.SaveCustomFieldsError, (state, payload) => {
    return {
      ...state,
      saveCustomFieldsSuccess: false,
      saveCustomFieldsError: payload.msg,
    };
  }),

  // Intake Forms
  on(UserActions.GetAllIntakeForms, (state, payload) => {
    return {
      ...state,
      intakeFormsLoading: true,
    };
  }),
  on(UserActions.GetAllIntakeFormsSuccess, (state, payload) => {
    return {
      ...state,
      intakeForms: payload.data,
      intakeFormsLoading: false,
      intakeFormsSuccess: true,
    };
  }),
  on(UserActions.GetAllIntakeFormsError, (state, payload) => {
    return {
      ...state,
      intakeForms: [],
      intakeFormsLoading: false,
      intakeFormsSuccess: false,
    };
  }),

  // ADD TEMPLATE
  on(UserActions.AddTemplate, (state, payload) => ({
    ...state,
  })),
  on(UserActions.AddTemplateSuccess, (state, payload) => ({
    ...state,
    templateAdd: true,
  })),
  on(UserActions.AddTemplateError, (state, payload) => ({
    ...state,
    templateAddError: payload.msg,
  })),
  // LIST TEMPLATE
  on(UserActions.GetTemplate, (state) => ({
    ...state,
  })),
  on(UserActions.GetTemplateSuccess, (state, payload) => {
    return {
      ...state,
      templateListStatus: true,
      templateList: payload.data ? payload.data : [],
    };
  }),
  on(UserActions.GetTemplateError, (state, payload) => ({
    ...state,
    templateListError: payload.msg,
  })),
  // UPDATE TEMPLATE
  on(UserActions.UpdateTemplate, (state, payload) => ({
    ...state,
  })),
  on(UserActions.UpdateTemplateSuccess, (state, payload) => ({
    ...state,
    templateUpdate: true,
  })),
  on(UserActions.UpdateTemplateError, (state, payload) => ({
    ...state,
    templateUpdateError: payload.msg,
  })),
  // DELETE TEMPLATE
  on(UserActions.DeleteTemplate, (state, payload) => ({
    ...state,
  })),
  on(UserActions.DeleteTemplateSuccess, (state, payload) => ({
    ...state,
    templateDelete: true,
  })),
  on(UserActions.DeleteTemplateError, (state, payload) => ({
    ...state,
    templateDeleteError: payload.msg,
  })),
  // LIST SMS TEMPLATE
  on(UserActions.GetSMSTemplate, (state) => ({
    ...state,
  })),
  on(UserActions.GetSMSTemplateSuccess, (state, payload) => {
    return {
      ...state,
      sms_templateListStatus: true,
      sms_templateList: payload.data ? payload.data : [],
    };
  }),
  on(UserActions.GetSMSTemplateError, (state, payload) => ({
    ...state,
    sms_templateListError: payload.msg,
  })),
  // List Mms Templates
  on(UserActions.GetMmsTemplate, (state) => ({
    mms_templateListError: null,
    ...state,
  })),
  on(UserActions.GetMMSTemplateSuccess, (state, payload) => ({
    ...state,
    mms_templateListStatus: true,
    mms_templateListError: null,
    mms_templateList: payload.data ? payload.data : [],
  })),
  on(UserActions.GetMMSTemplateError, (state, payload) => ({
    ...state,
    mms_templateListError: payload.msg,
  })),

  on(UserActions.AddVcard, (state, payload) => ({
    ...state,
    AddingVcard: true,
  })),
  on(UserActions.AddVcardStarted, (state, payload) => ({
    ...state,
    AddingVcard: true,
  })),
  on(UserActions.AddVcardSuccess, (state, payload) => ({
    ...state,
    userVcard: payload.data,
    userVcardSuccess: true,
    userVcardError: false,
    AddingVcard: false,
  })),
  on(UserActions.AddVcardError, (state, payload) => ({
    ...state,
    userVcardSuccess: false,
    userVcardError: payload.msg,
    AddingVcard: false,
  })),
  on(UserActions.ContactSentSms, (state) => ({
    ...state,
    ContactSentSmsSuccess: false,
    ContactSendingSms: true,
  })),
  on(UserActions.ContactSentSmsSuccess, (state) => ({
    ...state,
    ContactSentSmsSuccess: true,
    ContactSentSmsError: null,
    ContactSendingSms: false,
  })),
  on(UserActions.ContactSentSmsError, (state, payload) => ({
    ...state,
    ContactSentSmsSuccess: false,
    ContactSentSmsError: payload.msg,
    ContactSendingSms: false,
  })),
  on(UserActions.saveLeadNotification, (state) => ({
    ...state,
    saveLeadNotificationError: null,
    saveLeadNotificationSuccess: null,
  })),
  on(UserActions.saveLeadNotificationSuccess, (state) => ({
    ...state,
    saveLeadNotificationError: null,
    saveLeadNotificationSuccess: true,
  })),
  on(UserActions.saveLeadNotificationError, (state, payload) => ({
    ...state,
    saveLeadNotificationError: payload.msg,
    saveLeadNotificationSuccess: null,
  })),
  on(UserActions.getAiSettings, (state) => ({
    ...state,
    aiSettings: null,
    aiSettingsError: null,
    aiSettingsSuccess: null,
  })),
  on(UserActions.getAiSettingsSuccess, (state, payload) => ({
    ...state,
    aiSettings: payload.data,
    aiSettingsError: null,
    aiSettingsSuccess: true,
  })),
  on(UserActions.getAiSettingsError, (state, payload) => ({
    ...state,
    aiSettings: null,
    aiSettingsError: payload.msg,
    aiSettingsSuccess: null,
  })),
  on(UserActions.toggleAi, (state) => ({
    ...state,
    toggleAiError: null,
    toggleAiLoading: true,
    toggleAiSuccess: null,
  })),
  on(UserActions.toggleAiSuccess, (state) => ({
    ...state,
    toggleAiError: null,
    toggleAiLoading: null,
    toggleAiSuccess: true,
  })),
  on(UserActions.toggleAiError, (state, payload) => ({
    ...state,
    toggleAiError: payload.msg,
    toggleAiLoading: null,
    toggleAiSuccess: null,
  })),

  on(UserActions.GetEmailGeneralTemplate, (state) => ({
    ...state,
  })),
  on(UserActions.GetEmailGeneralTemplateSuccess, (state, payload) => {
    return {
      ...state,
      emailGeneraltemplateListStatus: true,
      emailGeneraltemplateList: payload.data ? payload.data : [],
    };
  }),
  on(UserActions.GetEmailGeneralTemplateError, (state, payload) => ({
    ...state,
    emailGeneraltemplateListError: payload.msg,
  })),

  on(UserActions.DeleteEmailGeneralTemplate, (state, payload) => ({
    ...state,
  })),
  on(UserActions.DeleteEmailGeneralTemplateSuccess, (state, payload) => ({
    ...state,
    emailgeneraltemplateDelete: true,
  })),
  on(UserActions.DeleteEmailGeneralTemplateError, (state, payload) => ({
    ...state,
    emailgeneraltemplateDeleteError: payload.msg,
  })),

  on(UserActions.UpdateGeneralTemplate, (state, payload) => ({
    ...state,
  })),
  on(UserActions.UpdateGeneralTemplateSuccess, (state, payload) => ({
    ...state,
    generaltemplateUpdate: true,
  })),
  on(UserActions.UpdateGeneralTemplateError, (state, payload) => ({
    ...state,
    generaltemplateUpdateError: payload.msg,
  })),
  on(UserActions.fileupload, (state, payload) => ({
    ...state,
    fileUploading: payload.start,
  })),
  on(UserActions.GetHistory, (state, payload) => ({
    ...state,
    HistoryListLoading: true,
    lastPayload: payload,
  })),
  on(UserActions.GetHistorySuccess, (state, payload) => {
    const data: any[] = [...payload.data];
    return {
      ...state,
      HistoryListLoading: false,
      HistoryList: data,
      HistoryListCount: payload.count,
      count: payload.count,
    };
  }),
  on(UserActions.GetHistoryError, (state, payload) => {
    return {
      ...state,
      HistoryListLoading: false,
      HistoryListError: payload.msg,
    };
  }),
  on(UserActions.GetRefinancePurpose, (state, payload) => ({
    ...state,
    RefinancePurposeListLoading: true,
  })),
  on(UserActions.GetRefinancePurposeSuccess, (state, payload) => {
    return {
      ...state,
      RefinancePurposeListLoading: false,
      RefinancePurposeList: payload.data,
    };
  }),
  on(UserActions.GetRefinancePurposeError, (state, payload) => {
    return {
      ...state,
      RefinancePurposeListLoading: false,
      RefinancePurposeListError: payload.msg,
    };
  }),
  on(UserActions.GetPurchasePurpose, (state, payload) => ({
    ...state,
    PurchasePurposeListLoading: true,
  })),
  on(UserActions.GetPurchasePurposeSuccess, (state, payload) => {
    return {
      ...state,
      PurchasePurposeListLoading: false,
      PurchasePurposeList: payload.data,
    };
  }),
  on(UserActions.GetPurchasePurposeError, (state, payload) => {
    return {
      ...state,
      PurchasePurposeListLoading: false,
      PurchasePurposeListError: payload.msg,
    };
  }),

  on(UserActions.GetPackage, (state, payload) => ({
    ...state,
    PackageLoading: true,
  })),
  on(UserActions.GetPackageSuccess, (state, payload) => {
    return {
      ...state,
      PackageLoading: false,
      Package: payload.data,
    };
  }),
  on(UserActions.GetPackageError, (state, payload) => {
    return {
      ...state,
      PackageLoading: false,
      PackageError: payload.msg,
    };
  })
);
