<div
  class="media-layout pb-3 pt-4 v2-ui-integretion"
  [ngClass]="{
    'drip-media-height': url.includes('drip-campaign') || url.includes('contacts' ) || url.includes('settings/milestones'),
    'media-height': url.includes('recording')
  }"
>
  <div class="d-flex justify-content-center align-items-center no-files" *ngIf="audioList.length == 0">
    <span class="font-hn-medium font-16 color-black-text">There are no recorded files</span>
  </div>
  <div class="d-flex flex-wrap">
    <div
      class="video-card d-flex flex-column mb-2"
      *ngFor="let items of (audioList$ | async | filter: searchText); let i = index"
      [ngClass]="{
        'col-12 col-sm-6 col-md-4': url.includes('drip-campaign') || url.includes('contacts') || url.includes('settings/milestones'),
        'col-12 col-sm-6 col-md-3': url.includes('recording')
      }"
    >
      <div
        class="box-card shadow cursor-pointer position-relative d-flex flex-column p-3"
        [ngClass]="displayNone[i] === 1 ? 'border-line' : ''"
        (click)="selectAudio(i, items)"
      >
        <div class="select-box" [style.display]="displayNone[i] === 1 ? 'block' : 'none'">
          <i class="material-icons"> check_circle </i>
        </div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="record-icon">
            <i class="material-icons" [ngClass]="icons[i] == 'pause' ? 'rec' : ''"> mic_none </i>
          </div>
          <div class="audio-player ml-2">
            <ngb-progressbar type="primary" [value]="percentage[i]"></ngb-progressbar>
            <audio id="audio{{ i }}" (ended)="audEnded(i)" (timeupdate)="updateTime(i)" [src]="items.url"></audio>
          </div>
          <div
            class="play-icon ml-2 d-flex justify-content-center align-items-center cursor-pointer"
            (click)="startAudio(i); $event.stopPropagation()"
          >
            <i class="material-icons"> {{ icons[i] }} </i>
          </div>
        </div>
        <div class="duration-box font-10">
          <span>{{ items.duration }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center py-2 video-details">
        <span class="font-14 text-capitalize">{{ items.file_name }}</span>
      </div>
    </div>
  </div>
</div>
