<div class="px-2 pb-3 v2-ui-integretion">
  <ng-container>
    <div class="modal-header pb-0">
      <h4 class="color-grey-text text-center text-uppercase px-2">Change Profile</h4>
      <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <i class="material-icons icon-color"> close </i>
      </button>
    </div>
  </ng-container>
  <div class="modal-body">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="column my-3 position-relative">
        <div *ngIf="!imageUrl[0]"><img src="{{ currentImage }}" class="profileImage2" alt="" /></div>
        <div *ngIf="imageUrl[0]"><img [src]="imageUrl[0]" class="profileImage2" alt="" /></div>
        <div
          *ngIf="!loading"
          class="upload-icon rounded-circle bgc-primary align-items-center justify-content-center d-flex"
        >
          <img src="../../../../../assets/icons/camera.svg" alt="upload" />
          <input type="file" (change)="changeImage($event)" accept=".jpeg,.jpg,.png" />
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-3 bottom-btn">
        <button type="button" class="btn btn-normal mr-2 px-4" (click)="closeModal()">Cancel</button>
        <button
          type="button"
          class="btn btn-primary px-4"
          [disabled]="loading"
          (click)="changeProfileImage()"
        >
          {{ saveButtonLabel }}
          <span
            [ngClass]="{
                  'spinner-border spinner-border-sm': loading,
                  nil: !loading
                }"
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
