<div class="position-relative call-popup-drag" *ngIf="hide" cdkDragBoundary=".dashboard" cdkDrag>
  <div class="call-popup-body d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-column justify-content-start w-50">
      <span class="font-14 font-hn-medium text-capitalize  color-text-black">{{
        contactDetails?.firstName + ' ' + contactDetails?.lastName
      }}</span>
      <span class="font-22 font-hn-medium font-lato-light color-text-black" *ngIf="callModalStatus !== 'connected'">{{
        callModalStatus
      }}</span>
      <div class="font-22 font-hn-medium font-lato-light color-text-black" *ngIf="callModalStatus == 'connected'">
        <span>{{ minutesLabel }}</span
        ><span class="px-1">:</span> <span>{{ secondsLabel }}</span>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between btns-area w-50">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="btn-call-area" (click)="minimizeCallBox(callModal)">
          <img src="../../../../assets/icons/icon_3.svg" alt="" />
        </div>
        <span class="color-text-black font-10 font-lato-regular pt-1">Maximize</span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="btn-call-area"><img src="../../../../assets/icons/icon_2.svg" alt="" /></div>
        <span class="color-text-black font-10 font-lato-regular pt-1">Mute</span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="btn-call-area btn-call-drop" (click)="disconnectCall()">
          <img src="../../../../assets/icons/call_white.svg" alt="" />
        </div>
        <span class="color-text-black font-10 font-lato-regular pt-1">Decline</span>
      </div>
    </div>
  </div>
</div>
<ng-template #callModal let-modal class="modal call-modal">
  <div class="d-flex flex-column position-relative call-popup">
    <dailyai-hidden-input></dailyai-hidden-input>
    <div class="call-popup-header">
      <div class="call-animation d-flex justify-content-center align-items-center">
        <img class="img-circle" src="../../../../assets/icons/icon_1.svg" alt="" />
      </div>
    </div>
    <div class="call-popup-body d-flex flex-column justify-content-center align-items-center">
      <span class="font-18 font-hn-medium text-capitalize pt-2">{{
        contactDetails?.firstName + ' ' + contactDetails?.lastName
      }}</span>
      <span class="font-32 font-hn-medium font-lato-light pt-2" *ngIf="callModalStatus !== 'connected'">{{
        callModalStatus
      }}</span>
      <div
        class="d-flex flex-row justify-content-center font-32 font-hn-medium font-lato-light pt-2 color-text-black"
        *ngIf="callModalStatus == 'connected'"
      >
        <span>{{ minutesLabel }}</span
        ><span class="px-1">:</span> <span>{{ secondsLabel }}</span>
      </div>
      <div class="d-flex justify-content-between w-100 px-5 btns-area">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="btn-call-area" (click)="minimizeCallBox()">
            <img src="../../../../assets/icons/icon_3.svg" alt="" />
          </div>
          <span class="color-text-black font-12 font-lato-regular pt-1">Minimize</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="btn-call-area"><img src="../../../../assets/icons/icon_2.svg" alt="" /></div>
          <span class="color-text-black font-12 font-lato-regular pt-1">Mute</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="btn-call-area btn-call-drop" (click)="disconnectCall()">
            <img src="../../../../assets/icons/call_white.svg" alt="" />
          </div>
          <span class="color-text-black font-12 font-lato-regular pt-1">Decline</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
