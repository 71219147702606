import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { LoginState } from 'src/app/modules/login/store/state';
import { UserService } from 'src/app/modules/settings/services/user.service';
import { SharedService } from '../../services/shared.service';
import { EmailConfirmationComponent } from '../email-confirmation/email-confirmation.component';


@Component({
  selector: 'dailyai-email-change-modal',
  templateUrl: './email-change-modal.component.html',
  styleUrls: ['./email-change-modal.component.scss'],
})
export class EmailChangeModalComponent implements OnInit, OnDestroy {

  changeEmailForm: FormGroup;

  changeEmailConfirmForm: FormGroup;

  @Input() fromParent;

  oldEmail: any;

  newOld

  isShown = false;

  newEmail: any;

  timeLeft = 600;

  interval;

  newMails: any;

  verificationCode: any;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  currentUser$: Observable<any>;

  currentUser: any;

  emailChangeRequestedOnTime = 0;

  loading: boolean;

  emailCh: any;



  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private store: Store<LoginState>,
  ) {
    this.changeEmailForm = this.createEmailFormGroup();
    this.changeEmailConfirmForm = this.createEmailConfirmFormGroup();


  }

  ngOnInit(): void {
    this.oldEmail = this.fromParent.prop1.email;
    this.newOld = this.oldEmail;
    this.currentUser$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectCurrentUser));
    this.currentUser$.subscribe(data => {
      this.currentUser = data.uid;
    })

    this.sharedService
      .changeUserEmail(this.currentUser).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data?.emailChangeRequestedOn) {
          const emailChangedRequestOn = new Date(data?.emailChangeRequestedOn).getTime();
          const currentDateTime = Date.now();
          const differenceInSeconds = (currentDateTime - emailChangedRequestOn) / 1000;
          this.timeLeft = 600 - differenceInSeconds;

        } else {
          this.timeLeft = 600;
          this.emailChangeRequestedOnTime = 0
        }
      });

  }

  createEmailFormGroup() {
    return new FormGroup({
      new_email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  createEmailConfirmFormGroup() {
    return new FormGroup({
      new_passcode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });
  }

  timerString = "10:00"

  timer() {
    this.interval && clearInterval(this.interval);
    this.timerString = "10:00"
    this.interval = setInterval(() => {
      this.timeLeft = Math.ceil(this.timeLeft);
      if (this.timeLeft > 0) {


        this.timeLeft--;


      } else if (this.timeLeft <= 0) {
        this.timeLeft = 0;
      }
      const min = Math.floor(this.timeLeft / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
      const sec = (this.timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 }).slice(0, 2);
      this.timerString = `${min}:${sec}`;


    }, 1000)
  }


  async addEmail() {

    this.loading = true;
    this.newMails = this.changeEmailForm.value;
    this.newEmail = this.changeEmailForm.value.new_email;
    const purpose = "generatePassCode"
    const res = await this.userService.sendPasscode(purpose, this.newEmail);

    if (res['err'] === false) {
      this.loading = false;
      this.toastr.success('Verification Code Sent', 'Success');
      this.isShown = true;
      this.timer()
      this.changeEmailForm.reset();
    } else {
      this.isShown = false;
      this.loading = false;
      if (`${res['toast']}` === null) {
        this.toastr.error(`${res['msg']}`, 'Error Message');
      } else {
        this.toastr.error(`${res['toast']}`, 'Error Message');
      }
    }

  }

  verifyEmail: any;

  async resendCode() {
    const purpose = "generatePassCode"
    const res = await this.userService.sendPasscode(purpose, this.newMails.new_email);
    if (res['err'] === false) {
      this.toastr.success('Verification Code Sent', 'Success');
    } else if (`${res['toast']}` === null) {
        this.toastr.error(`${res['msg']}`, 'Error Message');
      } else {
        this.toastr.error(`${res['toast']}`, 'Error Message');
      }
  }

  async confirmEmail() {
    this.verificationCode = this.changeEmailConfirmForm.value.new_passcode;
    const modalRefs = this.ngbModal.open(EmailConfirmationComponent, {
      centered: true,
      windowClass: 'call-popup',
      backdrop: 'static',
      keyboard: false,
      size: 'sm'
    });
    const data = {
      prop1: this.verificationCode
    }
    modalRefs.componentInstance.fromConfirmation = data;
  }

  closeModal() {
    this.activeModal.dismiss();
    this.interval && clearInterval(this.interval)
  }

  closeModals(all?: boolean) {
    this.activeModal.dismiss();
    if (all) {
      this.ngbModal.dismissAll();
      this.interval && clearInterval(this.interval);
    }
  }

  changeMail() {
    this.interval && clearInterval(this.interval);
    this.isShown = false;
  }

  get new_email() {
    return this.changeEmailForm.get('new_email');
  }

  get new_passcode() {
    return this.changeEmailForm.get('new_passcode');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
