import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DataTransactionService {
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private toast: ToastrService,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  /**
   *
   * @param userId id of the user
   * @param dataUrl blob object of the video file
   * @param type type of file video/audio
   * @param duration duration of the recording
   * @param fileName name of the file
   * @param thumbUrl thumbnail link
   */

  async uploadFile(
    userId: string,
    dataUrl: string,
    type: string,
    duration?: number | string,
    fileName?: string,
    thumbUrl?: string
  ) {
    try {
      let imgUrl;
      this.toast.info('Please wait, Media saving is in progress..', 'Media Saving', {
        closeButton: true,
        disableTimeOut: true,
      });
      if (type === 'video') {
        const thumbPath = `user_recordings/${userId}/${fileName}-img`;
        const imgRef = this.storage.ref(thumbPath);
        const task1 = await imgRef.putString(thumbUrl, 'data_url', { contentType: 'image/jpg' });
        imgUrl = await task1.ref.getDownloadURL();
      }
      const path = `user_recordings/${userId}/${fileName}`;
      const ref = this.storage.ref(path);
      const task = await this.storage.upload(path, dataUrl);
      const videoUrl = await task.ref.getDownloadURL();
      const metaData = await task.ref.getMetadata();
      const newFileRef = await this.db
        .collection<any>('user_recordings')
        .add({ time: new Date(), thumbnail_actual: imgUrl ?? null });
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.auth.getUserToken()}`,
          'Access-Control-Allow-Origin': 'http://localhost:4200/',
        }),
      };
      let res: any = null;
      if (type === 'video') {
        res = await this.http
          .post(
            `${environment.cloud_base_url}/videoConverter`,
            { filename: fileName, uid: userId, url: videoUrl, doc_id: newFileRef.id },
            httpOptions
          )
          .toPromise();
      }
      const obj = {
        url: type === 'video' ? res.data.url[0].mediaLink : videoUrl,
        file_name: type === 'video' ? `${fileName}.mp4` : `${fileName}`,
        type: type === 'video' ? res.data.url[0].contentType : 'audio/wav',
        uid: userId,
        duration,
        doc_id: newFileRef.id,
      };
      if (!res || res.err === false) {
        this.toast.clear();
        this.toast.success('Media saved Successfully');
      } else {
        this.toast.clear();
        this.toast.error('Error in saving video');
      }
      return await newFileRef.update(obj).then().catch();
    } catch (error) {
      console.warn(error);
    }
  }

  getAudioList(userId) {
    return this.db
      .collection<any>('user_recordings', (ref) =>
        ref.where('uid', '==', userId).where('type', '==', 'audio/wav').orderBy('file_name', 'asc')
      )
      .valueChanges();
  }

  async deleteFile(list) {
    try {
      for (const item of list) {
        await this.db.collection<any>('user_recordings').doc(item.doc_id).delete();
        if (item.type === 'video/mp4') {
          const videoRef = this.storage.ref('user_recordings').child(`${item.uid  }/${  item.file_name}`);
          await videoRef.delete();
          if (item.thumbnail) {
            const thumb = this.storage
              .ref('video_gif')
              .child(`${item.uid  }/${  item.file_name.slice(0, Math.max(0, item.file_name.lastIndexOf('.')))  }.gif`);
            await thumb.delete();
          }
          if (item.thumbnail_actual) {
            const thumb = this.storage.storage.refFromURL(item.thumbnail_actual);
            await thumb.delete();
          }
        } else {
          const ref = this.storage.storage.refFromURL(item.url);
          await ref.delete();
          if (item.thumbnail) {
            const thumb = this.storage.storage.refFromURL(item.thumbnail);
            await thumb.delete();
          }
        }
      }
      this.toast.success('Media Removed Successfully');
    } catch (error) {
      this.toast.success('Error Removing Media Successfully');
    }
  }
}
