import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dailyai-noting-to-show',
  templateUrl: './noting-to-show.component.html',
  styleUrls: ['./noting-to-show.component.scss']
})
export class NotingToShowComponent implements OnInit {
  constructor() {}

  @Input() content;

  ngOnInit() {}
}
