import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { selectEmailGeneralTemplateList } from 'src/app/modules/settings/store/selector';
import { GetEmailGeneralTemplate } from 'src/app/modules/settings/store/action';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'dailyai-share-add-template',
  templateUrl: './share-add-template.component.html',
  styleUrls: ['./share-add-template.component.scss'],
})
export class ShareAddTemplateComponent implements OnInit, OnDestroy {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  currentUser: User;

  currentUser$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  userData;

  GetEmailGeneralTemplate$: Observable<any>;

  genEmailTempList = [];

  selectedTemplate;

  constructor(private modalService: NgbModal, private store: Store<any>, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data && data.uid) {
        this.userData = data;
      }
    });
    this.store.dispatch(GetEmailGeneralTemplate({ uid: this.userData.uid }));
    this.GetEmailGeneralTemplate$ = this.store.pipe(select(selectEmailGeneralTemplateList));
    this.GetEmailGeneralTemplate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tempList) => {
      if (tempList) {
        this.genEmailTempList = JSON.parse(JSON.stringify(tempList));
        this.genEmailTempList.sort((a, b) => {
          return <any>new Date(a.created_at) - <any>new Date(b.created_at);
        });
        this.selectedTemplate = this.genEmailTempList[0];
      }
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  selectedTemp(template) {
    this.selectedTemplate = template;
  }

  useThisTemplate(selectedTemplate) {
    this.passBack(selectedTemplate);
    this.activeModal.close();
  }

  passBack(item) {
    this.passEntry.emit(item);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
