<ng-container class="modal">
  <div class="modal-body m-3 p-0 v2-ui-integretion">
    <div class="font-18 font-w-400 text-uppercase mx-3 pt-3 pb-2">Change phone number</div>
    <div class="d-flex flex-column mx-3 bottom-dashed-border pb-3">
      <span class="font-14 text-common">Your current number</span>
      <span class="font-14 color-secondary font-w-500">{{ oldPhone | phoneNumberFormat }}</span>
    </div>
    <div class="mx-3 pt-3">
      <form [formGroup]="changePhoneNumberForm" autocomplete="none">
        <div class="mb-3">
          <label for="number" class="font-14 color-black-grey-text">Enter new number</label>
          <div class="position-relative" style="height: 44px">
            <input
              class="form-control"
              type="text"
              [appOnlyNumber]="true"
              maxlength="10"
              formControlName="new_number"
              placeholder="1234567890"
              [ngClass]="{ 'border-danger': !changePhoneNumberForm.valid }"
            />
            <span
              *ngIf="(new_number?.dirty || new_number?.touched) && new_number?.invalid && new_number?.errors?.required"
              class="color-danger font-13 error-input error-message1"
            >
              Phone number is required
            </span>
            <span
              *ngIf="(new_number?.dirty || new_number?.touched) && new_number?.invalid && new_number?.errors?.pattern"
              class="color-danger font-13 error-input error-message2"
            >
              Invalid phone number
            </span>
          </div>
        </div>
        <span class="font-14 mt-2 color-black-grey-text">Update in</span>
        <div class="d-flex py-2 align-items-center">
          <div class="check-box">
            <label class="v2-checkbox mb-0 p-0">
              <input type="checkbox" formControlName="vCard" />
              <span class="checkmark"></span>
            </label>
            <span class="pl-4 font-14">V-Card</span>
          </div>
          <div class="check-box ml-4">
            <label class="v2-checkbox mb-0 p-0">
              <input type="checkbox" formControlName="website" />
              <span class="checkmark"></span>
            </label>
            <span class="pl-4 font-14">Website</span>
          </div>
        </div>
        <div class="px-3 py-2 my-2 bg-gray-light rounded-sm">
          <span class="font-14 text-common">
            <p class="text-common">
              Once the phone number is verified, all future outgoing and incoming communications (including SMS, MMS and
              phone calls) will use this new phone number
            </p>
          </span>
        </div>
        <div class="d-flex justify-content-end pt-4 pb-3">
          <button (click)="cancel()" type="button" class="btn btn-normal mr-2 px-4" [disabled]="loading">Cancel</button>
          <button
            *ngIf="!loading"
            type="button"
            class="btn btn-primary px-4 ml-2"
            [disabled]="!changePhoneNumberForm.valid"
            (click)="otpVerify()"
          >
            Call to Verify
          </button>
          <button *ngIf="loading" [disabled]="loading" class="btn btn-primary px-4 ml-2">
            Call to Verify<span
              [ngClass]="{
                nil: !loading
              }"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
