import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppService } from 'src/app/shared/app.service';
import { switchMap, map, catchError, takeUntil } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { DashboardService } from '../service/dashboard.service';
import {
  LoadingCommunications,
  LoadCommunicationSuccess,
  LoadCommunicationError,
  ListTask,
  ListOpenTaskSuccess,
  ListDueTaskSuccess,
  ListContactActivity,
  ListAllContactActivity,
  ListCallContactActivity,
  ListMsgContactActivity,
  ListMailContactActivity,
  ListContactNurture,
  ListContactNurtureBirthday,
  ListContactNurtureAnniversary,
  ListAllTaskSuccess
} from './action';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private dashService: DashboardService,
    private store$: Store<State>,
    private subService: SubscriptionService
  ) { }

  loadCommunications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadingCommunications),
      switchMap(payload =>
        this.dashService.loadUserCommunication(payload.userId).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return LoadCommunicationSuccess(res);
          }),
          catchError(err => {
            return of(LoadCommunicationError({ msg: err }));
          })
        )
      )
    )
  );

  ListOPenTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListTask),
      switchMap(payload =>
        this.dashService.getOpenTasks(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListOpenTaskSuccess({ data: res });
          })
        )
      )
    )
  );

  ListDueTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListTask),
      switchMap(payload =>
        this.dashService.getDueTasks(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListDueTaskSuccess({ data: res });
          })
        )
      )
    )
  );

  LisAllTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListTask),
      switchMap(payload =>
        this.dashService.getAllTasks(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListAllTaskSuccess({ data: res });
          })
        )
      )
    )
  );

  ListAllContactActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactActivity),
      switchMap(payload =>
        this.dashService.getAllContactActivity(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListAllContactActivity({ data: res });
          })
        )
      )
    )
  );

  ListCallContactActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactActivity),
      switchMap(payload =>
        this.dashService.getCallContactActivity(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListCallContactActivity({ data: res });
          })
        )
      )
    )
  );

  ListMsgContactActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactActivity),
      switchMap(payload =>
        this.dashService.getMsgContactActivity(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListMsgContactActivity({ data: res });
          })
        )
      )
    )
  );

  ListMailContactActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactActivity),
      switchMap(payload =>
        this.dashService.getMailContactActivity(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListMailContactActivity({ data: res });
          })
        )
      )
    )
  );

  ListContactNurtureBirthday$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactNurture),
      switchMap(payload =>
        from(this.dashService.getContactWithTodaysBirthday(payload.id, payload.user)).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListContactNurtureBirthday({ data: res });
          })
        )

      )
    )
  );

  ListContactNurtureAnniversary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListContactNurture),
      switchMap(payload =>
        from(this.dashService.getContactWithTodaysAnniversary(payload.id, payload.user)).pipe(
          takeUntil(this.subService.unsubscribe$),
          map(res => {
            return ListContactNurtureAnniversary({ data: res });
          })
        )
      )
    )
  );
}
