import { createAction, props } from '@ngrx/store';
import { ContactDetail } from './models';

export const AddContact = createAction(
  '[Contacts Page] Adding Contact',

  props<{ data: ContactDetail; task: any }>()
);
export const BatchAddContactToDrips = createAction(
  '[Conatact Details Page] BatchAddContactsToDrip',
  props<{ contact_Ids: string[]; drip_campaigns: string[] ,changedBy?:string}>()
);
export const BatchAddContactToDripsSuccess = createAction('[Contacts Page] BatchAddContact To Drips Success');
export const BatchAddContactToDripsError = createAction(
  '[Contacts Page] BatchAddContact To Drips Error',
  props<{ msg: any }>()
);
export const AddContactToDrips = createAction(
  '[Conatact Details Page] AddContactsToDrip',
  props<{ contact_id: string; drip_campaigns: string[] ,changedBy?:string , method?:string}>()
);
export const AddContactToDripsSuccess = createAction('[Contacts Page] AddContact To Drips Success');
export const AddContactToDripsError = createAction('[Contacts Page] AddContact To Drips Error', props<{ msg: any }>());
export const RemoveContactFromDrips = createAction(
  '[Conatact Details Page] RemoveContactsFromDrip',
  props<{ contact_id: string; drip_campaigns: string[] , changedBy?:string , method?:string }>()
);
export const RemoveContactFromDripsSuccess = createAction('[Contacts Page] Remove Contact From Drips Success');
export const RemoveContactFromDripsError = createAction(
  '[Contacts Page] Remove Contact From Drips Error',
  props<{ msg: any }>()
);
export const AddContactSuccess = createAction('[Contacts Page] AddContact Success');
export const AddContactError = createAction('[Contacts page] AddContact Error', props<{ msg: string }>());
export const DeleteContact = createAction(
  '[Contacts Page] Delete Contact',
  props<{
    uId: string;
  }>()
);
export const DeleteContactSuccess = createAction('[Contacts Page] DeleteContact Success');
export const DeleteContactError = createAction('[Contacts page] DeleteContact Error', props<{ msg: string }>());

export const UpdateContact = createAction(
  '[Contacts Page] Update Contact',
  props<{ data: ContactDetail; oldContact?: any }>()
);
export const UpdateContactSuccess = createAction('[Contacts Page] UpdateContact Success');
export const UpdateContactError = createAction('[Contacts page] UpdateContact Error', props<{ msg: string }>());

export const GetContactDetail = createAction('[Contacts] Get Contact Detail ', props<{ id: string }>());
export const GetContactDetailSuccess = createAction(
  '[Contacts] Get Contact Detail Success',
  props<{ detail: ContactDetail }>()
);
export const GetContactDetailError = createAction('[Contacts] Get Contact Detail Error', props<{ msg: string }>());
export const ListContact = createAction(
  '[Contacts Page] List Contacts  ',
  props<{ id: string; curPage?: any; count?: any }>()
);
export const ListContactSuccess = createAction(
  '[Contacts Page] ListContactSuccess',
  props<{ data: any; count?: any; contact_ids?: any; search?: boolean }>()
);
export const ListSearchSuccess = createAction(
  '[Contacts Page] List Search Contacts NgbTypeahead',
  props<{ data: any; count?: any; search?: boolean }>()
);
export const ListContactError = createAction('[Contacts page] List Contact Error', props<{ msg: string }>());
export const ClearForm = createAction('[Contacts Page] Clear Form');

export const ContactFileAdd = createAction(
  '[Contacts Page]  Contact File Add  ',
  props<{ contactId: string; title: string; comment: string; imageUrl: any; upload_by: string; fileName: string }>()
);
export const ContactFileAddSuccess = createAction('[Contacts Page]Contact File Add Success');
export const ContactFileAddError = createAction('[Contacts page] Contact File Add Error', props<{ msg: string }>());
export const UploadCsv = createAction(
  '[Contacts Page] Upload Csv',
  props<{ id: string; file: any; assigned_to?: string; parentId?: string; optout?: boolean;groupId?: string[],selectedDripIds?:string[] }>()
);
export const UploadCsvSuccess = createAction('[Contacts Page] Upload Csv Success');
export const UploadCsvError = createAction('[Contacts page] Upload Csv Error', props<{ msg: string }>());

export const GetContactFiles = createAction('[Contacts Page] Get Contacts Files  ', props<{ id: string }>());
export const GetContactFilesSuccess = createAction(
  '[Contacts Page] Get Contacts Files Success',
  props<{ data: any }>()
);
export const GetContactFilesError = createAction('[Contacts page] Get Contacts Files Error', props<{ msg: string }>());

export const DeleteContactFile = createAction(
  '[Contacts Page] Delete Contact File',
  props<{
    doc_id: string;
    image: string;
  }>()
);
export const DeleteContactFileSuccess = createAction('[Contacts Page] Delete Contact File Success');
export const DeleteContactFileError = createAction(
  '[Contacts page] Delete Contact File Error',
  props<{ msg: string }>()
);

export const UpdateContactFile = createAction(
  '[Contacts Page] Update Contact File',
  props<{
    id: string;
    file: object;
  }>()
);
export const UpdateContactFileSuccess = createAction('[Contacts Page] Update Contact File Success');
export const UpdateContactFileError = createAction(
  '[Contacts page] Update Contact File Error',
  props<{ msg: string }>()
);

export const DownloadFile = createAction(
  '[Contacts Page] Download Contact File',
  props<{
    id: string;
    url: string;
    fileName: string;
  }>()
);
export const DownloadFileSuccess = createAction('[Contacts Page] Download Contact File Success');
export const DownloadFileError = createAction('[Contacts page] Download Contact File Error', props<{ msg: string }>());

export const AddNote = createAction(
  '[Contacts Page] Add Note ',
  props<{
    data: any;
  }>()
);
export const GetTagsList = createAction(
  '[Contacts Page] Get Tags List ',
  props<{ user: any }>()
);
export const GetTagsListSuccess = createAction('[Contacts Page] Get Tags List Success', props<{ data: any[] }>());
export const GetTagsListError = createAction('[Contacts page] Get  Tags List Error', props<{ msg: string }>());

export const AddNoteSuccess = createAction('[Contacts Page] Add Note Success');
export const AddNoteError = createAction('[Contacts page] Add Note Error', props<{ msg: string }>());

export const GetNotes = createAction('[Contacts Page] Get  Notes  ', props<{ id: string }>());
export const GetNotesSuccess = createAction('[Contacts Page] Get Notes  Success', props<{ data: any }>());
export const GetNotesError = createAction('[Contacts page] Get  Notes Error', props<{ msg: string }>());
export const FileUploadProgress = createAction('[Contacts Page] File upload', props<{ progress: number }>());
export const UpdateNotes = createAction(
  '[Contacts Page] Update  Notes  ',
  props<{ newNote: string; id: string; title: string }>()
);
export const UpdateNotesSuccess = createAction('[Contacts Page] Update  Notes Success  ');
export const UpdateNotesError = createAction('[Contacts Page] Update  Notes Error  ', props<{ msg: string }>());
export const GetMyUsers = createAction('[Contacts Page] GetMyUsers', props<{ id: string }>());
export const GetMyUsersSuccess = createAction('[Contacts Page] GetMyUsersSuccess', props<{ data: any }>());
export const GetMySLO = createAction('[Contacts Page] GetMySLO', props<{ id: string }>());
export const GetMySLOSuccess = createAction('[Contacts Page] GetMySLOSuccess', props<{ data: any }>());

export const FetchContactCount = createAction('[Contacts Page] FetchContactCount', props<{ id: any }>());
export const FetchContact = createAction('[Contacts Page] FetchContact', props<{ data: any }>());
export const FetchContactModified = createAction(
  '[Contacts Page] FetchContactModified',
  props<{ data: any; silent?: boolean }>()
);

export const SaveContactCount = createAction('[Contacts Page] SaveContactCount', props<{ count: any }>());
export const SearchContact = createAction('[Contacts Page] SearchContact', props<{ data: any }>());
export const FilterContact = createAction('[Contacts Page] FilterContact', props<{ data: any }>());
export const GetMyDripData = createAction('[Contacts Page] GetMyDripData');
export const GetMyDripDataSuccess = createAction('[Contacts Page] GetMyDripDataSuccess', props<{ data: any }>());

export const AssignTags = createAction('[Contacts Page] Assigning Tags', props<{ data: any }>());
export const AssignTagsSuccess = createAction('[Contacts Page] Assigning Tags Success', props<{ data: any }>());
export const AssignTagsError = createAction('[Contacts page] Assigning Tags Error', props<{ msg: string }>());

export const DeleteAssignedTags = createAction(
  '[Contacts Page] Delete Assigned tags',
  props<{ data: any; tag: string }>()
);
export const DeleteAssignedTagsSuccess = createAction('[Contacts Page] Delete Assigned tags Success');
export const DeleteAssignedTagsError = createAction(
  '[Contacts page] Delete Assigned tags Error',
  props<{ msg: string }>()
);

export const UpdateContactStatus = createAction('[Contacts Page] Update Contact Status  ', props<{ data: any }>());

export const SortContact = createAction('[Contacts Page] Sort Contact  ', props<{ data: any }>());

export const OpenAssignDripModal = createAction('[Contacts Page] openAssignDripModal');
export const CloseAssignDripModal = createAction('[Contacts Page] CloseAssignDripModal');

export const AssignToOfficer = createAction('[Contacts Page] Assign To Officer', props<{ data: any }>());
export const AssignToOfficerSuccess = createAction('[Contacts Page] Assign To Officer Success', props<{ data: any }>());
export const AssignToOfficerError = createAction('[Contacts Page] Assign To Officer Error', props<{ msg: string }>());

export const DeleteContactBatch = createAction(
  '[Contacts Page] Delete Contact Batch',
  props<{ data: any; single?: boolean, refreshData?: any }>()
);
export const DeleteContactBatchSuccess = createAction(
  '[Contacts Page]Delete Contact Batch Success',
  props<{ data: any }>()
);
export const DeleteContactBatchError = createAction(
  '[Contacts Page]Delete Contact Batch Error',
  props<{ msg: string }>()
);

export const TagsBatch = createAction('[Contacts Page] Tags Batch', props<{ data: any }>());
export const TagsBatchSuccess = createAction('[Contacts Page]Tags Batch Success', props<{ data: any }>());
export const TagsBatchError = createAction('[Contacts Page]Tags Batch Error', props<{ msg: string }>());

export const massEmail = createAction(
  '[Contacts Page] Mass Email',
  props<{
    data: {
      contactsData: { contactId: string; contact_assigned_to: string; isMO: boolean; email: string }[];
      mailData: { subject: string; body: string };
      areAllSelected: boolean;
      removingDocIds: any;
    };
  }>()
);
export const massEmailSuccess = createAction('[Contacts Page] Mass Email Success', props<{ data: any }>());
export const massEmailError = createAction('[Contacts Page] Mass Email Error', props<{ data: any }>());

export const massEmailCampain = createAction('[Contact Page]MassEmailCampain', props<{ campainData: any }>());
export const massEmailCampainSuccess = createAction(
  '[Contacts Page] Mass Email Campain Success',
  props<{ data: any }>()
);
export const massEmailCampainError = createAction('[Contacts Page] Mass Email Campain Error', props<{ data: any }>());

export const massSms = createAction(
  '[Contacts Page] Mass Sms',
  props<{
    data: {
      contactsData: { contactId: string; isMO: boolean; contact_assigned_to: string; to: string }[];
      smsData: { body: string; from: string };
    };
  }>()
);
export const massSmsSuccess = createAction('[Contacts Page] Mass Sms Success', props<{ data: any }>());
export const massSmsError = createAction('[Contacts Page] Mass Sms Error', props<{ data: any }>());

export const massVoiceDrop = createAction('[Contacts Page] Mass Voice Drop', props<{ data: any }>());
export const massVoiceDropSuccess = createAction('[Contacts Page] Mass Voice Drop Success');
export const massVoiceDropError = createAction('[Contacts Page] Mass Voice Drop Error', props<{ data: any }>());
export const getContactWithPagination = createAction(
  '[Contact page] get contact with pagination',
  props<{ user_id: string; curPage?: number; partnerId?: string; data?: any; search?: boolean }>()
);
export const contactWithPaginationSuccess = createAction(
  '[Contact page] contact pagination success',
  props<{ data: any }>()
);
export const contactWithPaginationError = createAction(
  '[Contact page] contact with pagination error',
  props<{ data: string }>()
);
export const getLastDoc = createAction('[Contact Store] load last doc', props<{ doc_id: string }>());
export const getLastDocSuccess = createAction('[Contact Store] get last doc success', props<{ last_doc: any }>());

export const resetLastDoc = createAction('[Contact Page] reset last Doc');
export const GetContactTaskSuccess = createAction('[Contact Store] Get Contact Task Success', props<{ detail: any }>());

export const resetContactDemandList = createAction('[Contact Page] Reset contacts on demand list');
export const contactsOnlyMe = createAction('[Contacts Page] Only Me', props<{ onlyMe: boolean }>());

export const getExtraContactIdsSuccess = createAction(
  '[Contacts Page]getExtraContactIdsSuccess',
  props<{ data: any }>()
);

export const setAreAllSelected = createAction(
  '[Contacts Page] Set are all selected',
  props<{ areAllSelected: boolean }>()
);

export const setParentContact = createAction('[Contact Page]Set ParentContact', props<{ parentContact }>());
export const setParentContactSuccess = createAction('[Contacts Page]setParentContactSuccess', props<{ data: any }>());
export const setParentContactError = createAction('[Contacts Page]setParentContactError', props<{ msg: string }>());
export const deleteNote = createAction('[Contact Page] DeleteNote', props<{ data: any }>());
export const deleteNoteSuccess = createAction('[Contact Page] DeleteNoteSuccess');
export const deleteNoteError = createAction('[Contact Page]DeleteNoteError', props<{ msg: string }>());

export const testEmailCampagin = createAction('[Contact Page]testEmailcampagin', props<{ data: any }>());
export const testEmailCampaginSuccess = createAction('[Contact Page]testemailsuccesscam', props<{ data: any }>());
export const testEmailCampaginError = createAction('[Contact Page]testEmailCampaginError', props<{ msg: any }>());
export const testEmailNormal = createAction('[Contact Page]testEmailNormal', props<{ data: any }>());
export const testEmailNormalSuccess = createAction('[Contact Page]testemailsuccesscam', props<{ data: any }>());
export const testEmailNormalError = createAction('[Contact Page]testEmailNormalError', props<{ msg: any }>());

export const resetContactTask = createAction('[Contact Page] Reset contact tasks');
export const listLoadingSuccess = createAction('[Contact Page] List loading success');

export const ClearContactDetail = createAction('[Contact Page] Clear ContactDetail');

