import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/models/user.model';
import { PermissionObject } from 'src/app/shared/utils';

export const LogingIn = createAction('[Login Page] LogingIn', props<{ userEmail: string; userPassword: string }>());
export const LoginSuccess = createAction('[Login Page] Login Success', props<User>());
export const LoginError = createAction('[Login Page] Login Error', props<{ msg: string }>());
export const LoginReset = createAction('[Login Page] Reset Login Page');
export const ResetMailSent = createAction('[ResetPassword Page] Reset Mail Sent');
export const ResetMailError = createAction('[ResetPassword Page] Reset Mail Error', props<{ msg: string }>());
export const ResetMailSending = createAction('[ResetPassword Page] Reset Mail Sending..', props<{ email: any }>());
export const GetEnterprisePermissions = createAction('[getPermission] getPermission');
export const PermissionSuccess = createAction('[getPermission] permisssionSuccess', props<{ data: PermissionObject }>());

export const ResetPasswordSuccess = createAction('[ResetPassword Page] Reset Password Success');
export const ResetPasswordError = createAction('[ResetPassword Page] Reset Password Error', props<{ msg: string }>());
export const Resetting = createAction('[ResetPassword Page] Resetting..', props<{ code: any; newPassword: any }>());

export const Logout = createAction('[Login Page] Logout', props<{ action: string }>());

export const SetPasswordSuccess = createAction('[SetPassword Page] Set Password Success');
export const SetPasswordError = createAction('[SetPassword Page] Set Password Error', props<{ msg: string }>());
export const Setting = createAction(
  '[SetPassword Page] Setting..',
  props<{ uid: string; newPassword: string; email: string; timezone: string }>()
);
