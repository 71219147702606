import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ContactsState } from 'src/app/modules/contacts/store/state';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'dailyai-mobile-side-nav',
  templateUrl: './mobile-side-nav.component.html',
  styleUrls: ['./mobile-side-nav.component.scss']
})
export class MobileSideNavComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<any> = new Subject<any>();

  currentUser$: Observable<any>;

  currentUser: any;

  userImage: any;

  activeTab: string;

  constructor(private store: Store<ContactsState>, private sharedService: SharedService, private router: Router) {}

  ngOnInit() {
    this.activeTab = this.router.url.split('/')[1];
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(e => {
        this.sharedService.openSideNav({ stat: false });
      });
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (!data) {
        return;
      }
      this.currentUser = data;
      this.userImage = data.image;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
