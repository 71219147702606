<div class="w-100 d-flex mb-3 email-cc-bcc-box">
  <div class="d-flex flex-column form-control add-mail-cc">
    <div class="d-flex justify-content-between align-items-center">
      <div *ngIf="enableTO" class="d-flex to-text"><span class="font-14"> To </span></div>
      <div *ngIf="!showOnlyEmail" class="d-flex flex-wrap w-100">
        <div
          *ngFor="let item of selectedItems; let i = index"
          class="d-flex align-items-center attach-files mr-1 mb-1 ml-1 validStyle bg-white"
        >
          <span class="px-2 py-1 font-14"
            >{{ item.firstName + ' ' + item.lastName }} {{ item?.partnerContact ? ' (P) ' : '' }}</span
          >
          <span class="material-icons mx-1 font-16 cursor-pointer" (click)="deleteSelectedContact(i)"> close</span>
        </div>
        <div *ngIf="focused && enableTO" class="form-control" style="max-width: 300px">
          <input
            class="border-0"
            name="somethingAutofillDoesntKnow"
            #instance="ngbTypeahead"
            type="text"
            autocomplete="doNotAutoComplete"
            placeholder="Name or email"
            (ngModelChange)="searchContact($event)"
            [ngbTypeahead]="search"
            AutoSizeInput
            [minWidth]="280"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            (selectItem)="addChip($event)"
            (focus)="focus$.next($event.target.value)"
          />
        </div>
        <ng-template #rt let-r="result" let-t="term">
          <ngb-highlight [result]="r?.firstName + ' ' + r?.lastName + '(' + r?.email + ')'" [term]="t"> </ngb-highlight>
        </ng-template>
      </div>
      <div *ngIf="showOnlyEmail" class="d-flex flex-wrap w-100">
        <div
          *ngFor="let toEmail of toEmailData; let i = index"
          class="d-flex align-items-center bg-white validStyle attach-files mr-1"
        >
          <span class="px-2 py-1 font-14" id="email-id">{{ toEmail }}</span>
        </div>
      </div>
      <div class="d-flex">
        <span class="pr-2 cursor-pointer" (click)="clickCc()" *ngIf="!ccBox && enableCC"> Cc</span>
        <span class="border-left px-2" *ngIf="enableCC && enableBCC && !ccBox && !bccBox"></span>
        <span (click)="clickBcc()" class="cursor-pointer" *ngIf="!bccBox && enableBCC"> Bcc</span>
      </div>
    </div>
    <div *ngIf="ccBox" class="d-flex justify-content-between mt-1">
      <div class="d-flex to-text mt-1"><span class="font-14"> Cc </span></div>
      <div class="d-flex flex-wrap w-100">
        <div
          class="d-flex align-items-center attach-files mr-1 mb-1"
          *ngFor="let item of ccMails"
          style="border: '1px solid #e0e6ed'"
          [ngClass]="
            item.valid === validEnum.VALID
              ? 'validStyle bg-white '
              : item.valid === validEnum.DUPLICATE
              ? 'duplicateStyle'
              : 'invalidStyle'
          "
          [ngbTooltip]="item.email"
          tooltipClass="tooltip-page-class"
        >
          <span [ngClass]="item.valid === validEnum.INVALID ? 'text-white' : ''" class="px-2 py-1 font-14">{{
            (item.email | slice: 0:20) + (item.email.length > 20 ? '...' : '')
          }}</span>
          <span
            [ngClass]="item.valid === validEnum.INVALID ? 'text-white' : ''"
            class="material-icons mx-1 font-16 cursor-pointer"
            (click)="removeCCMail(item.index, 'cc')"
          >
            close</span
          >
        </div>
        <div *ngIf="focused" class="form-control" style="max-width: 300px">
          <input
            #CC
            class="border-0"
            autocomplete="doNotAutoComplete"
            [(ngModel)]="ccMail"
            (ngModelChange)="ccMail = $event.toLowerCase()"
            [ngModelOptions]="{ standalone: true }"
            AutoSizeInput
            (keypress)="onKeypressEvent($event, 'cc')"
            (keydown)="onKeypressEvent($event, 'cc')"
            [minWidth]="280"
            autofocus
            (click)="enableForMergeField()"
            (keyup)="enableForMergeField()"
          />
        </div>
      </div>
    </div>
    <div *ngIf="bccBox" class="d-flex justify-content-between mt-1">
      <div class="d-flex to-text"><span class="font-14"> Bcc </span></div>
      <div class="d-flex flex-wrap w-100">
        <div
          class="d-flex align-items-center attach-files mr-1 mb-1"
          *ngFor="let item of bccMails; let i = index"
          [ngClass]="
            item.valid === validEnum.VALID
              ? 'validStyle bg-white '
              : item.valid === validEnum.DUPLICATE
              ? 'duplicateStyle'
              : 'invalidStyle'
          "
          [ngbTooltip]="item.email"
          tooltipClass="tooltip-page-class"
        >
          <span [ngClass]="item.valid === validEnum.INVALID ? 'text-white' : ''" class="px-2 py-1 font-14">{{
            (item.email | slice: 0:20) + (item.email.length > 20 ? '...' : '')
          }}</span>
          <span
            [ngClass]="item.valid === validEnum.INVALID ? 'text-white' : ''"
            class="material-icons mx-1 font-16 cursor-pointer"
            (click)="removeCCMail(item.index, 'bcc')"
          >
            close</span
          >
        </div>
        <div *ngIf="focused" class="form-control" style="max-width: 300px">
          <input
            #BCC
            class="border-0"
            [(ngModel)]="bccMail"
            autocomplete="doNotAutoComplete"
            (ngModelChange)="bccMail = $event.toLowerCase()"
            [ngModelOptions]="{ standalone: true }"
            (keypress)="onKeypressEvent($event, 'bcc')"
            (keydown)="onKeypressEvent($event, 'bcc')"
            autofocus
            AutoSizeInput
            [minWidth]="280"
            (click)="enableForMergeField()"
            (keyup)="enableForMergeField()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
