/*
  Provides interface for dealing with common date time operations to issolate codebase from 3rd party dependency to moment.
*/
import * as moment from 'moment-timezone';

export const timeZonesMap: { [index: string]: string } = {
  'Chicago -06:00': 'America/Chicago',
  'Denver -07:00': 'America/Denver',
  'Phoenix -07:00': 'America/Phoenix',
  'LosAngeles -08:00': 'America/Los_Angeles',
  'Anchorage -09:00': 'America/Anchorage',
  'Honolulu -10:00': 'Pacific/Honolulu',
  'Kolkata +05:30': 'Asia/Kolkata',
  HST: 'Pacific/Honolulu',
  AKDT: 'America/Anchorage',
  PDT: 'America/Los_Angeles',
  MST: 'America/Phoenix',
  MDT: 'America/Denver',
  CDT: 'America/Chicago',
  EDT: 'America/New_York',
  AKST: 'America/Anchorage',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',
  EST: 'America/New_York',
};

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const isPastDate = (date: string, timeZone: string) => {
  const fundedDate = moment(date).tz(timeZonesMap[timeZone]).hours(23).minutes(59).seconds(59).toISOString();
  const curDate = moment().tz(timeZonesMap[timeZone]).toISOString();
  return fundedDate < curDate;
};

export const parseDateString = (date) => {
  if (!date) return null;

  const dateValue = moment(date);
  if (!dateValue.isValid()) return null;

  return dateValue;
};

export const dateStringFormatter = (date) => {
  if (!date) return '--';
  const dateValue = moment(date);
  if (!dateValue.isValid()) return '--';

  return dateValue.format(DEFAULT_DATE_FORMAT);
};

export const generateTimezoneDate = (value: any, timeZone: string) => {
  if (!value) {
    return undefined;
  }
  const dateValue = moment.tz(value, timeZonesMap[timeZone]);

  if (!dateValue.isValid()) {
    return undefined;
  }

  return dateValue;
};

export const generateTimezoneDateParts = (value: any, timeZone: string) => {
  const dateValue = generateTimezoneDate(value, timeZone);
  if (!dateValue) throw new Error('generateTimezoneDateParts: Unable to generate date parts from an invalid date.');
  return {
    day: Number(dateValue.format('DD')),
    month: Number(dateValue.format('MM')),
    year: Number(dateValue.format('YYYY')),
  };
};

export const dateIsAfter = (dateValue, isAfterDateValue) => {
  const dateOriginal = moment(dateValue);
  const compareDate = moment(isAfterDateValue);

  if (!dateOriginal.isValid() || !compareDate.isValid()) {
    console.error('dateIsAfter: unable to compare an invalid date');
    return false;
  }

  return moment(dateValue).isSameOrAfter(moment(isAfterDateValue));
};

export const formatDateWithTimezone = (value: any, timeZone: string, format = DEFAULT_DATE_FORMAT) => {
  return generateTimezoneDate(value, timeZone).format(format).toString();
};

export function getDate(
  dateobj: { year: number; month: number; day: number },
  duetime: { hour: number; minute: number; second: number }
) {
  const dueTime = new Date(dateobj.year, dateobj.month - 1, dateobj.day, duetime.hour, duetime.minute).toISOString();
  return dueTime;
}

export const castDate = (date, timeZone) => {
  if (!date) {
    return undefined;
  }
  const momentDate = moment.tz(date, timeZonesMap[timeZone]);

  return momentDate.isValid() ? momentDate.tz(timeZonesMap[timeZone]) : undefined;
};

export const castTimeStampToDate = (stamp, timeZone) => {
  if (!stamp) {
    return undefined;
  }
  const unixTimestamp = Number(stamp);
  const momentDate = moment(new Date(unixTimestamp));

  return momentDate.isValid() ? momentDate.tz(timeZonesMap[timeZone]) : undefined;
};

export const castDateTime = (date, timeZone) => {
  if (!date) {
    return undefined;
  }
  const momentDate = moment.tz(date, timeZonesMap[timeZone]);
  if (!momentDate.isValid()) {
    return momentDate;
  }
  return undefined;
};

export function getReminderTaskDueTime(delay: any) {
  const delayData = delay.split(' ');
  const date = new Date(new Date().setHours(0, 0, 0, 0));
  return moment(date).add(delayData[0], delayData[1]);
}

export function generateDateWithTimeZone(
  date: number,
  month: number,
  year: number,
  hours: number,
  minutes: number,
  timezone: string
) {
  const dateIso = moment()
    .tz(timeZonesMap[timezone])
    .date(date)
    .month(month)
    .year(year)
    .hour(hours)
    .minute(minutes)
    .toISOString();

  return dateIso;
}

/*
  AGGRID date comparator
  compares dates from 1 row to another for sorting

  return ...
  -1 : filterLocalDateAtMidnight < cellValue
  0 : dates are the same
  1 : filterLocalDateAtMidnight > cellValue
*/
export const dateMomentComparator = (filterLocalDateAtMidnight: Date, cellValue: any) => {
  if (!cellValue || !moment(cellValue).isValid()) return 1;

  if (!filterLocalDateAtMidnight || !moment(filterLocalDateAtMidnight).isValid()) {
    return -1;
  }

  const filterLocalDateAtMidnightText = moment(filterLocalDateAtMidnight).format('MM/DD/YYYY');
  const cellValueText = moment(cellValue).format('MM/DD/YYYY');

  if (filterLocalDateAtMidnightText === cellValueText) return 0;

  if (moment(filterLocalDateAtMidnight).isBefore(moment(cellValue))) {
    return 1;
  }
  if (moment(filterLocalDateAtMidnight).isAfter(moment(cellValue))) {
    return -1;
  }

  return 0;
};

export function convertDateToTimezoneWithFormat(date: any, timezone: string, format: string) {
  try {
    return moment(date).tz(timeZonesMap[timezone]).format(format);
  } catch {
    return date;
  }
}

export function differenceInHours(date1: any, date2:any){
  const moment1 = moment(date1);
  const moment2 = moment(date2);
  const duration = moment.duration(moment2.diff(moment1));
  return duration.asHours();
}
