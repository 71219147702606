import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Component({
  selector: 'dailyai-force-sso-popup',
  templateUrl: './force-sso-popup.component.html'
})
export class ForceSsoPopupComponent implements OnInit {
  email = 'anderson.chasew@gmail.com';

  loading = true;

  currentAuthUser: any;

  constructor(
    private angularFireAuth: AngularFireAuth,
    public toastr: ToastrService,
    public ngbModal: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {

    this.loading = false;
  }

  async addMicrosoftAccountToUser() {
    try {
      this.loading = true;

      const microsoftProvider = new firebase.default.auth.OAuthProvider('microsoft.com');

      await this.currentAuthUser.linkWithPopup(microsoftProvider);

      const microsoftLoginInfo = this.currentAuthUser.providerData.find((x) => { return x.providerId === 'microsoft.com' })
      const passwordLoginInfo = this.currentAuthUser.providerData.find((x) => { return x.providerId === 'password' })

      if (microsoftLoginInfo.email === passwordLoginInfo.email) {
        await this.currentAuthUser.unlink('password');

        this.toastr.success(`The Microsoft account ${microsoftLoginInfo.email} was successfully used to sign in.`);

        this.ngbModal.dismissAll();
      } else {
        await this.currentAuthUser.unlink('microsoft.com');

        this.toastr.error(`The Microsoft account ${microsoftLoginInfo.email} cannot be linked with ${passwordLoginInfo.email}. Email mismatch.`);
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
      const message = error.code === 'auth/credential-already-in-use' ? `The Microsoft account ${error.email} is already associated with a different user account.` : error.message;
      this.toastr.error(message);
      console.error(error);
    }
  }

  signOut() {
    this.ngbModal.dismissAll();
    this.router.navigate(['/logout']);
  }
}
