import { createAction, props } from '@ngrx/store';
import { CustomUserFields } from 'src/app/shared/utils';
import { IntakeForm } from 'src/app/models/intake-form.model';
/* ADD USER ACTIONS */
export const AddUser = createAction(
  '[Users Page] Adding User',

  props<{
    firstName: string;
    lastName: string;
    designation: string;
    email: string;
    extra_user: boolean;
  }>()
);
export const AddUserSuccess = createAction('[Users Page] AddUser Success');
export const AddUserError = createAction('[Users page] AddUser Error', props<{ msg: string }>());
/* DELETE USER ACTIONS  */
export const DeleteUser = createAction(
  '[Users Page] Delete User',
  props<{
    uId: string;
    action: string;
    taskId?: any[];
    assignedTasks?: Object[];
  }>()
);
export const ActivateUser = createAction('[Users Page]Activate User', props<{ juniorId: string; token: string }>());

export const ActivateUserSuccess = createAction('[Users Page] ActivateUser Success');
export const ActivateUserError = createAction('[Users page] ActivateUser Error', props<{ msg: string }>());

export const DeleteUserSuccess = createAction('[Users Page] DeleteUser Success');
export const DeleteUserError = createAction('[Users page] DeleteUser Error', props<{ msg: string }>());
export const ListUser = createAction('[Users Page] List Users', props<{ parentId: string }>());
export const ListUserSuccess = createAction('[Users Page] ListUserSuccess', props<{ data: any }>());
export const ListUserError = createAction('[Users page] List User Error', props<{ msg: string }>());
export const GetChildList = createAction(
  '[Users Page] Child List Loading',
  props<{ parentId: string; moExtra?: boolean }>()
);
export const GetChildListSuccess = createAction('[Users Page] Child List Success', props<{ data: any }>());
export const GetChildListError = createAction('[Users Page] Child List Error', props<{ msg: string }>());
export const AddCredit = createAction(
  '[Users Page] Adding Credit',

  props<{
    jId: string;
    action: string;
  }>()
);
export const AddCreditSuccess = createAction('[Users Page] AddCredit Success');
export const AddCreditError = createAction('[Users page] AddCredit Error', props<{ msg: string }>());
export const GetUserCount = createAction('[Users page] GetUserCount');
export const GetUsers = createAction('[Users page] GetUsers', props<{ data: any }>());
export const UserCount = createAction('[Users page] UserCount', props<{ data: any }>());
export const SearchUsers = createAction('[Users page] SearchUsers', props<{ data: any }>());

/* ADD MILESTONE ACTIONS */
export const AddMilestone = createAction(
  '[Users Page] Add Milestone',

  props<{
    data: any;
    delete_ids: string[];
    userType?: string;
    prevData: any;
    radio: string;
    tab: string;
  }>()
);
export const AddMilestoneSuccess = createAction('[Users Page] AddMilestone Success');
export const AddMilestoneError = createAction('[Users page] AddMilestone Error', props<{ msg: string }>());

/* GET MILESTONE ACTIONS */
export const GetMilestone = createAction(
  '[Users Page] GetMilestone',

  props<{
    uid: string;
    userType?: string;
    tab?: string;
  }>()
);
export const GetMilestoneSuccess = createAction(
  '[Users Page] GetMilestone Success',
  props<{ data: any; statusNames?: any[] }>()
);
export const GetMilestoneError = createAction('[Users page] GetMilestone Error', props<{ msg: string }>());

/**
 * intake form action
 */
export const GetAllIntakeForms = createAction('[intake page] GetAllIntakeForms');
export const GetAllIntakeFormsSuccess = createAction(
  '[intake page] GetAllIntakeFormsSuccess',
  props<{ data: IntakeForm[] }>()
);
export const GetAllIntakeFormsError = createAction('[intake page] GetAllIntakeFormsError', props<{ data: any }>());

/* GET CUSTOM FIELDS ACTIONS */
export const GetCustomFields = createAction(
  '[Custom Fields Page] GetCustomFields',
  props<{
    uid: string;
    isEdit?: boolean;
  }>()
);
export const GetCustomFieldsSuccess = createAction(
  '[Custom Fields Page] GetCustomFields Success',
  props<{ data: any; uId: string; user_custom_fields_value: any }>()
);
export const GetCustomFieldsError = createAction('[Custom Fields Page] GetCustomFields Error', props<{ msg: any }>());

export const GetCustomFieldsEdit = createAction(
  '[Custom Fields Page] GetCustomFieldsEdit',
  props<{
    uid: string;
    isEdit?: boolean;
  }>()
);
export const GetCustomFieldsSuccessEdit = createAction(
  '[Custom Fields Page] GetCustomFieldsEdit Success',
  props<{ data: CustomUserFields; uId: string }>()
);
export const GetCustomFieldsErrorEdit = createAction(
  '[Custom Fields Page] GetCustomFieldsEdit Error',
  props<{ msg: any }>()
);

/* SAVE CUSTOM FIELDS ACTIONS */
export const SaveCustomFields = createAction(
  '[Custom Fields Page] SaveCustomFields',
  props<{
    uid: string;
    data: {
      custom_fields_contacts: any[];
      custom_fields_transaction: any[];
      user_custom_fields_value: any[];
      uId: string;
    };
    userType?: string;
  }>()
);

export const SaveCustomFieldsSuccess = createAction(
  '[Custom Fields Page] SaveCustomFieldsSuccess',
  props<{ data: any }>()
);
export const SaveCustomFieldsError = createAction('[Custom Fields Page] SaveCustomFieldsError', props<{ msg: any }>());

/* ADD TEMPLATE ACTIONS */
export const AddTemplate = createAction(
  '[Users Page] Add Template',
  props<{
    data: any;
    page: string;
  }>()
);
export const AddTemplateSuccess = createAction('[Users Page] AddTemplate Success');
export const AddTemplateError = createAction('[Users page] AddTemplate Error', props<{ msg: string }>());
/* GET TEMPLATE ACTIONS */
export const GetTemplate = createAction(
  '[Users Page] GetTemplate',

  props<{
    uid: string;
    user: any;
  }>()
);
export const GetTemplateSuccess = createAction('[Users Page] GetTemplate Success', props<{ data: any }>());
export const GetTemplateError = createAction('[Users page] GetTemplate Error', props<{ msg: string }>());
/* UPDATE TEMPLATE ACTIONS */
export const UpdateTemplate = createAction(
  '[Users Page] Update Template',
  props<{
    data: any;
  }>()
);
export const UpdateTemplateSuccess = createAction('[Users Page] UpdateTemplate Success');
export const UpdateTemplateError = createAction('[Users page] UpdateTemplate Error', props<{ msg: string }>());
/* DELETE TEMPLATE ACTIONS */
export const DeleteTemplate = createAction(
  '[Users Page] Delete Template',
  props<{
    templateId: string;
    page: string;
  }>()
);
export const DeleteTemplateSuccess = createAction('[Users Page] DeleteTemplate Success');
export const DeleteTemplateError = createAction('[Users page] DeleteTemplate Error', props<{ msg: string }>());
/* GET SMSTEMPLATE ACTIONS */
export const GetSMSTemplate = createAction(
  '[Users Page] GetSMSTemplate',

  props<{
    uid: string;
    user: any;
  }>()
);
export const GetSMSTemplateSuccess = createAction('[Users Page] GetSMSTemplate Success', props<{ data: any }>());
export const GetSMSTemplateError = createAction('[Users page] GetSMSTemplate Error', props<{ msg: string }>());

export const GetMmsTemplate = createAction('[Users Page] Get Mms Templates', props<{ uid: string; user: any }>());
export const GetMMSTemplateSuccess = createAction('[Users Page] GetMMSTemplate Success', props<{ data: any }>());
export const GetMMSTemplateError = createAction('[Users page] GetMMSTemplate Error', props<{ msg: string }>());

export const AddVcard = createAction('[Users Page] Add V-Card', props<{ data: any; noToast?: boolean }>());
export const AddVcardStarted = createAction('[Users Page] Add V-Card Started');

export const AddVcardSuccess = createAction('[Users Page] Add V-Card Success', props<{ data: any }>());
export const AddVcardError = createAction('[Users Page] Add V-Card Error', props<{ msg: string }>());

export const ContactSentSms = createAction(
  '[Users page] Sent Sms ',
  props<{
    data: { from: string; to: any; body: string; mediaUrl: string };
  }>()
);
export const ContactSentSmsSuccess = createAction('[Users Page] Sent Sms Success');
export const ContactSentSmsError = createAction('[Users Page] Sent Sms Error', props<{ msg: string }>());

export const saveLeadNotification = createAction(
  '[Users Page] Save Lead Notification',
  props<{
    data: any;
    enterprise: boolean;
    userType: string;
  }>()
);

export const toggleAi = createAction('[Users Page] Toggle Ai', props<{ toggle: boolean }>());

export const toggleAiSuccess = createAction('[Users Page] Toggle Ai success');

export const toggleAiError = createAction('[Users Page] Toggle Ai error', props<{ msg: string }>());

export const saveLeadNotificationSuccess = createAction('[Users Page] Save Lead Notification success');
export const saveLeadNotificationError = createAction(
  '[Users Page] Save Lead Notification error',
  props<{ msg: string }>()
);

export const getAiSettings = createAction('[Users Page] Get Ai Settings', props<{ shared: boolean }>());
export const getAiSettingsSuccess = createAction('[Users Page] Get Ai Settings success', props<{ data: any }>());
export const getAiSettingsError = createAction('[Users Page] Get Ai Settings Error', props<{ msg: string }>());

export const GetEmailGeneralTemplate = createAction(
  '[general-templates Page] GetEmailGeneralTemplate',
  props<{ uid: string }>()
);
export const GetEmailGeneralTemplateSuccess = createAction(
  '[general-templates Page] GetEmailGeneralTemplate Success',
  props<{ data: any }>()
);
export const GetEmailGeneralTemplateError = createAction(
  '[general-templates page] GetEmailGeneralTemplate Error',
  props<{ msg: string }>()
);

export const DeleteEmailGeneralTemplate = createAction(
  '[general-templates Page] DeleteEmailGeneralTemplate',
  props<{ templateId: string }>()
);
export const DeleteEmailGeneralTemplateSuccess = createAction(
  '[general-templates Page] DeleteEmailGeneralTemplate Success'
);
export const DeleteEmailGeneralTemplateError = createAction(
  '[general-templates page] DeleteEmailGeneralTemplate Error',
  props<{ msg: string }>()
);

export const UpdateGeneralTemplate = createAction(
  '[general-templates page] Update UpdateGeneralTemplate',
  props<{ data: any }>()
);
export const UpdateGeneralTemplateSuccess = createAction('[general-templates page] UpdateGeneralTemplate Success');
export const UpdateGeneralTemplateError = createAction(
  '[general-templates page] UpdateGeneralTemplate Error',
  props<{ msg: string }>()
);
export const GetCampaignUsers = createAction(
  '[Settings Campaign] get campaign users',
  props<{ curPage: number; search?: string; sort?: { sortBy: string; order: number }; filters?: any[] }>()
);

export const CampaignUsersSuccess = createAction(
  '[Settings Campaign] get campaign users Success',
  props<{ data: any[]; count: number }>()
);

export const GetCampaignList = createAction(
  '[Settings Campaign] get campaign users List',
  props<{ page: number; search?: string; sort?: { sortBy: string; order: number } }>()
);

export const CampaignListSuccess = createAction(
  '[Settings Campaign] get campaign users List Success',
  props<{ data: any[]; count: number }>()
);

export const setCampaignUserCurPage = createAction('setCampaignUserCurPage', props<{ curPage: number }>());
export const setCampaignListCurPage = createAction('setCampaignListCurPage', props<{ curPage: number }>());
export const setCurrentCampaignUid = createAction('setCampaignUid', props<{ uid: string }>());

export const refreshCampUserData = createAction('Refresh camp userData');
export const fileupload = createAction('[email file Page] progress..', props<{ start: boolean }>());

export const GetHistory = createAction(
  '[Settings History] get history List',
  props<{
    data: {
      curPage?: number;
      term?: string;
      params?: { status?: string; date?: { from?: string; to?: string }; amount: { from?: Number; to?: Number } };
    };
    token: string;
  }>()
);

export const GetHistorySuccess = createAction(
  '[Settings History] get history List Success',
  props<{ data: any[]; count: number }>()
);

export const GetHistoryError = createAction('[Settings History] get history List Success', props<{ msg: string }>());

export const GetRefinancePurpose = createAction(
  '[Settings RefinancePurpose] get purpose List',
  props<{ user: any; radio: string }>()
);

export const GetRefinancePurposeSuccess = createAction(
  '[Settings RefinancePurpose] get purpose List Success',
  props<{ data: any }>()
);

export const GetRefinancePurposeError = createAction(
  '[Settings RefinancePurpose] get purpose List Success',
  props<{ msg: string }>()
);

export const SaveRefinancePurpose = createAction(
  '[Settings Refinance Purpose] add purpose List',
  props<{ user: any; purpose: string; radio: string }>()
);

export const SaveRefinancePurposeSuccess = createAction('[Settings Refinance Purpose] add purpose List Success');

export const SaveRefinancePurposeError = createAction(
  '[Settings Refinance Purpose] add purpose List Success',
  props<{ msg: string }>()
);

export const DeleteRefinancePurpose = createAction(
  '[Settings Refinance Purpose] delete purpose List',
  props<{ user: any; doc_id: string; radio: string }>()
);

export const DeleteRefinancePurposeSuccess = createAction('[Settings Refinance Purpose] delete purpose List Success');

export const DeleteRefinancePurposeError = createAction(
  '[Settings Refinance Purpose] delete purpose List Success',
  props<{ msg: string }>()
);

export const GetPurchasePurpose = createAction(
  '[Settings PurchasePurpose] get purpose List',
  props<{ user: any; radio: string }>()
);

export const GetPurchasePurposeSuccess = createAction(
  '[Settings PurchasePurpose] get purpose List Success',
  props<{ data: any }>()
);

export const GetPurchasePurposeError = createAction(
  '[Settings PurchasePurpose] get purpose List Success',
  props<{ msg: string }>()
);

export const SavePurchasePurpose = createAction(
  '[Settings Purchase Purpose] add purpose List',
  props<{ user: any; purpose: string; radio: string }>()
);

export const SavePurchasePurposeSuccess = createAction('[Settings Purchase Purpose] add purpose List Success');

export const SavePurchasePurposeError = createAction(
  '[Settings Purchase Purpose] add purpose List Success',
  props<{ msg: string }>()
);

export const DeletePurchasePurpose = createAction(
  '[Settings Purchase Purpose] delete purpose List',
  props<{ user: any; doc_id: string; radio: string }>()
);

export const DeletePurchasePurposeSuccess = createAction('[Settings Purchase Purpose] delete purpose List Success');

export const DeletePurchasePurposeError = createAction(
  '[Settings Purchase Purpose] delete purpose List Success',
  props<{ msg: string }>()
);

export const GetPackage = createAction(
  '[Settings Package] get Package ',
  props<{
    enterpriseId: string;
  }>()
);

export const GetPackageSuccess = createAction('[Settings Package] get Package  Success', props<{ data: any }>());

export const GetPackageError = createAction('[Settings Package] get Package  Success', props<{ msg: string }>());
