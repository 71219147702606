import { createReducer, on } from '@ngrx/store';
import * as DripStateActions from './action';
import { DripState } from './state';

export const initialState: DripState = {
  DripAdd: false,
  DripAddSuccess: null,
  DripAddError: null,
  DripDelete: false,
  DripDeleteSuccess: null,
  DripDeleteError: null,
  DripStepDelete: false,
  DripStepDeleteSuccess: null,
  DripStepDeleteError: null,
  DripEdit: false,
  DripEditSuccess: null,
  DripEditError: null,
  DripList: [],
  DripListError: null,
  DripListSuccess: null,
  LoadDrip: false,
  count: 0,
  curPage: 1,
  ListSources: [],
  ListSourcesError: null,
  ListSourcesSuccess: null,
  active: 0,
  DripListWithDemand: [],
  DripListWithDemandMongo: [],
  DripListWithDemandError: null,
  DripListWithDemandSuccess: null,
  DripListWithDemandLoading: false,
  last_doc: null,
  onlyMe: false,
  jlosearchResults: [],
};

export const DripReducer = createReducer(
  initialState,
  on(DripStateActions.ListDrip, (state) => ({
    ...state,
    DripListSuccess: null,
    LoadDrip: true,
    DripListError: null,
  })),
  on(DripStateActions.DripsOnlyMe, (state, payload) => ({
    ...state,
    onlyMe: payload.onlyMe,
  })),
  on(DripStateActions.DripClear, (state) => ({
    ...state,
    DripList: [],
  })),
  on(DripStateActions.ListDripSuccess, (state, payload) => {
    return {
      ...state,
      DripListSuccess: true,
      LoadDrip: false,
      DripList: payload.data && payload.data.list ? payload.data.list : payload.data,
      count: payload.data && payload.data.count ? payload.data.count : state.count,
      active: payload.data.active >= 0 ? payload.data.active : state.active,
    };
  }),
  on(DripStateActions.ListDripError, (state, payload) => ({
    ...state,
    LoadDrip: false,
    DripListSuccess: false,
    DripListError: payload.msg,
  })),
  on(DripStateActions.AddDrip, (state) => ({
    ...state,
    DripAdd: true,
    DripAddError: null,
  })),
  on(DripStateActions.AddDripSuccess, (state) => ({
    ...state,
    DripAdd: true,
    DripAddSuccess: true,
    DripAddError: null,
  })),
  on(DripStateActions.AddDripError, (state, payload) => ({
    ...state,
    DripAdd: false,
    DripAddSuccess: false,
    DripAddError: payload.msg,
  })),
  on(DripStateActions.DeleteDrip, (state) => ({
    ...state,
    DripDelete: true,
    DripDeleteError: null,
    DripDeleteSuccess: true,
  })),
  on(DripStateActions.DeleteDripSuccess, (state) => ({
    ...state,
    DripDelete: true,
    DripDeleteError: null,
    DripDeleteSuccess: true,
  })),
  on(DripStateActions.DeleteDripError, (state, payload) => ({
    ...state,
    DripDelete: false,
    DripDeleteError: payload.msg,
    DripDeleteSuccess: false,
  })),
  on(DripStateActions.DeleteDripStep, (state) => ({
    ...state,
    DripStepDelete: true,
    DripStepDeleteSuccess: true,
    DripStepDeleteError: null,
  })),
  on(DripStateActions.DeleteDripStepSuccess, (state) => ({
    ...state,
    DripStepDelete: true,
    DripStepDeleteSuccess: true,
    DripStepDeleteError: null,
  })),
  on(DripStateActions.DeleteDripStepError, (state, payload) => ({
    ...state,
    DripStepDelete: false,
    DripStepDeleteSuccess: false,
    DripStepDeleteError: payload.msg,
  })),
  on(DripStateActions.EditDrip, (state) => ({
    ...state,
    DripEdit: true,
    DripEditSuccess: true,
    DripEditError: null,
  })),
  on(DripStateActions.EditDripSuccess, (state) => ({
    ...state,
    DripEdit: true,
    DripEditSuccess: true,
    DripEditError: null,
  })),
  on(DripStateActions.EditDripError, (state, payload) => ({
    ...state,
    DripEdit: false,
    DripEditSuccess: false,
    DripEditError: payload.msg,
  })),
  on(DripStateActions.SaveDripsCount, (state, payload) => ({
    ...state,
    count: payload.count,
    active: payload.active,
  })),
  on(DripStateActions.GetDripsList, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage,
  })),
  on(DripStateActions.ListSources, (state) => ({
    ...state,
  })),
  on(DripStateActions.ListSourcesSuccess, (state, payload) => {
    return {
      ...state,
      ListSourcesSuccess: true,
      ListSources: payload.data && payload.data.list ? payload.data.list : payload.data,
      count: payload.data && payload.data.count !== undefined ? payload.data.count : state.count,
    };
  }),
  on(DripStateActions.ListSourcesError, (state, payload) => ({
    ...state,
    ListSourcesSuccess: false,
    ListSourcesError: payload.msg,
  })),
  on(DripStateActions.ListDripWithDemandScroll, (state) => ({
    ...state,
    DripListWithDemandLoading: true,
    DripListWithDemandError: null,
    DripListWithDemandSuccess: null,
  })),
  on(DripStateActions.ListDripWithDemandScrollError, (state, payload) => ({
    ...state,
    DripListWithDemandLoading: false,
    DripListWithDemandError: payload.data,
    DripListWithDemandSuccess: null,
  })),
  on(DripStateActions.ListDripWithDemandScrollSuccess, (state, payload) => {
    let tempArray = payload.data.data ? payload.data.data : payload.data;
    if (payload.search) {
      if (payload.curPage && payload.curPage !== 1) {
        tempArray = [...state.DripListWithDemand, ...tempArray];
      } else {
        tempArray = payload.data.data ? payload.data.data : payload.data;
      }
    } else {
      tempArray = [...state.DripListWithDemand, ...tempArray];
    }


    return {
      ...state,
      DripListWithDemandLoading: false,
      DripListWithDemandSuccess: true,
      DripListWithDemandError: null,
      DripListWithDemand: tempArray,
      last_doc: !payload.search && tempArray && tempArray.length > 0 ? tempArray[tempArray.length - 1] : null,
    };
  }),
  on(DripStateActions.ListDripWithDemandScrollMongo, (state) => ({
    ...state,
  })),
  on(DripStateActions.ListDripWithDemandScrollMongoError, (state, payload) => ({
    ...state,
  })),
  on(DripStateActions.ListDripWithDemandScrollMongoSuccess, (state, payload) => {
    let tempArray = payload.data ?? [];
    if (payload.curPage && payload.curPage !== 1) {
      tempArray = [...state.DripListWithDemandMongo, ...tempArray];
    } else {
      tempArray = payload.data.data ? payload.data.data : payload.data;
    }
    const uniqueDrips = [...new Map(tempArray.map((drip) => [drip['drip_campaign_id'], drip])).values()];

    return {
      ...state,
      DripListWithDemandMongo: uniqueDrips,
    };
  }),
  on(DripStateActions.ResetDemandScrollData, (state) => ({
    ...state,
    DripListWithDemand: [],
    last_doc: null,
  })),
  on(DripStateActions.ResetDemandScrollDataMongo, (state) => ({
    ...state,
    DripListWithDemandMongo: [],
  })),
  on(DripStateActions.JloSearchSuccess, (state, payload) => {
    return {
      ...state,
      jlosearchResults: payload.data,
    };
  })
);
