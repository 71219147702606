import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { Observable } from 'rxjs';
import { AudioRecordingService } from '../../services/audio-recording.service';
import { DataTransactionService } from '../../services/data-transaction.service';

@Component({
  selector: 'dailyai-audio-record-model',
  templateUrl: './audio-record-model.component.html',
  styleUrls: ['./audio-record-model.component.scss']
})
export class AudioRecordModelComponent implements OnInit {
  @ViewChild('audio') audio;

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  condition = false;

  isRecording = false;

  recordedTime;

  blobUrl;

  private stream: MediaStream;

  private recordRTC: any;

  play = true;

  pause = false;

  blobRecieved = false;

  finalOut;

  audioName = '';

  private currentUser$: Observable<any>;

  private userId: string;

  constructor(public modal: NgbModal,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer,
    private store: Store<any>,
    private dts: DataTransactionService,
    private activeModal: NgbActiveModal) {

    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.subscribe(data => {
      if (data) {
        this.userId = data.uid;
      }
    });

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobUrl = data.url;
      this.finalOut = data.blob;
      const audio: HTMLAudioElement = this.audio.nativeElement;
      audio.src = this.blobUrl;
    });
  }

  ngOnInit() { }

  closeModal() {
    this.activeModal.dismiss();
  }


  startRecording() {
    this.blobRecieved = false;
    this.condition = true;
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  audioEnded() {
    this.play = true;
  }

  abortRecording() {
    this.condition = false;
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    this.condition = false;
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
      this.blobRecieved = true;
    }
  }

  pauseRecording() {
    this.condition = false;
    this.audioRecordingService.pauseRecording();
  }

  playRecording() {
    const audio: HTMLAudioElement = this.audio.nativeElement;
    audio.play();
    this.play = false;
    this.pause = true;
  }

  pauseSrc() {
    const audio: HTMLAudioElement = this.audio.nativeElement;
    audio.pause();
    this.play = true;
    this.pause = false;
  }

  resumeRecording() {
    this.condition = true;
    this.audioRecordingService.resumeRecording();
  }

  saveAudio() {
    this.saved.emit('success');
    this.dts.uploadFile(this.userId, this.finalOut, 'audio', this.recordedTime, this.audioName);
    this.blobUrl = null;
    this.modal.dismissAll();
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  get recieved() {
    return this.blobRecieved;
  }
}
