import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './state';

export const selectUsersState = createFeatureSelector<UserState>('users');

export const selectUserList = createSelector(selectUsersState, (usersState) => usersState.userList);
export const selectUserListSuccess = createSelector(selectUsersState, (usersState) => usersState.UserListSuccess);
export const selectMilestoneList = createSelector(selectUsersState, (usersState) => usersState.milestoneList);

export const selectStatusNames = createSelector(selectUsersState, (usersState) => usersState.statusNames);

export const selectMilestoneListStatus = createSelector(
  selectUsersState,
  (usersState) => usersState.milestoneListStatus
);

export const selectMilestoneAddStatus = createSelector(selectUsersState, (usersState) => usersState.milestoneAdd);

export const selectSaveCustomFieldsSuccess = createSelector(
  selectUsersState,
  (usersState) => usersState.saveCustomFieldsSuccess
);

export const selectSaveCustomFieldsError = createSelector(
  selectUsersState,
  (usersState) => usersState.saveCustomFieldsError
);
export const selectCustomFieldsStatus = createSelector(selectUsersState, (usersState) => usersState.customFieldsStatus);
export const selectCustomFieldsList = createSelector(selectUsersState, (usersState) => usersState.customFieldsList);

export const selectCustomFieldsListError = createSelector(
  selectUsersState,
  (usersState) => usersState.customFieldsListError
);

/**
 * intake form selectors
 */

export const selectIntakeFormLoadingStatus = createSelector(
  selectUsersState,
  (usersState) => usersState.intakeFormsLoading
);
export const selectIntakeFormsList = createSelector(selectUsersState, (usersState) => usersState.intakeForms);

export const selectIntakeFormsSuccess = createSelector(selectUsersState, (usersState) => usersState.intakeFormsSuccess);

export const selectCustomFieldsStatusEdit = createSelector(
  selectUsersState,
  (usersState) => usersState.customFieldsStatusEdit
);
export const selectCustomFieldsListEdit = createSelector(
  selectUsersState,
  (usersState) => usersState.customFieldsListEdit
);

export const selectCustomFieldsListErrorEdit = createSelector(
  selectUsersState,
  (usersState) => usersState.customFieldsListErrorEdit
);
export const selectTemplateList = createSelector(selectUsersState, (usersState) => usersState.templateList);

export const selectSmsList = createSelector(selectUsersState, (usersState) => usersState.sms_templateList);

export const selectMmsList = createSelector(selectUsersState, (usersState) => usersState.mms_templateList);

export const selectCreditStatus = createSelector(selectUsersState, (usersState) => usersState.AddingCredit);

export const userDeleteStatus = createSelector(selectUsersState, (usersState) => usersState.UserDelete);

export const userAddStatus = createSelector(selectUsersState, (usersState) => usersState.addStatus);

export const userActivateStatus = createSelector(selectUsersState, (usersState) => usersState.UserActivate);

export const selectChildList = createSelector(selectUsersState, (userState) => userState.childList);

export const userCount = createSelector(selectUsersState, (userState) => userState.count);

export const vCardLoader = createSelector(selectUsersState, (userState) => userState.AddingVcard);

export const sendingVcard = createSelector(selectUsersState, (userState) => userState.ContactSendingSms);

export const selectAiSettings = createSelector(selectUsersState, (userState) => userState.aiSettings);
export const selectAiSettingsSuccess = createSelector(selectUsersState, (userState) => userState.aiSettingsSuccess);

export const selectEmailGeneralTemplateList = createSelector(
  selectUsersState,
  (usersState) => usersState.emailGeneraltemplateList
);

export const selectCampaignUsersNCount = createSelector(selectUsersState, (state) => ({
  count: state.campaignUsersCount,
  list: state.campaignUsersList,
  loading: state.campaignUserLoading,
}));
export const selectCampaignListNCount = createSelector(selectUsersState, (state) => ({
  count: state.campaignListCount,
  loading: state.campaignListLoading,
  list: state.campaignList,
  curPage: state.campaignListCurPage,
  uid: state.currentCampaignUid,
}));

export const selectOldPayload = createSelector(selectUsersState, (state) => ({
  oldPayload: state.oldCampPayload,
}));
export const selectcampUserCurPage = createSelector(selectUsersState, (state) => ({
  curPage: state.campaignUsersCurPage,
}));
export const selectFileUpload = createSelector(selectUsersState, (usersState) => usersState.fileUploading);

export const userDeleteLoader = createSelector(selectUsersState, (usersState) => usersState.UserDeleteLoading);

export const selectExtraMergeFields = createSelector(selectUsersState, (usersState) => usersState.extraMergeFields);

export const selectHistoryListNCount = createSelector(selectUsersState, (state) => ({
  loading: state.HistoryListLoading,
  list: state.HistoryList,
  count: state.HistoryListCount,
}));

export const selectPurposeListStatus = createSelector(selectUsersState, (usersState) => usersState.PurposeList);

export const selectRefinancePurposeList = createSelector(
  selectUsersState,
  (usersState) => usersState.RefinancePurposeList
);
export const selectPurchasePurposeList = createSelector(
  selectUsersState,
  (usersState) => usersState.PurchasePurposeList
);

export const selectPackage = createSelector(selectUsersState, (state) => ({
  packageLoading: state.PackageLoading,
  package: state.Package,
}));
