import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoRecordingService } from '../../services/video-recording.service';

@Component({
  selector: 'dailyai-video-play-model',
  templateUrl: './video-play-model.component.html',
  styleUrls: ['./video-play-model.component.scss']
})
export class VideoPlayModelComponent implements OnDestroy, OnInit {
  @ViewChild('video') video;

  isRecording = false;

  icons = 'play_arrow';

  currentUser$: Observable<any>;

  userDetails;

  URL = '';

  @Input()
  set videoURL(pValue) {
    this.URL = pValue;
  }

  @Input() videoThumbNail: string;


  constructor(public modal: NgbModal,
    private videoRecordingService: VideoRecordingService,
    private store: Store<any>,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.subscribe(data => {
      this.userDetails = data;

    });
  }

  ngAfterViewInit() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.controls = true;
    video.autoplay = false;
  }

  startPlayVideo() {
    const video: HTMLVideoElement = this.video.nativeElement;
    video.controls = false;
    video.paused ? video.play() : video.pause();
    if (video.paused) {
      video.pause();
      this.icons = 'play_arrow';
    } else {
      video.play();
      this.icons = 'pause';
    }
  }

  abortRecording() {
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }
}
