import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuardService implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const user: any = await this.auth.isLoggedIn();
    const userData = await this.auth.getDB();

    if (user) {
      this.auth.setUser();
      if (userData.userType === 'superAdmin') {
        this.auth.signout();
        return false;
      }
      if (userData.status && userData.status === 'Deactive') {
        this.toastr.warning('Your Account Has Been Deactivated By Your SLO', 'Authentication Error');
        this.router.navigate(['/login']);
        this.auth.signout();
        return false;
      }
      if (userData.test) {
        return true;
      }
      if (userData.onboarding.status === 'complete') {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
      this.router.navigate(['/login']);
      return false;

  }
}
