import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { TaskService } from '../services/tasks.service';
import * as TaskStateActions from './action';

@Injectable()
export class TaskEffects {
  constructor(
    private actions$: Actions,
    private taskService: TaskService,
    private appService: AppService,
    private store$: Store<State>,
    private subService: SubscriptionService
  ) { }

  ListTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskStateActions.FetchAssignedTasks),
      switchMap(payload => {
        this.appService.showLoading(true);
        return this.taskService.getTasks().pipe(
          takeUntil(this.subService.unsubscribe$),
          map(x => {
            this.appService.showLoading(false);
            return TaskStateActions.TaskResults({ data: x });
          })
        );
      })
    )
  );

  FetchTaskCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskStateActions.FetchTaskCount),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap(payload =>
        from(this.taskService.fetchCount(payload.state.login.currentUser.uid)).pipe(
          map(data => {
            return TaskStateActions.TaskCount({ data: data['count'] });
          })
        )
      )
    )
  );

  FetchTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskStateActions.FetchTasks),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap(payload => {
        this.appService.showLoading(true);
        return from(
          this.taskService.fetchTasks({ curPage: payload.state.tasks.curPage }, payload.state.login.currentUser.uid)
        ).pipe(
          map(tasks => {
            this.appService.showLoading(false);
            return TaskStateActions.TaskResults({ data: tasks });
          })
        );
      })
    )
  );

  FilterTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskStateActions.FilterTasks),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap(payload => {
        this.appService.showLoading(true);
        return from(this.taskService.filterTasks(payload.state.tasks.params, payload.state.login.currentUser.uid)).pipe(
          map(result => {
            this.appService.showLoading(false);
            return TaskStateActions.TaskResults({ data: result });
          })
        );
      })
    )
  );

  SortContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskStateActions.SortTasks),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap(payload => {
        this.appService.showLoading(true);
        return from(this.taskService.sortTasks(payload.action.data, payload.state.login.currentUser.uid)).pipe(
          map(result => {
            this.appService.showLoading(false);
            return TaskStateActions.TaskResults({ data: result });
          })
        );
      })
    )
  );
}
