import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import { environment, firebase as config } from 'src/environments/environment';

const app = firebase.initializeApp(config);
if (environment.emulator) {
  const baseurl = environment.custom_network ? 'cf-emulator' : 'localhost';
  app.auth().useEmulator(`http://${baseurl}:9099`);
  app.firestore().useEmulator(baseurl, 8081);

  app.storage().useEmulator(baseurl, 9199);
}
const db = firebase.firestore(app);

export const getAppFirebaseDB = () => {
  return db;
}
