import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNoKeypress]'
})
export class NoKeyDirective {
  constructor(private el: ElementRef) { }

  @Input() appNoKeypress: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if (this.appNoKeypress) {
      e.stopPropagation();
      e.preventDefault();
    }
    e.stopPropagation();
  }
}
