<div class="px-2 pb-3">
  <div class="modal-header pb-0">
    <h5 class="color-grey-text font-15">{{ editMode ? 'EDIT' : 'CREATE' }} NOTE</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeEvent()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="px-4 my-2">
    <ckeditor
      [(ngModel)]="noteString"
      #myckeditor
      name="myckeditor"
      required
      [config]="ckeConfig"
      debounce="500"
      (ready)="viewEditorready()"
    >
    </ckeditor>
    <div *ngIf="!viewEditorLoaded" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div class="error font-11 px-4 my-2" *ngIf="addNoteSave && this.noteWithoutTagsLength === 0">*invalid note</div>
  <div class="radio-btns-area px-2" ngbRadioGroup name="radioBasic">
    <label [ngbTooltip]="tooltipVisibilityMessages.public" tooltipClass="tooltip-page-class"  ngbButtonLabel class="font-14">
      <input
        id="public"
        ngbButton
        type="radio"
        name="visibility"
        [value]="getVisibilityType.public"
        class="mr-1"
        [(ngModel)]="visibility"
      />
      Make Public
    </label>
    <label [ngbTooltip]="tooltipVisibilityMessages.internal" tooltipClass="tooltip-page-class" ngbButtonLabel class="font-14">
      <input
        ngbButton
        type="radio"
        name="visibility"
        [value]="getVisibilityType.internal"
        class="mr-1"
        [(ngModel)]="visibility"
        id="internal"
      />
      Make Internal
    </label>
    <label [ngbTooltip]="tooltipVisibilityMessages.private" tooltipClass="tooltip-page-class" ngbButtonLabel class="font-14">
      <input
        ngbButton
        type="radio"
        name="visibility"
        [value]="getVisibilityType.private"
        class="mr-1"
        [(ngModel)]="visibility"
        id="private"
      />
      Make Private
    </label>
  </div>
  <div class="d-flex justify-content-end mx-4 v2-ui-integretion mb-3">
    <button class="btn btn-primary" (click)="addNote()">{{ editMode ? 'Save' : 'Add' }} Note</button>
  </div>
</div>
