import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaService {
  private subject = new Subject<any>();

  sendSelected(item: Object) {
    this.subject.next({ value: item });
  }

  clearMessage() {
    this.subject.next();
  }

  getMedia(): Observable<any> {
    return this.subject.asObservable();
  }
}
