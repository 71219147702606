import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralTemplatesService {
  constructor(private db: AngularFirestore, private toastr: ToastrService) {}

  async addEmailTemplate(payload) {
    if (payload.template_type === 'email_template') {
      const addTemp = await this.db.collection<any>('general-templates').add({ ...payload });
      this.toastr.success('Template saved successfully');
      return this.db.collection<any>('general-templates').doc(addTemp.id).update({ templateId: addTemp.id });
    }
  }

  listEmailTemplates(uid: string): Observable<any> {
    return this.db
      .collection<any>('general-templates', (ref) => ref.where('viewersList', 'array-contains', uid))
      .valueChanges();
  }

  async updateTemplate(payload: any): Promise<any> {
    return this.db.collection<any>('general-templates').doc(payload.templateId).update({
      templateName: payload.templateName,
      templateSubject: payload.templateSubject,
      templateContent: payload.templateContent,
      email_attachments_array: payload.email_attachments_array,
      cc: payload.cc,
      bcc: payload.bcc,
    });
  }

  async deleteEmailTemplate(uid: string): Promise<any> {
    return this.db.collection<any>('general-templates').doc(uid).delete();
  }

  deleteMultipleEmailTemplates(templates) {
    templates.forEach((element) => {
      this.db.collection<any>('general-templates').doc(element.templateId).delete();
    });
    this.toastr.success('Templates deleted successfully');

  }

  getCurrentManagers(enterpriseId) {
    const managersRef = this.db
      .collection<any>('users', (ref) =>
        ref
          .where('userType', '==', 'manager')
          .where('enterpriseId', '==', enterpriseId)
          .where('paymentPermission', '==', true)
      )
      .valueChanges();
    return managersRef;
  }

  async updatePermission(payload: any, uId: string) {
    await this.db.collection<any>('permissions').doc(uId).set(payload, { merge: true });
  }

  async getPermission(id) {
    const permissionsSnapshot = await this.db.collection<any>('permissions').doc(id).get().toPromise();
    return permissionsSnapshot.data();
  }

  changeCompanyName(uid, companyName) {
    this.db
      .collection<any>('users')
      .doc(uid)
      .update({ companyName })
      .then((data) => {
        this.toastr.success('Company Name Changed');
      });
  }

  removePaymentPermission(uid) {
    this.db
      .collection<any>('users')
      .doc(uid)
      .update({ paymentPermission: false })
      .then((data) => {
        this.toastr.success('Manager Removed');
      });
  }

  addPaymentPermission(uid) {
    this.db
      .collection<any>('users')
      .doc(uid)
      .update({ paymentPermission: true })
      .then((data) => {
        this.toastr.success('Manager Added');
      });
  }
}
