import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { LoginState } from 'src/app/modules/login/store/state';
import { environment } from 'src/environments/environment';
import { SharedService } from '../../services/shared.service';
import { ConfirmVerifiedModalComponent } from '../confirm-verified-modal/confirm-verified-modal.component';

@Component({
  selector: 'dailyai-verified-number-change',
  templateUrl: './verified-number-change.component.html',
  styleUrls: ['./verified-number-change.component.scss']
})
export class VerifiedNumberChangeComponent implements OnInit, OnDestroy {

  changePhoneNumberForm: FormGroup;

  phnoPattern = '^[1-9]{1}[0-9]{9}$';

  modalReference: NgbModalRef;

  oldPhone: any;

  @Input() fromPhone;

  verificationCode: [] = [];

  currentUser$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  currentUser: any;

  phoneStatus: any;

  phoneStatusFail: any;

  loading: boolean;

  constructor(
    private ngbModal: NgbModal,
    private _sharedService: SharedService,
    private store: Store<LoginState>,
    private toastr: ToastrService,

  ) {
    this.changePhoneNumberForm = this.createPhonenumberForm();
    this.currentUser$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectCurrentUser));
    this.currentUser$.subscribe((data) => {
      this.currentUser = data;
    })
  }

  ngOnInit(): void {
    this.oldPhone = this.fromPhone.prop1.phoneNo;
    this.getPhoneStatus()
  }

  getPhoneStatus() {
    this._sharedService
      .changeUserPhone(this.currentUser?.uid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const status = data?.status;
        if (this.phoneStatus === undefined) {
          this.phoneStatus = status;
        } else if (this.phoneStatus !== status) {
          this.phoneStatus = status;
        }
      });
  }

  createPhonenumberForm() {
    return new FormGroup({
      new_number: new FormControl('', [Validators.required, Validators.pattern(this.phnoPattern)]),
      vCard: new FormControl(false),
      website: new FormControl(false)
    });
  }

  async otpVerify() {
    this.verificationCode = []
    this.loading = true;
    const { new_number, vCard, website } = this.changePhoneNumberForm.value
    const phoneNumberObj = {
      "update": true,
      "website": website,
      "vCard": vCard,
      "phoneNumber": this.getNum(new_number),
      "friendlyName": this.getNum(new_number)
    }
    const request = await this._sharedService.getValidationCode(phoneNumberObj);
    request.pipe(take(1)).subscribe(
      (response: any) => {
        if (response && response.validationCode) {
          this.ngbModal.dismissAll();
          const modalReference = this.ngbModal.open(ConfirmVerifiedModalComponent, {
            centered: true,
            windowClass: 'model-campaign-filter',
            keyboard: false,
            backdrop: 'static',
          });
          this.verificationCode = response.validationCode;
          const data = {
            prop1: this.verificationCode,
            prop2: phoneNumberObj,
          };
          modalReference.componentInstance.fromPhoneConfirm = data;
        }
      },
      (error) => {
        this.loading = false;
        if(error?.error?.toast){
          return this.toastr.error(error?.error?.toast, 'Error Message');
        }
          return this.toastr.error('User already exists in CRM', 'Error Message');

      }
    );
  }

  getNum(num): any {
    const {countryCode} = environment
    return `${countryCode}${  num}`;
  }

  cancel() {
    this.ngbModal.dismissAll();
  }

  get new_number() {
    return this.changePhoneNumberForm.get('new_number');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
