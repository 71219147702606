import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export enum NoteVisibility {
  public = "public",
  internal = "internal",
  private = "private"
}

@Component({
  selector: 'dailyai-note-popup',
  templateUrl: './note-popup.component.html',
  styleUrls: ['./note-popup.component.scss'],
})
export class NotePopupComponent {
  noteString = '';

  visibility = NoteVisibility.internal;

  addNoteSave = false;

  noteWithoutTagsLength = 0;

  viewEditorLoaded = false;

  editMode = false;

  getVisibilityType = NoteVisibility;

  ckeConfig = {
    title: 'Note',
    allowedContent: true,
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'basicstyles', groups: ['basicstyles'] },
      { name: 'paragraph', groups: ['list', 'blocks', 'align'] },
      { name: 'colors' },
    ],
    skin: 'kama',
    resize_enabled: false,
    removePlugins: 'smiley,indentblock,elementspath',
    forcePasteAsPlainText: false,
    extraPlugins: 'divarea,justify,colordialog,uploadimage',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: '',
    removeButtons:
      'Cut,Copy,Insert,Cleanup,Subscript,Superscript,links,Paste,PasteText,PasteFromWord,Save,NewPage,CopyFormatting,RemoveFormat,CreateDiv,Iframe,Flash,Blockquote,Anchor,Templates,Print,Find,Replace,SelectAll,Table,HorizontalRule,SpecialChar,PageBreak,Styles',

    format_tags: 'p;h1;h2;h3;pre;div',
  };

  tooltipVisibilityMessages = {
    [NoteVisibility.internal]: 'Shown to all users that have access to the contact but not included on partner reports',
    [NoteVisibility.public]: 'Shown to all users that have access to the contact and included on partner reports',
    [NoteVisibility.private]: 'Only shown to the user that created the note and not included on partner reports'
  }

  @Output()
  closed: EventEmitter<any> = new EventEmitter<any>();

  constructor(public modalService: NgbModal) { }

  closeEvent() {
    this.modalService.dismissAll();
  }

  validate() {
    return this.noteString
      ? this.noteString
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/ /g, '')
        .replace(/&nbsp;/g, '')
        .replace(/\n/g, '').length
      : 0;
  }

  /**
   * to add note
   */
  addNote() {
    this.addNoteSave = true;
    if (this.validate() !== 0) {
      const payload = {
        note: this.noteString.replace(/\n/g, ''),
        visibility: this.visibility
      };
      this.closed.emit(payload);
    }
  }

  editNoteInit(noteObj: any) {
    if (!noteObj) {
      return;
    }
    const { note, visibilityType } = noteObj;
    this.noteString = note;
    this.visibility = visibilityType;
    this.editMode = true;
  }

  viewEditorready() {
    this.viewEditorLoaded = true;
  }
}
