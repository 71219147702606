import { createReducer, on } from '@ngrx/store';
import * as ContactsStateActions from './action';

import { ContactsState } from './state';

export const initialState: ContactsState = {
  UploadCsv: false,
  UploadCsvError: null,
  UploadCsvSuccess: null,
  ContactList: [],
  ContactListError: null,
  ContactListSuccess: null,
  ContactAdd: false,
  ContactDelete: false,
  ContactUpdate: false,
  ContactAddError: null,
  ContactAddSuccess: null,
  ContactDeleteError: null,
  ContactDeleteSuccess: null,
  ContactUpdateError: null,
  ContactUpdateSuccess: null,
  ContactDetail: null,
  ContactDetailError: '',
  ContactDetailLoading: true,
  ContactFileAdd: false,
  FileUploadProgress: 0,
  ContactFileAddError: null,
  ContactFileAddSuccess: false,
  GetContactFiles: [],
  GetContactFilesSuccess: false,
  GetContactFilesError: null,
  DeleteContactFile: false,
  DeleteContactFileSuccess: false,
  DeleteContactFileError: null,
  UpdateContactFile: false,
  UpdateContactFileSuccess: false,
  UpdateContactFileError: null,
  DownloadFile: false,
  DownloadFileSuccess: false,
  DownloadFileError: null,
  AddNote: false,
  AddNoteSuccess: false,
  AddNoteError: null,
  GetNotes: [],
  GetNotesSuccess: false,
  GetNotesError: null,
  UpdateNotes: false,
  UpdateNotesSuccess: false,
  UpdateNotesError: null,
  GetMyUsers: [],
  GetMyUsersSuccess: false,
  addToDripError: null,
  addToDripSuccess: null,
  addingToDrips: false,
  batchAddToDripError: null,
  batchAddToDripSuccess: null,
  batchAddingToDrips: false,
  removingFromDripError: null,
  removingFromDrips: false,
  removingFromToDripSuccess: null,
  getSLO: null,
  count: 0,
  curPage: 1,
  MyDrips: null,
  GetTagsList: [],
  GetTagsListSuccess: false,
  GetTagsListError: null,
  AssignTags: [],
  AssignTagsSuccess: false,
  AssignTagsError: null,
  DeleteAssignedTags: [],
  DeleteAssignedTagsSuccess: false,
  DeleteAssignedTagsError: null,
  assignDripModalOpen: false,
  filterData: null,
  searchTerm: null,
  sortOrder: null,
  sortBy: null,
  contact_ids: [],
  massEmailSending: false,
  massEmailSuccess: false,
  massEmailError: null,
  massSmsSending: false,
  massSmsSuccess: false,
  massSmsError: null,
  contactWithDemand: [],
  contactWithPaginationError: null,
  contactWithPaginationSuccess: null,
  last_doc: null,
  loadingContactWithPagination: null,
  ContactTasks: null,
  SearchContactsList: [],
  massVoiceDropSuccess: false,
  massVoiceDropSending: false,
  massVoiceDropError: null,
  onlyMe: false,
  mongoFetchParams: {},
  lastAction: 'fetch',
  extraContactIds: [],
  curPage2: 1,
  loadingContactIds: false,
  areAllSelected: false,
  setParentContactError: null,
  setParentContactSuccess: false,
  setParentContact: false,
  deleteNote: false,
  deleteNoteError: null,
  deleteNoteSuccess: false,
  massEmailCampain: false,
  massEmailCampainError: null,
  massEmailCampainSuccess: false,
  testEmailCampagin: false,
  testEmailCampaginSuccess: false,
  testEmailCampaginError: null,
  testEmailNormal: false,
  testEmailNormalSuccess: false,
  testEmailNormalError: null,
  lastPayload: null,
  contactListLoading: false,
};

export const ContactsReducer = createReducer(
  initialState,
  on(ContactsStateActions.BatchAddContactToDrips, (state) => ({
    ...state,
    batchAddToDripError: null,
    batchAddToDripSuccess: null,
    batchAddingToDrips: true,
  })),
  on(ContactsStateActions.contactsOnlyMe, (st, payload) => ({
    ...st,
    onlyMe: payload.onlyMe,
  })),
  on(ContactsStateActions.setAreAllSelected, (st, payload) => ({
    ...st,
    areAllSelected: payload.areAllSelected,
  })),
  on(ContactsStateActions.BatchAddContactToDripsSuccess, (state) => ({
    ...state,
    batchAddToDripError: null,
    batchAddToDripSuccess: true,
    batchAddingToDrips: false,
  })),
  on(ContactsStateActions.BatchAddContactToDripsError, (state, data) => ({
    ...state,
    batchAddToDripError: data.msg,
    batchAddToDripSuccess: null,
    batchAddingToDrips: false,
  })),
  on(ContactsStateActions.AddContactToDrips, (state) => ({
    ...state,
    addToDripError: null,
    addToDripSuccess: null,
    addingToDrips: true,
  })),
  on(ContactsStateActions.AddContactToDripsSuccess, (state) => ({
    ...state,
    addToDripError: null,
    addToDripSuccess: true,
    addingToDrips: false,
  })),
  on(ContactsStateActions.AddContactToDripsError, (state, data) => ({
    ...state,
    addToDripError: data.msg,
    addToDripSuccess: null,
    addingToDrips: false,
  })),
  on(ContactsStateActions.RemoveContactFromDrips, (state) => ({
    ...state,
    removingFromDrips: true,
    removingFromDripError: null,
    removingFromToDripSuccess: null,
  })),
  on(ContactsStateActions.RemoveContactFromDripsSuccess, (state) => ({
    ...state,
    removingFromDrips: false,
    removingFromDripError: null,
    removingFromToDripSuccess: true,
  })),
  on(ContactsStateActions.RemoveContactFromDripsError, (state, data) => ({
    ...state,
    removingFromDrips: false,
    removingFromDripError: data.msg,
    removingFromToDripSuccess: null,
  })),
  on(ContactsStateActions.AddContact, (state) => ({
    ...state,
    ContactUpdateSuccess: false,
    ContactUpdateError: null,
    ContactAdd: true,
    ContactAddError: null,
    ContactAddSuccess: true,
  })),
  on(ContactsStateActions.AddContactSuccess, (state) => ({
    ...state,
    ContactUpdateSuccess: false,
    ContactUpdateError: null,
    ContactAdd: true,
    ContactAddError: null,
    ContactAddSuccess: true,
  })),
  on(ContactsStateActions.AddContactError, (state, payload) => ({
    ...state,
    ContactUpdateSuccess: false,
    ContactUpdateError: null,
    ContactAddError: payload.msg,
    ContactAdd: false,
    ContactAddSuccess: false,
  })),
  on(ContactsStateActions.DeleteContact, (state) => ({
    ...state,
    ContactDelete: true,
    ContactDeleteError: null,
    ContactDeleteSuccess: true,
  })),
  on(ContactsStateActions.DeleteContactSuccess, (state) => ({
    ...state,
    ContactDelete: true,
    ContactDeleteError: null,
    ContactDeleteSuccess: true,
  })),
  on(ContactsStateActions.DeleteContactError, (state, payload) => ({
    ...state,
    ContactDelete: false,
    ContactDeleteError: payload.msg,
    ContactDeleteSuccess: false,
  })),
  on(ContactsStateActions.UpdateContact, (state) => ({
    ...state,
    ContactAdd: false,
    ContactAddSuccess: false,
    ContactAddError: null,
    ContactUpdate: true,
    ContactUpdateSuccess: null,
    ContactUpdateError: null,
  })),
  on(ContactsStateActions.UpdateContactSuccess, (state) => ({
    ...state,
    ContactAdd: false,
    ContactAddSuccess: false,
    ContactAddError: null,
    ContactUpdate: true,
    ContactUpdateError: null,
    ContactUpdateSuccess: true,
  })),
  on(ContactsStateActions.UpdateContactError, (state, payload) => ({
    ...state,
    ContactAdd: false,
    ContactAddSuccess: false,
    ContactAddError: null,
    ContactUpdate: false,
    ContactUpdateError: payload.msg,
    ContactUpdateSuccess: false,
  })),
  on(ContactsStateActions.GetContactDetail, (state, payload) => ({
    ...state,
    ContactDetailLoading: true,
    ContactDetailError: '',
  })),
  on(ContactsStateActions.GetContactDetailSuccess, (state, res) => ({
    ...state,
    ContactDetailLoading: false,
    ContactDetail: res.detail,
  })),
  on(ContactsStateActions.ClearContactDetail, (state) => ({
    ...state,
    ContactDetail: null,
  })),
  on(ContactsStateActions.GetContactDetailError, (state, res) => ({
    ...state,
    ContactDetailLoading: false,
    ContactDetailError: res.msg,
  })),

  on(ContactsStateActions.ListContact, (state) => ({
    ...state,
    ContactListSuccess: false,
    contactListLoading: true,
  })),
  on(ContactsStateActions.FetchContactModified, (state, payload) => ({
    ...state,
    lastPayload: payload.data,
    mongoFetchParams: payload.data,
    ContactListSuccess: !!payload.silent,
    contactListLoading: !payload.silent,
    curPage: payload.data && payload.data.curPage ? payload.data.curPage : 1,
  })),
  on(ContactsStateActions.ListContactSuccess, (state, payload) => {
    const data: any[] = [...payload.data];
    return {
      ...state,
      ContactListSuccess: true,
      ContactList: data,
      count: payload.count !== undefined ? payload.count : state.count,
      contact_ids: data !== undefined ? data.map((cont) => cont.firebase_id) : state.contact_ids,
      curPage2: state.curPage,
    };
  }),
  on(ContactsStateActions.getExtraContactIdsSuccess, (state, payload) => ({
    ...state,
    extraContactIds: [...new Set(payload.data.map((d) => d.doc_id))],
    loadingContactIds: false,
  })),
  on(ContactsStateActions.ListSearchSuccess, (state, payload) => {
    const data: any[] = [...payload.data];
    return {
      ...state,
      SearchContactsList: data,
    };
  }),
  on(ContactsStateActions.UpdateContactError, (state, payload) => ({
    ...state,
    ContactListSuccess: false,
    contactListLoading: true,
    ContactListError: payload.msg,
  })),
  on(ContactsStateActions.FilterContact, (state, payload) => ({
    ...state,
    filterData: payload.data.params,
    curPage: payload.data.curPage,
    mongoFetchParams: payload.data,
    lastAction: 'filter',
    loadingContactIds: true,
  })),
  on(ContactsStateActions.SortContact, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage,
    sortBy: payload.data.sortby,
    sortOrder: payload.data.order,
    mongoFetchParams: payload.data,
    lastAction: 'sort',
    loadingContactIds: true,
  })),
  on(ContactsStateActions.SearchContact, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage,
    searchTerm: payload.data.term,
    mongoFetchParams: payload.data,
    lastAction: 'search',
    loadingContactIds: true,
  })),
  on(ContactsStateActions.ClearForm, (state) => ({
    ...state,
    ContactAdd: false,
    ContactAddSuccess: false,
    ContactAddError: null,
    ContactUpdate: false,
    ContactUpdateSuccess: false,
    ContactUpdateError: null,
  })),
  on(ContactsStateActions.ContactFileAdd, (state) => ({
    ...state,
    ContactFileAdd: true,
    ContactFileAddError: null,
    ContactFileAddSuccess: false,
    FileUploadProgress: 0,
  })),
  on(ContactsStateActions.FileUploadProgress, (state, payload) => ({
    ...state,
    FileUploadProgress: payload.progress,
  })),
  on(ContactsStateActions.ContactFileAddSuccess, (state) => ({
    ...state,
    ContactFileAdd: false,
    ContactFileAddError: null,
    ContactFileAddSuccess: true,
  })),
  on(ContactsStateActions.ContactFileAddError, (state, payload) => ({
    ...state,
    ContactFileAdd: false,
    ContactFileAddError: payload.msg,
    ContactFileAddSuccess: false,
  })),
  on(ContactsStateActions.UploadCsv, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.UploadCsvSuccess, (state, payload) => ({
    ...state,
    UploadCsv: false,
    UploadCsvError: null,
    UploadCsvSuccess: true,
  })),
  on(ContactsStateActions.UploadCsvError, (state, payload) => ({
    ...state,
    UploadCsv: false,
    UploadCsvError: payload.msg,
    UploadCsvSuccess: false,
  })),
  on(ContactsStateActions.GetContactFiles, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetContactFilesSuccess, (state, payload) => ({
    ...state,
    GetContactFilesSuccess: true,
    GetContactFiles: payload.data,
  })),
  on(ContactsStateActions.GetContactFilesError, (state, payload) => ({
    ...state,
    GetContactFilesError: payload.msg,
  })),
  on(ContactsStateActions.DeleteContactFile, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.DeleteContactFileSuccess, (state) => ({
    ...state,
    DeleteContactFileSuccess: true,
  })),
  on(ContactsStateActions.DeleteContactFileError, (state, payload) => ({
    ...state,
    DeleteContactFileError: payload.msg,
    DeleteContactFileSuccess: true,
  })),
  on(ContactsStateActions.UpdateContactFile, (state) => ({
    ...state,
    UpdateContactFile: true,
  })),
  on(ContactsStateActions.UpdateContactFileSuccess, (state) => ({
    ...state,
    UpdateContactFileSuccess: true,
  })),
  on(ContactsStateActions.UpdateContactFileError, (state, payload) => ({
    ...state,
    UpdateContactFile: false,
    UpdateContactFileError: payload.msg,
    UpdateContactFileSuccess: false,
  })),
  on(ContactsStateActions.DownloadFile, (state) => ({
    ...state,
    DownloadFile: true,
  })),
  on(ContactsStateActions.DownloadFileSuccess, (state) => ({
    ...state,
    DownloadFileSuccess: true,
    DownloadFile: false,
  })),
  on(ContactsStateActions.DownloadFileError, (state, payload) => ({
    ...state,
    DownloadFile: false,
    DownloadFileError: payload.msg,
    DownloadFileSuccess: false,
  })),

  on(ContactsStateActions.AddNote, (state) => ({
    ...state,
    AddNote: true,
  })),
  on(ContactsStateActions.AddNoteSuccess, (state) => ({
    ...state,
    AddNoteSuccess: true,
    AddNote: false,
  })),
  on(ContactsStateActions.AddNoteError, (state, payload) => ({
    ...state,
    AddNote: false,
    AddNoteError: payload.msg,
    AddNoteSuccess: false,
  })),

  on(ContactsStateActions.GetNotes, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetNotesSuccess, (state, payload) => ({
    ...state,
    GetNotesSuccess: true,
    GetNotes: payload.data,
  })),
  on(ContactsStateActions.GetNotesError, (state, payload) => ({
    ...state,
    GetNotesSuccess: false,
    GetNotesError: payload.msg,
  })),
  on(ContactsStateActions.UpdateNotes, (state) => ({
    ...state,
    UpdateNotes: true,
    UpdateNotesSuccess: null,
  })),
  on(ContactsStateActions.UpdateNotesSuccess, (state) => ({
    ...state,
    UpdateNotesSuccess: true,
    UpdateNotesError: null,
    UpdateNotes: null,
  })),
  on(ContactsStateActions.UpdateNotesError, (state, payload) => ({
    ...state,
    UpdateNotesError: payload.msg,
    UpdateNotesSuccess: null,
    UpdateNotes: null,
  })),
  on(ContactsStateActions.GetMyUsers, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetMySLO, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetMySLOSuccess, (state, payload) => ({
    ...state,
    getSLO: payload.data,
  })),
  on(ContactsStateActions.GetMyUsersSuccess, (state, payload) => ({
    ...state,
    GetMyUsers: payload.data,
    GetMyUsersSuccess: true,
  })),
  on(ContactsStateActions.SaveContactCount, (state, payload) => ({
    ...state,
    count: payload.count,
  })),
  on(ContactsStateActions.FetchContact, (state, payload) => ({
    ...state,
    curPage: payload.data.curPage,
    mongoFetchParams: payload.data,
    lastAction: 'fetch',
    loadingContactIds: true,
  })),
  on(ContactsStateActions.GetMyDripData, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetMyDripDataSuccess, (state, payload) => ({
    ...state,
    MyDrips: payload.data,
  })),
  on(ContactsStateActions.GetTagsList, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.GetTagsListSuccess, (state, payload) => ({
    ...state,
    GetTagsListSuccess: true,
    GetTagsList: payload.data,
  })),
  on(ContactsStateActions.GetTagsListError, (state, payload) => ({
    ...state,
    GetTagsListSuccess: false,
    GetTagsListError: payload.msg,
  })),
  on(ContactsStateActions.AssignTags, (state) => ({
    ...state,
  })),
  on(ContactsStateActions.AssignTagsSuccess, (state, payload) => ({
    ...state,
    AssignTagsSuccess: true,
    AssignTags: payload.data,
  })),
  on(ContactsStateActions.AssignTagsError, (state, payload) => ({
    ...state,
    AssignTagsSuccess: false,
    AssignTagsError: payload.msg,
  })),
  on(ContactsStateActions.OpenAssignDripModal, (state) => ({
    ...state,
    assignDripModalOpen: true,
  })),
  on(ContactsStateActions.CloseAssignDripModal, (state) => ({
    ...state,
    assignDripModalOpen: false,
  })),
  on(ContactsStateActions.massEmail, (state, payload) => ({
    ...state,
    massEmailSending: true,
  })),
  on(ContactsStateActions.massEmailSuccess, (state, payload) => ({
    ...state,
    massEmailSuccess: true,
    massEmailError: false,
    massEmailSending: false,
  })),
  on(ContactsStateActions.massEmailError, (state, payload) => ({
    ...state,
    massEmailSuccess: false,
    massEmailError: payload.data,
    massEmailSending: false,
  })),
  on(ContactsStateActions.massSms, (state, payload) => ({
    ...state,
    massSmsSending: true,
  })),
  on(ContactsStateActions.massSmsSuccess, (state, payload) => ({
    ...state,
    massSmsSuccess: true,
    massSmsSending: false,
    massSmsError: false,
  })),
  on(ContactsStateActions.massSmsError, (state, payload) => ({
    ...state,
    massSmsSuccess: false,
    massSmsSending: false,
    massSmsError: payload.data,
  })),
  on(ContactsStateActions.massVoiceDrop, (state) => ({
    ...state,
    massVoiceDropSending: true,
  })),
  on(ContactsStateActions.massVoiceDropSuccess, (state, payload) => ({
    ...state,
    massVoicedropSuccess: true,
    massVoiceDropSending: false,
    massVoiceDropError: false,
  })),
  on(ContactsStateActions.massVoiceDropError, (state, payload) => ({
    ...state,
    massVoiceDropSuccess: false,
    massVoiceDropSending: false,
    massVoiceDropError: payload.data,
  })),
  on(ContactsStateActions.getContactWithPagination, (state) => ({
    ...state,
    loadingContactWithPagination: true,
    contactWithPaginationSuccess: null,
    contactWithPaginationError: null,
  })),
  on(ContactsStateActions.contactWithPaginationSuccess, (state, payload) => {
    let tempArray = payload.data.data ? payload.data.data : payload.data.contact;
    if (!payload.data.data) {
      tempArray = [...state.contactWithDemand, ...tempArray];
    }

    return {
      ...state,
      loadingContactWithPagination: false,
      contactWithPaginationSuccess: true,
      contactWithPaginationError: null,
      contactWithDemand: tempArray,
    };
  }),
  on(ContactsStateActions.contactWithPaginationError, (state, payload) => ({
    ...state,
    loadingContactWithPagination: false,
    contactWithPaginationSuccess: null,
    contactWithPaginationError: payload.data,
  })),
  on(ContactsStateActions.getLastDocSuccess, (state, payload) => {
    return {
      ...state,
      last_doc: '',
    };
  }),
  on(ContactsStateActions.resetLastDoc, (state) => ({
    ...state,
    last_doc: null,
  })),
  on(ContactsStateActions.GetContactTaskSuccess, (state, payload) => ({
    ...state,
    ContactTasks: payload.detail,
  })),
  on(ContactsStateActions.resetContactTask, (state, payload) => ({
    ...state,
    ContactTasks: [],
  })),
  on(ContactsStateActions.resetContactDemandList, (state) => ({
    ...state,
    contactWithDemand: [],
    last_doc: null,
  })),
  on(ContactsStateActions.setParentContact, (state, payload) => ({
    ...state,
    setParentContactSuccess: false,
    setParentContact: true,
    setParentContactError: null,
  })),
  on(ContactsStateActions.setParentContactSuccess, (state, payload) => ({
    ...state,
    setParentContactError: null,
    setParentContact: false,
    setParentContactSuccess: true,
  })),
  on(ContactsStateActions.setParentContactError, (state, payload) => ({
    ...state,
    setParentContactSuccess: false,
    setParentContactError: payload.msg,
    setParentContact: false,
  })),
  on(ContactsStateActions.deleteNote, (state, action) => ({
    ...state,
    deleteNoteSuccess: false,
    deleteNoteError: null,
    deleteNote: true,
  })),
  on(ContactsStateActions.deleteNoteSuccess, (state, payload) => ({
    ...state,
    deleteNoteSuccess: true,
    deleteNote: false,
    deleteNoteError: null,
  })),
  on(ContactsStateActions.deleteNoteError, (state, payload) => ({
    ...state,
    deleteNote: false,
    deleteNoteSuccess: false,
    deleteNoteError: payload.msg,
  })),
  on(ContactsStateActions.massEmailCampain, (state, action) => ({
    ...state,
    massEmailCampainSuccess: false,
    massEmailCampainError: null,
    massEmailCampain: true,
  })),
  on(ContactsStateActions.massEmailCampainSuccess, (state, payload) => ({
    ...state,
    massEmailCampainSuccess: true,
    massEmailCampain: false,
    massEmailCampainError: null,
  })),
  on(ContactsStateActions.massEmailCampainError, (state, payload) => ({
    ...state,
    massEmailCampain: false,
    massEmailCampainSuccess: false,
    massEmailCampainError: payload.data,
  })),
  on(ContactsStateActions.testEmailCampagin, (state, action) => ({
    ...state,
    testEmailCampaginSuccess: false,
    testEmailCampaginError: null,
    testEmailCampagin: true,
  })),
  on(ContactsStateActions.testEmailCampaginSuccess, (state, payload) => ({
    ...state,
    testEmailCampaginSuccess: true,
    testEmailCampagin: false,
    testEmailCampaginError: null,
  })),
  on(ContactsStateActions.testEmailCampaginError, (state, payload) => ({
    ...state,
    testEmailCampagin: false,
    testEmailCampaginSuccess: false,
    testEmailCampaginError: payload.msg,
  })),
  on(ContactsStateActions.testEmailNormal, (state, action) => ({
    ...state,
    testEmailNormalSuccess: false,
    testEmailNormalError: null,
    testEmailNormal: true,
  })),
  on(ContactsStateActions.testEmailNormalSuccess, (state, payload) => ({
    ...state,
    testEmailNormalSuccess: true,
    testEmailNormal: false,
    testEmailNormalError: null,
  })),
  on(ContactsStateActions.testEmailNormalError, (state, payload) => ({
    ...state,
    testEmailNormal: false,
    testEmailNormalSuccess: false,
    testEmailNormalError: payload.msg,
  })),
  on(ContactsStateActions.listLoadingSuccess, (state) => ({
    ...state,
    contactListLoading: false,
  }))
);
