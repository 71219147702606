import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../modules/settings/services';
import { UserService } from '../../../modules/settings/services/user.service';

@Component({
  selector: 'dailyai-profile-change-modal',
  templateUrl: './profile-image-change-modal.component.html',
  styleUrls: ['./profile-image-change-modal.component.scss'],
})
export class ProfileImageChangeModalComponent implements OnInit {
  @Input() fromParent;

  files: any[];

  imageUrl: any[] = [];

  currentImage = '';

  currentUser: any;

  saveButtonLabel = 'Save';

  loading = false;

  message = {
    success: `
      <div>Your profile image was changed.</div>
    `,
    error: `
      <span>Something went wrong and your profile image did not change. You can try again, but if the problem persist contact technical support.</span>
      <ul>
        <li>Call Daily AI support at (720)548-6794.</li>
        <li>Email Daily AI support at support@dailyai.io.</li>
      </ul>
    `
  };

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userService: UserService,
    private settingsService:SettingsService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.fromParent.prop1;
    this.currentImage = this.currentUser.image ?? './assets/adduser-icon.svg';
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  changeImage(event: any) {
    this.files = event.target.files;
    if (!this.files) {
      return;
    }
    if (this.files[0].type.split('/')[0] === 'image') {
      this.imageUrl = this.files[0] ?? '';
      this.readImage(this.imageUrl);
    } else {
      this.toastr.warning('Only Images are Accepted');
      this.files = [];
    }
  }

  readImage(inputValue: any): void {
    const file: File = inputValue;
    const myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.imageUrl[0] = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  async changeProfileImage() {
    this.loading = true;
    this.saveButtonLabel = 'Saving...';
    try {
      const imageUrl = await this.settingsService.fileUpload(this.imageUrl);
      await this.userService.changeProfileImage(imageUrl);
      this.setPostProfileChangeState();
      this.toastr.success(this.message.success, 'Success', {
        enableHtml: true,
        timeOut: 10000,
        disableTimeOut: false,
        closeButton: true,
        positionClass: 'toast-top-full-width',
      });
    } catch (error) {
      this.setPostProfileChangeState();
      this.toastr.error(this.message.error, 'Error', {
        enableHtml: true,
        disableTimeOut: true,
        closeButton: true,
        positionClass: 'toast-top-full-width',
      });
    }
  }

  setPostProfileChangeState() {
    this.loading = false;
    this.saveButtonLabel = 'Save';
    this.closeModal();
  }
}
