
import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import { GeneralTemplatesService } from 'src/app/modules/settings/services/general-templates.service';
import { SettingsService } from 'src/app/modules/settings/services';
import { AuthService } from 'src/app/services/auth.service';
import { CommunicationsState } from 'src/app/modules/communications/store/state';
import { Store, select } from '@ngrx/store';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { selectSendingEmail } from 'src/app/modules/communications/store/selectors';
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { emailEditorConfig } from 'src/app/shared/lib/emailEditorConfig';
import { urlMergeFields, templateMergeFields } from 'src/app/shared/lib/mergeField';
import { MediaService } from 'src/app/modules/drip-campaign/services/getMedia.service';
import { takeUntil, take } from 'rxjs/operators';
import { MediaAudioSelectFromComponent } from 'src/app/modules/drip-campaign/components/media-audio-select-from/media-audio-select-from.component';
import { environment } from 'src/environments/environment';
import { selectExtraMergeFields } from 'src/app/modules/settings/store/selector';
import { SharedService } from '../../services/shared.service';
import { replaceVideoUrliframeWithAnchor, sleep } from '../../utils';
import { EmailAttachmentComponent } from '../email-attachment/email-attachment.component';
import { ValidationState } from '../../enums/commonEnums';

@Component({
  selector: 'dailyai-shared-add-edit-email-popup',
  templateUrl: './shared-add-edit-email-popup.component.html',
  styleUrls: ['./shared-add-edit-email-popup.component.scss'],
})
export class SharedAddEditEmailPopupComponent implements OnInit, OnDestroy {
  @ViewChild(EmailAttachmentComponent) EmailAttachmentComponent;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  @ViewChild('input1') CC: ElementRef;

  mailForm: FormGroup;

  currentContact: any;

  public editorOptions = {
    placeholder: 'insert content...',
  };

  quillEditorRef: any;

  contactEmailLoader$: Observable<any>;

  extraMergeFields$: Observable<any>;

  currentUser: any;

  focus$ = new Subject<string>();

  ccMailArray = [];

  bccMailArray = [];

  emailAttachmentsArray;

  file;

  formValid = false;

  templateDetailsClone: any;

  mergeFieldList = [];

  searchText;

  caretPos = 0;

  mergefiledin = '';

  subjectcaretPos = 0;

  popRef;

  embedVideoDetail: any;

  closedmediaModal = '';

  ngUnsubscribe: Subject<any> = new Subject<any>();

  mergeFieldEventForCC: Subject<string> = new Subject<string>();

  ckeConfig: any;

  @ViewChild('myckeditor') ckeditor: any;

  linkText = '';

  selectedTextForLink: any;

  mergefiledInLinkCurPosition = 0;

  emailEditorLoaded = false;

  urlMergeFieldsList: any;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private store: Store<CommunicationsState>,
    private auth: AuthService,
    private settingsService: SettingsService,
    private genTempService: GeneralTemplatesService,
    private toastr: ToastrService,
    private mediaSvc: MediaService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.ckeConfig = emailEditorConfig;

    this.contactEmailLoader$ = this.store.pipe(select(selectSendingEmail));
    this.currentUser = this.auth.getUserData();
    this.initForm();
    this.templateDetailsClone = this.templateDetails;
    console.log(this.templateDetails);
  }

  close() {
    this.modal.dismissAll();
    this.mailForm.reset();
  }

  initForm() {
    this.mailForm = this.fb.group({
      templateName:
        this.templateDetails && this.templateDetails['templateName']
          ? this.templateDetails['templateName']
          : ['', Validators.required],
      subject:
        this.templateDetails && this.templateDetails['templateSubject']
          ? this.templateDetails['templateSubject']
          : ['', Validators.required],
      content:
        this.templateDetails && this.templateDetails['templateContent']
          ? this.templateDetails['templateContent']
          : ['', Validators.required],
    });

    this.ccMailArray = this.templateDetails && this.templateDetails['cc'] ? this.templateDetails['cc'] : [];
    this.bccMailArray = this.templateDetails && this.templateDetails['bcc'] ? this.templateDetails['bcc'] : [];
    this.mailForm.patchValue({
      content:
        this.templateDetails && this.templateDetails['templateContent']
          ? this.templateDetails['templateContent']
          : this.currentUser.emailSignature
          ? '<br><br><br><br>{{user_email_signature}}'
          : '',
    });
  }

  async saveTemplate() {

    if (this.mailForm.invalid) {

      this.mailForm.markAllAsTouched();

    } else {
      await sleep(1000);
      const InvalidBcc = this.bccMailArray.filter((mail) => mail.valid == ValidationState.INVALID);
      const InvalidCc = this.ccMailArray.filter((mail) => mail.valid == ValidationState.INVALID);
      if (InvalidBcc.length > 0 || InvalidCc.length > 0) {
        this.toastr.error('Invalid cc or bcc emails found');
        return;
      }
      this.formValid = true;
      const email_attachments_array = this.emailAttachmentsArray
        ? await this.EmailAttachmentComponent.getFirebaseUrl(this.emailAttachmentsArray)
        : [];

      let emailContent = replaceVideoUrliframeWithAnchor(this.mailForm.value.content);
      emailContent = await this.newImageHandelr(emailContent);
      emailContent = this.fixImageUrl(emailContent);

      if (this.templateDetails) {
        const payload = {
          templateName: this.mailForm.value.templateName,
          templateSubject: this.mailForm.value.subject,
          templateContent: emailContent,
          cc: [...new Set(this.ccMailArray.map((e) => e.email))],
          bcc: [...new Set(this.bccMailArray.map((e) => e.email))],
          email_attachments_array: this.templateDetails['email_attachments_array']
            ? [...this.templateDetails['email_attachments_array'], ...email_attachments_array]
            : email_attachments_array,
          added_by: this.currentUser.uid,
          created_at: new Date().toISOString(),
          viewersList: [this.currentUser.uid],
          template_type: 'email_template',
          templateId: this.templateDetails['templateId'],
        };

        await this.genTempService.updateTemplate(payload);
        this.toastr.success('Template updated successfully');
      } else {
        const payload = {
          templateName: this.mailForm.value.templateName,
          templateSubject: this.mailForm.value.subject,
          templateContent: emailContent,
          cc: [...new Set(this.ccMailArray.map((e) => e.email))],
          bcc: [...new Set(this.bccMailArray.map((e) => e.email))],
          email_attachments_array,
          added_by: this.currentUser.uid,
          created_at: new Date().toISOString(),
          viewersList: [this.currentUser.uid],
          template_type: 'email_template',
        };
        await this.genTempService.addEmailTemplate(payload);
      }
      if (this.bccMailArray.length > 0 || this.ccMailArray.length > 0) {
        this.toastr.info('CC and BCC saved will not be applicable to mass email', 'My Templates', {
          closeButton: true,
          disableTimeOut: true,
        });
      }

      this.passEntry.emit('reset');
      this.close();
      this.bccMailArray = [];
      this.ccMailArray = [];
    }
  }

  editorOnFocus(linkPop, ck) {
    this.ckeditor = ck;
    this.emailEditorLoaded = true;
    this.ckeditor.instance.commands.link.exec = (b) => {
      this.modal.open(linkPop, {
        centered: true,
        windowClass: 'routeDripPopup',
        backdrop: 'static',
        keyboard: true,
      });
    };
    this.ckeditor.instance.commands.image.exec = async (b) => {
      this.sharedService.imageHandlerForEmail(this.ckeditor, this.currentUser.uid);
    };
    this.ckeditor.instance.addCommand('videouploadcmd', {
      exec: (edt) => {
        this.videoHandler();
      },
    });
  }

  async videoHandler() {
    const modalRef = this.modal.open(MediaAudioSelectFromComponent, {
      centered: true,
      windowClass: 'routeDripPopup',
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.dripType = 'media';
    modalRef.componentInstance.fromWhere = 'videoEmbedInEmail';
    (modalRef.componentInstance.close as Observable<any>).pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x) {
        this.closedmediaModal = x;
        modalRef.close();
      }
    });
    this.mediaSvc
      .getMedia()
      .pipe(take(1))
      .subscribe(async (value) => {
        const supportedVideoArray = ['video/quicktime', 'video/mp4', 'video/webm', 'video/x-msvideo'];
        let videowithanchor = '';
        if (value) {
          this.embedVideoDetail = value;

          if (this.embedVideoDetail && this.embedVideoDetail['value']['doc_id']) {
            const vidUrl = `${environment.player_url}/player?url=${encodeURIComponent(
              this.embedVideoDetail['value']['url']
            )}&user_id=${this.currentUser.uid}`;
            videowithanchor = `<a  href="${vidUrl}"><img height="200px" class="vidCrmq" src="${this.embedVideoDetail['value']['thumbnail']}"></a>`;
            this.ckeditor.instance.ui.editor.insertHtml(videowithanchor);
          } else if (
            this.embedVideoDetail &&
            !this.embedVideoDetail['value']['doc_id'] &&
            supportedVideoArray.includes(value['value'][0]['type'])
          ) {
            const link = await this.settingsService.uploadImageEmail(
              this.embedVideoDetail['value'][0],
              this.currentUser.uid
            );
            const vidUrl = `${environment.player_url}/player?url=${encodeURIComponent(String(link))}&user_id=${
              this.currentUser.uid
            }`;
            videowithanchor = `<a href="${vidUrl}"><img height="200" src="${
              environment.player_url
            }/player/gif?url=${encodeURIComponent(
              String(link)
            )}" alt="https://firebasestorage.googleapis.com/v0/b/dailycrm-c9949.appspot.com/o/video_default_thumbnail%2Fmail-video-icon_20200704_193513175.png?alt=media&token=46d39c03-affe-478f-9458-0b1092542c71"></a>`;

            this.ckeditor.instance.ui.editor.insertHtml(videowithanchor);
          } else {
            this.toastr.warning('The selected file type not supported', 'Video embed in email');
          }
        }
      });
  }

  @Input()
  set contact(data) {
    if (data) {
      this.currentContact = data;
    }
  }

  mailAttachmentDetails(event) {
    this.emailAttachmentsArray = event;
  }

  removeAttachment(fullarray?, itemIndex?, item?, i?) {
    fullarray.splice(itemIndex, 1);
  }

  fileEvent(event) {
    this.file = event;
  }

  getChoiceLabel(choice: string, index?) {
    switch (this.mergefiledin) {
    case 'mailEditor': {
      this.ckeditor.instance.ui.editor.insertText(`{{${choice}}}`);

    break;
    }
    case 'subjectArea': {
      const message: any = this.mailForm['controls']['subject']['value']
        ? this.mailForm['controls']['subject']['value']
        : ``;
      const result: string = message
        ? `${message.slice(0, this.subjectcaretPos)  }{{${choice}}}${  message.slice(this.subjectcaretPos)}`
        : `{{${choice}}}`;
      this.subjectcaretPos += choice.length + 4;
      this.mailForm['controls']['subject'].patchValue(result);

    break;
    }
    case 'cc': {
      if (this.subjectcaretPos === 0) {
        this.mergeFieldEventForCC.next(`{{${choice}}}`);
      } else {
        this.toastr.info('Merge Fields can only be added to blank fields');
      }

    break;
    }
    default: {
      this.toastr.warning('Please focus either on  Email editor or on email subject');
    }
    }
    this.popRef.close();
  }

  getCaretPos(oField, fieldname) {
    this.mergefiledin = fieldname;
    this.subjectcaretPos = oField.selectionStart;
    this.mergeFieldList = this.mergeFieldList.filter((val) => val !== 'user_email_signature');
  }

  openTagList(popover) {
    this.extraMergeFields$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectExtraMergeFields));
    this.extraMergeFields$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fieldList) => {
      this.mergeFieldList = this.mergefiledin === 'mailEditor' ? [...templateMergeFields, 'user_email_signature', ...fieldList] : [...templateMergeFields, ...fieldList];

      this.urlMergeFieldsList = [...urlMergeFields, ...fieldList];
    });
    this.popRef = popover;
    if (popover.isOpen()) {
      this.searchText = '';
      popover.close();
    } else {
      this.searchText = '';
      popover.open();
    }
  }


  async newImageHandelr(emailContent) {
    const imgpattern = new RegExp(`<img style="height:100" src="(.*?)" alt="(.*?)">`, 'g');
    const imgpatternfromSystem = new RegExp(`<img height="100" src="(.*?)">`, 'g');
    const imgpatternfromSystem2 = new RegExp(`<img src="(.*?)">`, 'g');
    const imgpatternfromSystem3 = new RegExp(`<img src="(.*?)" height="100">`, 'g');
    const srcpattern = new RegExp(`src="(.*?)"`, 'g');
    let matches = [...emailContent.matchAll(imgpattern)];
    matches = [
      ...emailContent.matchAll(imgpatternfromSystem),
      ...emailContent.matchAll(imgpatternfromSystem2),
      ...emailContent.matchAll(imgpatternfromSystem3),
    ];
    for (const [i, item] of matches.entries()) {
      const imageurl = item[0];
      const imgsrc = [...imageurl.matchAll(srcpattern)];
      if (!imgsrc[0][1].split(',')[0].includes('base64')) {
        continue;
      } else {
        const link = await this.settingsService.uploadImageEmailNew(imgsrc[0][1], this.currentUser.uid);
        const atagcontent = item[0].replace(imgsrc[0][1], link);
        emailContent = emailContent.replace(item[0], atagcontent);
      }
    }
    return emailContent;
  }

  fixImageUrl(emailContent) {
    const imgPattern = /<a href="([^<>]*)"><img/gi;
    const matches = [...emailContent.matchAll(imgPattern)];
    let updatedContent = emailContent;
    matches.forEach(item => {
      const url = item[1].toLowerCase();
      if(!url.includes("https://") && !url.includes("http://")) {
        updatedContent = updatedContent.replaceAll(item[0], item[0].replace(item[1], `https://${item[1]}`));
      }
    });
    return updatedContent;
  }

  checkImageUrl(emailContent: string) {
    let isCorrectUrl = true;
    const imgPattern = /<a href="([^<>]*)"><img/gi;
    const matches = [...emailContent.matchAll(imgPattern)];
    matches.forEach(item => {
      const url = item[1].toLowerCase();
      if(!url.includes("https://") && !url.includes("http://")) {
        isCorrectUrl = false;
      }
    });
    return isCorrectUrl;
  }

  @Input() public templateDetails;

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showEmojiPicker = false;

  sets = ['native', 'google', 'twitter', 'facebook', 'emojione', 'apple', 'messenger'];

  set = 'google';

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmojiMail(event) {
    this.ckeditor.instance.ui.editor.insertText(event.emoji.native);
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onClickedOutside(event) {
    if (event.target.type !== 'button') {
      this.showEmojiPicker = false;
    }
  }

  onClick(event: any): void {
    this.mergefiledin = 'mailEditor';
    if (this.ckeditor.instance.ui.editor.getSelection()) {
      const a = this.ckeditor.instance.ui.editor.getSelection().getNative();
      this.selectedTextForLink =
        a && a.anchorNode.data
          ? a.baseOffset < a.extentOffset
            ? a.anchorNode.data.slice(a.baseOffset, a.extentOffset)
            : a.anchorNode.data.slice(a.extentOffset, a.baseOffset)
          : '';
    }
  }

  onEditorChange(event: any): void {
    const emailContent = this.ckeditor.instance.ui.editor.getData();
    if(!this.checkImageUrl(emailContent)) {
      const fixedEmailContent = this.fixImageUrl(emailContent);
      this.ckeditor.instance.ui.editor.setData(fixedEmailContent);
    }
  }

  setCurserPostionAndInput(position) {
    this.mergefiledInLinkCurPosition = position;
  }

  linkSet(item) {
    this.linkText = `${this.linkText.slice(0, this.mergefiledInLinkCurPosition)}{{${item}}}${this.linkText.slice(
      this.mergefiledInLinkCurPosition
    )}`;
    this.mergefiledInLinkCurPosition = this.mergefiledInLinkCurPosition + item.length + 4;
    this.popRef.close();
  }

  addLink(modal) {
    if (this.linkText && this.selectedTextForLink) {
      this.linkText = !this.linkText.includes('{{') ? `https://${this.linkText}` : this.linkText;
      this.ckeditor.instance.ui.editor.insertHtml(`<a href="${this.linkText}">${this.selectedTextForLink}</a>`);
      this.linkText = '';
      this.selectedTextForLink = '';
      modal.close();
    }
  }

  cancelLinkModal(modal) {
    this.linkText = '';
    this.selectedTextForLink = '';
    modal.close();
  }

  ccMergeFieldSetter(event) {
    this.mergefiledin = event?.type;
    this.subjectcaretPos = 0;
  }

  recipientSetter(data) {
    this.ccMailArray = data.cc ?? [];
    this.bccMailArray = data.bcc ?? [];
  }
}
