import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { takeUntil } from 'rxjs/operators';
import { DataTransactionService } from '../../services/data-transaction.service';
import { VideoRecordingService } from '../../services/video-recording.service';
import { VideoPlayModelComponent } from '../video-play-model/video-play-model.component';
import { VideoRecordModelComponent } from '../video-record-model/video-record-model.component';

@Component({
  selector: 'dailyai-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit, OnDestroy {
  unSubscribe: Subject<any> = new Subject<any>();

  @Output() private selectedMedia = new EventEmitter();

  @Output() private selectFiles = new EventEmitter();

  displayNone = [];

  selectedList = [];

  currentUser$: Observable<any>;

  userDetails;

  videoList$: Observable<any>;

  videoList = [];

  modelRef: NgbModalRef;

  uploading = false;

  searchText = '';

  url = '';

  constructor(
    private ngbModal: NgbModal,
    private videoRecordingService: VideoRecordingService,
    private store: Store<any>,
    private dts: DataTransactionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.subscribe((data) => {
      this.userDetails = data;
    });
    this.videoList$ = this.videoRecordingService.getVideoList(this.userDetails.uid);
    this.videoList$.pipe(takeUntil(this.unSubscribe)).subscribe((val) => {
      this.videoList = val;
      this.videoList.sort((a, b) => a.file_name.localeCompare(b.file_name));

      this.uploading = false;
    });
    this.url = this.router.url.split('/')[1];
  }

  videoModel() {
    this.modelRef = this.ngbModal.open(VideoRecordModelComponent, {
      centered: true,
      windowClass: 'routeDripPopup',
      backdrop: 'static',
      keyboard: false,
    });
    this.modelRef.componentInstance.saved.subscribe((val) => {
      this.uploading = true;
    });
  }

  videoPlayModel(url, thumbnail) {
    console.log('play button clcked')
    const modal = this.ngbModal.open(VideoPlayModelComponent, {
      centered: true,
      windowClass: 'routeDripPopup',
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.videoURL = url;
    modal.componentInstance.videoThumbNail = thumbnail;
  }

  selectVideo(index, item) {
    if (this.url === 'drip-campaign' || this.url === 'settings' || this.url === 'contacts' || this.url === 'calendar' || this.url === 'communications') {
      const arr = new Array(this.displayNone.length).fill(0);
      this.displayNone = arr;
      this.displayNone[index] = this.displayNone[index] === 1 ? 0 : 1;
      this.selectedMedia.emit(item);
      return;
    }
    this.displayNone[index] = this.displayNone[index] === 1 ? 0 : 1;
    const findIndex = this.selectedList.findIndex((x) => x.doc_id === item.doc_id);
    if (findIndex === -1) {
      this.selectedList.push(item);
    } else {
      this.selectedList.splice(findIndex, 1);
    }
    this.selectFiles.emit({ list: this.selectedList, type: 'video' });
  }

  confirmationTemp() {
    const confirmRef = this.ngbModal.open(ConfirmationComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    confirmRef.result.then((result) => (result === 'submit' ? this.delete() : this.ngbModal.dismissAll()));
  }


  delete() {
    this.dts.deleteFile(this.selectedList);
    this.selectedList = [];
    this.displayNone = [];
    this.selectFiles.emit({ list: this.selectedList, type: 'video' });
  }

  @Input()
  set search(pSearch) {
    this.searchText = pSearch;
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
