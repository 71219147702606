import { createReducer, on } from '@ngrx/store';
import { makeUserPermission } from 'src/app/shared/utils';
import * as LoginStateActions from './actions';

import { LoginState } from './state';

export const initailState: LoginState = {
  currentUser: null,
  loggingIn: false,
  loginError: null,
  loginSuccess: null,
  resetError: null,
  resetSending: false,
  resetSent: null,
  resetting: false,
  resetPassError: null,
  resetSuccess: null,
  setting: false,
  setPassError: null,
  permissionObject: null,
};

export const loginReducer = createReducer(
  initailState,
  on(LoginStateActions.LogingIn, (state, payload) => ({
    ...state,
    loggingIn: true,
    loginError: null,
  })),
  on(LoginStateActions.LoginSuccess, (state, payload) => ({
    ...state,
    loginSuccess: true,
    loginError: null,
    currentUser: payload,
    loggingIn: false,
  })),
  on(LoginStateActions.LoginError, (state, payload) => ({
    ...state,
    loginError: payload.msg,
    loginSuccess: null,
    loggingIn: false,
  })),
  on(LoginStateActions.ResetMailSending, (state) => ({
    ...state,
    resetSending: true,
    resetError: null,
    resetSent: null,
  })),
  on(LoginStateActions.ResetMailSent, (state) => ({
    ...state,
    resetSending: false,
    resetError: null,
    resetSent: true,
  })),
  on(LoginStateActions.ResetMailError, (state, payload) => ({
    ...state,
    resetSending: false,
    resetError: payload.msg,
    resetSent: null,
  })),
  on(LoginStateActions.Resetting, (state) => ({
    ...state,
    resetting: true,
    resetPassError: null,
    resetSuccess: null,
  })),
  on(LoginStateActions.ResetPasswordSuccess, (state) => ({
    ...state,
    resetting: false,
    resetPassError: null,
    resetSuccess: true,
  })),
  on(LoginStateActions.ResetPasswordError, (state, payload) => ({
    ...state,
    resetting: false,
    resetPassError: payload.msg,
    resetSuccess: null,
  })),
  on(LoginStateActions.Setting, (state) => ({
    ...state,
    setting: true,
    setPassError: null,
  })),
  on(LoginStateActions.SetPasswordSuccess, (state) => ({
    ...state,
    setting: false,
    resetPassError: null,
  })),
  on(LoginStateActions.SetPasswordError, (state, payload) => ({
    ...state,
    setting: false,
    setPassError: payload.msg,
  })),
  on(LoginStateActions.LoginReset, (state) => ({
    ...initailState,
  })),
  on(LoginStateActions.PermissionSuccess, (state, payload) => ({
    ...state,
    permissionObject: makeUserPermission(state.currentUser.userType, payload.data)
  }))

);
