import { createReducer, on } from '@ngrx/store';
import { filterDuplicatesByParameter } from 'src/app/shared/utils';
import * as CommunicationsStateActions from './actions';

import { CommunicationsState } from './state';

export const initialState: CommunicationsState = {
  ContactsList: null,
  ContactListSuccess: false,
  ContactListError: null,
  ContactSmsLog: null,
  ContactsmsLogSuccess: false,
  ContactSmsLogError: null,
  ContactEmailLog: null,
  ContactEmailLogSuccess: false,
  ContactEmailLogError: null,
  ContactSentSms: false,
  ContactSentSmsSuccess: false,
  ContactSentSmsError: null,
  ContactSentEmail: false,
  ContactSentEmailSuccess: false,
  ContactSentEmailError: null,
  CurrentSelectedContact: null,
  ContactSendingSms: false,
  ContactSendingMail: false,
  last_doc_email_logs: null,
  last_doc_sms_logs: null,
  last_doc_contacts: null,
  smsLoader: false,
  emailLoader: false,
  endOfContactList: false,
  conversationLoading: false,
  endOfContactSmsLog: false,
  endOfContactEmailLog: false,
};

export const CommunicationReducer = createReducer(
  initialState,
  on(CommunicationsStateActions.ListCommunicationContacts, (state) => ({
    ...state,
    ContactListSuccess: false,
    conversationLoading: true,
  })),
  on(CommunicationsStateActions.ListCommunicationContactsSuccess, (state, payload) => {
    let tempContactsArray: any[] = payload.data.data ? payload.data.data : payload.data;
    const { refresh } = payload;
    const endOfContactList = refresh ? state.endOfContactList : tempContactsArray.length < 50;
    if (!refresh && state.last_doc_contacts) {
      tempContactsArray = [...state.ContactsList, ...tempContactsArray];
    }
    return {
      ...state,
      endOfContactList,
      ContactListSuccess: true,
      conversationLoading: false,
      ContactsList: tempContactsArray,
      last_doc_contacts:
        tempContactsArray && tempContactsArray.length > 0 ? tempContactsArray[tempContactsArray.length - 1] : null,
    };
  }),
  on(CommunicationsStateActions.ListCommunicationContactsError, (state, payload) => ({
    ...state,
    ContactListSuccess: false,
    conversationLoading: false,
    ContactsListError: payload.msg,
  })),
  on(CommunicationsStateActions.ListMyConversationCommunicationContacts, (state, { limit }) => ({
    ...state,
    ContactListSuccess: false,
    conversationLoading: !limit,
  })),
  on(CommunicationsStateActions.ListMyConversationCommunicationContactsSuccess, (state, payload) => {
    const tempContactsArray: any[] = payload.data.data ? payload.data.data : payload.data;
    return {
      ...state,
      ContactListSuccess: true,
      conversationLoading: false,
      ContactsList: tempContactsArray,
    };
  }),
  on(CommunicationsStateActions.ListMyConversationCommunicationContactsError, (state, payload) => ({
    ...state,
    ContactListSuccess: false,
    conversationLoading: false,
    ContactsListError: payload.msg,
  })),

  on(CommunicationsStateActions.ResetCommunicationContacts, (state) => ({
    ...state,
    ContactsList: [],
    last_doc_contacts: null,
  })),
  on(CommunicationsStateActions.ListCommunicationContactsSmsLogs, (state) => ({
    ...state,
    ContactsmsLogSuccess: false,
    smsLoader: true,
  })),
  on(CommunicationsStateActions.ListCommunicationContactsSmsLogsSuccess, (state, payload) => {
    const tempArray: any[] = payload.data;
    let reverse: any[] = [];
    const endOfContactSmsLog = tempArray.length < 10;
    for (let i = tempArray.length - 1; i >= 0; i--) {
      reverse.push(tempArray[i]);
    }

    if (state.last_doc_sms_logs && reverse) {
      reverse = [...reverse, ...state.ContactSmsLog];
      const output = filterDuplicatesByParameter(reverse, "sent_date")
      reverse = output.sort((a, b) => a.sent_date.seconds - b.sent_date.seconds);
    }
    return {
      ...state,
      endOfContactSmsLog,
      ContactsmsLogSuccess: true,
      smsLoader: false,
      ContactSmsLog: reverse,
      last_doc_sms_logs: reverse && reverse.length > 0 ? reverse[0] : null,
    };
  }),
  on(CommunicationsStateActions.ListCommunicationContactsSmsLogsError, (state, payload) => ({
    ...state,
    ContactsmsLogSuccess: false,
    smsLoader: false,
    ContactSmsLogError: payload.msg,
  })),
  on(CommunicationsStateActions.ResetSmsLogs, (state) => ({
    ...state,
    last_doc_sms_logs: null,
    ContactSmsLog: [],
  })),
  on(CommunicationsStateActions.ListCommunicationContactsEmailLogs, (state, payload) => ({
    ...state,
    ContactSentEmailSuccess: false,
    emailLoader: true,
  })),
  on(CommunicationsStateActions.ListCommunicationContactsEmailLogsSuccess, (state, payload) => {
    const tempArray: any[] = payload.data.data ? payload.data.data : payload.data;
    const endOfContactEmailLog = tempArray.length < 10;
    let reverse: any[] = [];
    for (let i = tempArray.length - 1; i >= 0; i--) {
      reverse.push(tempArray[i]);
    }

    if (state.last_doc_email_logs) {
      reverse = [...reverse, ...state.ContactEmailLog];
    }

    const flags = {};
    const output = [];
    for (const log of reverse) {
      if (!flags[log.sent_date]) {
        flags[log.sent_date] = true;
        output.push(log);
      }
    }
    reverse = output.sort((a, b) => a.sent_date.seconds - b.sent_date.seconds);
    return {
      ...state,
      emailLoader: false,
      endOfContactEmailLog,
      ContactEmailLog: reverse,
      ContactEmailLogSuccess: true,
      last_doc_email_logs: reverse && reverse.length > 0 ? reverse[0] : null,
    };
  }),
  on(CommunicationsStateActions.ListCommunicationContactsEmailLogsError, (state, payload) => ({
    ...state,
    ContactEmailLogSuccess: false,
    emailLoader: false,
    ContactEmailLogError: payload.msg,
  })),
  on(CommunicationsStateActions.ResetEmailLogs, (state) => ({
    ...state,
    last_doc_email_logs: null,
    ContactEmailLog: [],
  })),
  on(CommunicationsStateActions.CommunicationContactSentSms, (state, payload) => ({
    ...state,
    ContactSentSmsSuccess: false,
    ContactSendingSms: true,
  })),
  on(CommunicationsStateActions.CommunicationContactSentSmsSuccess, (state) => ({
    ...state,
    ContactSentSmsSuccess: true,
    ContactSentSmsError: null,
    ContactSendingSms: false,
  })),
  on(CommunicationsStateActions.CommunicationContactSentSmsError, (state, payload) => ({
    ...state,
    ContactSentSmsSuccess: false,
    ContactSentSmsError: payload.msg,
    ContactSendingSms: false,
  })),
  on(CommunicationsStateActions.CommunicationContactSentEmail, (state, payload) => ({
    ...state,
    ContactSentSms: true,
    ContactSentEmailSuccess: false,
    ContactSendingMail: true,
  })),
  on(CommunicationsStateActions.CommunicationContactSentEmailSuccess, (state, payload) => ({
    ...state,
    ContactSentEmailSuccess: true,
    ContactSentEmail: true,
    ContactSendingMail: false,
  })),
  on(CommunicationsStateActions.CommunicationContactSentEmailError, (state, payload) => ({
    ...state,
    ContactSentEmailSuccess: false,
    ContactSendingMail: false,
    ContactSentEmailError: payload.msg,
  })),
  on(CommunicationsStateActions.SelectedCommunicationContact, (state, payload) => ({
    ...state,
    CurrentSelectedContact: payload.data,
  }))
);
