import { Component } from '@angular/core';
import { AgFilterComponent } from '@ag-grid-community/angular';
import {
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from '@ag-grid-community/core';

@Component({
  selector: 'name-email-filter',
  template: `
    <div class="m-3">
      <label for="nameEmailFilter" class="form-label p-1">
        <strong>Filter by Name or Email</strong>
      </label>
      <input type="email"
        name="nameEmailFilter"
        class="form-control"
        [(ngModel)]="filterText"
        (ngModelChange)="onInputChanged()"
        placeholder="filter" />
    </div>
  `,
})
export class NameEmailFilterComponent implements AgFilterComponent {
  params: any;

  valueGetter: (rowNode: RowNode) => any;

  filterText = '';

  agInit(params: (IFilterParams | {rootProperty:string})): void {
    this.params = params;
   }

  doesFilterPass(params: IDoesFilterPassParams) {
    let root = {...params.data};
    if (this.params.rootProperty && params.data?.[this.params.rootProperty]) {
      root = params.data[this.params.rootProperty];
    }

    const words = (this.filterText
      .toLowerCase()
      .trim()
      .split(' '));

    const firstName = root.firstName?.toLowerCase() ?? '';
    const lastName = root.lastName?.toLowerCase() ?? '';
    const email = root.email?.toLowerCase() ?? '';

    if (!firstName.startsWith(words[0]) && !lastName.startsWith(words[0]) && !email.startsWith(words[0]))
      return false;

    if (words.length > 1
      && firstName  !== words.join(' ')
      && (firstName === words[0]
      && !lastName.startsWith(words[1]))) {
        return false;
    }
    return true;
  }

  isFilterActive(): boolean {
    return this.filterText && this.filterText.length > 2;
  }

  getModel() {
    return { filter: this.filterText , type: "contains", filterType: "text" };
  }

  setModel(model: any) {
    this.filterText = model?.value ?? model?.filter ?? "";
  }

  onInputChanged() {
    this.params.filterChangedCallback();
  }
}
