import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-confirm-with-note',
  templateUrl: './confirm-with-note.component.html',
  styleUrls: ['./confirm-with-note.component.scss'],
})
export class ConfirmWithNoteComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) { }

  @Output() modalAction: EventEmitter<any> = new EventEmitter<any>();

  @Input() title: string;

  @Input() notes: any;

  @Input() noteHead: string;

  @Input() yesText = 'Yes';

  @Input() noText = 'No';

  ngOnInit(): void { }

  close() {
    this.modalAction.emit(false);
    this.activeModal.close(false);
  }

  sumbit() {
    this.modalAction.emit(true);
    this.activeModal.close(true);
  }
}
