import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  Action
} from '@ngrx/store';

import { InjectionToken } from '@angular/core';

import { LoginState } from '../modules/login/store/state';
import { loginReducer } from '../modules/login/store/reducer';
import { SignupState } from '../modules/signup/store/state';
import { signupReducer } from '../modules/signup/store/reducer';
import { ContactsState } from '../modules/contacts/store/state';
import { ContactsReducer } from '../modules/contacts/store/reducer';
import { UserState } from '../modules/settings/store/state';
import { UserReducer } from '../modules/settings/store/reducer';
import { addPartnerReducer } from '../modules/partners/store/reducer';
import { AddPartnerState } from '../modules/partners/store/state';
import { TaskState } from '../modules/tasks/store/state';
import { TasksReducer } from '../modules/tasks/store/reducer';
import { DashboardState } from "../modules/dashboard/store/state";
import { dashboardReducer } from '../modules/dashboard/store/reducer';
import { DripState } from '../modules/drip-campaign/store/state';
import { DripReducer } from '../modules/drip-campaign/store/reducer';
import { TransactionReducer } from '../modules/transactions/store/reducer';
import { TransactionState } from '../modules/transactions/store/state';
import { CommunicationReducer } from '../modules/communications/store/reducer';
import { CommunicationsState } from '../modules/communications/store/state';
import { EnterpriseState } from '../modules/role-modules/teams/store/state';
import { EnterpriseReducer } from '../modules/role-modules/teams/store/reducers';

export interface State {
  login: LoginState;
  signup: SignupState;
  contact: ContactsState;
  users: UserState;
  partner: AddPartnerState;
  tasks: TaskState;
  drip: DripState;
  transactions: TransactionState;
  communications: CommunicationsState;
  dashboard: DashboardState;
  enterprise: EnterpriseState
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */


export function redeclareStore(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action['action'] == 'Logout') {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const reducers = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    login: loginReducer,
    signup: signupReducer,
    contact: ContactsReducer,
    users: UserReducer,
    partner: addPartnerReducer,
    tasks: TasksReducer,
    dashboard: dashboardReducer,
    drip: DripReducer,
    transactions: TransactionReducer,
    communications: CommunicationReducer,
    enterprise: EnterpriseReducer
  })
});

export const metaReducers: MetaReducer<State>[] = [redeclareStore];
