import { User } from 'src/app/models/user.model';

export enum ContactStatusUploadSource {
  IN_APP_UPDATE = 'In-App Update',
  CSV_UPLOAD = 'CSV Upload',
  FUNDED_DATE = 'the Funded Date',
  MILESTONE_DATE = 'Milestone Date Automation',
  DRIP_CAMPAIGN = 'Drip Campaign Automation for',
  INTEGRATION = '3rd Party Integration Update',
};

export enum CarrierType {
  MOBILE = 'mobile',
  LANDLINE = 'landline',
  VOIP = 'voip'
};

export interface ContactDetail {
  primary_transaction?: string;
  partnerContact: any;
  addedBy?: string;
  contact_assigned_to?: string;
  assignedto?: string;
  address: string;
  currentStatus?: string;
  dateOfBirth: string;
  firstName: string;
  middleName?: string;
  gender: string;
  lastName: string;
  phoneNumber: string;
  photoURL?: string;
  custom: any;
  doc_id: string;
  viewersList?: string[];
  state?: string;
  lead?: string;
  email: string;
  countryCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  partnerId?: string;
  partnerName?: string;
  associated_loan_ids?: any[];
  employerName: string;
  encompass_id?: string;
  faxNumber?: number;
  homePhone?: number;
  owner_id: string;
  source: string;
  zip?: string;
  drips?: any[];
  tags?: any[];
  status?: string;
  office_location: string;
  office_address: string;
  office_state: string;
  office_zip: string;
  anniversary: string;
  spouse_name: string;
  spouse_email: string;
  spouse_number: string;
  spouse_dob: string;
  customer_id: string;
  TRUE_AI_USER_ID?: string;
  TRUE_AI_CONTACT_ID?: string;
  doNotCall?: boolean;
  property_addressLine1?: string;
  property_addressLine2?: string;
  property_city?: string;
  property_state?: string;
  property_zip?: string;
  companyName?: string;
  partnerType?: string;
  status_updated_on?: string;
  status_updated_by?: string;
  status_updated_source?: ContactStatusUploadSource;
  status_updated_doc?: string;
  carrierType?: CarrierType;
  optOutEmail?: boolean;
  optOutMobile?: boolean;
}

export interface TaskDetail {
  task_extra?: string;
  assignedto: User;
  task: string;
  transactionid: string;
  dueTime: string;
  assignedby: User;
  contactId: string;
  created_at: string;
  updated_at?: string;
  task_id: string;
  visibility: boolean;
  taskStatus: string;
  mggInfo?: {
    title: string;
    message: string;
  };
  customTask?: string;
  currentStep?: number;
  drip_name?: string;
  total_steps?: string;
  url?: string;
  campaign_name?: string;
  source?: string
}

export interface LookupPhoneNumberData {
  CarrierName: string | null;
  CarrierType: CarrierType | null;
  PhoneNumber: string;
  Valid: boolean;
  LastDateChecked: firebase.default.firestore.Timestamp;
}

export interface LookupResponse {
  lookupResponse: LookupPhoneNumberData;
}
