import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'link-button-renderer',
  template: `<button class="btn btn-link"  (click)="clickAction()" type="button">{{params.value}} </button>`,
  styles: [
    `
      .btn-link { color: #181d1f !important; text-decoration: solid !important; }
    `,
  ],
})
export class LinkButtonRendererComponent implements ICellRendererAngularComp {
  params;

  clickAction = () => {
    this.params.clickAction(this.params.data);
  }

  refresh(params: ICellRendererParams|{clickAction:any}): boolean {
    this.params = params
    return true;
  }

  agInit(params: ICellRendererParams|{clickAction:any}): void {
    this.params = params
  }
}
