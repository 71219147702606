<div class="d-flex flex-column px-4 pb-3 pt-2 v2-ui-integretion">
  <div class="modal-header" style="padding: 0rem !important">
    <span class="font-16 font-weight-bold color-grey-text py-3">
      Resync Milestones
    </span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="material-icons icon-color py-2"> close </i>
    </button>
  </div>
  <dailyai-hidden-input></dailyai-hidden-input>

  <div class="border"></div>

  <div class="d-flex flex-column max-height-500 my-16px">
    <div class="d-flex font-13 color-grey-text py-3">
      <span>
        Select the Team Leads that you want to receive the updated milestones.
      </span>
    </div>

    <div class="d-flex flex-column font-14 color-grey-text pb-2 w-50">

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="selectAll"
          [checked]="usersList?.length === selectedUsersUids?.size"
          (change)="toggleSelectAll($event.target.checked)"
          [indeterminate]="isSelectAllIndeterminate()"
        >
        <label class="form-check-label" for="selectAll">
          Select All
        </label>
      </div>

      <div class="border-dark border-bottom"></div>

      <div *ngFor="let user of usersList" class="d-flex flex-column manager border-bottom py-2x">
        <div
          class="d-flex align-items-center py-2x"
          [class.bg-gray-light]="user.userType !== userType.MANAGER"
          [class.label-no-padding]="user.userType === userType.TEAM_LEADER && !user.managerId"
          [class.alert-failed]="user.resyncFailed && !showResyncMilestonesFailedMessage"
        >
          <div class="form-check font-14">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="'managerCheckbox'+user.uid"
              [checked]="isUserChecked(user)"
              (change)="toggleUserSelection($event.target.checked, user)"
            >
            <label class="form-check-label" [for]="'managerCheckbox'+user.uid">
              {{user.firstName}} {{user.lastName}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 border-bottom"></div>

  <div
    class="d-flex align-items-start h-60 alert alert-success my-2 border-0 rounded-0"
    *ngIf="showResyncMilestonesSuccessMessage"
  >
    <img src="../../../../assets/icons/resync-success.svg" alt="" class="font-25 mx-3 py-2" />

    <span class="font-16">
      Milestones resynced successfully
    </span>
  </div>

  <div
    class="d-flex align-items-start alert alert-danger my-2 border-0 rounded-0"
    *ngIf="showResyncMilestonesFailedMessage"
  >
    <img src="../../../../assets/icons/resync-failed.svg" alt="" class="font-25 mx-3 py-2" />

    <div>
      <span class="font-16">Milestones failed to  resync</span>

      <span class="font-13">
        Something went wrong. Milestones are not resynced. Try again. If the problem persists, contact support@dailyai.io
      </span>
    </div>
  </div>

  <div
    class="d-flex align-items-start alert alert-warning my-2 border-0 rounded-0"
    *ngIf="showResyncMilestonesPartialSuccessMessage"
  >
    <img src="../../../../assets/icons/resync-warning.svg" alt="" class="font-25 mx-3 py-2" />

    <div class="d-flex flex-column">
      <span class="font-16">Milestones partially resynced</span>

      <span class="font-13">
        Milestones did not resync for the above highlighted Team Leads.
        Try resyncing again. If the problem persists, contact support@dailyai.io
      </span>
    </div>
  </div>

  <div class="font-13 color-grey-text">
    <span>This resync will completely override all components of milestones.  Any drip campaigns that are triggered by milestone actions will also be overridden.  Any contacts that are currently in one of these drip campaigns will be removed.  Milestone components that will be overridden include:</span>
    <ul>
      <li>Milestone Templates</li>
      <li>Milestone Statuses (Prospect and In-Process)</li>
      <li>Milestone Actions</li>
      <li>Loan Purposes</li>
      <li>Dates Milestones</li>
    </ul>
  </div>

  <div class="d-flex justify-content-center align-items-center mb-3 mt-4">
    <button class="btn btn-normal px-4 mr-2" (click)="close()">Cancel</button>
    <button
      class="btn btn-primary px-4"
      [disabled]="showResyncMilestonesSuccessMessage || isLoading || selectedUsersUids.size === 0"
      (click)="submit()"
    >
      Resync
      <span
        *ngIf="isLoading"
        [ngClass]="{
              'spinner-border spinner-border-sm': isLoading,
              nil: !isLoading
            }"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
</div>
