<div class="px-2 pb-2 v2-ui-integretion">
  <div class="modal-header pb-0">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <h5 class="pt-3 color-grey-text text-center font-18 w-100">Record video message for your drips</h5>
  <div class="modal-body">
    <div class="row">
      <div class="w-100 position-relative margin-responsive">
        <video #video (ended)="vidEnded()"></video> <canvas id="canvas"></canvas>
        <div *ngIf="!isRecording" class="record-icon" (click)="startVideoRecording()">
          <i class="material-icons record-ani"> videocam </i>
        </div>
        <div *ngIf="isRecording" class="control-icons d-flex justify-content-center align-items-center w-100">
          <span
            *ngIf="!pause"
            (click)="pauseVideo()"
            class="icon-pause d-flex justify-content-center align-items-center mr-2 shadow cursor-pointer"
          >
            <i class="material-icons"> pause </i>
          </span>
          <span
            *ngIf="!play"
            (click)="playAgain()"
            class="icon-pause d-flex justify-content-center align-items-center mr-2 shadow cursor-pointer"
          >
            <i class="material-icons"> play_arrow </i>
          </span>
          <span
            *ngIf="!recordStopped"
            class="icon-stop d-flex justify-content-center align-items-center mr-2 shadow cursor-pointer"
            (click)="stopVideoRecording()"
          >
            <i class="material-icons"> stop </i>
          </span>
          <span
            *ngIf="recordStopped"
            (click)="startVideoRecording('new')"
            class="icon-mic d-flex justify-content-center align-items-center shadow cursor-pointer"
          >
            <i class="material-icons"> videocam </i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column mx-2 mb-4">
    <label class="font-14"> File Name</label>
    <div class="d-flex">
      <input type="text" [(ngModel)]="videoName" placeholder="Enter video title.." class="form-control" />
      <button [disabled]="videoName == '' || !recordStopped" (click)="saveVideo()" class="btn btn-primary ml-3">
        Save
      </button>
    </div>
  </div>
</div>
