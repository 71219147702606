import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/modules/login/store/state';
import { UserService } from 'src/app/modules/settings/services/user.service';

@Component({
  selector: 'dailyai-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit {
  timeLeft = 5;

  interval;

  @Input() fromConfirmation;

  emailConfirmCode: any;

  userToken: { createdDate: Date; token: string } = null;

  currentUser: any;

  userSubscription: Subscription;

  loading: boolean;

  finalEmail: any;

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private store: Store<LoginState>
  ) {}

  ngOnInit(): void {
    this.emailConfirmCode = this.fromConfirmation.prop1;
  }

  closeModals(all?: boolean) {
    this.activeModal.dismiss();
    if (all) {
      this.ngbModal.dismissAll();
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  async changeEmail() {
    this.loading = true;
    const purpose = 'changeEmail';
    const res = await this.userService.changeUserEmail(purpose, this.emailConfirmCode);
    this.finalEmail = res;

    if (res['err'] === false) {
      this.loading = false;
      this.toastr.success('Email Changed', 'Success');
    } else {
      this.loading = false;
      this.activeModal.dismiss();
      if (`${res['toast']}` === null) {
        this.toastr.error(`${res['msg']}`, 'Error Message');
      } else {
        this.toastr.error(`${res['toast']}`, 'Error Message');
      }
    }
  }
}
