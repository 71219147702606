import { createAction, props } from '@ngrx/store';

export const SigningUp = createAction(
  '[Signup Page] SigningUp',
  props<{
    firstName: string;
    lastName: string;
    userEmail: string;
    userPassword: string;
    phoneNo: string;
    timezone: string;
    userType?: string;
    parentId?: string;
    companyName: string;
    jlo_should_refill?: boolean;
    privileged_jlo?: boolean;
  }>()
);
export const SignupReset = createAction('[Signup Page] Signup Reset');
export const SignupSuccess = createAction('[Signup Page] Signup Success');
export const SignupError = createAction('[Signup Page] Signup Error', props<{ msg: string }>());
