import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { from, merge, of } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  switchMap,
  tap,
  withLatestFrom,
  takeUntil,
  delay,
} from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { IntakeForm } from 'src/app/models/intake-form.model';
import { UserService } from '../services/user.service';
import { MilestoneService } from '../services/milestone.service';
import { TemplateService } from '../services/template.service';
import { SettingsService } from '../services/settings.service';
import { GeneralTemplatesService } from '../services/general-templates.service';
import { CustomFieldsService } from '../services/custom-fields.service';
import { IntakeFormService } from '../services/intake-form.service';
import * as UserActions from './action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private appService: AppService,
    private toastr: ToastrService,
    private store$: Store<State>,
    private milestoneService: MilestoneService,
    private templateService: TemplateService,
    private generalTempService: GeneralTemplatesService,
    private settingsService: SettingsService,
    private modal: NgbModal,
    private subService: SubscriptionService,
    private customFieldsService: CustomFieldsService,
    private intakeFormService: IntakeFormService
  ) { }

  AddUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AddUser),
      switchMap((payload) => from(this.userService.addUser(payload)).pipe(
        delay(3000),
        switchMap((user) => {
          this.appService.showLoading(false);
          return merge(of(UserActions.AddUserSuccess()), of(UserActions.GetUsers({ data: { curPage: 1 } })));
        }),
        tap((v) => {
          this.toastr.success('Invite sent');
        }),
        catchError((error) => {
          this.appService.showLoading(false);
          this.toastr.error(error.error.msg);
          return of(UserActions.AddUserError({ msg: error.error.msg }));
        })
      )
      )
    );
  }
  );

  AddMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AddMilestone),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => from(this.milestoneService.addMilestone(payload.action)).pipe(
        switchMap((milestone) => {
          this.toastr.success('Milestone changes saved');
          this.appService.showLoading(false);

          return merge(
            of(UserActions.AddMilestoneSuccess()),
            of(
              UserActions.GetMilestone({
                uid: payload.state.login.currentUser.uid,
                userType: payload.state.login.currentUser.userType,
                tab: payload.action.tab,
              })
            )
          );
        }),
        catchError((error) => {
          console.warn('error', error);

          this.appService.showLoading(false);
          this.toastr.error(error.error.msg);
          return of(UserActions.AddMilestoneError({ msg: error.error.msg }));
        })
      )
      )
    );
  }
  );

  AddTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.AddTemplate),
      switchMap((payload) => from(this.templateService.addTemplate(payload)).pipe(
        map((res) => {
          this.appService.showLoading(false);
          this.toastr.success('Template added successfully');
          return UserActions.AddTemplateSuccess();
        }),
        catchError((error) => {
          this.appService.showLoading(false);
          this.toastr.error(error.error.msg);
          return of(UserActions.AddTemplateError({ msg: error.error.msg }));
        })
      )
      )
    );
  }
  );

  UpdateTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.UpdateTemplate),
      switchMap((payload) => from(this.templateService.updateTemplate(payload.data)).pipe(
        map((res) => {
          this.toastr.success('Template updated successfully');
          this.appService.showLoading(false);
          return UserActions.UpdateTemplateSuccess();
        }),
        catchError((error) => {
          console.error('error', error);
          this.appService.showLoading(false);
          this.toastr.error(error.error.msg);
          return of(UserActions.UpdateTemplateError({ msg: error.error.msg }));
        })
      )
      )
    );
  }
  );

  DeleteTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.DeleteTemplate),
      switchMap((payload) => from(this.templateService.deleteTemplate(payload.templateId, payload.page)).pipe(
        map((res) => {
          this.toastr.success('Template deleted successfully');
          this.appService.showLoading(false);
          return UserActions.DeleteTemplateSuccess();
        }),
        catchError((error) => {
          this.appService.showLoading(false);
          this.toastr.error(error.error.msg);
          return of(UserActions.DeleteTemplateError({ msg: error.error.msg }));
        })
      )
      )
    );
  }
  );

  ListUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ListUser),
      mergeMap((payload) =>
        this.userService.getUsers(payload).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((user) => {
            return UserActions.ListUserSuccess({ data: user });
          }),
          catchError((error) => of(UserActions.ListUserError({ msg: error.msg })))
        )
      )
    )
  );

  @Effect()
  GetMilestone$ = this.actions$.pipe(
    ofType(UserActions.GetMilestone),
    withLatestFrom(this.store$, (action, state) => ({ state, action })),
    switchMap((payload) =>
      this.milestoneService
        .getMilestone(payload.action.uid, payload.state.login.currentUser, payload.action.userType, payload.action.tab)
        .pipe(
          takeUntil(this.subService.unsubscribe$),
          map((milestones: any[]) => {
            let milestones2 = milestones ? [...milestones] : [];
            let statusNames: { status: string; inProcess: boolean }[] = payload.state.users.statusNames
              ? payload.state.users.statusNames
              : [];

            if (payload.action.tab && payload.action.tab === 'prospect') {
              statusNames = milestones2.map((m) => {
                return {
                  status: m.status_name,
                  inProcess: m.inProcess ? m.inProcess : false,
                  showInPipelineView: m.show_in_pipeline_view != false,
                };
              });

              milestones2 = milestones2.filter((m) => !m.inProcess);
            }
            return UserActions.GetMilestoneSuccess({ data: milestones2, statusNames });
          }),
          catchError((error) => {
            console.warn('error==>', error);
            return of(UserActions.GetMilestoneError({ msg: error.msg }));
          })
        )
    )
  );

  @Effect()
  GetAllIntakeForms$ = this.actions$.pipe(
    ofType(UserActions.GetAllIntakeForms),
    withLatestFrom(this.store$, (action, state) => ({ state, action })),
    mergeMap((payload) =>
      this.intakeFormService.getAllIntakeForms(payload.state.login.currentUser).pipe(
        takeUntil(this.subService.unsubscribe$),
        map((intakeForms) => {
          if (intakeForms) {
            const modifiedIntakeForm: IntakeForm[] = intakeForms.sort((a: IntakeForm, b: IntakeForm) =>
              a.formName.toLowerCase() < b.formName.toLowerCase() ? -1 : 1
            );
            return UserActions.GetAllIntakeFormsSuccess({ data: modifiedIntakeForm });
          }
          return UserActions.GetAllIntakeFormsSuccess({ data: [] });
        }),
        catchError((error) => {
          return of(UserActions.GetAllIntakeFormsError({ data: error }));
        })
      )
    ));

  @Effect()
  GetTemplate$ = this.actions$.pipe(
    ofType(UserActions.GetTemplate),
    mergeMap((payload) =>
      this.templateService.listTemplate(payload.uid, payload.user).pipe(
        takeUntil(this.subService.unsubscribe$),
        map((templates) => {
          return UserActions.GetTemplateSuccess({ data: templates });
        }),
        catchError((error) => {
          return of(UserActions.GetTemplateError({ msg: error }));
        })
      )
    )
  );

  @Effect()
  GetSMSTemplate$ = this.actions$.pipe(
    ofType(UserActions.GetSMSTemplate),
    mergeMap((payload) =>
      this.templateService.listsmsTemplate(payload.uid, payload.user).pipe(
        takeUntil(this.subService.unsubscribe$),
        map((templates) => {
          return UserActions.GetSMSTemplateSuccess({ data: templates });
        }),
        catchError((error) => {
          return of(UserActions.GetSMSTemplateError({ msg: error.msg }));
        })
      )
    )
  );

  @Effect()
  GetMmsTemplate$ = this.actions$.pipe(
    ofType(UserActions.GetMmsTemplate),
    mergeMap((payload) => {
      return this.templateService.listMmsTemplate(payload.uid, payload.user).pipe(
        takeUntil(this.subService.unsubscribe$),
        map((templates) => UserActions.GetMMSTemplateSuccess({ data: templates }))
      );
    }),
    catchError((error) => {
      return of(UserActions.GetMMSTemplateError({ msg: error.msg }));
    })
  );

  GetChild$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetChildList),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) =>
        this.userService.getChild(payload.action, payload.state.login.currentUser, payload.action.moExtra).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((children) => UserActions.GetChildListSuccess({ data: children })),
          catchError((err) => of(UserActions.GetChildListError({ msg: err.message })))
        )
      )
    )
  );

  GetCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetCustomFields),
      withLatestFrom(this.store$, (payload, state) => ({
        ...payload,
        user: state.login.currentUser,
        user_custom_fields_value: state.login.currentUser?.user_custom_fields_value,
      })),
      switchMap((payload) =>
        this.customFieldsService.getCustomFields(payload.user).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((customFields) =>
            UserActions.GetCustomFieldsSuccess({
              data: customFields,
              uId: payload.uid,
              user_custom_fields_value: payload.user_custom_fields_value,
            })
          ),
          catchError((err) => of(UserActions.GetCustomFieldsError({ msg: err.message })))
        )
      )
    )
  );

  GetCustomFieldsEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetCustomFieldsEdit),
      withLatestFrom(this.store$, (payload, state) => ({
        ...payload,
        user: state.login.currentUser,
      })),
      switchMap((payload) =>
        this.customFieldsService.getCustomFields(payload.user, payload.isEdit).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((customFields) =>
            UserActions.GetCustomFieldsSuccessEdit({
              data: customFields,
              uId: payload.uid,
            })
          ),
          catchError((err) => of(UserActions.GetCustomFieldsErrorEdit({ msg: err.message })))
        )
      )
    )
  );

  SaveCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SaveCustomFields),
      switchMap((payload) =>
        from(this.customFieldsService.saveCustomFields(payload.uid, payload.data, payload.userType)).pipe(
          switchMap((resp) => {
            this.toastr.success('Saved Successfully!');
            return merge(
              of(UserActions.SaveCustomFieldsSuccess({ data: payload.data })),
              of(UserActions.GetCustomFields({ uid: payload.uid }))
            );
          }),
          catchError((err) => {
            this.toastr.error(err.msg);
            return of(UserActions.SaveCustomFieldsError({ msg: err.message }));
          })
        )
      )
    )
  );

  DeleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeleteUser),
      switchMap((payload) =>
        from(
          this.userService.deleteUser(payload).pipe(
            map((contact) => UserActions.DeleteUserSuccess()),
            tap((v) => {
              this.toastr.success('Deactivated user');
              this.appService.showLoading(false);
            }),
            catchError((error) => {
              this.toastr.error(error.error.msg);
              return of(UserActions.DeleteUserError({ msg: error.error.msg }));
            })
          )
        )
      )
    )
  );

  AddCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.AddCredit),
      switchMap((payload) =>
        from(this.userService.chargeCard(payload)).pipe(
          map((_) => {
            return UserActions.AddCreditSuccess();
          }),
          tap((v) => {
            this.toastr.success('Purchased successfully');
          }),
          catchError((error) => {
            this.toastr.error(error.msg);
            return of(UserActions.AddCreditError({ msg: error.message }));
          })
        )
      )
    )
  );

  ActivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ActivateUser),
      switchMap((payload) =>
        this.userService.activateUser(payload).pipe(
          map((status) => {
            this.toastr.success(status.msg);
            return UserActions.ActivateUserSuccess();
          }),
          catchError((error) => {
            this.toastr.error(error.error.msg);
            return of(UserActions.ActivateUserError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  FetchUserCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUserCount),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      mergeMap((payload) =>
        from(this.userService.fetchCount(payload.state.login.currentUser.uid)).pipe(
          map((data) => {
            return UserActions.UserCount({ data: data['count'] });
          })
        )
      )
    )
  );

  FetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUsers),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      mergeMap((payload) =>
        from(this.userService.fetchUsers(payload.action.data, payload.state.login.currentUser.uid)).pipe(
          map((result) => {
            return UserActions.ListUserSuccess({ data: result });
          })
        )
      )
    )
  );

  SearchContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SearchUsers),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) =>
        from(this.userService.searchUsers(payload.action.data, payload.state.login.currentUser.uid)).pipe(
          map((result) => {
            return UserActions.ListUserSuccess({ data: result });
          })
        )
      )
    )
  );

  AddVCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.AddVcard),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) =>
        from(this.settingsService.addVcard(payload.action.data)).pipe(
          map((result) => {
            this.modal.dismissAll();
            if (!payload.action.noToast) {
              this.toastr.success('V-Card Saved Successfully');
            }
            return UserActions.AddVcardSuccess({ data: result });
          }),
          catchError((error) => {
            this.modal.dismissAll();
            this.toastr.error(error.error.msg);
            return of(UserActions.AddVcardError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  ContactSentSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.ContactSentSms),
      switchMap((payload) => {
        return from(this.settingsService.sendSms(payload.data)).pipe(
          map((contact) => {
            this.toastr.clear();
            this.toastr.success('Message Sent Successfully');

            return UserActions.ContactSentSmsSuccess();
          }),
          catchError((error) => {
            this.toastr.error('Error Sending Message');
            return of(UserActions.ContactSentSmsError({ msg: error.error.msg }));
          })
        );
      })
    )
  );

  ToggleAi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.toggleAi),
      withLatestFrom(this.store$, (action, state) => ({
        state: state.login,
        action,
      })),

      switchMap((payload) => {
        const id =
          payload.state.currentUser.userType === 'admin'
            ? payload.state.currentUser.enterpriseId
            : payload.state.currentUser.uid;
        return from(
          this.settingsService.toggleAi(id, payload.action.toggle, payload.state.currentUser?.userType === 'admin')
        ).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((toggle) => {
            const options = { positionClass: 'toast-top-right' };
            if (payload.action.toggle) {
              this.toastr.success('Notification Center is Turned On', undefined, options);
            } else {
              this.toastr.success('Notification Center is Turned Off', undefined, options);
            }
            return UserActions.toggleAiSuccess();
          }),
          catchError((error) => {
            return of(UserActions.toggleAiError({ msg: error.error.msg }));
          })
        );
      })
    );
  });

  SaveLeadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveLeadNotification),
      withLatestFrom(this.store$, (action, state) => ({
        state: state.login,
        action,
      })),
      switchMap((payload) => {
        return from(
          this.settingsService.saveLeadNotification({
            ...payload.action,
            uid: payload.state.currentUser.uid,
            enterpriseId: payload.state.currentUser.enterpriseId,
          })
        ).pipe(
          map((notification) => {
            switch (payload.action.data['event']) {
              case 'nurture': {
                if (payload.action.data['event'] === 'nurture') {
                  this.toastr.success('Nurture notification saved successfully');
                  break;
                }
              }
              case 'lead': {
                this.toastr.success('Lead notification saved successfully');
                break;
              }
              default: {
                this.toastr.success(`${payload.action.data['event']} notification saved successfully`);
              }
            }
            return UserActions.saveLeadNotificationSuccess();
          }),
          catchError((error) => {
            this.toastr.error('Error saving lead notification');
            return of(UserActions.saveLeadNotificationError({ msg: error }));
          })
        );
      })
    )
  );

  getAiSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getAiSettings),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        return this.settingsService
          .getAiSettings({
            uid: payload.state.login.currentUser.uid,
            enterpriseId: payload.state.login.currentUser.enterpriseId,
            userType: payload.state.login.currentUser.userType,
            ...payload.action,
          })
          .pipe(
            takeUntil(this.subService.unsubscribe$),
            map((result) => {
              return UserActions.getAiSettingsSuccess({ data: result });
            }),
            catchError((error) => of(UserActions.getAiSettingsError({ msg: error })))
          );
      })
    )
  );

  GetCamapignUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetCampaignUsers),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) =>
        from(
          this.settingsService.getCampaignUsers({
            curPage: payload.state.users.campaignUsersCurPage,
            parentId: payload.state.login.currentUser.parentId
              ? payload.state.login.currentUser.parentId
              : payload.state.login.currentUser.uid,
            search: payload.action.search,
            sort: payload.action.sort,
            filters: payload.action.filters,
          })
        ).pipe(map((result) => UserActions.CampaignUsersSuccess(result)))
      )
    )
  );

  GetCamapignList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetCampaignList),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) =>
        from(
          this.settingsService.getCampaignList({
            curPage: payload.state.users.campaignListCurPage,
            user_id: payload.state.users.currentCampaignUid,
            search: payload.action.search,
            sort: payload.action.sort,
          })
        ).pipe(map((result) => UserActions.CampaignListSuccess(result)))
      )
    )
  );

  RefreshCampUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.refreshCampUserData),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        if (payload.state.users.oldCampPayload) {
          return from(
            this.settingsService.getCampaignUsers({
              ...payload.state.users.oldCampPayload,
              parentId: payload.state.login.currentUser.parentId
                ? payload.state.login.currentUser.parentId
                : payload.state.login.currentUser.uid,
            })
          ).pipe(map((result) => UserActions.CampaignUsersSuccess(result)));
        }
        return from(
          this.settingsService.getCampaignUsers({
            curPage: 1,
            parentId: payload.state.login.currentUser.parentId
              ? payload.state.login.currentUser.parentId
              : payload.state.login.currentUser.uid,
          })
        ).pipe(map((result) => UserActions.CampaignUsersSuccess(result)));

      })
    )
  );

  @Effect()
  GetEmailGeneralTemplate$ = this.actions$.pipe(
    ofType(UserActions.GetEmailGeneralTemplate),
    mergeMap((payload) =>
      this.generalTempService.listEmailTemplates(payload.uid).pipe(
        takeUntil(this.subService.unsubscribe$),
        map((templates) => {
          if (templates) {
            return UserActions.GetEmailGeneralTemplateSuccess({ data: templates });
          }
        }),
        catchError((error) => {
          return of(UserActions.GetEmailGeneralTemplateError({ msg: error }));
        })
      )
    )
  );

  DeleteEmailGeneralTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeleteEmailGeneralTemplate),
      switchMap((payload) =>
        from(this.generalTempService.deleteEmailTemplate(payload.templateId)).pipe(
          map((res) => {
            this.toastr.success('Template deleted(s) successfully');
            this.appService.showLoading(false);
            return UserActions.DeleteEmailGeneralTemplateSuccess();
          }),
          catchError((error) => {
            this.appService.showLoading(false);
            this.toastr.error(error.error.msg);
            return of(UserActions.DeleteEmailGeneralTemplateError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  UpdateGeneralTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UpdateGeneralTemplate),
      switchMap((payload) =>
        from(this.generalTempService.updateTemplate(payload.data)).pipe(
          map((res) => {
            this.toastr.success('Template updated successfully');
            this.appService.showLoading(false);
            return UserActions.UpdateGeneralTemplateSuccess();
          }),
          catchError((error) => {
            console.warn('error', error);
            this.appService.showLoading(false);
            this.toastr.error(error.error.msg);
            return of(UserActions.UpdateGeneralTemplateError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  getHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetHistory),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return this.settingsService
          .getHistory(payload.action.data, payload.state.login.currentUser.uid, payload.action.token)
          .pipe(
            map((data) => {
              this.appService.showLoading(false);
              return UserActions.GetHistorySuccess({
                data: data['data'],
                count: data['count'],
              });
            }),
            catchError((error) => of(UserActions.GetHistoryError({ msg: error.message ? error.message : null })))
          );
      })
    )
  );

  GetRefinancePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetRefinancePurpose),
      switchMap((payload) => {
        return from(this.settingsService.getPurpose(payload.user, payload.radio)).pipe(
          map((data) => {
            this.appService.showLoading(false);

            return UserActions.GetRefinancePurposeSuccess({
              data,
            });
          }),
          catchError((error) => {
            console.log('error', error);

            return of(UserActions.GetRefinancePurposeError({ msg: error.message ? error.message : null }));
          })
        );
      })
    )
  );

  SaveRefinancePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SaveRefinancePurpose),
      switchMap((payload) =>
        from(this.settingsService.savePurpose(payload.user, payload.purpose, payload.radio)).pipe(
          map((res) => {
            this.toastr.success('Secondary Workflow saved successfully');
            return UserActions.GetRefinancePurpose({ user: payload.user, radio: payload.radio });
          }),
          catchError((error) => {
            console.log('err', error);
            return of(UserActions.SaveRefinancePurposeError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  DeleteRefinancePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeleteRefinancePurpose),
      switchMap((payload) =>
        from(this.settingsService.deletePurpose(payload.user, payload.doc_id, payload.radio)).pipe(
          map((res) => {
            this.toastr.success('Secondary Workflow deleted successfully');
            return UserActions.GetRefinancePurpose({ user: payload.user, radio: payload.radio });
          }),
          catchError((error) => {
            console.log('err', error);
            return of(UserActions.DeleteRefinancePurposeError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  GetPurchasePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetPurchasePurpose),
      switchMap((payload) => {
        return from(this.settingsService.getPurpose(payload.user, payload.radio)).pipe(
          map((data) => {
            this.appService.showLoading(false);

            return UserActions.GetPurchasePurposeSuccess({
              data,
            });
          }),
          catchError((error) => {
            console.log('error', error);

            return of(UserActions.GetPurchasePurposeError({ msg: error.message ? error.message : null }));
          })
        );
      })
    )
  );

  SavePurchasePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SavePurchasePurpose),
      switchMap((payload) =>
        from(this.settingsService.savePurpose(payload.user, payload.purpose, payload.radio)).pipe(
          map((res) => {
            this.toastr.success('Primary Workflow saved successfully');
            return UserActions.GetPurchasePurpose({ user: payload.user, radio: payload.radio });
          }),
          catchError((error) => {
            console.log('err', error);
            return of(UserActions.SavePurchasePurposeError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  DeletePurchasePurpose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeletePurchasePurpose),
      switchMap((payload) =>
        from(this.settingsService.deletePurpose(payload.user, payload.doc_id, payload.radio)).pipe(
          map((res) => {
            this.toastr.success('Primary Workflow deleted successfully');
            return UserActions.GetPurchasePurpose({ user: payload.user, radio: payload.radio });
          }),
          catchError((error) => {
            console.log('err', error);
            return of(UserActions.DeletePurchasePurposeError({ msg: error.error.msg }));
          })
        )
      )
    )
  );

  getPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetPackage),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(this.settingsService.getPackage(payload.enterpriseId)).pipe(
          map((data) => {

            this.appService.showLoading(false);
            return UserActions.GetPackageSuccess({
              data,
            });
          }),
          catchError((error) => of(UserActions.GetPackageError({ msg: error.message ? error.message : null })))
        );
      })
    )
  );
}
