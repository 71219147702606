<form #startTeam [formGroup]="changeEmailForm">
  <div class="px-2 pb-3 v2-ui-integretion">
    <ng-container *ngIf="!isShown">
      <div class="modal-header pb-0">
        <h4 class="color-grey-text text-center text-uppercase px-2">Change Email</h4>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <i class="material-icons icon-color"> close </i>
        </button>
      </div>
    </ng-container>
    <div class="modal-body" *ngIf="!isShown">
      <div class="d-flex flex-column">
        <div class="col-12 px-0 d-flex flex-column" style="margin-bottom: 1em">
          <label class="font-14 color-black-grey-text">Current Email</label>
          <input type="text" class="form-control text-left" value="{{ oldEmail || newOld }}" disabled />
        </div>
        <div class="col-12 px-0 d-flex flex-column">
          <label class="font-14 color-black-grey-text">New Email</label>
          <input
            formControlName="new_email"
            type="text"
            class="form-control text-left input-popover-placeholder"
            placeholder="New Email"
          />
          <div class="error-box" style="height: 12px">
            <span *ngIf="(new_email?.dirty || new_email?.touched) && new_email?.invalid && new_email?.errors?.required">
              ***New Email is required***
            </span>
            <span *ngIf="(new_email?.dirty || new_email?.touched) && new_email?.invalid && new_email?.errors?.email">
              ***Please enter Valid Email***
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-3 bottom-btn">
          <button type="button" class="btn btn-normal mr-2 px-4" (click)="closeModal()">Cancel</button>
          <button
            *ngIf="!loading"
            type="button"
            class="btn btn-primary px-4"
            [disabled]="!changeEmailForm.valid"
            (click)="addEmail()"
          >
            Confirm
          </button>
          <button *ngIf="loading" [disabled]="loading" class="btn btn-normal px-4">
            Confirm<span
              [ngClass]="{
                nil: !loading
              }"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
    <form [formGroup]="changeEmailConfirmForm" *ngIf="isShown">
      <div class="modal-header pb-0">
        <h4 class="color-grey-text text-center text-uppercase px-2">Email Verification</h4>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <i class="material-icons icon-color"> close </i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column">
          <p class="justify-content-center" style="color: gray; font-size: 16px">
            <b
              >Please enter the verification code sent to:
              <div>
                <span class="px-0" style="color: gray">{{ newMails.new_email }}</span>
              </div>
            </b>
          </p>
          <div class="col-12 px-0 py-1 d-flex flex-column">
            <div class="d-flex justify-content-start">
              <span class="px-0" style="color: black; margin-bottom: 0.5em"
                >Code Expires in:<span
                  class="px-0"
                  [ngClass]="{ message: timeLeft < 600, message1: timeLeft <= 0 }"
                  *ngIf="timeLeft < 600"
                  >{{ timerString }}&nbsp;mins</span
                >
                <span class="px-2" style="color: red; margin-bottom: 0.5em" *ngIf="timeLeft <= 0">Expired!</span>
              </span>
            </div>
            <input
              formControlName="new_passcode"
              [appOnlyNumber]="true"
              type="text"
              class="form-control text-left input-popover-placeholder"
              placeholder="New Passcode"
            />
            <div class="error-box">
              <span
                *ngIf="
                  (new_passcode?.dirty || new_passcode?.touched) &&
                  new_passcode?.invalid &&
                  new_passcode?.errors?.required
                "
              >
                ***Passcode is required***
              </span>
              <span
                *ngIf="
                  (new_passcode?.dirty || new_passcode?.touched) &&
                  new_passcode?.invalid &&
                  new_passcode?.errors?.minlength
                "
              >
                ***Please enter Valid Passcode***
              </span>
              <span
                *ngIf="
                  (new_passcode?.dirty || new_passcode?.touched) &&
                  new_passcode?.invalid &&
                  new_passcode?.errors?.maxlength
                "
              >
                ***Please enter Valid Passcode***
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center bottom-btn">
            <span>
              <button style="border: none" type="button" class="btn btn-sm rebtn" (click)="resendCode()">
                Resend Code</button
              ><span style="color: black">|</span>
              <button
                style="border: none"
                type="button"
                class="btn btn-sm rebtn"
                (click)="changeMail(); changeEmailConfirmForm.reset()"
              >
                Change Mail
              </button>
            </span>
          </div>
          <div class="d-flex justify-content-end align-items-center mt-3 bottom-btn">
            <button type="button" class="btn btn-normal mr-2 px-4" (click)="closeModal()">Cancel</button>
            <button
              type="button"
              class="btn btn-primary px-4"
              [disabled]="!changeEmailConfirmForm.valid || !timeLeft"
              (click)="confirmEmail()"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</form>
