<div class="bg-blur">
  <div class="loader">
    <img src="../../../../assets/icons/loader.svg" />
    <div class="pro" *ngIf="progress>-1">
      <div class="mt-2 progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0"
          aria-valuemax="100" [style.width.%]="progress">
          {{ progress | number: '1.0-0' }} %
        </div>
      </div>
    </div>
  </div>
</div>
