<div class="px-2 pb-4 media-layout-drip v2-ui-integretion" id="x">
  <div class="modal-header pb-0">
    <h5 class="py-3 color-grey-text text-uppercase pl-0">Media</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="model-media-drip">
    <dailyai-video-list (selectedMedia)="getSelectedMedia($event)" *ngIf="dripTyp === 'media'"></dailyai-video-list>
    <dailyai-audio-list (selectedMedia)="getSelectedMedia($event)" *ngIf="dripTyp === 'voiceDrop'"></dailyai-audio-list>
    <div class="d-flex justify-content-end mt-4 mr-3">
      <button class="btn btn-normal mr-2 px-4" (click)="closeModal()">Cancel</button>
      <button class="btn btn-primary px-3" (click)="selectFile()">Select File</button>
    </div>
  </div>
</div>
