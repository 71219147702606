import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private db: AngularFirestore, private auth: AuthService) {}

  async addTemplate(payload: any): Promise<any> {
    const {uid} = this.auth.currentUser;
    const userQuery = await this.db.collection<any>('users').doc(uid).ref.get();

    const user = userQuery.data();
    let user_uids: string[] = [];
    user_uids = user.userType === 'Super Admin' || user.userType === 'admin' ? [user.enterpriseId] : [user.uid];

    if (payload.page == 'email') {
      const addStatus = await this.db
        .collection<any>('mail-templates')
        .add({ ...payload.data, viewersList: user_uids });
      return this.db
        .collection<any>('mail-templates')
        .doc(addStatus.id)
        .update({ templateId: addStatus.id, created_at: new Date().toISOString() });
    } if (payload.page === 'mms') {
      const addStatus = await this.db.collection<any>('mms-templates').add({ ...payload.data, viewersList: user_uids });
      return this.db
        .collection<any>('mms-templates')
        .doc(addStatus.id)
        .update({ mmsId: addStatus.id, created_at: new Date().toISOString() });
    }
      const addStatus = await this.db.collection<any>('sms-templates').add({ ...payload.data, viewersList: user_uids });
      return this.db
        .collection<any>('sms-templates')
        .doc(addStatus.id)
        .update({ smsId: addStatus.id, created_at: new Date().toISOString() });

  }

  listTemplate(uid: string, user: any): Observable<any> {
    let id = uid;
    if (user.isEnterprise && (user.userType === 'Super Admin' || user.userType === 'admin')) {
        id = user.enterpriseId;
      }
    return this.db
      .collection<any>('mail-templates', (ref) => ref.where('viewersList', 'array-contains', id).orderBy('created_at'))
      .valueChanges();
  }

  listsmsTemplate(uid: string, user: any): Observable<any> {
    let id = uid;
    if (user.isEnterprise && (user.userType === 'Super Admin' || user.userType === 'admin')) {
        id = user.enterpriseId;
      }
    return this.db
      .collection<any>('sms-templates', (ref) => ref.where('viewersList', 'array-contains', id).orderBy('created_at'))
      .valueChanges();
  }

  listMmsTemplate(uid: string, user: any) {
    let id = uid;
    if (user.isEnterprise && (user.userType === 'Super Admin' || user.userType === 'admin')) {
        id = user.enterpriseId;
      }
    return this.db
      .collection<any>('mms-templates', (ref) => ref.where('viewersList', 'array-contains', id).orderBy('created_at'))
      .valueChanges();
  }

  async updateTemplate(payload: any): Promise<any> {
    if (payload.page == 'email') {
      return this.db
        .collection<any>('mail-templates')
        .doc(payload.templateId)
        .update({ ...payload });
    } if (payload.page == 'mms') {
      return this.db
        .collection<any>('mms-templates')
        .doc(payload.mmsId)
        .update({ ...payload });
    }
      return this.db
        .collection<any>('sms-templates')
        .doc(payload.smsId)
        .update({ ...payload });

  }

  async deleteTemplate(uid: string, page: string): Promise<any> {
    if (page == 'email') {
      return this.db.collection<any>('mail-templates').doc(uid).delete();
    } if (page === 'mms') {
      return this.db.collection<any>('mms-templates').doc(uid).delete();
    }
      return this.db.collection<any>('sms-templates').doc(uid).delete();

  }
}
