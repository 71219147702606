export const mergeFields = [
  'administrative_assistant',
  'administrative_assistant_email',
  'appraised_value',
  'broker',
  'broker_email',
  'broker_first_name',
  'broker_last_name',
  'broker_number',
  'builder',
  'builder_email',
  'builder_first_name',
  'builder_last_name',
  'builder_number',
  'builders_transaction_coordinator',
  'builders_transaction_coordinator_email',
  'builders_transaction_coordinator_first_name',
  'builders_transaction_coordinator_last_name',
  'builders_transaction_coordinator_number',
  'business_development_manager',
  'business_development_manager_email',
  'buyers_agent_transaction_coordinator',
  'buyers_agent_transaction_coordinator_email',
  'buyers_agent_transaction_coordinator_first_name',
  'buyers_agent_transaction_coordinator_last_name',
  'buyers_agent_transaction_coordinator_number',
  'buyers_attorney',
  'buyers_attorney_email',
  'buyers_attorney_first_name',
  'buyers_attorney_last_name',
  'buyers_attorney_number',
  'buying_agent',
  'buying_agent_email',
  'buying_agent_first_name',
  'buying_agent_last_name',
  'buying_agent_number',
  'closing_date',
  'coborrower_email',
  'coborrower_name',
  'coborrower_number',
  'contact_in_120min',
  'contact_in_15min',
  'contact_in_30min',
  'contact_in_45min',
  'contact_in_60min',
  'contact_in_90min',
  'cpa',
  'cpa_email',
  'cpa_first_name',
  'cpa_last_name',
  'cpa_number',
  'credit_score',
  'credit_score_co_borr',
  'down_payment',
  'financial_advisor',
  'financial_advisor_email',
  'financial_advisor_first_name',
  'financial_advisor_last_name',
  'financial_advisor_number',
  'from_1_day_ago',
  'from_2_days_ago',
  'from_3_days_ago',
  'from_4_days_ago',
  'from_5_days_ago',
  'from_6_days_ago',
  'from_7_days_ago',
  'funded_date',
  'greeting',
  'greeting_informal',
  'home_warranty',
  'home_warranty_email',
  'home_warranty_first_name',
  'home_warranty_last_name',
  'home_warranty_number',
  'in_1_day',
  'in_2_days',
  'in_3_days',
  'in_4_days',
  'in_5_days',
  'in_6_days',
  'in_7_days',
  'income',
  'insurance_agent',
  'insurance_agent_email',
  'insurance_agent_first_name',
  'insurance_agent_last_name',
  'insurance_agent_number',
  'junior_loan_officer',
  'junior_loan_officer_email',
  'last_msg_time',
  'lead_city',
  'lead_email_address',
  'lead_first_name',
  'lead_middle_name',
  'lead_last_name',
  'lead_phone_number',
  'listing_agent',
  'listing_agent_email',
  'list_agent_first_name',
  'list_agent_last_name',
  'list_agent_number',
  'loan_amount',
  'loan_number',
  'loan_officer',
  'loan_officer_assistant',
  'loan_officer_assistant_email',
  'loan_processor',
  'loan_processor_email',
  'loan_program',
  'loan_purpose',
  'loan_to_value',
  'loan_type',
  'lock_date',
  'marketing_manager',
  'marketing_manager_email',
  'milestone',
  'optin_button',
  'optin_link',
  'property_address',
  'property_addressLine1',
  'property_addressLine2',
  'property_city',
  'property_state',
  'property_zip',
  'rate',
  //   'realtor_first_name',
  'referral_partner_email',
  'referral_partner_first_name',
  'referral_partner_middle_name',
  'referral_partner_full_name',
  'referral_partner_last_name',
  'referral_partner_number',
  'sales_manager',
  'sales_manager_email',
  'listing_agent_transaction_coordinator',
  'listing_agent_transaction_coordinator_email',
  'listing_agent_transaction_coordinator_first_name',
  'listing_agent_transaction_coordinator_last_name',
  'listing_agent_transaction_coordinator_number',
  'sellers_attorney',
  'sellers_attorney_email',
  'sellers_attorney_first_name',
  'sellers_attorney_last_name',
  'sellers_attorney_number',
  'senior_loan_officer',
  'senior_loan_officer_email',
  'term',
  'time_now',
  'title',
  'title_email',
  'title_first_name',
  'title_last_name',
  'title_number',
  'today_day',
  'transaction_purchase_price',
  'unsubscribe_link',
  'user_company',
  'user_email',
  'user_first_name',
  'user_full_name',
  'user_last_name',
  'user_phone_number',
  'user_website',
];
export const templateMergeFields = [
  'administrative_assistant',
  'administrative_assistant_email',
  'appraised_value',
  'broker',
  'broker_email',
  'broker_first_name',
  'broker_last_name',
  'broker_number',
  'builder',
  'builder_email',
  'builder_first_name',
  'builder_last_name',
  'builder_number',
  'builders_transaction_coordinator',
  'builders_transaction_coordinator_email',
  'builders_transaction_coordinator_first_name',
  'builders_transaction_coordinator_last_name',
  'builders_transaction_coordinator_number',
  'business_development_manager',
  'business_development_manager_email',
  'buyers_agent_transaction_coordinator',
  'buyers_agent_transaction_coordinator_email',
  'buyers_agent_transaction_coordinator_first_name',
  'buyers_agent_transaction_coordinator_last_name',
  'buyers_agent_transaction_coordinator_number',
  'buyers_attorney',
  'buyers_attorney_email',
  'buyers_attorney_first_name',
  'buyers_attorney_last_name',
  'buyers_attorney_number',
  'buying_agent',
  'buying_agent_email',
  'buying_agent_first_name',
  'buying_agent_last_name',
  'buying_agent_number',
  'closing_date',
  'coborrower_email',
  'coborrower_name',
  'coborrower_number',
  'contact_in_120min',
  'contact_in_15min',
  'contact_in_30min',
  'contact_in_45min',
  'contact_in_60min',
  'contact_in_90min',
  'cpa',
  'cpa_email',
  'cpa_first_name',
  'cpa_last_name',
  'cpa_number',
  'credit_score',
  'credit_score_co_borr',
  'down_payment',
  'financial_advisor',
  'financial_advisor_email',
  'financial_advisor_first_name',
  'financial_advisor_last_name',
  'financial_advisor_number',
  'from_1_day_ago',
  'from_2_days_ago',
  'from_3_days_ago',
  'from_4_days_ago',
  'from_5_days_ago',
  'from_6_days_ago',
  'from_7_days_ago',
  'funded_date',
  'greeting',
  'greeting_informal',
  'home_warranty',
  'home_warranty_email',
  'home_warranty_first_name',
  'home_warranty_last_name',
  'home_warranty_number',
  'in_1_day',
  'in_2_days',
  'in_3_days',
  'in_4_days',
  'in_5_days',
  'in_6_days',
  'in_7_days',
  'income',
  'insurance_agent',
  'insurance_agent_email',
  'insurance_agent_first_name',
  'insurance_agent_last_name',
  'insurance_agent_number',
  'junior_loan_officer',
  'junior_loan_officer_email',
  'last_msg_time',
  'lead_city',
  'lead_email_address',
  'lead_first_name',
  'lead_middle_name',
  'lead_last_name',
  'lead_phone_number',
  'listing_agent',
  'listing_agent_email',
  'listing_agent_first_name',
  'listing_agent_last_name',
  'listing_agent_number',
  'loan_amount',
  'loan_number',
  'loan_officer',
  'loan_officer_assistant',
  'loan_officer_assistant_email',
  'loan_processor',
  'loan_processor_email',
  'loan_program',
  'loan_purpose',
  'loan_to_value',
  'loan_type',
  'lock_date',
  'marketing_manager',
  'marketing_manager_email',
  'milestone',
  'optin_button',
  'optin_link',
  'property_address',
  'property_addressLine1',
  'property_addressLine2',
  'property_city',
  'property_state',
  'property_zip',
  'rate',
  //   'realtor_first_name',
  'referral_partner_email',
  'referral_partner_first_name',
  'referral_partner_middle_name',
  'referral_partner_full_name',
  'referral_partner_last_name',
  'referral_partner_number',
  'sales_manager',
  'sales_manager_email',
  'listing_agent_transaction_coordinator',
  'listing_agent_transaction_coordinator_email',
  'listing_agent_transaction_coordinator_first_name',
  'listing_agent_transaction_coordinator_last_name',
  'listing_agent_transaction_coordinator_number',
  'sellers_attorney',
  'sellers_attorney_email',
  'sellers_attorney_first_name',
  'sellers_attorney_last_name',
  'sellers_attorney_number',
  'senior_loan_officer',
  'senior_loan_officer_email',
  'term',
  'time_now',
  'title',
  'title_email',
  'title_first_name',
  'title_last_name',
  'title_number',
  'today_day',
  'transaction_purchase_price',
  'unsubscribe_link',
  'user_company',
  'user_email',
  'user_first_name',
  'user_full_name',
  'user_last_name',
  'user_phone_number',
  'user_website',
];
export const urlMergeFields = ['optin_link_url', 'unsubscribe_link_url', 'user_website_url'];

// export const mergeFieldsobj = [
//   { value: '{contact_in_15min}}' },
//   { value: '{contact_in_30min}}' },
//   { value: '{contact_in_45min}}' },
//   { value: '{contact_in_60min}}' },
//   { value: '{contact_in_90min}}' },
//   { value: '{contact_in_120min}}' },
//   { value: '{from_1_day_ago}}' },
//   { value: '{from_2_days_ago}}' },
//   { value: '{from_3_days_ago}}' },
//   { value: '{from_4_days_ago}}' },
//   { value: '{from_5_days_ago}}' },
//   { value: '{from_6_days_ago}}' },
//   { value: '{from_7_days_ago}}' },
//   { value: '{in_1_day}}' },
//   { value: '{in_2_days}}' },
//   { value: '{in_3_days}}' },
//   { value: '{in_4_days}}' },
//   { value: '{in_5_days}}' },
//   { value: '{in_6_days}}' },
//   { value: '{in_7_days}}' },
//   { value: '{last_msg_time}}' },
//   { value: '{lead_city}}' },
//   { value: '{lead_email_address}}' },
//   { value: '{lead_first_contact_month}}' },
//   { value: '{lead_first_contact_date}}' },
//   { value: '{lead_first_contact_type}}' },
//   { value: '{lead_last_contact_type}}' },
//   { value: '{realtor_first_name}}' },
//   { value: '{time_now}}' },
//   { value: '{today_day}}' },
//   { value: '{greeting}}' },
//   { value: '{greeting_informal}}' },
//   { value: '{user_phone_number}}' },
//   { value: '{user_company}}' },
//   { value: '{user_email}}' },
//   { value: '{user_first_name}}' },
//   { value: '{user_last_name}}' },
//   { value: '{user_full_name}}' },
//   { value: '{user_website}}' },
//   { value: '{lead_first_name}}' },
//   { value: '{lead_phone_number}}' },
//   { value: '{optin_button}}' },
//   { value: '{optin_link}}' },
//   { value: '{unsubscribe_link]}' },
// ];
