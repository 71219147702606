
import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GridFilterType } from '../../enums/commonEnums';

@Component({
  selector: 'applied-filter-bubbles',
  template: `
  <div class="mx-1" >
      <div class="d-flex flex-wrap">
          <button type="button" *ngFor="let filterObject of this.displayFiltersList" class="filter-bubble  ml-2 mb-0"
            style="font-size:.75em" (click)="this.removeFilter(filterObject)">
          <i class="mr-1 icon-filter"></i>
            {{filterObject.display}}
            <i class="ml-1 icon-close"></i>
          </button>
        </div>
    </div>
  `
})
export class AppliedFilterBubblesComponent implements OnChanges  {
  displayFiltersList: any[] = [];

  @Input()
  filtersList: any;

  @Input()
  keyHeaderValues: any;

  @Output()
  filterTrimmed: EventEmitter<any> = new EventEmitter<any>();

  getSetDisplayText({ currentText, filter }: { currentText; filter; }): string {
    const valueTotal = filter.values.length;
    return `${currentText} (${valueTotal})`;
  }

  resetDisplayFilterList() {
    this.displayFiltersList = [];
    Object.keys(this.filtersList).forEach((key) => {
      let name = this.keyHeaderValues[key] ?? key;

      if (this.filtersList[key].filterType === GridFilterType.SET) {
        name = this.getSetDisplayText({ currentText: name, filter: this.filtersList[key] })
      }
      // handles wierd case where cancelling a custom text filter sets it to empty rather than removing it.
       if (this.filtersList[key].type === GridFilterType.TEXT && !this.filtersList[key].filter)
         return;

      this.displayFiltersList = [...this.displayFiltersList, {
        name,
        key,
        value:this.filtersList[key],
        display:`${name}`
      }];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filtersList)
      return;

    this.filtersList = changes.filtersList.currentValue;
    this.displayFiltersList = [];
    if (!this.filtersList)
      return;

    this.resetDisplayFilterList();
  }

  removeFilter(filter) {
    this.filterTrimmed.emit(filter.key);
  }
}
