<div class="v2-ui-integretion">
  <div class="position-relative close-btn">
    <button type="button" class="close m-2" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="">
    <div class="w-100 record-layout d-flex flex-column justify-content-center align-items-center">
      <div class="record-bg">
        <div class="record-icon">
          <img [ngClass]="condition ? 'rec' : ''" src="../../../../../assets/icons/mic_white.png" alt="" />
        </div>
      </div>
      <div class="mb-3 mt-5">
        <span class="font-22 font-800" *ngIf="!recordedTime">00:00</span>
        <span class="font-22 font-800" *ngIf="recordedTime">{{ recordedTime }}</span>
      </div>
      <img class="w-85" src="../../../../../assets/icons/recod_wave.svg" alt="" />
      <audio #audio (ended)="audioEnded()"></audio>
      <div class="control-icons d-flex justify-content-center align-items-center w-100">
        <span
          *ngIf="condition"
          (click)="pauseRecording()"
          class="icon-pause d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
        >
          <i class="material-icons"> pause </i>
        </span>
        <span
          *ngIf="!condition && isRecording"
          (click)="resumeRecording()"
          class="icon-pause d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
        >
          <i class="material-icons"> play_arrow </i>
        </span>
        <span
          *ngIf="blobRecieved && play"
          (click)="playRecording()"
          class="icon-pause d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
        >
          <i class="material-icons"> play_circle_outline </i>
        </span>
        <span
          *ngIf="blobRecieved && !play"
          (click)="pauseSrc()"
          class="icon-pause d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
        >
          <i class="material-icons"> pause_circle_outline </i>
        </span>
        <span
          *ngIf="!isRecording"
          class="icon-mic d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
          (click)="startRecording()"
        >
          <i class="material-icons"> record_voice_over </i>
        </span>
        <span
          *ngIf="isRecording"
          class="icon-stop d-flex justify-content-center align-items-center ml-3 shadow cursor-pointer"
          (click)="stopRecording()"
        >
          <i class="material-icons"> stop </i>
        </span>
      </div>
      <div class="d-flex flex-column mt-4 mb-5 w-100 px-4">
        <label class="font-14"> File Name</label>
        <div class="d-flex">
          <input type="text" [(ngModel)]="audioName" placeholder="Please enter audio name" class="form-control" />
          <button [disabled]="audioName == '' || !blobRecieved" (click)="saveAudio()" class="btn btn-primary ml-3">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
