import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaService } from '../../../modules/drip-campaign/services/getMedia.service';

@Component({
  selector: 'dailyai-share-media-audio-list',
  templateUrl: './share-media-audio-list.component.html',
  styleUrls: ['./share-media-audio-list.component.scss']
})
export class ShareMediaAudioListComponent implements OnInit {
  dripTyp = '';

  @Output() private selectedMediaFile = new EventEmitter();

  @Output() private close = new EventEmitter()

  selectedMedia = '';

  constructor(private mediaSvc: MediaService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  getSelectedMedia(selectedMedia) {
    this.selectedMedia = selectedMedia;
  }

  selectFile() {
    this.mediaSvc.sendSelected(this.selectedMedia);
    this.close.emit('close-all');
  }

  @Input()
  set user(pValue) {
    this.dripTyp = pValue;
  }

  closeModal() {
    this.close.emit('close')
  }
}

