<div class="px-2 pb-3">
  <div class="modal-header pb-0">
    <dailyai-hidden-input></dailyai-hidden-input>
    <h5 class="pt-3 color-grey-text">MAP ATTRIBUTES</h5>
    <button type="button" class="close close-mapping-modal" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body input-layout mt-1">
    <div class="d-flex">
      <div class="col-5 pl-0"><label class="font-14 font-weight-bold mb-3">CSV FILE FIELDS</label></div>
      <div class="col-5 px-0">
        <label class="font-14 font-weight-bold mb-3">MAP TO</label>
      </div>
    </div>
    <form [formGroup]="mappingForm" *ngIf="mappingForm">
      <div class="mapping-attributes" formArrayName="mappings">
        <div
          class="row mx-0 form-layout my-3"
          *ngFor="let control of mappingForm.get('mappings')['controls']; let i = index"
        >
          <div class="col-5 pl-0 d-flex flex-column" [formGroupName]="i">
            <input type="text" placeholder="First Name" class="form-control" formControlName="csv_file_header" />
          </div>
          <div class="col-5 px-0 d-flex flex-column" [formGroupName]="i">
            <select
              name="name"
              class="custom-select"
              formControlName="map_to_header"
              (change)="selectionChange($event, i)"
            >
              <option selected disabled [value]="null">Select Mapping</option>
              <option [value]="opt" *ngFor="let opt of db_fileds[i]">{{ opt }}</option>
            </select>
          </div>
          <div class="col-1">
            <button
              type="button"
              class="close pt-2 clear-mapping-each-btn"
              aria-label="Close"
              (click)="clearMapping(i)"
            >
              <i class="material-icons icon-color"> close </i>
            </button>
          </div>
          <div class="col-1">
            <button
              type="button"
              class="close pt-2"
              aria-label="Close"
              (click)="removeMapping(i)"
              id="csv-delete-mapping-btn"
            >
              <i class="material-icons icon-color"> delete </i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <small *ngIf="prop.fileType === 'contact'" class="text-danger">
          *Must map to these fields
          <ul>
            <li>First Name</li>
            <li>Email</li>
            <li>Mobile phone</li>
          </ul>
        </small>
        <small *ngIf="prop.fileType === 'transaction'" class="text-danger">
          *Must map to these fields
          <ul>
            <li>Loan #</li>
            <li>Full Name or First Name</li>
            <li>Email</li>
          </ul>
        </small>
        <small *ngIf="prop.fileType === 'partner'" class="text-danger">
          *Must map to these fields
          <ul>
            <li>First Name</li>
            <li>Email</li>
            <li>Mobile phone</li>
          </ul>
        </small>
      </div>
      <div class="d-flex justify-content-end mt-5 bottom-btn">
        <button
          type="button"
          class="btn btn-light font-hn-medium font-12 mr-2"
          (click)="initForm(prop.file_headers)"
          id="css-upload-reset-btn"
          [disabled]="uploading"
        >
          RESET
        </button>
        <button
          type="button"
          id="css-upload-contiue-btn"
          class="btn btn-primary font-hn-medium font-12"
          (click)="setMappingAndUpload()"
          [disabled]="uploading"
        >
          CONTINUE
        </button>
      </div>
    </form>
  </div>
</div>
