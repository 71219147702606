import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-checkbox-confirmation',
  templateUrl: './checkbox-confirmation.component.html',
  styleUrls: ['./checkbox-confirmation.component.scss']
})
export class CheckboxConfirmationComponent implements OnInit {
  @Output() checkedValue: EventEmitter<any> = new EventEmitter<any>();

  check = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onCancel() {
    this.activeModal.close();
  }

  onSubmit(action: string) {
    this.activeModal.close(action);
    this.checkedValue.emit(this.check);
  }
}
