import GraphemeSplitter from 'grapheme-splitter';
import { MediaAudioSelectFromComponent } from 'src/app/modules/drip-campaign/components/media-audio-select-from/media-audio-select-from.component';
import { Observable } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { UserType } from '../enums/commonEnums';

const splitter = new GraphemeSplitter();

export function getDate(
  dateobj: { year: number; month: number; day: number },
  duetime: { hour: number; minute: number; second: number }
) {
  const dueTime = new Date(dateobj.year, dateobj.month - 1, dateobj.day, duetime.hour, duetime.minute).toISOString();
  return dueTime;
}

export function makeObject(_object: any) {
  const arr = ['firstName', 'lastName', 'email', 'uid', 'phoneNumber', 'companyName', 'partnerType'];
  const newObject = Object.keys(_object).reduce((object, key) => {
    if (arr.includes(key)) {
      object[key] = _object[key];
    }
    return object;
  }, {});

  return newObject;
}

export const timeZonesMap: { [index: string]: string } = {
  'Chicago -06:00': 'America/Chicago',
  'Denver -07:00': 'America/Denver',
  'Phoenix -07:00': 'America/Phoenix',
  'LosAngeles -08:00': 'America/Los_Angeles',
  'Anchorage -09:00': 'America/Anchorage',
  'Honolulu -10:00': 'Pacific/Honolulu',
  'Kolkata +05:30': 'Asia/Kolkata',
  HST: 'Pacific/Honolulu',
  AKDT: 'America/Anchorage',
  PDT: 'America/Los_Angeles',
  MST: 'America/Phoenix',
  MDT: 'America/Denver',
  CDT: 'America/Chicago',
  EDT: 'America/New_York',
  AKST: 'America/Anchorage',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',
  EST: 'America/New_York',
};

export const timeZonesDisplayNameMap = {
  'Kolkata +05:30': 'Kolkata',
  HST: 'Hawaii Standard Time',
  AKDT: 'Alaska Daylight Time',
  PDT: 'Pacific Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  CDT: 'Central Daylight Time',
  EDT: 'Eastern Daylight Time',
  AKST: 'Alaska Standard Time',
  PST: 'Pacific Standard Time',
  CST: 'Central Standard Time',
  EST: 'Eastern Standard Time',
};

export const cardBrandToPfClass: { [index: string]: string } = {
  visa: 'dc_visa02_bevel',
  mastercard: 'dc_mastercard_bevel',
  amex: 'dc_americanexpress_bevel',
  discover: 'dc_discover_bevel',
  diners: 'dc_dinersclub_bevel',
  maestro: 'dc_maestro_bevel',
  paypal: 'dc_paypal_bevel',
};

export function smoothUpdateChartData(value, category, chart, filteredDataObject) {
  chart.data.forEach(element => {
    element[value] = filteredDataObject[element[category]] ? filteredDataObject[element[category]] : 0;
  });
  Object.keys(filteredDataObject).forEach((x) => {
    if (!_.find(chart.data, (y) => y[category] === x)) {
      chart.addData({ leadPartner: x, leadCount: filteredDataObject[x] });
    }
  });

  chart.invalidateRawData();
}

export function setBraintreeBrandIcons(cards) {
  cards.forEach((card) => {
    let pfClass = 'pf pf-credit-card';
    card.cardType = card.cardType === 'American Express' ? 'amex' : card.cardType;
    if (card.cardType.toLowerCase() in cardBrandToPfClass) {
      pfClass = `${'dc_payment_icons_bevel_32' + ' '}${cardBrandToPfClass[card.cardType.toLowerCase()]}`;
    }
    card['cardClass'] = pfClass;
  });
}

export function braintreeCardSortFunction(x, y) {
  if (x.default) {
    return -1;
  } if (y.default) {
    return 1;
  }

  if (x.last4 < y.last4) {
    return -1;
  } if (x.last4 > y.last4) {
    return 1;
  }

  return 0;
}

export interface CustomFieldGeneric {
  backendName: string;
  fieldName: string;
  fieldType: string;
  includeAsMerge: boolean;
  mergeFieldName: string;
}

export interface CustomUserFields {
  custom_fields_contacts: CustomFieldGeneric[];
  custom_fields_transaction: CustomFieldGeneric[];
  user_custom_fields_value: CustomFieldGeneric[];
  uId: string;
}

export interface CallerIdValidationPayload {
  phoneNumber: string;
  friendlyName: string;
}

export interface VerifyPhonePayload {
  update: boolean,
  website: boolean,
  vCard: boolean,
  phoneNumber: string;
  friendlyName: string;
}

export function sleep(time: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(null), time);
  });
}

export const vCard = function () {
  const start = 'BEGIN:VCARD\nVERSION:3.0';
  const end = 'END:VCARD';
  let data = '';

  const init = function () {
    data = '';
  };

  const name = function (surname: string, lastname: string) {
    data += `N:${lastname};${surname}`;
    data += '\n';
  };

  const cell = function (val: string) {
    data += `TEL;TYPE=CELL:${val}`;
    data += '\n';
  };

  const work = function (val: string) {
    data += `TEL;TYPE=WORK,VOICE:${val}`;
    data += '\n';
  };

  const other = function (val: string) {
    data += `TEL;TYPE=OTHER:${val}`;
    data += '\n';
  };

  const email = function (val: string) {
    data += `EMAIL;TYPE=PREF,INTERNET:${val}`;
    data += '\n';
  };
  const url = function (val: string) {
    data += `URL;CHARSET=UTF-8:${val}`;
    data += '\n';
  };
  const organization = function (val: string) {
    data += `ORG;CHARSET=UTF-8:${val}`;
    data += '\n';
  };
  const get = function () {
    return `${start}\n${data}${end}`;
  };

  return {
    init,
    name,
    cell,
    work,
    other,
    email,
    url,
    organization,
    get,
  };
};

export function replaceVideoUrliframeWithAnchor(emailContent) {
  const pattern = new RegExp(
    `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="(.*?)"></iframe>`,
    'g'
  );
  const matches = [...emailContent.matchAll(pattern)];
  for (const [i, item] of matches.entries()) {
    const iframecontent = item[0];
    const atagcontent = item[0]
      .replace(/<iframe/g, '<a')
      .replace(/<\/iframe/g, `${item[1]}</a`)
      .replace('src', 'href');
    emailContent = emailContent.replace(iframecontent, atagcontent);
  }
  return emailContent;
}

export function videoHandler(
  modalService,
  ngUnsubscribe,
  mediaService,
  quillEditorRef,
  settingService,
  currentUser,
  addForm
) {
  const modalRef = modalService.open(MediaAudioSelectFromComponent, {
    centered: true,
    windowClass: 'routeDripPopup',
    backdrop: 'static',
    keyboard: true,
  });
  modalRef.componentInstance.dripType = 'media';
  modalRef.componentInstance.fromWhere = 'videoEmbedInEmail';
  (modalRef.componentInstance.close as Observable<any>).pipe(takeUntil(ngUnsubscribe)).subscribe((x) => {
    if (x) {
      modalRef.close();
    }
  });
  mediaService
    .getMedia()
    .pipe(take(1))
    .subscribe(async (value) => {
      if (value) {
        const currEditor = quillEditorRef;
        const range = currEditor.getSelection(true);
        if (value && value['value']['doc_id']) {
          const videowithanchor = `<a  href="${value['value']['url']}"><img height="300px" class="vidCrmq" src="${value['value']['thumbnail']}"></a>`;
          const x = currEditor.clipboard.dangerouslyPasteHTML(range.index, videowithanchor);
          this.addForm.get('message').setValue(currEditor.root.innerHTML);
        } else if (value && !value['value']['doc_id']) {
          const link = await settingService.uploadImageEmail(value['value'][0], currentUser.uid);
          const videowithanchor = `<a href="${link}"><img height="300" src="https://firebasestorage.googleapis.com/v0/b/dailycrm-c9949.appspot.com/o/video_default_thumbnail%2Fmail-video-icon_20200704_193513175.png?alt=media&token=46d39c03-affe-478f-9458-0b1092542c71"></a>`;
          const x = currEditor.clipboard.dangerouslyPasteHTML(range.index, videowithanchor);
          addForm.get('message').setValue(currEditor.root.innerHTML);
        }
      }
    });
}
export interface CreateTeams {
  admin: boolean;
  managers: boolean;
}

export interface ContactListDownload {
  managers: boolean;
  teamLeads: boolean;
  loanOfficers: boolean;
  marketingOfficers: boolean;
}

export interface AddLoanOfficers {
  admin: boolean;
  managers: boolean;
  teamLeads: boolean;
}

export interface PartnerMessage {
  enable: boolean;
  admin: boolean;
  managers: boolean;
  teamLeads: boolean;
  loanOfficers: boolean;
}

export interface WebsiteLanding {
  enable: boolean;

  managers: boolean;
  teamLeads: boolean;
  loanOfficers: boolean;
}

export interface MarkettingScreen {
  enable: boolean;
  admin: boolean;
  managers: boolean;
  teamLeads: boolean;
  loanOfficers: boolean;
}

export interface Milestones {
  managers: boolean;
  teamLeads: boolean;
}

export interface Nuture {
  managers: boolean;
  teamLeads: boolean;
  loanOfficers: boolean;
}

export interface ContactMassAction {
  enable: boolean;
  dripCampaigns: boolean;
  tags: boolean;
  group: boolean;
  reasign: boolean;
  delete: boolean;
  email: boolean;
  sms: boolean;
  voiceMail: boolean;
  mergeContacts: boolean;
}

export interface PermissionObject {
  monthlySub: boolean;
  addManagers: boolean;
  createTeams: CreateTeams;
  contactListDownload: ContactListDownload;
  managersBuyCredit: boolean;
  addLoanOfficers: AddLoanOfficers;
  partnerMessage: PartnerMessage;
  websiteLanding: WebsiteLanding;
  markettingScreen: MarkettingScreen;
  milestones: Milestones;
  nuture: Nuture;
  generalEmailTemplate: boolean;
  complianceOptOutLanguage: boolean;
  complianceSenderID: boolean;
  contactMassAction: ContactMassAction;
}
export function makeUserPermission(userType: string, permisssionData) {
  let payload;
  if (permisssionData !== undefined) {
    if (userType === UserType.SUPER_ADMIN || userType === UserType.ADMIN) {
      let changeCompanyName = true;
      let showAohLaunchpad = false;
      if (userType === UserType.ADMIN) {
        changeCompanyName = permisssionData.companyName ? permisssionData.companyName.admins : false;
        showAohLaunchpad = !!permisssionData?.aohLaunchpad;
      }

      payload = {
        aohLaunchpad: showAohLaunchpad,
        requireSSO: !!permisssionData?.requireSSO?.admin,
        monthlySub: permisssionData.monthlySub,
        addManagers: permisssionData.addManagers,
        createTeams: permisssionData.createTeams.admin,
        contactListDownload: permisssionData.contactListDownload.admins
          ? permisssionData.contactListDownload.admins
          : false,
        changeCompanyName,

        addLoanOfficers: permisssionData.addLoanOfficers.admin,
        partnerMessage: permisssionData.partnerMessage.enable ? permisssionData.partnerMessage.admin : false,
        websiteLanding: false,
        markettingScreen: permisssionData.markettingScreen.enable ? permisssionData.markettingScreen.admin : false,
        generalEmailTemplate: true,
        complianceOptOutLanguage: permisssionData.complianceOptOutLanguage,
        complianceSenderID: permisssionData.complianceSenderID,
        nuture: true,
        milestones: true,
        contactMassAction: permisssionData.contactMassAction.enable
          ? {
            enable: true,
            dripCampaigns: permisssionData.contactMassAction.dripCampaigns,
            tags: permisssionData.contactMassAction.tags,
            group: permisssionData.contactMassAction.group,
            reasign: permisssionData.contactMassAction.reasign,
            delete: permisssionData.contactMassAction.delete,
            email: permisssionData.contactMassAction.email,
            sms: permisssionData.contactMassAction.sms,
            voiceMail: permisssionData.contactMassAction.voiceMail,
            mergeContacts: permisssionData.contactMassAction.mergeContacts,
          }
          : {
            enable: false,
            dripCampaigns: false,
            tags: false,
            group: false,
            reasign: false,
            delete: false,
            email: false,
            sms: false,
            voiceMail: false,
            mergeContacts: false,
          },
        tlAlreadyHasPermission: permisssionData.addLoanOfficers.teamLeads,
      };
    } else if (userType === UserType.MANAGER) {
      payload = {
        aohLaunchpad: !!permisssionData?.aohLaunchpad,
        monthlySub: permisssionData.monthlySub,
        requireSSO: !!permisssionData?.requireSSO?.manager,
        addManagers: false,
        managersBuyCredit: permisssionData.managersBuyCredit,
        changeCompanyName: permisssionData.companyName ? permisssionData.companyName.managers : false,
        createTeams: permisssionData.createTeams.managers,
        contactListDownload: permisssionData.contactListDownload.managers,
        addLoanOfficers: permisssionData.addLoanOfficers.managers,
        partnerMessage: permisssionData.partnerMessage.enable ? permisssionData.partnerMessage.managers : false,
        websiteLanding: permisssionData.websiteLanding.enable ? permisssionData.websiteLanding.managers : false,
        markettingScreen: permisssionData.markettingScreen.enable ? permisssionData.markettingScreen.managers : false,
        milestones: permisssionData.milestones.managers,
        nuture: permisssionData.nuture.managers,
        generalEmailTemplate: permisssionData.generalEmailTemplate,
        complianceOptOutLanguage: permisssionData.complianceOptOutLanguage,
        complianceSenderID: permisssionData.complianceSenderID,
        contactMassAction: permisssionData.contactMassAction.enable
          ? {
            enable: true,
            dripCampaigns: permisssionData.contactMassAction.dripCampaigns,
            tags: permisssionData.contactMassAction.tags,
            group: permisssionData.contactMassAction.group,
            reasign: permisssionData.contactMassAction.reasign,
            delete: permisssionData.contactMassAction.delete,
            email: permisssionData.contactMassAction.email,
            sms: permisssionData.contactMassAction.sms,
            voiceMail: permisssionData.contactMassAction.voiceMail,
            mergeContacts: permisssionData.contactMassAction.mergeContacts,
          }
          : {
            enable: false,
            dripCampaigns: false,
            tags: false,
            group: false,
            reasign: false,
            delete: false,
            email: false,
            sms: false,
            voiceMail: false,
            mergeContacts: false,
          },
        tlAlreadyHasPermission: permisssionData.addLoanOfficers.teamLeads,
      };
    } else if (userType === UserType.TEAM_LEADER) {
      payload = {
        aohLaunchpad: !!permisssionData?.aohLaunchpad,
        monthlySub: permisssionData.monthlySub,
        requireSSO: !!permisssionData?.requireSSO?.teamLeads,
        addManagers: false,
        managersBuyCredit: permisssionData.managersBuyCredit,
        changeCompanyName: permisssionData.companyName ? permisssionData.companyName.TeamLeaders : false,
        createTeams: false,
        contactListDownload: permisssionData.contactListDownload.teamLeads,
        addLoanOfficers: permisssionData.addLoanOfficers.teamLeads,
        partnerMessage: permisssionData.partnerMessage.enable ? permisssionData.partnerMessage.teamLeads : false,
        websiteLanding: permisssionData.websiteLanding.enable ? permisssionData.websiteLanding.teamLeads : false,
        markettingScreen: permisssionData.markettingScreen.enable ? permisssionData.markettingScreen.teamLeads : false,
        milestones: permisssionData.milestones.teamLeads,
        nuture: permisssionData.nuture.teamLeads,
        generalEmailTemplate: permisssionData.generalEmailTemplate,
        complianceOptOutLanguage: permisssionData.complianceOptOutLanguage,
        complianceSenderID: permisssionData.complianceSenderID,
        contactMassAction: permisssionData.contactMassAction.enable
          ? {
            enable: true,
            dripCampaigns: permisssionData.contactMassAction.dripCampaigns,
            tags: permisssionData.contactMassAction.tags,
            group: permisssionData.contactMassAction.group,
            reasign: permisssionData.contactMassAction.reasign,
            delete: permisssionData.contactMassAction.delete,
            email: permisssionData.contactMassAction.email,
            sms: permisssionData.contactMassAction.sms,
            voiceMail: permisssionData.contactMassAction.voiceMail,
            mergeContacts: permisssionData.contactMassAction.mergeContacts,
          }
          : {
            enable: false,
            dripCampaigns: false,
            tags: false,
            group: false,
            reasign: false,
            delete: false,
            email: false,
            sms: false,
            voiceMail: false,
            mergeContacts: false,
          },
      };
    } else if (userType === UserType.JLO) {
      payload = {
        monthlySub: permisssionData.monthlySub,
        addManagers: false,
        requireSSO: !!permisssionData?.requireSSO?.loanOfficers,
        managersBuyCredit: permisssionData.managersBuyCredit,
        changeCompanyName: permisssionData.companyName ? permisssionData.companyName.jlos : false,
        createTeams: false,
        addLoanOfficers: false,
        contactListDownload: permisssionData.contactListDownload.loanOfficers,
        partnerMessage: permisssionData.partnerMessage.enable ? permisssionData.partnerMessage.loanOfficers : false,
        websiteLanding: permisssionData.websiteLanding.enable ? permisssionData.websiteLanding.loanOfficers : false,
        markettingScreen: permisssionData.markettingScreen.enable
          ? permisssionData.markettingScreen.loanOfficers
          : false,
        milestones: false,
        nuture: permisssionData.nuture.loanOfficers,
        generalEmailTemplate: permisssionData.generalEmailTemplate,
        complianceOptOutLanguage: permisssionData.complianceOptOutLanguage,
        complianceSenderID: permisssionData.complianceSenderID,
        contactMassAction: permisssionData.contactMassAction.enable
          ? {
            enable: true,
            dripCampaigns: permisssionData.contactMassAction.dripCampaigns,
            tags: permisssionData.contactMassAction.tags,
            group: permisssionData.contactMassAction.group,
            reasign: permisssionData.contactMassAction.reasign,
            delete: permisssionData.contactMassAction.delete,
            email: permisssionData.contactMassAction.email,
            sms: permisssionData.contactMassAction.sms,
            voiceMail: permisssionData.contactMassAction.voiceMail,
            mergeContacts: permisssionData.contactMassAction.mergeContacts,
          }
          : {
            enable: false,
            dripCampaigns: false,
            tags: false,
            group: false,
            reasign: false,
            delete: false,
            email: false,
            sms: false,
            voiceMail: false,
            mergeContacts: false,
          },
      };
    } else if (userType === UserType.MO) {
      payload = {
        monthlySub: permisssionData.monthlySub,
        addManagers: false,
        requireSSO: !!permisssionData?.requireSSO?.marketingOfficers,
        managersBuyCredit: permisssionData.managersBuyCredit,
        createTeams: false,
        contactListDownload: permisssionData.contactListDownload.marketingOfficers,
        addLoanOfficers: false,
        partnerMessage: false,
        websiteLanding: false,
        markettingScreen: false,
        milestones: false,
        nuture: false,
        generalEmailTemplate: permisssionData.generalEmailTemplate,
        complianceOptOutLanguage: permisssionData.complianceOptOutLanguage,
        complianceSenderID: permisssionData.complianceSenderID,
        contactMassAction: permisssionData.contactMassAction.enable
          ? {
            enable: true,
            dripCampaigns: permisssionData.contactMassAction.dripCampaigns,
            tags: permisssionData.contactMassAction.tags,
            group: permisssionData.contactMassAction.group,
            reasign: permisssionData.contactMassAction.reasign,
            delete: permisssionData.contactMassAction.delete,
            email: permisssionData.contactMassAction.email,
            sms: permisssionData.contactMassAction.sms,
            voiceMail: permisssionData.contactMassAction.voiceMail,
            mergeContacts: permisssionData.contactMassAction.mergeContacts,
          }
          : {
            enable: false,
            dripCampaigns: false,
            tags: false,
            group: false,
            reasign: false,
            delete: false,
            email: false,
            sms: false,
            voiceMail: false,
            mergeContacts: false,
          },
      };
    }
  }
  return payload;
}

export function formatMilestoneStatus(user_set_status: string[], default_status: string[]): string[] {
  const statusList: string[] = default_status;
  const filtered_array: string[] = statusList.filter((s) => !user_set_status.includes(s));
  let formatted_array: string[] = [...user_set_status, ...filtered_array];
  const index = formatted_array.indexOf('Closed Loan');
  if (index > -1 && index !== formatted_array.length - 1) {
    formatted_array = Object.assign([], formatted_array);
    formatted_array.splice(index, 1);
    formatted_array.push('Closed Loan');
  }
  return formatted_array;
}

export function formatMilestonePurposes(user_set_purpose: string[], default_purpose: string[]): string[] {
  const purposeList: string[] = default_purpose;
  const filtered_array: string[] = purposeList.filter((s) => !user_set_purpose.includes(s));
  const formatted_array: string[] = [...user_set_purpose, ...filtered_array];
  return formatted_array;
}

export const stripeDisabledToastContent = "This feature has been temporarily disabled while we enhance our payment processing. Please contact support for urgent requests."

export function filterDuplicatesByParameter(array, parameter: string) {
  const flags = {};
  const output = [];
  for (const log of array) {
    if (!flags[log[parameter]]) {
      flags[log[parameter]] = true;
      output.push(log);
    }
  }
  return output;
}

// Function to convert getTimezoneOffset() minutes to {+|-}hhmm format
export function convertOffsetToHours(offset: number) {
  if (!offset || typeof offset !== 'number') {
    return undefined;
  }
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.round(offset % 60);
  return `${offset < 0 ? '-' : '+'}${`${hours}`.padStart(2, '0')}${`${minutes}`.padStart(2, '0')}`;
}

export const isBothNotNullAndNotEqual = (param1: any, param2: any) => {
  return ((param1 || param1 === 0) || (param2 || param2 === 0)) && param1 !== param2;
};
// Map of Javascript code points to GSM-7
export const UnicodeToGsm: Record<string, Array<number>> = {
  0x000a: [0x0a],
  0x000c: [0x1b, 0x0a],
  0x000d: [0x0d],
  0x0020: [0x20],
  0x0021: [0x21],
  0x0022: [0x22],
  0x0023: [0x23],
  0x0024: [0x02],
  0x0025: [0x25],
  0x0026: [0x26],
  0x0027: [0x27],
  0x0028: [0x28],
  0x0029: [0x29],
  0x002a: [0x2a],
  0x002b: [0x2b],
  0x002c: [0x2c],
  0x002d: [0x2d],
  0x002e: [0x2e],
  0x002f: [0x2f],
  0x0030: [0x30],
  0x0031: [0x31],
  0x0032: [0x32],
  0x0033: [0x33],
  0x0034: [0x34],
  0x0035: [0x35],
  0x0036: [0x36],
  0x0037: [0x37],
  0x0038: [0x38],
  0x0039: [0x39],
  0x003a: [0x3a],
  0x003b: [0x3b],
  0x003c: [0x3c],
  0x003d: [0x3d],
  0x003e: [0x3e],
  0x003f: [0x3f],
  0x0040: [0x00],
  0x0041: [0x41],
  0x0042: [0x42],
  0x0043: [0x43],
  0x0044: [0x44],
  0x0045: [0x45],
  0x0046: [0x46],
  0x0047: [0x47],
  0x0048: [0x48],
  0x0049: [0x49],
  0x004a: [0x4a],
  0x004b: [0x4b],
  0x004c: [0x4c],
  0x004d: [0x4d],
  0x004e: [0x4e],
  0x004f: [0x4f],
  0x0050: [0x50],
  0x0051: [0x51],
  0x0052: [0x52],
  0x0053: [0x53],
  0x0054: [0x54],
  0x0055: [0x55],
  0x0056: [0x56],
  0x0057: [0x57],
  0x0058: [0x58],
  0x0059: [0x59],
  0x005a: [0x5a],
  0x005b: [0x1b, 0x3c],
  0x005c: [0x1b, 0x2f],
  0x005d: [0x1b, 0x3e],
  0x005e: [0x1b, 0x14],
  0x005f: [0x11],
  0x0061: [0x61],
  0x0062: [0x62],
  0x0063: [0x63],
  0x0064: [0x64],
  0x0065: [0x65],
  0x0066: [0x66],
  0x0067: [0x67],
  0x0068: [0x68],
  0x0069: [0x69],
  0x006a: [0x6a],
  0x006b: [0x6b],
  0x006c: [0x6c],
  0x006d: [0x6d],
  0x006e: [0x6e],
  0x006f: [0x6f],
  0x0070: [0x70],
  0x0071: [0x71],
  0x0072: [0x72],
  0x0073: [0x73],
  0x0074: [0x74],
  0x0075: [0x75],
  0x0076: [0x76],
  0x0077: [0x77],
  0x0078: [0x78],
  0x0079: [0x79],
  0x007a: [0x7a],
  0x007b: [0x1b, 0x28],
  0x007c: [0x1b, 0x40],
  0x007d: [0x1b, 0x29],
  0x007e: [0x1b, 0x3d],
  0x00a1: [0x40],
  0x00a3: [0x01],
  0x00a4: [0x24],
  0x00a5: [0x03],
  0x00a7: [0x5f],
  0x00bf: [0x60],
  0x00c4: [0x5b],
  0x00c5: [0x0e],
  0x00c6: [0x1c],
  0x00c9: [0x1f],
  0x00d1: [0x5d],
  0x00d6: [0x5c],
  0x00d8: [0x0b],
  0x00dc: [0x5e],
  0x00df: [0x1e],
  0x00e0: [0x7f],
  0x00e4: [0x7b],
  0x00e5: [0x0f],
  0x00e6: [0x1d],
  0x00c7: [0x09],
  0x00e8: [0x04],
  0x00e9: [0x05],
  0x00ec: [0x07],
  0x00f1: [0x7d],
  0x00f2: [0x08],
  0x00f6: [0x7c],
  0x00f8: [0x0c],
  0x00f9: [0x06],
  0x00fc: [0x7e],
  0x0393: [0x13],
  0x0394: [0x10],
  0x0398: [0x19],
  0x039b: [0x14],
  0x039e: [0x1a],
  0x03a0: [0x16],
  0x03a3: [0x18],
  0x03a6: [0x12],
  0x03a8: [0x17],
  0x03a9: [0x15],
  0x20ac: [0x1b, 0x65],
};


export function hasAnyUCSCharacters(message: string): boolean {
  let result = false;
  const graphemes = splitter.splitGraphemes(message);
  for (const grapheme of graphemes) {
    if (grapheme.length >= 2 || (grapheme.length === 1 && !UnicodeToGsm[grapheme.charCodeAt(0)])) {
      result = true;
      break;
    }
  }
  return result;
}
