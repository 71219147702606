<div class="container-fluid px-4 pb-5">
  <div class="modal-header pb-3 px-0">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="content">
    <p lang="en-US" class="text-center">
      <span lang="en-US"><strong>Daily AI, LLC</strong></span>
    </p>
    <p lang="en-US" class="text-center">
      <span lang="en-US"><strong>Terms of Service</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>LAST UPDATED: February 8th, 2021</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US">Welcome to Daily AI. Please read the following terms and conditions of service carefully as
        they contain the
        legal terms and conditions of your access to and use of the Services (defined below) provided by Daily AI, LLC,
        and its subsidiaries, including Daily AI Mortgage LLC (collectively, (&ldquo;</span><span
        lang="en-US"><strong>Daily AI,</strong></span><span lang="en-US">,&rdquo; &ldquo;</span><span
        lang="en-US"><strong>Company</strong></span><span lang="en-US">&rdquo;, &ldquo;</span><span
        lang="en-US"><strong>we</strong></span><span lang="en-US">,&rdquo; &ldquo;</span><span
        lang="en-US"><strong>our</strong></span><span lang="en-US">,&rdquo;, or &ldquo;</span><span
        lang="en-US"><strong>us</strong></span><span lang="en-US">&rdquo;),including access or use of the Services
        through the Daily AI website (the &ldquo;</span><span lang="en-US"><strong>Site</strong></span><span
        lang="en-US">&rdquo;),or mobile application (the &ldquo;</span><span
        lang="en-US"><strong>App</strong></span><span lang="en-US">&rdquo;), and any other services Company may offer
        (collectively, the &ldquo;</span><span lang="en-US"><strong>Services</strong></span><span lang="en-US">&rdquo;).
        The terms &ldquo;</span><span lang="en-US"><strong>Customer</strong></span><span lang="en-US">&rdquo;,
        &ldquo;</span><span lang="en-US"><strong>you</strong></span><span lang="en-US">&rdquo; or &ldquo;</span><span
        lang="en-US"><strong>your</strong></span><span lang="en-US">&rdquo; means the individual or business entity who
        registers for the Services and accepts these Terms.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US">The terms of service, together with the online registration information and ordering process
        agreed to by
        Customer on the Site or App, (the &ldquo;</span><span lang="en-US"><strong>Terms</strong></span><span
        lang="en-US">&rdquo;), are legally binding and constitute the terms and conditions under which Daily AI is
        willing to
        provide you with the Services. &ldquo;</span><span lang="en-US"><strong>Order</strong></span><span
        lang="en-US">&rdquo; means electronic information submitted by Customer in the registration and ordering process
        in which
        Customer submits information about the number and type of Users and other information necessary for
        Customer&rsquo;s use of the Services. By completing the registration process, creating an Account (defined
        below), and or using the Services, Customer is accepting these Terms (on behalf of yourself or the entity which
        you represent), and you represent and warrant that you have the right, authority, and capacity to enter into
        these Terms (on behalf of yourself or the entity that you represent). If you do not agree with all of the
        provisions of these Terms, do not access and/or use the Services.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US">The Terms will take precedence over any conflicting or inconsistent terms and conditions, and
        information
        included in other materials (e.g. promotional materials and mailers) on the Site or App. In the event of an
        inconsistency between these Terms and any terms in an Order, the terms of the Order will control only to the
        extent of such conflict.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US">Please note, Daily AI may modify these Terms at any time at its sole DISCRETION. Modifications
        become effective
        immediately upon your first access to or use of the Services after the &ldquo;Last Updated&rdquo; date at the
        top of these Terms. Your continued access to or use of the Services after the modifications have become
        effective will be deemed your conclusive acceptance of the modified Terms. PLEASE REGULARLY CHECK THE SITE
        AND/OR APP TO VIEW THE THEN-CURRENT TERMS. If you do not agree with the modifications, do not access or use the
        Services.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>1. DEFINITIONS.</strong></span><span lang="en-US">Capitalized terms will have the
        meanings set forth in this Section 1, or in the section where they are first
        used.</span>
    </p>
    <p lang="en-US">
      <strong>1.1 </strong> &ldquo;<span lang="en-US"><strong> Customer Data</strong></span><span lang="en-US">&rdquo;
        means all information, data, files, links, and other materials </span><span lang="en-US">submitted or uploaded
        to the Services by Customer or Users</span><span lang="en-US">, or otherwise provided to Daily AI under the
        Terms. Customer Data excludes Usage Data.</span>
    </p>
    <p lang="en-US">
      <strong>1.2 </strong> &ldquo;<span lang="en-US"><strong> Documentation</strong></span><span lang="en-US">&rdquo;
        means the User documentation provided or made available by Daily AI to Customer describing the use and
        operation of the Service.
      </span>
    </p>
    <p lang="en-US">
      <strong>1.3 </strong> &ldquo;<span lang="en-US"><strong> Usage Data</strong></span><span lang="en-US">&rdquo;
        means any and all data collected or generated by Daily AI in connection with Customer&rsquo;s use the
        Services. Usage Data does not include public keys, usernames, or passwords, which are part of Customer Data.
      </span>
    </p>
    <p lang="en-US">
      <strong>1.4 </strong> &ldquo;<span lang="en-US"><strong>User</strong></span><span lang="en-US">&rdquo; means any
        Customer employee or contractor, or other individual or entity, that accesses or is
        authorized by Customer to access the Services. A user is &ldquo;authorized&rdquo; when added to the Services
        even if the user is not activated for, or active in, any features of the Services. Any access or use of the
        Services by any User is considered use or access by Customer.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2. SERVICES.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.1 Services. </strong></span><span lang="en-US">Subject to these Terms, Daily AI will
        provide Customer access to the Services that are specifically described
        in an applicable Order. Each Order will (a)&nbsp;reference these Terms, (b)&nbsp;be incorporated by reference
        into these Terms, and (c)&nbsp;be subject to the terms and conditions of these Terms.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.2 Access; Limited License. </strong></span><span lang="en-US">
        Subject to the terms and conditions of these Terms, Daily AI grants Customer, during the Term, a limited,
        revocable, non-exclusive, non-sublicensable, and non-transferable right to (a) access and use the Services to
        the extent identified in an Order, and (b) to use and reproduce the Documentation solely in connection with
        Customer’s use of the Services. To access or use certain features and functionalities of the Services, Customer
        must register for an account (&ldquo;</span><span lang="en-US"><strong>Account</strong></span><span
        lang="en-US">&rdquo;). In creating an Account, you agree to (i) provide true, accurate, current and complete
        information
        (&ldquo;</span><span lang="en-US"><strong>Account Data</strong></span><span lang="en-US">&rdquo;); and (ii)
        maintain and promptly update the Account Data to keep it true, accurate, current and
        complete.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.3 Services Features and Customer Data. </strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.3.1. Transaction Data. </strong></span><span lang="en-US">As a feature of the
        Services, Users may upload data relating to their loan and/or real estate transactions
        (&ldquo;</span><span lang="en-US"><strong>Transaction Data</strong></span><span lang="en-US">&rdquo;), which may
        include information about third parties. Customer represents and warrants that it will not
        upload to the Services any social security numbers, credit scores, or credit card information of third parties.
        Transaction Data will be considered Customer Data in accordance with the Terms.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.3.2 Marketing Campaigns.</strong></span><span lang="en-US">As part of the Services,
        Daily AI will provide marketing campaign templates (such as drip campaigns)
        (&ldquo;</span><span lang="en-US"><strong>Campaign Templates&rdquo;) </strong></span><span lang="en-US">to
        Customers. </span><span lang="en-US"><strong>All Campaign Templates are provided &ldquo;as-is&rdquo;, Daily AI
          makes no representations or warranties
          regarding Campaign Templates.</strong></span><span lang="en-US">
        Daily AI grants Customer a revocable, non-exclusive, non-sublicensable, royalty-free license to use the Campaign
        Templates for the Customer&rsquo;s business purposes. Daily AI retains all right and title to such Campaign
        Templates. Customer may modify and upload content into Campaign Templates to create customizable campaigns
        (&ldquo;</span><span lang="en-US"><strong>Customer Campaigns</strong></span><span lang="en-US">&rdquo;).
        Customer agrees to and grants Daily AI the right to use and analyze any content uploaded by Customer
        to Campaign Templates. Customer agrees and acknowledges that Daily AI may track, analyze, and use data,
        including but not limited to, metrics relating to the performance of any Customer Campaign.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.3.3 Messaging Credits.</strong></span><span lang="en-US"> Customer will be allocated
        a certain number of credits f</span><span lang="en-US">or SMS, voice dialer, ringless voicemail and media
        messages, s</span><span lang="en-US">et forth in the applicable Order, each month to market Customer&rsquo;s
        products and services to third parties
        (&ldquo;</span><span lang="en-US"><strong>Messaging Credits</strong></span><span lang="en-US">&rdquo;). The
        number of Messaging Credits will reset on the first day of each month. If a Customer does not
        exhaust their Messaging Credits for a given month the Messaging Credits will not carry over to the next month.
        However, if a Customer exhausts their allocated Messaging Credits for a given month, Customer will automatically
        be allocated additional credits (&ldquo;</span><span lang="en-US"><strong>Refresh Credits</strong></span><span
        lang="en-US">&rdquo;) and will be automatically charged a Message Credit Fee (defined below) as set forth in an
        applicable
        Order. If Customer does not exhaust all Refresh Credits within the month they are allocated, Refresh Credits
        will roll over to the subsequent month. At the start of each new month, Messaging Credits will be used prior to
        any Refresh Credits that may have been rolled over from the previous month. Refresh Credits will continue to
        roll over to subsequent months until all Refresh Credits are exhausted, or the Agreement expires or is
        terminated.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.4 Open Source Software. </strong></span><span lang="en-US">Certain aspects of the
        Services may include software that is subject to &ldquo;open source&rdquo; or
        &ldquo;free software&rdquo; licenses (&ldquo;</span><span lang="en-US"><strong>Open Source
          Software</strong></span><span lang="en-US">&rdquo;) and may be owned by third parties. The Open Source
        Software is not subject to the terms and conditions
        of these Terms, and instead, is licensed to Customer under the terms of the applicable Open Source Software
        license. Nothing in these Terms limits Customer&rsquo;s rights under, or grants Customer rights that supersede,
        the licenses applicable to the Open Source Software. If required by any license for particular Open Source
        Software, Daily AI will make such Open Source Software, and Daily AI&rsquo;s modifications to that Open Source
        Software, if any, available by written request at the notice address specified herein.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.5 Usage Data. </strong></span><span lang="en-US">Customer acknowledges and agrees
        that the provision of the Service involves, and Customer authorizes Daily
        AI’s: (a) collection of Usage Data in connection with providing the Services, (b) use of Usage Data in
        connection with providing, analyzing, and improving Daily AI products and services, and (c) generation and use
        of de-identified, aggregated, or anonymized data that does not include any identifying information of, or
        reasonably permit the identification of, Customer or any individual (including any User).</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>2.6 Professional Services. </strong></span><span lang="en-US">Any professional or
        advisory Services, such as implementation and onboarding services (“Professional Services”)
        ordered by Customer pursuant to an Order will be provided: (a) for the Fees and on the terms described in the
        applicable Order; and (b) in a professional manner and in accordance with industry standard practices. Customer
        shall make available in a timely manner at no charge to Daily AI all technical data, computer facilities,
        programs, files, documentation, test data, sample output, or other information and resources of Customer
        reasonably required by Daily AI for the performance of the Professional Services as specified in an
        Order.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3. RESPONSIBILITIES.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.1 Limitations. </strong></span><span lang="en-US">Customer agrees that Customer shall
        not: (a) permit any party to access and/or use the Services (including
        Documentation), other than the Users authorized under these Terms; (b) except as expressly permitted in an
        Order, license, rent, lease, loan, or sell or otherwise commercially exploit the Services to any third party;
        (c) use the Services in excess of the usage limitations described in the applicable Order, or use any feature or
        functionality of the Services that is not specified in the applicable Order; (d) interfere with, disrupt, create
        an undue burden on, alter, translate, or modify the Services, any part thereof, or the networks or services
        connected to the Services (through scripts or otherwise); (e) perform penetration tests or any other security
        tests on the Services, create derivative works of the Services, or reverse engineer, reverse compile, reverse
        assemble or do any other operation with the Services that would reveal any source code, trade secrets, know-how
        or other proprietary information; (f) access the Services in order to (i) build a competitive product or
        service, (ii) build a product using similar ideas, features, functions, or graphics of the Services, or (iii)
        copy any ideas, features, functions, or graphics of the Services; (g) without Daily AI’s express written
        permission, introduce software or automated agents or scripts to the Services so as to produce multiple
        accounts, generate automated searches, requests, or queries, or to strip or mine data from the Services; (h)
        perform or publish any performance or benchmark tests or analyses relating to the Services or the use thereof;
        (i) cover or obscure any page or part of the Services by any means; or (j) remove any copyright and other
        proprietary notices on the Services (or on any content displayed on the Services) and on all copies thereof.
        Except as expressly set forth in these Terms, no express or implied license or right of any kind is granted to
        Customer regarding the Services, or any part thereof. In the event (A) that Customer breaches any subsection
        “(a)” through “(j)” (above) or (B) of a threat to the security or technical integrity of the Services, Daily AI
        may, in its sole discretion, (1) suspend Customer’s access to the Services; (2) charge Customer for excess use;
        or (3) terminate Customer’s Account.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.2 Telephone Consumer Protection Act. </strong></span><span lang="en-US">Customer
        agrees that during its use of Services it will comply with the Telephone Consumer Protection Act
        (&ldquo;</span><span lang="en-US"><strong>TCPA</strong></span><span lang="en-US">&rdquo;), and that it is the
        Customer&rsquo; sole responsibility to comply with the TCPA. Customer further
        represents that prior to utilizing Messaging Credits to market its products and services to any third party,
        that the third party had the option, and opted-in, to receive such marketing messages from Customer. Customer is
        also solely responsible to monitor the National Do Not Call Registry and represents and warrants that if any
        third party is on such list, Customer will not use the Services (including available messaging functionality) to
        contact such third party in violation of the TCPA or National Do Not Call Registry.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.3 Fair Credit Reporting Act. </strong></span><span lang="en-US">Daily AI does not
        collect, maintain, or verify the accuracy of credit scores of any individual, nor will Daily
        AI disclose the credit score of any individual to any Customer or User. However, Daily AI may receive from Users
        via Transaction Data or otherwise, whether a third party qualified for certain Customer products and services
        (such as a loan) based on their credit score (&ldquo;</span><span lang="en-US"><strong>Credit
          Information</strong></span><span lang="en-US">&rdquo;). Customer represents and warrants that if it uploads or
        submits Credit Information to the Services it
        has given the third party the option, and the third party has opted-in, to the disclosure of its Credit
        Information.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.4 California Consumer Privacy Act. </strong></span><span lang="en-US">Customer agrees
        that during its use of the Services it will comply with the California Consumer Privacy Act
        (&ldquo;</span><span lang="en-US"><strong>CCPA</strong></span><span lang="en-US">&rdquo;), as applicable,
        including, but not limited to, sending all notices required by the CCPA, and having
        policies and procedures in place for California residents to access their personal information and if applicable
        opt-out of the sale of their personal information.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.5 Real Estate Settlement Procedures Act. </strong></span><span lang="en-US">Customer
        agrees that during the Term it will comply with the federal Real Estate Settlement Procedures Act, and
        its regulations where applicable.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.6 Usernames and Passwords. </strong></span><span lang="en-US">Customer is solely
        responsible for maintaining the confidentiality of all Customer usernames and passwords.
        Customer agrees (a) it will not (and will prohibit any User to) share usernames or passwords; (b) not to allow a
        third party to use its Account, usernames, or passwords at any time, except as expressly permitted under the
        Terms; and (c) to notify Daily AI promptly of any actual or reasonably suspected unauthorized use of its
        Account, usernames, or passwords, or any other breach or suspected breach of the Terms of which it becomes
        aware. Daily AI reserves the right to terminate any username that Daily AI reasonably determines may have been
        used by an unauthorized third party or an individual or entity other the User to whom such username and password
        was originally assigned.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.7 Users; Sub-Accounts. </strong></span><span lang="en-US">Customer is responsible for
        all acts or omissions of its Users, and for all activities that occur under its
        Account, including all sub-accounts created by or for Customer. Customer an unlimited number of sub-accounts,
        but for purposes of determining Fees, the Committed Quantity, the total number of Users across any one Customer
        business entity, including all affiliates of such Customer business entity, shall be summed across all such
        sub-accounts.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>3.8 Availability. </strong></span><span lang="en-US">Customer is responsible for
        obtaining all services and technologies necessary to access to the Services and
        Customer understands that such access may involve third party fees and costs (such as Internet service provider
        charges). Customer is responsible for all such fees and costs.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4. FEES; PAYMENTS.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.1 Fees; Payment.</strong></span><span lang="en-US">Customer shall pay Daily AI all
        fees set forth in the applicable Order and as described in this Section 4 (the
        &ldquo;</span><span lang="en-US"><strong>Fees</strong></span><span lang="en-US">&rdquo;). Unless otherwise
        stated in the applicable Order, Daily AI will charge, and You hereby authorize Daily
        AI to charge you all applicable Fees, including any past due Fees, plus late charges, if any, due to Daily AI
        under these Terms, using your selected method of payment as of the date the invoice is generated. All
        information regarding your payment method must be accurate, current, and complete. All Fees will be invoiced on
        a monthly basis, shall be paid in U.S. Dollars, and are non-refundable.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.2 Add-ons.</strong></span><span lang="en-US">
        Daily AI may offer additional features or functionality relating to the Services that are not included in the
        subscription purchased by Customer pursuant to an Order (&ldquo;</span><span
        lang="en-US"><strong>Add-Ons</strong></span><span lang="en-US">&rdquo;) for additional fees as further described
        on the Site or App. Customer acknowledges that Daily AI may,
        upon prior notice to Customer, charge Customer the full list prices for Customer’s use (after such notice) of
        any such Add-Ons beyond those included in the applicable Order.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.3 Committed Period. </strong></span><span lang="en-US">
        If the Order shows that Customer will pay for a committed period, such as a set number of months or years, all
        Fees will be invoiced in advance at the billing frequency specified in the Order based on the number of Users
        specified in the Order (the &ldquo;</span><span lang="en-US"><strong>Committed Quantity</strong></span><span
        lang="en-US">&rdquo; of Users). If the number of Users for Customer exceeds the Committed Quantity in any month,
        Daily AI
        may invoice, and Customer will pay, the difference between the Committed Quantity and the actual number of Users
        who utilized the Services, based on the price per User set forth in the applicable Order, for such month (the
        "</span><span lang="en-US"><strong>Monthly True-Up</strong></span><span> of Fees). </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.4 Automatic Renewal; Message Credit Fee.</strong></span><span lang="en-US">Customer
        acknowledges and agrees that it will be automatically charged for continued use of the Services for
        each Renewal Order Term (defined below). Customers also agrees to be automatically charged a fee, as set forth
        in the applicable Order, if Customer exceeds its use of allocated Messaging Credits for any given month
        (&ldquo;</span><span lang="en-US"><strong>Message Credit Fee</strong></span><span lang="en-US">&rdquo;). Daily
        AI will provide Customer notice prior to any auto-charge. </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.5 Adjustments; Interest; and Taxes.</strong></span><span lang="en-US">
        If any amounts are disputed in good faith, the remaining amounts will be paid when due and payment of such
        undisputed amounts may not be withheld for any reason. Any undisputed amount that is not paid when due will
        accrue a late fee of 1.5% per month or the maximum rate permitted by applicable law, whichever is less, from the
        due date until paid. Customer agrees to and acknowledges that Daily AI may send Customer payment reminders via
        email or phone. In addition, Customer will reimburse Daily AI for all costs (including reasonable attorney and
        collection agency fees) associated with collecting delinquent or dishonored payments. All Fees exclude, and
        Customer shall be responsible for, all sales, use, excise, and other taxes applicable to the transactions
        contemplated by these Terms, except for taxes based on Daily AI’s net income. If Customer is legally obligated
        to make any deduction or withholding from any Fees payment under these Terms, it shall also pay whatever
        additional amount is necessary to ensure that Daily AI receives the full amount otherwise receivable had there
        been no deduction or withholding obligation.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>4.6 Price Changes.</strong></span><span lang="en-US">
        Prices for using the Services are subject to change upon thirty (30) days’ notice from Daily AI. Such notice may
        be provided at any time by posting the changes to the Site or App.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5. TERM AND TERMINATION.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.1 Term.</strong></span><span lang="en-US">
        Unless otherwise expressly set forth in the Order, the term shall commence on the date you accept the Terms and
        will continue until the earlier of (a) the date the Terms are terminated as set forth herein, and (b) all Orders
        entered into under the Terms expire or are terminated as set forth therein the (&ldquo;</span><span
        lang="en-US"><strong>Term</strong></span><span lang="en-US">&rdquo;). </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.2 Order Renewal. </strong></span><span lang="en-US">Following the initial term under
        any Order (the &ldquo;</span><span lang="en-US"><strong>Initial Order Term</strong></span><span
        lang="en-US">&rdquo;), such Order will automatically renew for additional periods equal to the duration of the
        Initial Order
        Term (each, a &ldquo;</span><span lang="en-US"><strong>Renewal Order Term</strong></span><span
        lang="en-US">&rdquo; and together with the Initial Order Term, each and &ldquo;</span><span
        lang="en-US"><strong>Order Term</strong></span><span lang="en-US">&rdquo;), unless a Customer provides written
        notice to Daily AI of its intent to not renew such Order at least
        thirty (30) days&rsquo; prior to the end of the then-current Order Term. The Committed Quantity and Fees for
        each Renewal Order Term will be equal to the then-current Order Term unless Customer and Daily AI agree to
        create a new Order setting forth a new Committed Quantity and Fees.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.3 Termination. </strong></span><span lang="en-US">
        Except as otherwise provided for herein, either party may terminate these Terms prior to the expiration of an
        Order Term (a) for any reason upon thirty (30) days written notice to the other party, or (b) upon the material
        breach of the other party, if such breach remains uncured for fourteen (14) days following the breaching party’s
        receipt of written notice of the breach.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.4 Suspension for Non-Payment.</strong></span><span lang="en-US">
        In Customer fails to make payments of any applicable Fees due under these Terms and does not cure the same
        within fourteen (14) days of the invoice, Daily AI may, upon written notice to Customer suspend Customer’s
        access to all or part of the Services.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.5 Effect of Termination. </strong></span><span lang="en-US">Upon any termination or
        expiration of these Terms: (a) Customer shall promptly pay Daily AI all amounts owed
        under these Terms without regard to whether any invoices had or had not been issued; (b) all outstanding Orders
        shall be immediately terminated; (c) Customer’s access to the Services will be terminated; and (d) Customer Data
        will be deleted by Daily AI. Customer will not have any rights in or to the Services after any termination or
        expiration of these Terms.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>5.6 Survival.</strong></span><span lang="en-US">
        Sections 1 (Definitions), 2.5 (Usage Data), 4 (Fees; Payments), 4.1 (Limitations), 5.5 (Effect of Termination),
        5.6 (Survival), and 6 through 11 shall survive any termination or expiration of the Terms.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>6. OWNERSHIP.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>6.1 Ownership of Services. </strong></span><span lang="en-US">As between Daily AI and
        Customer, Daily AI owns all right, title and interest in and to the Services, the Site,
        the App, and Documentation, and Campaign Templates, including any and all intellectual property and other
        proprietary rights therein. Customer acknowledges and agrees that (a) it does not acquire any rights, express or
        implied in or to the Services, except as specifically set forth in these Terms; and (b) any configuration or
        deployment of the Services shall not affect or diminish Daily AI’s rights, title, and interest in and to the
        Services. All brand, product, and service names and marks used in the Services which identify Daily AI are
        proprietary names and marks of Daily AI. All brand, product, and service names and marks used in the Services
        which identify third parties or their products or services are proprietary names and marks of such third
        parties. Nothing in the Services shall be deemed to confer on any person any license or right with respect to
        any such name or mark. Unless otherwise stated in these Terms, Daily AI is the owner of all copyright and
        database rights in the Services and its contents. Customer may not publish, distribute, extract, reuse, or
        reproduce any such content in any material form other than in accordance with these Terms. Customer shall not
        remove, alter, or obscure any proprietary notices (including copyright notices) of Daily AI or its suppliers on
        the Services, the Site, the App, or Documentation.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>6.2 Ownership of Customer Data and License. </strong></span><span lang="en-US">As
        between Daily AI and Customer, Customer Data shall at all times remain the property of Customer. Except for
        the rights granted in this Section 6.2, Daily AI is not acquiring any right, title or interest in or to your
        Customer Data. You can remove Customer Data from the Services at any time by deleting such Customer Data or
        deleting your Account. You hereby grant to Daily AI a nonexclusive, royalty-free and fully paid, worldwide
        license to use, edit, modify, aggregate, reproduce, distribute, display, and perform the Customer Data, and to
        grant sublicenses of the foregoing rights, in connection with the operation of the Services, the promotion,
        advertising or marketing of the Services, and the operation of Daily AI’s business. You hereby irrevocably waive
        (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your
        Customer Data. Customer represents and warrants that it has all rights necessary for Customer’s and Daily AI’s
        use of the Customer Data as contemplated by this Agreement.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7. PRIVACY AND CONFIDENTIAL INFORMATION.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7.1 Privacy Policy</strong></span><span lang="en-US">. Daily AI’s Privacy Policy is
        located at </span><u><a href="https://dailyai.io/pages/privacy-policy/"><span
            lang="en-US"><strong>https://dailyai.io/pages/privacy-policy/</strong></span></a></u><span lang="en-US">
        Please review the Privacy Policy to learn about: (a) what information we may collect; (b) what we use that
        information for; and (c) with whom we share that information.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7.2 Definition. </strong></span><span lang="en-US">&ldquo;</span><span
        lang="en-US"><strong>Confidential Information</strong></span><span lang="en-US">&rdquo; means all information of
        the disclosing party disclosed to the receiving party that is marked or
        identified as confidential or disclosed in circumstances that would lead a reasonable person to believe such
        information is confidential. The Services shall be considered Daily AI’s Confidential Information,
        notwithstanding any failure to mark or identify it as such. Customer Data shall be considered Customer’s
        Confidential Information, notwithstanding any failure to mark or identify it as such. The relationship of the
        parties created by these Terms is not Confidential Information.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7.3 Protection. </strong></span><span lang="en-US">The party receiving Confidential
        Information (&ldquo;</span><span lang="en-US"><strong>Receiving Party</strong></span><span lang="en-US">&rdquo;)
        from the other party (&ldquo;</span><span lang="en-US"><strong>Disclosing Party</strong></span><span
        lang="en-US">&rdquo;) shall not use any Confidential Information of the Disclosing Party for any purpose not
        expressly
        permitted by these Terms, and shall disclose the Confidential Information of the Disclosing Party only to the
        employees, independent contractors, or agents of the Receiving Party who have a need to know such Confidential
        Information for purposes of these Terms and who are under a duty of confidentiality no less restrictive than the
        Receiving Party’s duty hereunder. The Receiving Party shall protect the Disclosing Party’s Confidential
        Information from unauthorized use, access, or disclosure in the same manner as the Receiving Party protects its
        own confidential or proprietary information of a similar nature, but in no caseless than reasonable care.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7.4 Exceptions. </strong></span><span lang="en-US">Confidential Information does not
        include information that: (a) was already lawfully known to the Receiving
        Party at the time of disclosure by the Disclosing Party; (b) is disclosed to the Receiving Party by a third
        party who had the right to make such disclosure without any confidentiality restrictions; (c) is, or through no
        fault of the Receiving Party has become, generally available to the public (including any information made
        available on the Site that is not protected by confidentiality obligations); or (d) is independently developed
        by the Receiving Party without access to, or use of, the Disclosing Party’s Confidential Information. In
        addition, the Receiving Party may disclose Confidential Information of the Disclosing Party to the extent that
        such disclosure is: (i) approved in writing by the Disclosing Party, (ii) necessary for the Receiving Party to
        enforce its rights under these Terms in connection with a legal proceeding; or (iii) required by law or by the
        order of a court or similar judicial or administrative body, provided that (if permitted by law) the Receiving
        Party promptly notifies the Disclosing Party of such required disclosure in writing prior to making such
        disclosure and cooperates with the Disclosing Party, at the Disclosing Party’s reasonable request and expense,
        in any lawful action to contest or limit the scope of such required disclosure. Notwithstanding the foregoing,
        Daily AI may use Customer’s name and logo in Daily AI’s marketing materials that include a customer list.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>7.5 Feedback. </strong></span><span lang="en-US">&ldquo;</span><span
        lang="en-US"><strong>Feedback</strong></span><span lang="en-US">&rdquo; means any and all Customer or User
        suggestions regarding new features, functionality, or performance
        for the Services, including suggestions submitted through the Site or App. Daily AI owns all Feedback, and
        Customer hereby assigns all rights and interest in such Feedback to Daily AI. Daily AI may use all Feedback for
        any lawful purpose, including, without limitation the development or improvement of features or functionality
        for the Service.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>8. INDEMNITY.</strong></span><span lang="en-US">Customer shall defend, at its own
        expense, any claim, suit, or action against Daily AI brought by a third party
        to the extent that such claim, suit, or action arising from or related to (a) any Customer Data, (b) any User
        acts or omissions, or (c) any failure by Customer to meet its obligations under Section 3 (Customer
        Responsibilities) (each, a &ldquo;</span><span lang="en-US"><strong>Daily AI Claim</strong></span><span
        lang="en-US">&rdquo;), and Customer shall indemnify and hold Daily AI harmless from and against all losses,
        damages,
        liabilities, costs, and expenses (including reasonable attorneys’ fees) awarded in such Daily AI Claim or those
        costs and damages agreed to in a monetary settlement of such Daily AI Claim. The foregoing obligations is
        conditioned on Daily AI promptly notifying Customer in writing of such Daily AI Claim. Notwithstanding the
        foregoing, Customer shall have no obligation under this Section 8 or otherwise with respect to any claim to the
        extent based upon the gross negligence or intentional misconduct of Daily AI.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>9 DISCLAIMER.</strong></span><span lang="en-US">YOU EXPRESSLY UNDERSTAND AND AGREE THAT
        TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED ON
        AN “AS-IS” AND “AS AVAILABLE” BASIS, AND DAILY AI AND ITS SUPPLIERS AND LICENSORS EXPRESSLY DISCLAIM ALL
        REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ALL
        REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
        PARTICULAR PURPOSE, IN EACH CASE ARISING FROM OR RELATED TO THESE TERMS AND THE SERVICES.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>10 LIMITATIONS ON LIABILITY. </strong></span><span lang="en-US">IN NO EVENT SHALL DAILY
        AI BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER
        ARISING, INCLUDING NEGLIGENCE), OR FOR ANY LOST PROFITS, INTERRUPTED COMMUNICATIONS, OR LOST DATA, ARISING OUT
        OF OR IN CONNECTION WITH THESE TERMS EVEN IF DAILY AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO
        THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DAILY AI’S AGGREGATE LIABILITY TO CUSTOMER UNDER THESE TERMS
        SHALL BE LIMITED TO THE GREATER OF THE AMOUNT OF FEES PAID OR PAYABLE BY CUSTOMER TO DAILY AI DURING THE TWELVE
        (12) MONTH PERIOD PRECEDING THE EVENTS FIRST GIVING RISE TO ANY SUCH LIABILITY, OR $50.00. THE LIMITATIONS OF
        DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN COMPANY AND YOU.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11. GENERAL PROVISIONS.</strong></span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.1 Governing Law. </strong></span><span lang="en-US">The Terms are governed by and
        construed in accordance with the laws of the State of Colorado without giving
        effect to any rule or law that would apply the law of another jurisdiction. Both parties submit to the exclusive
        personal jurisdiction in Colorado and further agree that any cause of action arising under these Terms shall be
        brought in a court in Denver County, Colorado.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.2 Compliance with Laws. </strong></span><span lang="en-US">Each party shall comply
        with all laws, rules and regulations applicable to such party while performing under
        these Terms.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.3 Severability; Waiver. </strong></span><span lang="en-US">If any provision of the
        Terms is held to be invalid or unenforceable for any reason, the remaining provisions
        shall continue in full force without being impaired or invalidated in any way, and the invalid or unenforceable
        provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.
        The waiver by either party of a breach of any provision of these Terms shall not constitute an ongoing or future
        waiver of such breach or provision.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.4 Force Majeure. </strong></span><span lang="en-US">Company will not be liable for
        any delay or failure to perform resulting from causes outside its reasonable
        control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military
        authorities, fire, floods, pandemic, accidents, strikes or shortages of transportation facilities, fuel, energy,
        labor or materials.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.5 Headings. </strong></span><span lang="en-US">Headings used in the Terms are for
        reference purposes only and in no way define, limit, construe or describe
        the scope or extent of such section.</span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.6 Assignment. </strong></span><span lang="en-US">These Terms may not be assigned or
        transferred to any third party without Daily AI&rsquo;s written consent, and
        any attempted assignment or transfer in violation of the foregoing will be null and void. Daily AI may assign or
        transfer the Terms pursuant to a transfer of all or substantially all of its assets without Customer&rsquo;s
        consent. The Terms will inure to any permitted successors or assigns.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.7 Independent Contractors. </strong></span><span lang="en-US">Customer and Daily AI
        are independent contractors, and no agency, partnership, joint venture or
        employee-employer relationship is intended or created by these Terms. Neither party shall have the power to
        obligate or bind the other party.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.8 Customer Communication. </strong></span><span lang="en-US">Customer understands
        and agrees that the Services require periodic email, SMS, and ringless voicemail
        communication including password resets, notifications, and other critical communications. Further, Customer
        understands and agrees that without such communications Customer will not be able to receive customer support,
        maintenance notifications, upgrade announcements, and other critical information to operate the Services. As a
        result, by purchasing access to the Services, Customer is consenting to Daily AI’s email, SMS, and ringless
        voicemail communications with (and notices sent to) administrative contacts supplied by Customer, and Customer
        represents and warrants that it has the authority to give such consent for all phone numbers of its Users,
        employees, and agents.
      </span>
    </p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.9 Notice. </strong></span><span lang="en-US">Any notices to Daily AI required or
        permitted hereunder shall be sent to Daily AI at the address below, or at
        such other address as Daily AI shall specify in writing. Any notices to Customer required or permitted hereunder
        shall be sent to the email address provided by Customer in the Order, or at such other address as Customer shall
        specify in writing. If to Customer, such notice shall be deemed given two business days after being sent to
        email address provided in the Order. If to Daily AI, such notice shall be deemed given two business days after
        being sent to the email address provided below; upon personal delivery; if sent by telephone facsimile, upon
        confirmation of receipt; or if sent by certified or registered mail, postage prepaid, five (5) days after the
        date of mailing.</span>
    </p>
    <p lang="en-US"><span lang="en-US">Daily AI Mortgage, LLC</span></p>
    <p lang="en-US"><span lang="sv-SE">Attn: Legal</span></p>
    <p lang="en-US">
      <span lang="en-US">2060 Broadway #280, Boulder CO 80302</span>
    </p>
    <p lang="en-US"><span lang="en-US">compliance@dailyai.io</span></p>
    <p lang="en-US">
      <span lang="en-US"><strong>11.10 Entire Agreement. </strong></span><span lang="en-US">These Terms, including all
        applicable Orders, constitute the entire understanding and agreement of the parties
        and supersedes any and all oral or written agreements or understandings between the parties as to the subject
        matter hereof.
      </span>
    </p>
  </div>
</div>