import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerService } from 'amazing-time-picker';
import * as moment from 'moment';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { User } from 'src/app/models/user.model';
import { AddTaskService } from 'src/app/modules/contacts/services/create-task.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { timeZonesMap } from '../../utils';
import { EMAIL_REGEX } from '../../config';

interface EventPayload {
  assignedby: any,
  assignedto: any,
  eventDetails?: any,
  eventName: string,
  description?: any,
  startDate: any,
  endDate: any,
  attendees: any[],
  task: string,
  task_id?: string,
  eventId?: string,
  toCalendar: boolean,
  oAuthCalendarEvent: boolean,
}
@Component({
  selector: 'dailyai-calender-events-model',
  templateUrl: './calender-events-model.component.html',
  styleUrls: ['./calender-events-model.component.scss'],
})
export class CalenderEventsModelComponent implements OnInit {
  @ViewChild('eventOrganizer') eventOrganizer: ElementRef;

  @ViewChild('eventAttendees') eventAttendees: ElementRef;

  @ViewChild('picker') picker: any;

  public date: moment.Moment;

  public disabled = false;

  public showSpinners = true;

  public showSeconds = false;

  public touchUi = false;

  public enableMeridian = false;

  public minDate: moment.Moment;

  public maxDate: moment.Moment;

  public stepHour = 1;

  public stepMinute = 1;

  public stepSecond = 1;

  currentUser: any;

  users: User[] = [];

  assignderr = false;

  popRef;

  linkItems: FormArray;

  addEventForm: FormGroup;

  show = true;

  public today = new Date();

  organizerList = [];

  attendeesList = [];

  tDate;

  tTime;

  startTime;

  endTime;

  timepicked = false;

  startDateTime: any;

  endDateTime: any;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  focus$ = new Subject<{ val: string, fromWhere: string }>();

  click$ = new Subject<string>();

  globalPopover: NgbPopover = null;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private atp: AmazingTimePickerService, private store: Store<any>,
    private taskService: AddTaskService, private toast: ToastrService,


  ) {

    this.buildForm();
  }

  ngOnInit() {
    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectCurrentUser)).subscribe((data) => {
      if (data && data.uid) {
        this.currentUser = data;
      }
    });
    this.tDate = this.getCurrentDate();
    this.startTime = moment().format('HH:mm')
    this.endTime = moment().add(1, 'hours').format('HH:mm')
  }

  getCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    const tDate = `${yyyy  }-${  mm  }-${  dd}`;
    return tDate
  }

  buildForm() {
    this.addEventForm = this.fb.group({
      eventName: [null, Validators.required],
      eventStartDate: [''],
      eventStartTime: [''],
      eventEndDate: [''],
      eventEndTime: [''],
      eventDesc: [''],
      eventOrganizer: [''],
      eventAttendees: [''],
      linkItems: this.fb.array([this.createLink()]),
    });
  }

  showValidator() {
    this.show = !this.show;
  }

  createLink(): FormGroup {
    return this.fb.group({
      link: '',
    });
  }

  openEndTime(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {
      this.timepicked = true;
      this.endTime = time;
      this.endDateTime = this.generateDateWithTimeZone(
        this.addEventForm.value.eventEndDate.day ? this.addEventForm.value.eventEndDate.day : new Date(this.tDate).getDate(),
        this.addEventForm.value.eventEndDate.month ? this.addEventForm.value.eventEndDate.month : new Date(this.tDate).getMonth(),
        this.addEventForm.value.eventEndDate.year ? this.addEventForm.value.eventEndDate.year : new Date(this.tDate).getFullYear(),
        this.endTime.slice(0, 2),
        this.endTime.slice(3, 5),
        this.currentUser.timezone)
    });
  }

  openStartTime(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {
      this.timepicked = true;
      this.startTime = time;
      this.startDateTime = this.generateDateWithTimeZone(
        this.addEventForm.value.eventStartDate.day ? this.addEventForm.value.eventStartDate.day : new Date(this.tDate).getDate(),
        this.addEventForm.value.eventStartDate.month ? this.addEventForm.value.eventStartDate.month : new Date(this.tDate).getMonth(),
        this.addEventForm.value.eventStartDate.year ? this.addEventForm.value.eventStartDate.year : new Date(this.tDate).getFullYear(),
        this.startTime.slice(0, 2),
        this.startTime.slice(3, 5),
        this.currentUser.timezone)
    });
  }

  addLinkItems(): void {
    this.linkItems = this.addEventForm.get('linkItems') as FormArray;
    this.linkItems.push(this.createLink());
  }

  removeLinkItems(i: number) {
    this.linkItems = this.addEventForm.get('linkItems') as FormArray;
    this.linkItems.removeAt(i);
  }

  onEnterOrganizer(eve, type) {
    if (type === 'organizer' && eve.eventOrganizer != '') {
      this.organizerList.push(eve.eventOrganizer);
      this.eventOrganizer.nativeElement.value = '';
      eve.eventOrganizer = '';
    } else if (type === 'attendees' && eve.eventAttendees != '') {
      if (EMAIL_REGEX.test(eve.eventAttendees)) {
        this.attendeesList.push(eve.eventAttendees);
        this.eventAttendees.nativeElement.value = '';
        eve.eventAttendees = '';
      }
      else this.toast.error('Enter Valid Email');


    }
  }

  deleteChip(i, type) {
    if (type === 'organizer') {
      this.organizerList.splice(i, 1);
    } else {
      this.attendeesList.splice(i, 1);
    }
  }

  generateDateWithTimeZone(
    date: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    timezone: string
  ) {
    const dateIso = moment()
      .tz(timeZonesMap[timezone])
      .year(year)
      .month(month - 1)
      .date(date)
      .hour(hours)
      .minute(minutes).format();
    return dateIso
  }

  generateDateTimeUTC(
    date: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    timezone: string
  ) {
    const dateTime = moment()
      .date(date)
      .month(month - 1)
      .year(year)
      .hour(hours)
      .minute(minutes)
      .format("YYYY-MM-DDTHH:mm:ss")
    const dateTimeUTC = moment.tz(dateTime, timeZonesMap[this.currentUser.timezone]).utc().format("YYYY-MM-DDTHH:mm:ss");
    return dateTimeUTC
  }

  createEvent() {
    const payload = this.getFormattedPayload();
    if (payload.eventName === null || payload.eventName === "") {
      this.toast.error('Please Enter Event Name');
      return
    }
    var diff = moment(payload.endDate).diff(payload.startDate);
    if (diff < 0) {
      this.toast.error('Start Date-Time Should be Lesser than End Date-Time!');
      
    }
    else {
      try {
        this.taskService.createEvent(payload);
        this.activeModal.dismiss();
      }
      catch (error) {
        this.toast.error('Please try again!');
      }
    }
  }

  getUserCalendarPlatform() {
    let platform = '';
    if (this.currentUser.gmailSubscription) { platform = "google" }
    else if (this.currentUser.outlookCalendarSubscription) { platform = "outlook" }
    return platform
  }

  attendesConvertion() {
    const alist: any = [];
    this.attendeesList.map(email => {
      alist.push({ email })
    })
    return alist
  }

  getFormattedPayload() {

    const attendeesList = this.attendesConvertion();
    if (this.addEventForm.value.startDate && this.timepicked) {
      this.startDateTime = this.generateDateWithTimeZone(
        this.addEventForm.value.eventStartDate.day,
        this.addEventForm.value.eventStartDate.month,
        this.addEventForm.value.eventStartDate.year,
        this.startTime.slice(0, 2),
        this.startTime.slice(3, 5),
        this.currentUser.timezone
      );
    }
    if (this.addEventForm.value.endDate && this.timepicked) {
      this.endDateTime = this.generateDateWithTimeZone(
        this.addEventForm.value.eventEndDate.day,
        this.addEventForm.value.eventEndDate.month,
        this.addEventForm.value.eventEndDate.year,
        this.endTime.slice(0, 2),
        this.endTime.slice(3, 5),
        this.currentUser.timezone
      );
    }
    const today = new Date(this.getCurrentDate());
    const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
    const payload: EventPayload = {
      task: 'calendarEvent',
      assignedto: {
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        uid: this.currentUser.uid
      },
      assignedby: {
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        uid: this.currentUser.uid
      },
      eventDetails: {},
      eventName: this.addEventForm.value.eventName,
      description: this.addEventForm.value.eventDesc,
      startDate: this.generateDateTimeUTC(
        this.addEventForm.value.eventStartDate.day ? this.addEventForm.value.eventStartDate.day : new Date(this.tDate).getDate(),
        this.addEventForm.value.eventStartDate.month ? this.addEventForm.value.eventStartDate.month : nextMonth.getMonth(),
        this.addEventForm.value.eventStartDate.year ? this.addEventForm.value.eventStartDate.year : new Date(this.tDate).getFullYear(),
        this.startTime.slice(0, 2),
        this.startTime.slice(3, 5),
        this.currentUser.timezone),
      endDate: this.generateDateTimeUTC(
        this.addEventForm.value.eventEndDate.day ? this.addEventForm.value.eventEndDate.day : new Date(this.tDate).getDate(),
        this.addEventForm.value.eventEndDate.month ? this.addEventForm.value.eventEndDate.month : nextMonth.getMonth(),
        this.addEventForm.value.eventEndDate.year ? this.addEventForm.value.eventEndDate.year : new Date(this.tDate).getFullYear(),
        this.endTime.slice(0, 2),
        this.endTime.slice(3, 5),
        this.currentUser.timezone),
      attendees: attendeesList,
      toCalendar: true,
      oAuthCalendarEvent: true,
    };
    return payload;
  }

  openTagList(popover, attendees?) {
    this.popRef = popover;
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  closePopup() {
    this.popRef.close();
  }

  onPopoverOpen(p: NgbPopover) {
    this.globalPopover = p;
  }

  removeAttendee(item: any) {
    this.attendeesList.splice(item, 1)
  }
}
