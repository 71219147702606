import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { SearchContact } from 'src/app/modules/contacts/store/action';
import { selectSearchContacts } from 'src/app/modules/contacts/store/selector';
import { ValidationState } from '../../enums/commonEnums';

@Component({
  selector: 'dailyai-to-cc-bcc',
  templateUrl: './to-cc-bcc.component.html',
  styleUrls: ['./to-cc-bcc.component.scss'],
})
export class ToCcBccComponent implements OnInit {
  ccBox: boolean;

  bccBox: boolean;

  ccMail = '';

  bccMail = '';

  ccMails: any[] = [];

  bccMails: any[] = [];

  focused = false;

  clickedInside = false;

  selectedItems: any[] = [];

  focus$ = new Subject<string>();

  click$ = new Subject<string>();

  contactList$: Observable<any>;

  contactList: any[];

  ccontrol: AbstractControl;

  bccontrol: AbstractControl;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  chipDelete: Subject<string> = new Subject<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  @Input() enableTO?: boolean = false;

  @Input() enableCC?: boolean = false;

  @Input() enableBCC?: boolean = false;

  /** List of emails for To field (shows only if showOnlyEmail is true) */
  @Input() toEmailData?: string[] = [];

  /** if true To field will only show email , else it will show contacts */
  @Input() showOnlyEmail?: boolean = false;

  /** used to identify drip step  */
  @Input() dripStep?: number;

  @Input() userData?: any;

  /** to add mergefield to cc */
  @Input() mergefieldSetter: Observable<string>;

  /** output data emitter */
  @Output() valueChange = new EventEmitter<outputDataType>();

  /** to enable mergefield in cc field */
  @Output() mergeFieldEnable = new EventEmitter<any>();

  /** Form control of CC field  */
  @Input() set ccControl(control: AbstractControl) {
    control?.value?.forEach((e) => {
      const validity = this.checkValidity(e, 'cc');
      this.ccMails.push({ email: e, index: this.ccMails.length, valid: validity });
    });
    if (this.ccMails.length > 0) {
      this.ccBox = true;
    }

    this.ccontrol = control;
    control.valueChanges.subscribe((emails) => {
      this.ccMails = [];
      emails?.forEach((e) => {
        const validity = this.checkValidity(e, 'cc');
        this.ccMails.push({ email: e, index: this.ccMails.length, valid: validity });
      });
      if (this.ccMails.length > 0) {
        this.ccBox = true;
      }
    });
  }

  /** Form control of CC field  */
  @Input() set bccControl(control: AbstractControl) {
    control?.value?.forEach((e) => {
      const validity = this.checkValidity(e, 'cc');
      this.bccMails.push({ email: e, index: this.bccMails.length, valid: validity });
    });
    if (this.bccMails.length > 0) {
      this.bccBox = true;
    }

    this.bccontrol = control;
    control.valueChanges.subscribe((emails) => {
      this.bccMails = [];
      emails?.forEach((e) => {
        const validity = this.checkValidity(e, 'bcc');
        this.bccMails.push({ email: e, index: this.bccMails.length, valid: validity });
      });
      if (this.bccMails.length > 0) {
        this.bccBox = true;
      }
    });
  }

  /** List of Contact Objects which should be displayed in To field */
  @Input() set toContactData(data: any[]) {
    this.selectedItems = data ?? [];
    this.sendUpdatedRecipientData();
  }

  /** Setting input Data for cc (list of emails ) */
  @Input() set ccData(emails: string[]) {
    this.ccMails = [];
    emails.forEach((e) => {
      const validity = this.checkValidity(e, 'cc');
      this.ccMails.push({ email: e, index: this.ccMails.length, valid: validity });
    });

    if (this.ccMails.length > 0) {
      this.ccBox = true;
    }
    this.sendUpdatedRecipientData();
  }

  /** Setting Input Data for bcc(list of emails ) */
  @Input() set bccData(emails: any[]) {
    emails.forEach((e) => {
      const validity = this.checkValidity(e, 'bcc');
      this.bccMails.push({ email: e, index: this.bccMails.length, valid: validity });
    });

    if (this.bccMails.length > 0) {
      this.bccBox = true;
    }
    this.sendUpdatedRecipientData();
  }

  public get validEnum(): typeof ValidationState {
    return ValidationState;
  }


  @HostListener('click')
  clicked() {
    this.clickedInside = true;
    this.focused = true;
  }

  @HostListener('document:click')
  clickedOut() {
    if (this.clickedInside === false) {
      if (this.ccMail.length > 0) {
        const validity = this.checkValidity(this.ccMail, 'cc');
        this.ccMails.push({ email: this.ccMail.toLowerCase(), index: this.ccMails.length, valid: validity });
        this.ccMail = '';
        this.sendUpdatedRecipientData();
      }
      if (this.bccMail.length > 0) {
        const validity = this.checkValidity(this.bccMail, 'bcc');
        this.bccMails.push({ email: this.bccMail.toLowerCase(), index: this.bccMails.length, valid: validity });
        this.bccMail = '';
        this.sendUpdatedRecipientData();
      }
      this.focused = false;
      if (this.ccMails.length === 0) {
        this.ccBox = false;
      }
      if (this.bccMails.length === 0) {
        this.bccBox = false;
      }
    }
    this.clickedInside = false;
  }

  /// //////
  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    if (this.mergefieldSetter) {
      this.mergefieldSetter.pipe(takeUntil(this.ngUnsubscribe)).subscribe((mergeField) => {
        const validity = this.checkValidity(mergeField, 'cc');
        this.ccMails.push({ email: mergeField, index: this.ccMails.length, valid: validity });
        this.ccBox = true;
        this.sendUpdatedRecipientData();
      });
    }
    this.contactList$ = this.store.pipe(select(selectSearchContacts));
    this.contactList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((list) => {
      this.contactList = list;
    });
    this.chipDelete.pipe(takeUntil(this.ngUnsubscribe), debounceTime(300)).subscribe((field) => {
      if (field === 'cc') {
        this.ccMails.pop();
      } else if (field === 'bcc') {
        this.bccMails.pop();
      }
      this.sendUpdatedRecipientData();
    });
  }

  /** opens cc box */
  clickCc() {
    this.ccBox = !this.ccBox;
  }

  /** opens bcc box */
  clickBcc() {
    this.bccBox = !this.bccBox;
  }

  /** contact typeahead */
  search = (text$: Observable<string>) => {
    let searchTerm = '';
    text$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(200),
        distinctUntilChanged(),
        tap((term) => (searchTerm = term))
      )
      .subscribe(this.searchContact.bind(this));
    const clicksWithClosedPopup$ = this.click$.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    return merge(this.contactList$, inputFocus$, clicksWithClosedPopup$).pipe(
      distinctUntilChanged(),
      map((term) => {
        if (searchTerm === '') {
          return [];
        }
          return this.contactList
            .filter((f) => !this.selectedItems.map((i) => i.doc_id).includes(f.doc_id))
            .filter(
              (v) => (`${v.firstName  } ${  v.lastName  }${v.email}`).toLowerCase().includes(searchTerm.toLowerCase())
            )
            .slice(0, 10);

      })
    );
  };

  searchContact(eve) {
    this.store.dispatch(SearchContact({ data: { term: eve, curPage: 1, ngb: true } }));
  }

  formatter(x: User | any) {
    return '';
  }

  addChip(event) {
    this.selectedItems.push(event.item);
    this.contactList = [];
    this.sendUpdatedRecipientData();
  }

  /** event emitter for enabling mergefield in cc */
  enableForMergeField() {
    this.mergeFieldEnable.emit({ type: 'cc', dripStep: this.dripStep });
  }

  /** to check if email is valid and if mergefield is valid */
  checkValidity(email: string, type?: string) {
    if (email.length > 2) {
      const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
      if (
        String(email).search(filter) === 0 ||
        (email.slice(0, 2) == '{{' && email.slice(email.length - 2) == '}}')
      ) {
        return ValidationState.VALID;
      }

        return ValidationState.INVALID;

    }
      return ValidationState.INVALID;

  }

  /** to remove cc or bcc chips */
  async removeCCMail(index: string, type: string) {
    if (type === 'cc') {
      this.ccMails = await this.ccMails.filter((e) => e?.index !== index);
    } else {
      this.bccMails = await this.bccMails.filter((e) => e?.index !== index);
    }

    this.sendUpdatedRecipientData();
  }

  /** to add and remove fields based on the key pressed */
  onKeypressEvent(event, type: string) {
    const keyList = [13, 32, 47, 44, 34, 39, 58, 59, 9];
    if (event?.keyCode && keyList.includes(event.keyCode)) {
      if (this.ccMail) {
        const validity = this.checkValidity(this.ccMail.trim(), 'cc');
        this.ccMails.push({ email: this.ccMail.trim().toLowerCase(), index: this.ccMails.length, valid: validity });
        this.ccMail = '';
      }
      if (this.bccMail) {
        const validity = this.checkValidity(this.bccMail.trim(), 'bcc');
        this.bccMails.push({ email: this.bccMail.trim().toLowerCase(), index: this.bccMails.length, valid: validity });
        this.bccMail = '';
      }
      event.preventDefault();
      this.sendUpdatedRecipientData();
    } else if (event?.keyCode && event.keyCode === 8) {
      if (type === 'cc' && !this.ccMail) {
        this.chipDelete.next('cc');
      } else if (type === 'bcc' && !this.bccMail) {
        this.chipDelete.next('bcc');
      }
    }
  }

  /** to emit the output data to parent components */
  sendUpdatedRecipientData() {
    this.valueChange.emit({
      to: this.showOnlyEmail ? this.toEmailData : this.selectedItems,
      cc: this.ccMails,
      bcc: this.bccMails,
      dripStep: this.dripStep,
    });
    if (this.ccontrol) {
      this.ccontrol.setValue(this.ccMails.map((e) => e.email));
    }
    if (this.bccontrol) {
      this.bccontrol.setValue(this.bccMails.map((e) => e.email));
    }
  }

  deleteSelectedContact(index: number) {
    this.selectedItems.splice(index, 1);
    this.sendUpdatedRecipientData();
  }
}

type outputDataType = {
  to: any[];
  cc: string[];
  bcc: string[];
  dripStep: number;
};
