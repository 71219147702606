import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dailyai-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {
  constructor(private ngbModal: NgbModal) { }

  @Input() failed_data: { logData: any[]; process_name: string };

  ngOnInit() { }

  closePopup() {
    this.ngbModal.dismissAll();
  }
}
