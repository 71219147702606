import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Plans, Coupons } from 'src/app/models/choose-plan.model';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LoginState } from 'src/app/modules/login/store/state';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtpPopupComponent } from 'src/app/modules/signup/components/otp-popup/otp-popup.component';
import * as moment from 'moment';
import { timeZonesMap } from '../../utils';
import { DISCOUNT, PLAN_NAMES } from '../../config';

@Component({
  selector: 'dailyai-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})

export class ChoosePlanComponent implements OnInit, OnDestroy {
  @Input() plans: Plans[];

  @Input() coupons: Coupons[] = null;

  @Input('actionStatus') isLoading: boolean;

  @Output() save: EventEmitter<{
    uid: string,
    data: {
      discountId: string,
      senior_planId: string,
      planName: string,
      quantity: number,
      isSmallEnterprise: boolean,
      slo_first_subscription_amount: number,
      amount: number,
      max: number
    }
  }> = new EventEmitter();

  @Output() upgrade: EventEmitter<{
    uid: string,
    data: {
      senior_planId: string,
      planName: string,
      quantity: number,
      amount: number,
      max: number
    }
  }> = new EventEmitter();

  currentUser: any;

  userType: string;

  isModal: boolean;

  terms = false;

  currentUser$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject();

  numberOfUsers: {
    grow_plan_i: number,
    grow_plan_ii: number,
    team_plan: number,
    enterprise_plan: number
  } = {
      grow_plan_i: null,
      grow_plan_ii: null,
      team_plan: null,
      enterprise_plan: null
    };

  selectedPlan: string;

  selectedPlanIndex: number;

  selectedPeriod: {
    grow_plan_i: string,
    grow_plan_ii: string,
    team_plan: string,
    enterprise_plan: string
  } = null;

  planNames: any = PLAN_NAMES;

  truncator = new RegExp(/[.,]00$/);

  constructor(
    private loginStore: Store<LoginState>,
    private modalService: NgbModal
  ) {
    this.currentUser$ = this.loginStore.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.currentUser = user;
      this.userType = user?.userType;
    });
  }

  ngOnInit(): void {
    this.plans.forEach(plan => this.numberOfUsers[plan.nickname] = plan.min);
    this.selectedPeriod = {
      grow_plan_i: '1_year',
      grow_plan_ii: '1_year',
      team_plan: '1_year',
      enterprise_plan: '1_year'
    };
    if (this.coupons === null) {
      this.isModal = true;
      const today = moment().tz(timeZonesMap[this.currentUser.timezone])
        ? moment().tz(timeZonesMap[this.currentUser.timezone]).toISOString()
        : '';
      if (this.currentUser.discount_expiry_date && moment(today).isBefore(this.currentUser.discount_expiry_date)) {
          this.selectedPeriod = {
            grow_plan_i: this.currentUser.discountId,
            grow_plan_ii: this.currentUser.discountId,
            team_plan: this.currentUser.discountId,
            enterprise_plan: this.currentUser.discountId
          };
        }
    }
  }

  get loader() {
    return this.coupons === null ? false : (this.coupons.length === 0);
  }

  currentAmount(planName: string) {
    return this.numberOfUsers[planName] * this.plans[this.selectedPlanIndex].amount / 100;
  }

  totalAfterDiscount(planName: string) {
    return this.currentAmount(planName) * this.selectedDiscount(this.selectedPeriod[planName]);
  }

  selectedDiscount(coupName: string) {
    return (100 - DISCOUNT[coupName]) / 100;
  }

  incrUserCount(name: string) {
    this.numberOfUsers[name]++;
  }

  decrUserCount(name: string) {
    this.numberOfUsers[name]--;
  }

  choosePlan(name: string, index: number) {
    if (!this.isModal) {
      this.selectedPlan = name;
      this.selectedPlanIndex = index;
    }
  }

  openTerms() {
    this.modalService.open(OtpPopupComponent, {
      centered: true,
      size: 'lg',
    });
  }

  upgradePlan(name: string, index: number) {
    this.selectedPlanIndex = index;
    this.upgrade.emit({
      uid: this.currentUser.uid,
      data: {
        senior_planId: this.plans[index].id,
        planName: name,
        quantity: this.numberOfUsers[name],
        amount: this.plans[this.selectedPlanIndex].amount,
        max: this.plans[this.selectedPlanIndex].max
      }
    });
  }

  saveAndProceed() {
    if (this.selectedPlan) {
      this.save.emit({
        uid: this.currentUser.uid,
        data: {
          discountId: this.selectedPeriod[this.selectedPlan],
          senior_planId: this.plans[this.selectedPlanIndex].id,
          planName: this.selectedPlan,
          quantity: this.numberOfUsers[this.selectedPlan],
          isSmallEnterprise: true,
          slo_first_subscription_amount: this.totalAfterDiscount(this.selectedPlan) * 100,
          amount: this.plans[this.selectedPlanIndex].amount,
          max: this.plans[this.selectedPlanIndex].max
        }
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
