<form [formGroup]="changeNameForm">
  <div class="px-2 pb-3 v2-ui-integretion">
    <ng-container *ngIf="!isShown">
      <div class="modal-header pb-0">
        <h4 class="color-grey-text text-center text-uppercase px-2">Change Company Name</h4>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
          <i class="material-icons icon-color"> close </i>
        </button>
      </div>
    </ng-container>
    <div class="modal-body" *ngIf="!isShown">
      <div class="d-flex flex-column">
        <div class="col-12 px-0 d-flex flex-column">
          <label class="font-14 color-black-grey-text">Company Name</label>
          <input
            formControlName="companyName"
            type="text"
            class="form-control text-left"
            [(ngModel)]="newCompanyName"
            placeholder="New company name"
            required
          />
          <div class="error-box">
            <span *ngIf="changeNameForm.get('companyName').hasError('required') && (changeNameForm.get('companyName').dirty || changeNameForm.get('companyName').touched)">
              Company name is required
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-3 bottom-btn">
          <button type="button" class="btn btn-normal mr-2 px-4" (click)="closeModal()">Cancel</button>
          <button
            type="button"
            class="btn btn-primary px-4"
            [disabled]="!changeNameForm.valid || loading"
            (click)="changeCompanyName()"
          >
            {{ saveButtonLabel }}
            <span
              [ngClass]="{
                  'spinner-border spinner-border-sm': loading,
                  nil: !loading
                }"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
