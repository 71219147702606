import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
  url?: Blob;
}

@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {
  private stream;

  private recorder;

  private interval;

  private startTime;

  private _recorded = new Subject<RecordedAudioOutput>();

  private _recordingTime = new Subject<string>();

  private _recordingFailed = new Subject<string>();

  paused = false;

  pauseTime;

  playTime;

  counter = 0;

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    this.counter = 0;
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        this.stream = s;
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next();
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  pauseRecording() {
    this.pauseTime = moment();
    this.paused = true;
    this.recorder.pauseRecording();
  }

  resumeRecording() {
    this.paused = false;
    this.playTime = moment();
    this.recorder.resumeRecording();
  }

  private record() {
    this.recorder = new RecordRTC(this.stream, {
      type: 'audio',
      mimeType: 'audio/wav',
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
      audioBitsPerSecond: 256 * 8 * 1000,
      audio: true,
      video: false,
    });

    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      let currentTime;
      let diffTime;
      let totalPaused;
      currentTime = moment();
      if (this.pauseTime && this.playTime) {
        totalPaused = moment.duration(this.playTime.diff(this.pauseTime));
        this.counter += totalPaused.seconds() + 1;
        this.pauseTime = undefined;
        this.playTime = undefined;
      }
      currentTime = moment().subtract(this.counter, 'seconds');
      diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = `${this.toString(diffTime.minutes())  }:${  this.toString(diffTime.seconds())}`;
      if (!this.paused) {
        this._recordingTime.next(time);
      }
    }, 1000);
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = `0${  value}`;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(
        (blob) => {
          const newBlob = this.recorder.getBlob();
          if (this.startTime) {
            const mp3Name = encodeURIComponent(`audio_${  Date.now()  }.mp3`);
            this.stopMedia();
            this._recorded.next({ blob: newBlob, title: mp3Name, url: blob });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next();
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track) => track.stop());
        this.stream = null;
      }
    }
  }
}
