import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { COLLECTIONS } from 'src/app/shared/config';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private db: AngularFirestore) {}

  async addTagsToContact(payload) {
    const contactId = payload.contact_id;
    const ref = await this.db.collection<any>(COLLECTIONS.CONTACTS).doc(contactId).ref.get();
    if (ref.exists) {
      return ref.ref.update({ tags: payload.tags, tags_updated_by: payload.tags_updated_by });
    } 
      return ref.ref.set({ tags: payload.tags, tags_updated_by: payload.tags_updated_by });
    
  }

  async deleteTagsToContact(payload) {
    const userId = payload.data.contact_id;
    const ref = await this.db.collection<any>(COLLECTIONS.CONTACTS).doc(userId).ref.get();
    if (ref.exists) {
      return ref.ref.update({ tags: firebase.default.firestore.FieldValue.arrayRemove(payload.tag) });
    } 
      return ref.ref.set({ tags: payload.tags });
    
  }

  async getAssignedTags(userId) {
    const ref = this.db
      .collection<any>(COLLECTIONS.CONTACTS, (ref) => ref.where('doc_id', 'array-contains', userId))
      .valueChanges();
  }
}
