import { createAction, props } from '@ngrx/store';
import { UserCommunications } from './communications-model';

export const LoadingCommunications = createAction(
  '[Dashboard Page] Loading Communications',
  props<{ userId: string }>()
);
export const LoadCommunicationSuccess = createAction(
  '[Dashboard Page] Loading Communications Success',
  props<UserCommunications>()
);
export const LoadCommunicationError = createAction(
  '[Dashboard Page] Loading Communication Error',
  props<{ msg: string }>()
);
export const ListTask = createAction(
  '[Dashboard Page] List Task',
  props<{ id: string }>()
);
export const ListOpenTaskSuccess = createAction(
  '[Dashboard Page] List Open  Task Success',
  props<{ data: any }>()
);
export const ListDueTaskSuccess = createAction(
  '[Dashboard Page] List Due Task Success',
  props<{ data: any }>()
);
export const ListAllTask = createAction(
  '[Dashboard page] List All', props<{ id: string }>()
);
export const ListAllTaskSuccess = createAction(
  '[Dashboard Page] List All Task Success', props<{ data: any }>()
)

// Contact Activity Actions


export const ListContactActivity = createAction(
  '[Dashboard Page] List Contact Activity',
  props<{ id: string }>()
);
export const ListAllContactActivity = createAction(
  '[Dashboard Page] List All Contact Activity',
  props<{ data: any }>()
);
export const ListCallContactActivity = createAction(
  '[Dashboard Page] List Call Contact Activity',
  props<{ data: any }>()
);
export const ListMsgContactActivity = createAction(
  '[Dashboard Page] List Msg Contact Activity',
  props<{ data: any }>()
);
export const ListMailContactActivity = createAction(
  '[Dashboard Page] List Mail Contact Activity',
  props<{ data: any }>()
);
export const ListContactNurture = createAction(
  '[Dashboard Page] List  Contact Nurture',
  props<{ id: string, user: any }>()
);
export const ListContactNurtureBirthday = createAction(
  '[Dashboard Page] List Contact Nurture  Birthday',
  props<{ data: any }>()
);
export const ListContactNurtureAnniversary = createAction(
  '[Dashboard Page] List Contact Nurture Anniversary',
  props<{ data: any }>()
);
