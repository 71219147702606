import { Component, Input } from '@angular/core';
import { hasAnyUCSCharacters } from '../../utils';

@Component({
  selector: 'dailyai-text-encoding-warning',
  templateUrl: './text-encoding-warning.component.html',
  styleUrls: ['./text-encoding-warning.component.scss'],
})
export class TextEncodingWarningComponent {
  show = false;

  @Input() customStyle = { right: '1rem', bottom: '1rem' };

  @Input() placement = 'auto';

  warningText = `Your content has USC Encoded characters (this may have occurred if you copy/pasted your content from certain sources).  Using this content may result in multiple credits being used when this text is sent.`;

  @Input() set message(content: string) {
    this.show = hasAnyUCSCharacters(content);
  }
}
