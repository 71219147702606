import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactsState } from './state';

export const selectContactsState = createFeatureSelector<ContactsState>('contact');

export const selectAddContactError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactAddError
);
export const selectAddContact = createSelector(selectContactsState, (contactsState) => contactsState.ContactAdd);
export const selectAddContactSuccess = createSelector(selectContactsState, (contactsState) => {
  return {
    addSuccess: contactsState.ContactAddSuccess,
    updateSuccess: contactsState.ContactUpdateSuccess,
  };
});


export const selectDeleteContactSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactDeleteSuccess
);
export const selectDeleteContactError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactDeleteError
);

export const selectBatchAddingToDrip = createSelector(selectContactsState, (state) => state.batchAddingToDrips);
export const selectAddingToDrip = createSelector(selectContactsState, (state) => state.addingToDrips);
export const selectRemovingFromDrip = createSelector(selectContactsState, (state) => state.removingFromDrips);

export const selectUpdateContactSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactUpdateSuccess
);
export const selectUpdateContactError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactUpdateError
);

export const selectGetDetailOfContact = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactDetail
);

export const selectGetDetailOfContactError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactDetailError
);
export const selectGetDetailOfContactLoading = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactDetailLoading
);


export const selectContactList = createSelector(selectContactsState, (contactsState) => contactsState.ContactList);
export const selectContactListSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactListSuccess
);
export const selectContactListError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactListError
);

export const selectContactFileAdd = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactFileAdd
);
export const selectFileUploadProgress = createSelector(
  selectContactsState,
  (contactsState) => contactsState.FileUploadProgress
);
export const selectContactFileAddSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactFileAddSuccess
);
export const selectContactFileAddError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.ContactFileAddError
);
export const selectUploadCsv = createSelector(selectContactsState, (uploadCsv) => uploadCsv.UploadCsv);
export const selectUploadCsvSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.UploadCsvSuccess
);
export const selectUploadCsvError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.UploadCsvError
);

export const selectGetContactFile = createSelector(
  selectContactsState,
  (contactsState) => contactsState.GetContactFiles
);
export const selectGetContactFileSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.GetContactFilesSuccess
);
export const selectGetContactFileError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.GetContactFilesError
);

export const selectDeleteContactFile = createSelector(
  selectContactsState,
  (contactsState) => contactsState.DeleteContactFile
);

export const selectDeleteContactFileSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.DeleteContactFileSuccess
);

export const selectDeleteContactFileError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.DeleteContactFileError
);

export const selectUpdateContactFile = createSelector(
  selectContactsState,
  (contactsState) => contactsState.UpdateContactFile
);

export const selectUpdateContactFileSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.UpdateContactFileSuccess
);

export const selectUpdateContactFileError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.UpdateContactFileError
);

export const selectDownloadFile = createSelector(selectContactsState, (contactsState) => contactsState.DownloadFile);

export const selectDownloadFileSuccess = createSelector(
  selectContactsState,
  (contactsState) => contactsState.DownloadFileSuccess
);

export const selectDownloadFileError = createSelector(
  selectContactsState,
  (contactsState) => contactsState.DownloadFileError
);

export const selectAddNote = createSelector(selectContactsState, (addNote) => addNote.AddNote);

export const selectAddNoteSuccess = createSelector(selectContactsState, (addNote) => addNote.AddNoteSuccess);

export const selectAddNoteError = createSelector(selectContactsState, (addNote) => addNote.AddNoteError);

export const selectGetNotes = createSelector(selectContactsState, (getNote) => getNote.GetNotes);
export const selectGetNotesSuccess = createSelector(selectContactsState, (getNote) => getNote.GetNotesSuccess);
export const selectGetNotesError = createSelector(selectContactsState, (getNote) => getNote.GetNotesError);

export const selectUpdateNoteSuccess = createSelector(selectContactsState, (getNotes) => getNotes.UpdateNotesSuccess);
export const selectMyUsers = createSelector(selectContactsState, (state) => state.GetMyUsers);
export const selectMySLO = createSelector(selectContactsState, (state) => state.getSLO);
export const selectMyDrips = createSelector(selectContactsState, (state) => state.MyDrips);

export const getCount = createSelector(selectContactsState, (state) => state.count);

export const selectGetTagsList = createSelector(selectContactsState, (state) => state.GetTagsList);
export const selectGetTagsListSuccess = createSelector(selectContactsState, (state) => state.GetTagsListSuccess);
export const selectGetTagsListError = createSelector(selectContactsState, (state) => state.GetTagsListError);

export const selectAssignTags = createSelector(selectContactsState, (state) => state.AssignTags);

export const selectAssignTagsSuccess = createSelector(selectContactsState, (state) => state.AssignTagsSuccess);

export const selectAssignTagsError = createSelector(selectContactsState, (state) => state.AssignTagsError);
export const selectDeleteAssignedTags = createSelector(selectContactsState, (state) => state.DeleteAssignedTags);

export const selectDeleteAssignedTagsSuccess = createSelector(
  selectContactsState,
  (state) => state.DeleteAssignedTagsSuccess
);

export const selectDeleteAssignedTagsError = createSelector(
  selectContactsState,
  (state) => state.DeleteAssignedTagsError
);

export const selectAssignDripModalOpen = createSelector(selectContactsState, (state) => state.assignDripModalOpen);

export const selectContactIds = createSelector(selectContactsState, (contactsState) => contactsState.contact_ids);
export const massSmsLoader = createSelector(selectContactsState, (contactsState) => contactsState.massSmsSending);
export const massEmailLoader = createSelector(selectContactsState, (contactsState) => contactsState.massEmailSending);
export const massVoiceDropLoader = createSelector(
  selectContactsState,
  (contactsState) => contactsState.massVoiceDropSending
);

export const selectContactWithDemand = createSelector(
  selectContactsState,
  (contactState) => contactState.contactWithDemand
);
export const contactWithDemandLoader = createSelector(
  selectContactsState,
  (contactState) => contactState.loadingContactWithPagination
);

export const selectContactTasks = createSelector(selectContactsState, (contactState) => contactState.ContactTasks);
export const selectSearchContacts = createSelector(
  selectContactsState,
  (contactState) => contactState.SearchContactsList
);
export const selectFilterContact = createSelector(selectContactsState, (contactState) => contactState.filterData);

export const selectCurrentPage = createSelector(selectContactsState, (contactState) => contactState.curPage);

export const selectCurrentPage2 = createSelector(selectContactsState, (contactState) => contactState.curPage2);

export const selectLastAction = createSelector(selectContactsState, (contactState) => contactState.lastAction);

export const selectMongoFetchParams = createSelector(
  selectContactsState,
  (contactState) => contactState.mongoFetchParams
);

export const selectExtraConatcIds = createSelector(selectContactsState, (contactState) => contactState.extraContactIds);

export const selectLodingIdsStatus = createSelector(
  selectContactsState,
  (contactState) => contactState.loadingContactIds
);

export const selectParentContact = createSelector(selectContactsState, (contactState) => contactState.setParentContact);
export const selectParentContactSuccess = createSelector(
  selectContactsState,
  (contactState) => contactState.setParentContactSuccess
);
export const selectParentContactError = createSelector(
  selectContactsState,
  (contactState) => contactState.setParentContactError
);
export const selectMassEmailCampagnLoader = createSelector(
  selectContactsState,
  (contactState) => contactState.massEmailCampain
);
export const selectTestEmailCampagnLoader = createSelector(
  selectContactsState,
  (contactState) => contactState.testEmailCampagin
);
export const selectTestEmailNormalLoader = createSelector(
  selectContactsState,
  (contactState) => contactState.testEmailNormal
);

export const selectlastPayload = createSelector(selectContactsState, (contactState) => ({
  ...contactState.lastPayload,
  onlyMe: contactState.onlyMe,
}));

export const selectContactListLoading = createSelector(
  selectContactsState,
  (contactsState) => contactsState.contactListLoading
);
