<div class="px-2 pb-4 v2-ui-integretion">
  <div class="modal-header pb-0">
    <h5 class="pt-3 color-grey-text text-uppercase">Select the source</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeSelectModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body input-layout">
    <div class="d-flex align-items center mt-4">
      <label for="files" class="btn btn-primary w-50 d-flex justify-content-center align-items-center">From
        System</label>
      <input *ngIf="url === 'contacts'" id="files" style="visibility: hidden;" accept=".wav , .mp3 , m4a" type="file"
        (change)="uploadFile($event)" />
      <input *ngIf="url === 'settings'" id="files" style="visibility: hidden;"
        accept=".jpg , .png , .gif, .jpeg, .mp4, .mov, .avi" type="file" (change)="uploadFile($event)" />
      <input *ngIf="url !== 'settings' && url !== 'contacts'" id="files" style="visibility: hidden;" type="file"
        (change)="uploadFile($event)" />
      <button class="btn btn-primary w-50" (click)="openMediaAudioList()">
        From Daily AI Media Files
      </button>
    </div>
  </div>
</div>