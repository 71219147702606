import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable()
export class AppService {
  private DetailsScreenEditMode = new BehaviorSubject<any>(false);

  constructor(private spinner: NgxSpinnerService) {}

  showLoading(loading: boolean): void {

  }

  setPartnerLoading(loading: boolean): void {
    this.DetailsScreenEditMode.next(loading);
  }

  setEditMode(val: any) {}

  getEditMode() {
    return this.DetailsScreenEditMode.asObservable();
  }

  showModel(show: boolean): void {

  }
}
