/**                                                              To use any enum
 *
 * to use .ts file:
 * import { UserType } from 'src/app/shared/enums/commonEnums';
 *  example:
 *   if(currentUser.userType===UserType.SUPER_ADMIN)
 *
 * to use in .html file:
 * Enums cannot be directly used inside HTML files.to use in HTML file,
 *  either assign enum to a variable or create a public function.
 * example:
 *
 * const userType=UserType
 *
 *              or
 *
 *  public get userType(): typeof UserType {
    return UserType;
  }
 *
 *
 *      *ngIf(currentUser.userType===userType.SUPER_ADMIN)
 *
 *
 */

export enum UserType {
  JLO = 'JLO',
  MO = 'MO',
  SUPER_ADMIN = 'Super Admin',
  TEAM_LEADER = 'TeamLeader',
  ADMIN = 'admin',
  MANAGER = 'manager',
  NONE = ''
}

  export enum GridFilterType {
    SET = 'set',
    NUMBER = 'number',
    DATE = 'date',
    TEXT = 'text'
  }

  export enum UserDesignation {
    SENIOR_LOAN_OFFICER = 'Senior Loan Officer',
    JUNIOR_LOAN_OFFICER = 'Junior Loan Officer',
    LOAN_OFFICER_ASSISTANT = 'Loan Officer Assistant',
    LOAN_PROCESSOR = 'Loan Processor',
    SALES_MANAGER = 'Sales Manager',
    BUSINESS_DEVELOPMENT_MANAGER = 'Business Development Manager',
    ADMINISTRATIVE_ASSISTANT = 'Administrative Assistant',
    MARKETING_MANAGER = 'Marketing Manager',
    LOAN_ASSIGNEE = 'Loan Assignee',
  }
  export enum TransactionDesignation {
    SENIOR_LOAN_OFFICER = 'senior_loan_officer',
    JUNIOR_LOAN_OFFICER = 'junior_loan_officer',
    LOAN_OFFICER_ASSISTANT = 'loan_officer_assistant',
    LOAN_PROCESSOR = 'loan_processor',
    SALES_MANAGER = 'sales_manager',
    BUSINESS_DEVELOPMENT_MANAGER = 'business_development_manager',
    ADMINISTRATIVE_ASSISTANT = 'administrative_assistant',
    MARKETING_MANAGER = 'marketing_manager',
    LOAN_ASSIGNEE = 'loan_assignee',
  }

  export enum ValidationState {
    VALID = 0,
    INVALID = 1,
    DUPLICATE = 2,
  }

  export enum AoHEnterpriseTeamId {
    AOH = 'O8VCiKUtPOv8AC8cO0LG',
    NEO = 'kSrcOUVBrtRF0zr8QzGM',
    SOSI = 'nti3LsHguj5Y8fbSQdn0',
    SOSI_NEW = 'ZMS6NwZwRJim6yBBlPlp'
  }

  export enum EStateType {
    ContactsGrid = 1,
    TransactionsGrid = 2
  }

  export enum EHttpStatus {
    OK = 200,
    CREATED = 201,
    DELETED = 202,
    NO_CONTENT=204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    GONE = 410,
    PAYLOAD_TO_LARGE = 413,
    UNSUPPORTED_MEDIA_TYPE = 415,
    LOCKED = 423,
    FAILED_DEPENDENCY = 424,
    TO_MANY_REQUEST = 429,
    CLIENT_CLOSED_REQUEST = 499,
    SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
    LOOP_DETECTED = 508,
  }

  export enum TransactionDetailLabels {
    LOAN_OFFICER = 'Loan Officer',
    TITLE = 'Title',
    LISTING_AGENT = 'Listing Agent',
    BUYING_AGENT = 'Buying Agent',
    SENIOR_LOAN_OFFICER = 'Senior Loan Officer',
    JUNIOR_LOAN_OFFICER = 'Junior Loan Officer',
    LOAN_OFFICER_ASSISTANT = 'Loan Officer Assistant',
    LOAN_PROCESSOR = 'Loan Processor',
    SALES_MANAGER = 'Sales Manager',
    BUSINESS_DEVELOPMENT_MANAGER = 'Business Development Manager',
  }
