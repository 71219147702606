<div class="d-flex flex-column px-4 pb-3 pt-2 v2-ui-integretion">
  <div class="modal-header" style="padding: 0rem !important">
    <span class="font-16 font-weight-bold color-grey-text py-3">
      {{ title }}
    </span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="material-icons icon-color py-2"> close </i>
    </button>
  </div>
  <dailyai-hidden-input></dailyai-hidden-input>
  <span *ngIf="!noteHead" class="font-14 font-weight-bold color-primary pb-2">Note</span>
  <div class="row mx-0 pb-3" *ngIf="noteHead">
    <span class="font-14 px-0 font-weight-bold color-primary mr-2">Note:</span>
    <span class="font-weight-bold px-0 font-14">{{ noteHead }}</span>
  </div>
  <div class="d-flex font-13 color-grey-text pb-2" *ngFor="let item of notes; let i = index">
    <span class="pr-1">{{ i + 1 }}.</span>
    <span>
      {{ item }}
    </span>
  </div>
  <div class="d-flex justify-content-center align-items-center mb-3 mt-4">
    <button class="btn btn-normal px-4 mr-2" (click)="close()">{{ noText }}</button>
    <button class="btn btn-primary px-4" (click)="sumbit()">{{ yesText }}</button>
  </div>
</div>
