<div class="message-chat-popup v2-ui-integretion px-2 pb-2">
  <dailyai-hidden-input></dailyai-hidden-input>
  <div class="modal-header pb-0">
    <h5 class="pt-3 color-grey-text">COMPOSE MAIL</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="material-icons icon-color" (click)="close()"> close </i>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="mailForm">
      <div class="form-group">
        <dailyai-to-cc-bcc
          [showOnlyEmail]="true"
          [toEmailData]="[currentContact?.email]"
          [enableCC]="true"
          [enableBCC]="true"
          [enableTO]="true"
          (valueChange)="recipientSetter($event)"
          (mergeFieldEnable)="ccMergeFieldSetter($event)"
          [mergefieldSetter]="mergeFieldEventForCC.asObservable()"
          [ccData]="[]"
        ></dailyai-to-cc-bcc>
        <div class="w-100 d-flex flex-column sub-attach-box pb-3">
          <div class="w-100 d-flex">
            <input
              formControlName="subject"
              id="title"
              autocomplete="off"
              class="form-control sub-mail"
              placeholder="Title"
              #subjectArea
              (click)="getCaretPosition(subjectArea, 'subjectArea')"
              (keyup)="getCaretPosition(subjectArea, 'subjectArea')"
            />
          </div>
          <div
            class="errox-box text-left"
            *ngIf="
              mailForm.get('subject').invalid && (mailForm.get('subject').touched || mailForm.get('subject').dirty)
            "
          >
            <p class="error">*Required</p>
          </div>
        </div>
        <div class="position-relative">
          <div
            *ngIf="emailEditorLoaded"
            class="position-absolute emoji-box mx-1 mb-3 mr-3"
            [ngbPopover]="popEmojiBox"
            [autoClose]="'outside'"
            placement="left"
          >
            <span class="material-icons"> mood </span>
          </div>
          <ng-template #popEmojiBox>
            <div class="emoji-popup">
              <emoji-mart
                title="Pick your emoji…"
                sheetSize="20"
                emoji="point_up"
                set="{{ set }}"
                (emojiSelect)="addEmojiMail($event)"
              ></emoji-mart>
            </div>
          </ng-template>
          <ckeditor
            #myckeditor
            name="myckeditor"
            required
            formControlName="content"
            [config]="ckeConfig"
            (click)="onClick($event)"
            (ready)="editorOnFocus(popTag2, myckeditor)"
            debounce="500"
            ><ckbutton
              [name]="'saveButton'"
              [command]="'videouploadcmd'"
              [icon]="
                'https://raw.githubusercontent.com/bahriddin/ckeditor-html5-video/master/html5video/icons/html5video.png'
              "
              [label]="'Insert Video'"
              [toolbar]="'mode,1'"
            >
            </ckbutton>
          </ckeditor>
          <div *ngIf="!emailEditorLoaded" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="d-flex v2-ui-integretion align-items-baseline">
          <div class="d-flex sub-attach-box mr-2 mt-2">
            <div
              class="upload-btn-email btn btn-border d-flex align-items-center justify-content-center"
              (change)="fileEvent($event)"
            >
              <span class="material-icons font-16"> attach_file </span> <input type="file" name="myfile" />
              <span class="font-14 pl-2">Attach Files</span>
            </div>
            <div class="emoji-icons" style="display: 'flex'">
              <ng-template #popEmojiBox>
                <emoji-mart
                  set="{{ set }}"
                  (emojiSelect)="addEmojiMail($event)"
                  title="Pick your emoji…"
                  [showPreview]="false"
                  (showSkinTones)="(false)"
                  sheetSize="20"
                ></emoji-mart
              ></ng-template>
            </div>
          </div>
          <dailyai-email-attachment (mailAttachmentArray)="mailAttachmentDetails($event)" [file]="file">
          </dailyai-email-attachment>
        </div>
        <div class="errox-box text-left">
          <p
            class="error"
            *ngIf="
              mailForm.get('content').invalid && (mailForm.get('content').touched || mailForm.get('content').dirty)
            "
          >
            *Required
          </p>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="button"
            class="btn btn-normal mr-2"
            [ngbPopover]="popTag1"
            triggers="manual"
            #p1="ngbPopover"
            placement="top"
            [autoClose]="'outside'"
            (click)="openTagList(p1)"
          >
            Merge Field
          </button>
          <button
            *ngIf="!(contactEmailLoader$ | async) && !formValid"
            type="button"
            class="btn btn-primary px-4"
            (click)="sentMail()"
          >
            Send
          </button>
          <button [disabled]="formValid" *ngIf="formValid" class="btn btn-primary px-4">
            Sending..
            <span
              *ngIf="formValid"
              [ngClass]="{
                'spinner-border spinner-border-sm': formValid,
                nil: !formValid
              }"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #popTag1 placement="bottom">
  <div class="tag-list-search m-2 v2-ui-integretion">
    <div class="input-group mb-2">
      <input type="text" class="form-control" placeholder="Search " aria-label="Search " [(ngModel)]="searchText" />
      <div class="input-group-append h-35">
        <span class="input-group-text" id="basic-addon2"> <i class="material-icons font-18"> search </i> </span>
      </div>
    </div>
    <div class="heightscroll">
      <div
        class="list-names cursor-pointer p-1"
        *ngFor="let item of mergeFieldList | filter: searchText:'item'"
        (click)="getChoiceLabel(item, -1)"
      >
        <span class="font-12 font-hn-light bold">
          {{ item }}
        </span>
      </div>
      <div *ngIf="!(mergeFieldList | filter: searchText:'item').length" class="heightscroll">
        <dailyai-noting-to-show [content]="'No Merge Fields Found'"> </dailyai-noting-to-show>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #popTag2 placement="bottom" let-modal>
  <div class="v2-ui-integretion m-2">
    <span>Display Text</span>
    <div class="input-group mb-2">
      <div class="input-group-prepend"></div>
      <input type="text" class="form-control pl-1" placeholder="Enter the Text" [(ngModel)]="selectedTextForLink" />
    </div>
    <span>Link URL</span>
    <div class="input-group mb-2">
      <div class="input-group-prepend"></div>
      <input
        type="text"
        class="form-control pl-1"
        placeholder="Enter the Link"
        [(ngModel)]="linkText"
        (click)="setCurserPostionAndInput($event.target.selectionStart)"
      />
    </div>
    <div class="d-flex justify-content-end w-100 px-2 pb-3 pt-2">
      <button
        type="button"
        class="btn btn-normal mr-2 px-3"
        [ngbPopover]="popTag3"
        triggers="manual"
        #p2="ngbPopover"
        placement="top"
        [autoClose]="'outside'"
        (click)="openTagList(p2)"
      >
        Merge Fields
      </button>
      <button class="btn btn-normal px-3 mr-1" (click)="cancelLinkModal(modal)">Cancel</button>
      <button
        [disabled]="linkText == '' || selectedTextForLink == ''"
        class="btn btn-primary px-3 ml-1"
        (click)="addLink(modal)"
      >
        Create
      </button>
    </div>
  </div>
</ng-template>
<ng-template #popTag3 placement="bottom" let-modal>
  <div class="v2-ui-integretion m-2">
    <div class="input-group mb-2">
      <div class="input-group-prepend"><span class="input-group-text material-icons pr-0">search</span></div>
      <input type="text" class="form-control pl-1" placeholder="Search merge field" [(ngModel)]="searchText" />
    </div>
    <div class="heightscroll">
      <div
        class="list-names cursor-pointer p-1"
        *ngFor="let item of urlMergeFieldsList | filter: searchText:'item'"
        (click)="linkSet(item)"
      >
        <span class="font-12 font-hn-light bold">
          {{ item }}
        </span>
      </div>
      <div *ngIf="!(urlMergeFieldsList | filter: searchText:'item').length" class="heightscroll">
        <dailyai-noting-to-show [content]="'No Merge Fields Found'"> </dailyai-noting-to-show>
      </div>
    </div>
  </div>
</ng-template>
