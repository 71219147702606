import { combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/services/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const api = environment.mongoUrl;
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private http: HttpClient
  ) {}

  getTasks() {
    const currentUser = this.auth.getUserData();
    const tasks1 = this.db
      .collection<any>('tasks', (ref) =>
        ref
          .where(
            'assignedby.uid',
            'in',
            currentUser.userType === 'MO' ? [currentUser.parentId, currentUser.uid] : [currentUser.uid]
          )
          .limit(10)
      )
      .valueChanges();
    const tasks2 = this.db
      .collection<any>('tasks', (ref) =>
        ref
          .where(
            'assignedto.uid',
            'in',
            currentUser.userType === 'MO' ? [currentUser.parentId, currentUser.uid] : [currentUser.uid]
          )
          .limit(10)
      )
      .valueChanges();
    const tasks = combineLatest([tasks1, tasks2]);

    return tasks;
  }

  async getjloTasks(jloId: string) {
    const tasks: any = [];
    const jloTasks = await this.db
      .collection<any>('tasks')
      .ref.where('assignedto.uid', '==', jloId)
      .where('taskStatus', 'in', ['open', 'postponed', 'on hold'])
      .get();

    jloTasks.forEach((element) => {
      let obj = {};
      obj = element.data();
      tasks.push(obj);
    });

    return tasks;
  }

  async fetchCount(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/tasks/task-count`, { id: data }, httpOptions).toPromise();
  }

  async fetchTasks(data, uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/tasks/task-list`, { value: data, id: uid }, httpOptions).toPromise();
  }

  async filterTasks(data, uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/tasks/task-filter`, { value: data, id: uid }, httpOptions).toPromise();
  }

  async sortTasks(data, uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/tasks/sort-tasks`, { value: data, id: uid }, httpOptions).toPromise();
  }
}
