import { createAction, props } from '@ngrx/store';

export const AddPartner = createAction(
  '[AddPartner Page] AddPartner',
  props<{
    partner: any;
    id: string;
    imageUrl: string;
  }>()
);

export const AddPartnerSuccess = createAction('[AddPartner Page] AddPartner Success');
export const AddPartnerError = createAction('[AddPartner Page] AddPartner Error', props<{ msg: string }>());

export const PartnersList = createAction('[Partners Page] List Partners', props<{ userId: string }>());
export const PartnersListSuccess = createAction(
  '[Partners Page] PartnersListSuccess',
  props<{ data: any; scroll?: boolean }>()
);
export const PartnersListError = createAction('[Partners page] Partners List Error', props<{ msg: string }>());


export const ListPartnersWithDemandScroll = createAction(
  '[Partner page] List partners with demand scroll',
  props<{ user_id: string }>()
);
export const ListPartnersWithDemandScrollSuccess = createAction(
  '[Partner page] List partners with demand scroll success',
  props<{ data: any }>()
);
export const ListPartnersWithDemandScrollError = createAction(
  '[Partner page] List partners with demand scroll error',
  props<{ data: any }>()
);

export const GetPartnersList = createAction('[Partners Page] GetPartnersList');
export const ResetPartnerList = createAction('[Partners Page] Reset Partners list');

export const UpdatePartner = createAction(
  '[AddPartner Page] UpdatePartner',
  props<{
    id: string;
    partner: any;
    imageUrl: string;
    updatedContactsList: any;
    referralContactUpdated: boolean;
  }>()
);

export const UpdatePartnerSuccess = createAction('[AddPartner Page] UpdatePartner Success');
export const UpdatePartnerError = createAction('[AddPartner Page] UpdatePartner Error', props<{ msg: string }>());
export const GetPartnerCount = createAction('[AddPartner Page] GetPartnerCount ', props<{ data: any }>());
export const FetchPartners = createAction('[AddPartner Page] FetchPartners ', props<{ data: any }>());
export const PartnerCount = createAction('[AddPartner Page] PartnerCount ', props<{ count: any }>());
export const SearchPartners = createAction(
  '[AddPartner Page] SearchPartners ',
  props<{ data: any; scroll?: boolean }>()
);
export const SortPartners = createAction('[AddPartner Page] SortPartners ', props<{ data: any }>());

export const DeletePartner = createAction('[Partner Page] Delete Partner', props<{ data: any }>());
export const DeletePartnerSuccess = createAction('[Partner Page] Delete Partner Success');
export const DeletePartnerError = createAction('[Partner Page] Delete Partner Error', props<{ msg: string }>());

export const ResetPartnerDemandList = createAction('[Partner Page] Reset Partner Demand List');

export const addedPatener = createAction('[Partner Page] addedpatner', props<{ data: any }>());

export const UploadPartnersCSV = createAction('[Partner Page] uploadCsv', props<{ id: string, file: any }>());
export const UploadPartnersCSVSuccess = createAction('[Partner Page] uploadCsvSuccess');
export const UploadPartnersCSVError = createAction('[Partner Page] uploadCsvError', props<{ msg: any }>());
