
import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'tooltip-component',
  template: ` <div class="custom-tooltip bg-light border border-2 rounded-3 " >

  <div class="my-3">
    <div *ngFor="let tip of data" class="w-100 px-3 mt-1">
      {{tip}}
    </div>
  </div>
</div>`,
  styles: [
    `
      :host {
        position: absolute;
        min-width: 50px;
        min-height: 40px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
  ],
})
export class GridTooltipComponent implements ITooltipAngularComp {
  params: any;

  data: string[] = [];

  tooltipClass: string;

  backgroundColor: "white";

  agInit(params: any): void {
    this.params = params;

    let tooltipValue: any;
    switch (params.location) {
      case 'header':
        tooltipValue = this.params.colDef?.headerName || "";
        break;
      case 'columnToolPanelColumn':
        tooltipValue = this.params.colDef?.headerName || "";
        break;

      case 'cell':
        tooltipValue = this.params.value;
        if (!tooltipValue || tooltipValue === '--' || tooltipValue === 'NULL') {
          tooltipValue = ['...']
        }
        break;
      default:
        tooltipValue = ['...'];
        break;
    }

    if (!Array.isArray(tooltipValue)) {
      tooltipValue = [tooltipValue];
    }
    this.data = tooltipValue;

    this.tooltipClass = ""
  }
}
