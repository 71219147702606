import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items, searchText: string, searchParam1?: string, searchParam2?: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    if (searchParam1) {
      const regex = new RegExp(searchText, 'i');
      return items.filter((it) => (it[searchParam1] ?? it).toLowerCase().search(regex) > -1);
    }

    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      if (it && typeof it === 'object') {
        if (it.file_name) {
          return it.file_name.toLowerCase().includes(searchText.toLowerCase());
        }
        return (`${it.firstName  } ${  it.lastName}`).toLowerCase().includes(searchText.toLowerCase());
      }
        return it.toLowerCase().includes(searchText);

    });
  }
}
