import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  OnInit,
  ElementRef,
} from '@angular/core';
import { StyleRenderer, lyl, WithStyles } from '@alyle/ui';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
} from '@alyle/ui/image-cropper';
import { Platform } from '@angular/cdk/platform';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/modules/settings/services';
import { ToastrService } from 'ngx-toastr';

const STYLES = () => ({
  cropper: lyl`{
    max-width: 400px
    height: 300px
  }`,
  sliderContainer: lyl`{
    text-align: center
    max-width: 400px
    margin: 14px
  }`,
});

@Component({
  selector: 'dailyai-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
export class ImageCropperComponent implements WithStyles, AfterViewInit, OnInit {
  classes = this.sRenderer.renderSheet(STYLES);

  croppedImage?: string;

  scale: number;

  ready: boolean;

  minScale: number;

  @ViewChild(LyImageCropper) cropper: LyImageCropper;

  @ViewChild('_fileInput') fileInput: ElementRef;

  myConfig: ImgCropperConfig = {
    width: 150,
    height: 150,
    fill: '#ffffff',
    type: 'image/png',
    responsiveArea: true,
  };

  uploading = false;

  constructor(
    readonly sRenderer: StyleRenderer,
    private _platform: Platform,
    private activeModal: NgbActiveModal,
    private settingService: SettingsService,
    private toastr: ToastrService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      const config: ImgCropperLoaderConfig = {
        scale: 0.745864772531767,
        xOrigin: 642.380608078103,
        yOrigin: 236.26357452128866,
        rotation: 0,
        originalDataURL: '',
      };
      this.cropper.loadImage(config);
    }
    this.fileInput?.nativeElement?.click();
  }

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
  }

  onLoaded(e: ImgCropperEvent) {
  }

  onError(e: ImgCropperErrorEvent) {
    e?.name &&
      this.toastr.error(`${e.name} is not a valid image`);
  }

  closeModal() {
    this.activeModal.close();
  }

  async uploadNewLogo() {
    this.uploading = true;
    await this.settingService.enterpriseLogoUpload(this.croppedImage);
    this.activeModal.close('uploaded');
  }
}
