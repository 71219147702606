<div class="v2-ui-integretion">
  <div class="modal-header pb-1 pt-4">
    <h4 class="color-grey-text text-center text-uppercase font-18 px-2 font-normal">CROP IMAGE</h4>
    <button type="button" class="close" [disabled]="uploading" (click)="closeModal()" aria-label="Close">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="border-top d-flex flex-column flex-sm-row px-4 pt-4 pb-3 bg-color img-crop-layout">
    <div class="d-flex flex-column col-12 col-sm-6 col-md-8 px-0">
      <div class="position-relative">
        <button class="ratio-button" (click)="croppedImage = undefined; cropper.setScale(1)" ly-button>1:1</button>
        <ly-img-cropper
          [withClass]="classes.cropper"
          [config]="myConfig"
          [(scale)]="scale"
          (minScale)="minScale = $event"
          (ready)="ready = true"
          (cleaned)="ready = false"
          (cropped)="onCropped($event)"
          (loaded)="onLoaded($event)"
          (error)="onError($event)"
          (click)="croppedImage = undefined"
        >
          <span>Drag and drop image</span>
        </ly-img-cropper>
      </div>
      <div class="d-flex justify-content-between pt-2" *ngIf="ready">
        <button (click)="croppedImage = undefined; cropper.zoomOut()" ly-button appearance="icon">
          <ly-icon>zoom_out</ly-icon>
        </button>
        <div class="w-100 d-flex align-items-center">
          <ly-slider
            [thumbVisible]="false"
            [min]="minScale"
            [max]="1"
            [(ngModel)]="scale"
            (input)="croppedImage = undefined; scale = $event.value"
            (click)="croppedImage = undefined"
            step="0.000001"
            class="slider"
            [color]="'#0047af'"
          ></ly-slider>
        </div>
        <button (click)="croppedImage = undefined; cropper.zoomIn()" ly-button appearance="icon">
          <ly-icon>zoom_in</ly-icon>
        </button>
      </div>
    </div>
    <div class="d-flex flex-column col-12 col-sm-6 col-md-4 pl-0 pl-sm-4 pr-0">
      <div class="previw-box d-flex justify-content-center align-items-center" *ngIf="!croppedImage">
        <button class="color-primary btn previw-btn font-500" [disabled]="!ready" (click)="cropper.crop()">
          Preview
        </button>
      </div>
      <div class="croped-img d-flex justify-content-center" *ngIf="croppedImage">
        <img class="h-100" [src]="croppedImage" />
      </div>
      <div class="crop-tools d-flex flex-column justify-content-center mt-3 py-3 px-2">
        <div [lyDisplay]="'flex'" class="d-flex align-items-center justify-content-center">
          <div
            class="d-flex align-items-center color-primary justify-content-center cursor-pointer w-100"
            ly-button
            (click)="_fileInput.click()"
          >
            <span class="material-icons font-16 pr-2"> crop_original </span>
            <span class="font-13">Change Image</span>
          </div>
          <span [lyFlex]="1"></span>
          <input
            #_fileInput
            type="file"
            (change)="croppedImage = undefined; cropper.selectInputEvent($event)"
            accept="image/*"
            hidden
          />
        </div>
        <div class="border-bottom dashed-border my-3"></div>
        <button
          (click)="croppedImage = undefined; cropper.fit()"
          [disabled]="!ready"
          ly-button
          class="btn btn-crop-tool mx-3"
        >
          Fit to image
        </button>
        <div class="border-bottom dashed-border my-3"></div>
        <button
          (click)="croppedImage = undefined; cropper.fitToScreen()"
          [disabled]="!ready"
          ly-button
          class="btn btn-crop-tool mx-3"
        >
          Fit to screen
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end align-items-center bottom-btn border-top pt-3 py-4 px-4">
    <button
      type="button"
      class="btn btn-normal mr-3 px-4"
      [disabled]="!ready || uploading"
      ngbTooltip="Cancels the current selection"
      tooltipClass="tooltip-page-class"
      (click)="croppedImage = undefined; cropper.clean()"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary d-flex align-items-center"
      [disabled]="!ready || uploading"
      (click)="cropper.crop(); uploadNewLogo()"
    >
      <span class="material-icons font-20 mr-2">crop</span>
      <span> Crop & Upload </span>
    </button>
  </div>
</div>
