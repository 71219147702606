import { Component, Input } from '@angular/core';

@Component({
  selector: 'dailyai-info-notification',
  templateUrl: './info-notification.component.html',
  styleUrls: ['./info-notification.component.scss'],
})
export class InfoNotificationComponent {

  @Input() notificationText: string;

  @Input() notificationDescription: string;

  @Input() extraLink: { link: string; text: string };

  @Input() showNotification: boolean;

  dismiss = false;
}
