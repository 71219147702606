import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'dailyai-milestones-notification',
  templateUrl: './milestones-notification.component.html',
  styleUrls: ['./milestones-notification.component.scss'],
})
export class MilestonesNotificationComponent implements AfterViewInit {
  showPopup = false;

  @ViewChild('warningNotification') notification: ElementRef;

  constructor( private sharedService: SharedService, private changeDetector: ChangeDetectorRef ) {}

  ngAfterViewInit() {
    if (this.notification) {
      const element: HTMLElement = this.notification.nativeElement;
      this.isEllipsisActive(element);
    }
  }

  isEllipsisActive(element: HTMLElement): void {
    this.showPopup = (window.innerWidth < 1001) || (element ? (element.offsetWidth < element.scrollWidth) : false);
    this.changeDetector.detectChanges();
  }

  isMilestonesBlocked() {
    return this.sharedService.isMilestonesBlocked;
  }
}
